import { AccountCircle, VerifiedOutlined } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SocialRegister from 'src/components/Auth/SocialRegister';
import FilledButton from 'src/components/Buttons/FilledButton';
import { FormikCheckBox, FormikForm, FormikPasswordField, FormikTextField } from 'src/components/Forms/FormikForm';
import Identity from 'src/components/Identity';
import Instructions from 'src/components/Instructions';
import WithHelp from 'src/components/Instructions/WithHelp';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { CountdownTimer } from 'src/components/Timer/Countdown';
import { Body2, H5 } from 'src/components/Typography';
import { IDENTITY } from 'src/consts/account';
import CountdownContext from 'src/context/CountdownContext';
import CountdownProvider from 'src/providers/CountdownProvider';
import TimerProvider from 'src/providers/TimerProvider';
import { setUser } from 'src/redux/containers/User';
import { challengeIdent, registerUser, verifyIdent } from 'src/services/auth.service';
import generateUsername from 'src/utilities/generateUsername';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useInviteHook from 'src/utilities/hooks/useInviteHook';
import useLoading from 'src/utilities/hooks/useLoadingHook';
import * as yup from 'yup';

function RegisterForm({ account, invite, showVerification, showRegistration }) {
  const [, setLoading] = useLoading();

  const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};

  const [AlertView, { setAlert }] = useAlertView();

  const changeSet = {
    type: [
      type,
      yup.string().required('Identity type is required!'),
    ],
    ident: [
      ident,
      yup.string().test({
        name: 'valid_identity',
        test: (value, ctx) => {
          // console.log('Testing', value, ctx.parent.type);
          switch (ctx.parent.type) {
            case IDENTITY.EMAIL: {
              if (!value) {
                return ctx.createError({ path: 'identity', message: 'Email is required!' });
              }
              const valid = yup.string().email().isValidSync(value);
              // console.log(' -->', value, valid);
              if (!valid) {
                return ctx.createError({ path: 'identity', message: 'Invalid email!' });
              }
              break;
            }
            case IDENTITY.PHONE: {
              if (!value) {
                return ctx.createError({ path: 'identity', message: 'Phone number is required!' });
              }
              const valid = yup.string().phone().isValidSync(value);
              // console.log(' -->', value, valid);
              if (!valid) {
                return ctx.createError({ path: 'identity', message: 'Invalid phone number!' });
              }
              break;
            }
            default: {
              break;
            }
          }
          return true;
        },
      }),
    ],
  };

  const submit = async (values) => {
    // if (signed && account) {
    //   // Check if this is the same account..
    //   if (account.ident === values.ident) {
    //     showRegistration(account, { token: signed });
    //     return;
    //   }
    // }
    // Now send this identity request for verification..
    setLoading(true);

    try {
      const response = await challengeIdent({
        ...values,
        invite,
      });
      const { otp, name, identity } = response;
      if (otp) {
        // Move to the verification step
        showVerification(values);
      } else {
        // Move to the registration step
        showRegistration({ ...values, name, identity });
      }
    } catch (e) {
      console.log('Failed to send verification code!', e);
      setAlert({
        severity: 'error',
        title: 'Failed to send verification code',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormikForm
        changeSet={changeSet}
        onSubmit={(values) => submit(values)}
      >
        <Stack spacing={4}>
          <Instructions>
            Enter your email address or phone number to start the registration process.
          </Instructions>
          <Identity type="type" name="ident" nopasskey setAlert={setAlert} disbaled={invite} />

          <AlertView />

          <FilledButton icon={<AccountCircle />} iconPosition="start" label="Continue" type="submit" />

        </Stack>
      </FormikForm>

      <Divider>or</Divider>

      <SocialRegister invite={invite} showRegistration={showRegistration} />
    </>
  );
}

function ResendCode({ resendCode }) {
  const { done } = useContext(CountdownContext);

  return (
    <Box>
      {!done && (
        <Body2>
          Please wait&nbsp;
          <CountdownTimer />
          , before re-requesting the code.
        </Body2>
      )}
      {done && (
        <Body2>
          Click&nbsp;
          <span className="resend" onClick={resendCode}>here</span>
          &nbsp;to resend the code.
        </Body2>
      )}
    </Box>
  );
}

function VerificationForm({ account, changeIdentity, showRegistration }) {
  const [ ,setLoading] = useLoading();
  const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};

  const [AlertView, { setAlert }] = useAlertView();

  const [endTime, setEndTime] = useState(new Date(new Date().getTime() + (5 * 60 * 1000)));

  const changeSet = {
    otp: [
      '',
      yup.string().required('Verification code is required!'),
    ],
  };

  const handleSubmit = async (values) => {
    console.log('Verifying code', account, values);
    setLoading(true);
    setAlert(null);
    try {
      const response = await verifyIdent({
        ...account,
        otp: values.otp,
      });
      console.log('Verification response', response);
      // Show the response
      showRegistration({ ...account, ...response });
    } catch (e) {
      console.log('Failed to verify!', e);
      setAlert({
        severity: 'error',
        title: 'Failed to verify',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    setLoading(true);
    setAlert(null);
    try {
      const response = await challengeIdent(account);
      console.log('Resending code, and restarting timer', response);
      setEndTime(new Date(new Date().getTime() + (5 * 60 * 1000)));
    } catch (e) {
      console.log('Failed to send verification code!', e);
      setAlert({
        severity: 'error',
        title: 'Failed to send verification code',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Stack spacing={4}>

        <FlexBox className="identityDisplay" justifyContent="space-between">
          <Body2>{ident}</Body2>
          <H5 onClick={changeIdentity} className="changeButton">Change</H5>
        </FlexBox>

        {type === IDENTITY.EMAIL && (<Instructions instruction="Check email">Please enter the verification code sent to your email address.</Instructions>)}
        {type === IDENTITY.PHONE && (<Instructions instruction="Check SMS">Please enter the verification code sent to your phone.</Instructions>)}

        <FormikTextField name="otp" label="Verification Code" fullWidth required />

        <AlertView />

        <FilledButton icon={<VerifiedOutlined />} iconPosition="start" label="Submit" type="submit" />

        <TimerProvider>
          <CountdownProvider end={endTime}>
            <ResendCode resendCode={resendCode} />
          </CountdownProvider>
        </TimerProvider>

      </Stack>
    </FormikForm>
  );
}

function RegistrationForm({ account, signed, invite, changeIdentity }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ ,setLoading] = useLoading();

  const { type, ident, code } = account ?? {};
  const { name, identity } = signed ?? {};

  const [AlertView, { setAlert }] = useAlertView();

  const passwords = {
    password: [
      '',
      yup.string().password('Password').required('Password is required!'),
    ],
    confirmPassword: [
      '',
      yup.string()
        .min(8, 'Must be at least 8 characters')
        .minUppercase(1, 'Must contain at least 1 uppercase character')
        .minNumbers(1, 'Must contain at least 1 number')
        .minSymbols(1, 'Must contain at least 1 symbol (excluding space)')
        .oneOf([yup.ref('password'), null], ' Passwords must match'),
    ],
  };

  const changeSet = {
    username: [
      generateUsername(),
      yup.string().required('Username is required!'),
    ],
    name: [
      name ?? '',
      yup.string().required('Full name is required!'),
    ],
    email: [
      [IDENTITY.PHONE].includes(type) ? '' : ident,
      yup.string().email('Invalid email!').required('Email required!'),
    ],
    ...([IDENTITY.PHONE, IDENTITY.EMAIL].includes(type) ? passwords : {}),
    code: [
      code,
      yup.string()
        .nullable()
        .matches(/^(?=.{5,12}$)[\p{L}\d_ -]+$/u, 'Invalid code'),
    ],
    notify_events: [
      true,
      yup.boolean(),
    ],
    notify_marketing: [
      true,
      yup.boolean(),
    ],
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setAlert(null);
    try {
      console.log('Registering', values);
      const response = await registerUser({
        ...values,
        token: identity,
        invite,
      });
      console.log('Registration response', response);
      dispatch(setUser(response));
      // Now that token is set - we can redirect..
      navigate('/account/settings');
    } catch (e) {
      console.log('Failed to register!', e);
      setAlert({
        severity: 'error',
        title: 'Failed to register',
        content: e.message,
      });
      setLoading(false);
    }
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Stack spacing={4}>
        <FlexBox className="identityDisplay" justifyContent="space-between">
          <Body2>{ident}</Body2>
          <H5 onClick={changeIdentity} className="changeButton">Change</H5>
        </FlexBox>

        <H5>Configure account</H5>

        <WithHelp instruction="This is a publicly displayed, we do not recommend using your actual name here or email address. Other users will see this for your activity.">
          <FormikTextField name="username" label="Username" fullWidth required />
        </WithHelp>

        <WithHelp instruction="This will be used for any internal communications, such as invoices, organizations etc.">
          <FormikTextField name="name" label="Full name" fullWidth required />
        </WithHelp>

        {[IDENTITY.PHONE].includes(type) && (
          <FormikTextField name="email" label="Contact email" fullWidth required />
        )}

        {[IDENTITY.PHONE, IDENTITY.EMAIL].includes(type) && (
          <>
            <Divider />

            <WithHelp instruction="Password must contain at least one upper case letter, one number and one symbol.">
              <FormikPasswordField name="password" label="Password" fullWidth required autoComplete="password" />
            </WithHelp>

            <FormikPasswordField name="confirmPassword" label="Confirm Password" fullWidth required />

            <Divider />
          </>
        )}

        {!invite && (
          <WithHelp instruction="If you have a special registration code, enter that here.">
            <FormikTextField name="code" label="Special Registration Code" fullWidth autoComplete="code" />
          </WithHelp>
        )}

        <WithHelp instruction="Uncheck this box if you do not want to receive any system notifications (updates to assets, assignments etc.)">
          <FormikCheckBox name="notify_events" label="Platform notifications" />
        </WithHelp>

        <WithHelp instruction="Uncheck this box if you do not want to receive any marketing communications from us or our carefully curated partners.">
          <FormikCheckBox name="notify_marketing" label="Marketing communications" />
        </WithHelp>

        <AlertView />

        <FilledButton icon={<AccountCircle />} iconPosition="start" label="Register" type="submit" />
      </Stack>
    </FormikForm>
  );
}

function PasswordRegister() {
  const { account, setAccount, invite, signed, setSigned } = useInviteHook();

  console.log('Account', account);

  const [page, setPage] = useState(0);

  const changeIdentity = () => setPage(0);

  const showVerification = (account) => {
    setAccount(account);
    setSigned(undefined);
    setPage(1);
  };

  const showRegistration = (reg) => {
    const { type, ident, name, identity } = reg;
    setAccount({ type, ident });
    setSigned({ name, identity });
    setPage(2);
  };

  return (
    <>
      {page === 0 && (
        <RegisterForm account={account} invite={invite} showVerification={showVerification} showRegistration={showRegistration} />
      )}

      {page === 1 && (
        <VerificationForm account={account} changeIdentity={changeIdentity} showRegistration={showRegistration} />
      )}

      {page === 2 && (
        <RegistrationForm account={account} signed={signed} invite={invite} changeIdentity={changeIdentity} />
      )}
    </>
  );
}

export default PasswordRegister;
