import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withDialogCard from 'src/components/Cards/DialogCard';
import ExplorerLocation from 'src/components/ExplorerLocation';
import { explorerLocationFields } from 'src/components/ExplorerLocation/explorerLocationFields';
import ExplorerTable from 'src/components/ExplorerTable';
import { FormikForm } from 'src/components/Forms/FormikForm';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { paginationFields } from 'src/components/Pagination/paginationFields';
import styles from 'src/styles/content.styles';
import { useAllFacilitiesQuery } from 'src/utilities/hooks/useAllFacilitiesQuery';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function Explorer() {
	const changeSet = {
		location: explorerLocationFields({}),
		...paginationFields('12'),
	};
	const { data: facilities, isLoading } = useAllFacilitiesQuery();

	usePageTitle('Explorer');

	return (
		<Box sx={styles} className='root'>
			<FormikForm changeSet={changeSet}>
				<Stack spacing={3}>
					<ExplorerLocation
						name='location'
						label='Location'
						facilities={facilities}
						loading={isLoading}
					/>
					<ExplorerTable />
				</Stack>
			</FormikForm>
		</Box>
	);
}

export default withAppLayout(withDialogCard(Explorer));
