import { LogoutOutlined, Wifi, WifiOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import { Body1 } from 'src/components/Typography';
import { initNetworkChange, selectNetwork } from 'src/redux/containers/NetworkSwitch';
import useIsMobile from 'src/utilities/hooks/useIsMobile';

export function useNetworkSwitch() {
  const checked = useSelector(selectNetwork);
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (v) => dispatch(initNetworkChange(v)),
    [dispatch],
  );

  return [checked, handleChange];
}

/**
 * Renders a network switch component.
 *
 * @return {JSX.Element} The network switch component.
 */
export function NetworkSwitch() {
  const isMobile = useIsMobile();
  const [checked, handleChange] = useNetworkSwitch();
  return !isMobile ? (
    <IconCircleButton
      onClick={() => handleChange(!checked)}
    >
      {checked ? (
          <WifiOff color="error" />
        )
        : (
          <Wifi color="success" />
        )}
    </IconCircleButton>
  ) : null;
}

export function NetworkSwitchMobile() {
  const isMobile = useIsMobile();
  const [checked, handleChange] = useNetworkSwitch();
  return isMobile ? (
    <Box
      flexDirection={'row'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Body1 className="text">{checked ? 'Online' : 'Offline'}</Body1>
      <IconCircleButton
        onClick={() => handleChange(!checked)}
      >
        {checked ? (
            <WifiOff color="error" />
          )
          : (
            <Wifi color="success" />
          )}
      </IconCircleButton>
    </Box>
  ) : null;
}
