import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AssetID from 'src/components/AssetID';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import StorageChargeCodeProperties from 'src/components/ChargeCode/StorageChargeCode';
import Errors from 'src/components/Forms/FormErrors';
import { FormikForm } from 'src/components/Forms/FormikForm';
import FormWrapper from 'src/components/Layout/FormWrapper';
import { ASSET_STATUS, ASSET_TYPES } from 'src/consts/asset';
import { MEASURE, MEASURES } from 'src/consts/measures';
import { selectAccountChain, selectAccountPlatform } from 'src/redux/containers/User';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useAssetMutation from 'src/utilities/hooks/useAssetMutation';
import useMeasures from 'src/utilities/hooks/useMeasures';

function calcAlcohol(level, cfrom) {
	const { bl, la, abv, tcf = '1.000' } = level ?? {};

	if (abv && tcf && (bl || la)) {
		if (la) {
			return {
				la: cfrom(la, MEASURES.l_m),
				bl: cfrom(
					Number(la) / ((Number(abv) * Number(tcf)) / 100.0),
					MEASURES.l_m,
				),
			};
		}

		return {
			bl: cfrom(bl, MEASURES.l_m),
			la: cfrom((Number(bl) * Number(abv) * Number(tcf)) / 100.0, MEASURES.l_m),
		};
	}
	return {
		bl: (bl ? cfrom(bl, MEASURES.l_m) : undefined),
		la: (la ? cfrom(la, MEASURES.l_m) : undefined),
	};
}

function convertProperties(properties, cfrom) {
	return {
		...properties,
		...(properties?.liquid
			? {
					liquid: {
						...properties?.liquid,
						...(properties?.liquid?.level
							? {
									level: {
										...(properties?.liquid?.level ?? {}),
										...calcAlcohol(properties?.liquid?.level, cfrom),
										abv: cfrom(
											properties?.liquid?.level?.abv ?? 0,
											MEASURE.alc,
										),
										tcf: properties?.liquid?.level?.tcf ?? '1.000',
									},
								}
							: {}),
						...(properties?.liquid?.weight
							? {
									weight: {
										...(properties?.liquid?.weight ?? {}),
									},
								}
							: {}),
					},
				}
			: {}),
	};
}

function AssetForm({
	id,
	assetType,
	entity,
	changeSet,
	readonly = false,
	edit = false,
	onCancel,
	onBeforeSave,
	onSave,
	children,
}) {
	const { from: cfrom } = useMeasures();

	const [AlertView, { setAlert }] = useAlertView();

	const [mutate, { isLoading: isMutating = false }] = useAssetMutation();

	const platform = useSelector(selectAccountPlatform);
	const chain = useSelector(selectAccountChain);

	const isAssetDefected = useMemo(
		() => entity?.asset_status === ASSET_STATUS.DEFECTED,
		[entity],
	);

	const handleSubmit = async (values) => {
		setAlert(null);
		try {
			const updated = onBeforeSave ? onBeforeSave(values) : values;
			console.log('Submitting', entity, updated);
			const properties = convertProperties(updated?.properties ?? {}, cfrom);
			const response = await mutate(id, {
				...entity,
				...updated,
				properties,
				asset_type: assetType,
				platform,
				chain
			});
			console.log('Saved item', response);
			onSave(response);
			setAlert({
				severity: 'success',
				title: id ? 'Updated' : 'Created',
				content: `Asset ${response.rw_asset_id} has been ${id ? 'updated' : 'created'}.`,
			});
		} catch (err) {
			setAlert({
				severity: 'error',
				title: id ? 'Failed to Update' : 'Failed to Add',
				content: err.message,
			});
		}
	};

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			edit={edit}
			readonly={readonly}
		>
			<FormWrapper>
				<AssetID type={assetType} label='ID.' />

				{children}

				{!readonly && [ASSET_TYPES.cask, ASSET_TYPES.ibc].includes(assetType) && (
					<StorageChargeCodeProperties name='charge_code' />
				)}

				<AlertView />

				{!readonly && !isAssetDefected && (
					<>
						<Errors />
						<Box className='action-buttons'>
							{onCancel && (
								<TextButton
									size='small'
									handleClick={onCancel}
									color='secondary'
									disabled={isMutating}
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small' disabled={isMutating}>
								{id?.length ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</>
				)}
			</FormWrapper>
		</FormikForm>
	);
}

export default AssetForm;
