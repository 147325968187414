import { FormControl, InputLabel, Select } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useMemo, useState } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { SKU_TYPES } from 'src/consts/sku';
import useMeasures from 'src/utilities/hooks/useMeasures';
import NumberWidget from './NumberWidget';

function SKUWidget({ title, skus = [] }) {
	const { getMeasure } = useMeasures();

	const [sku, setSku] = useState(skus?.[0]?.name ?? '');

	const data = useMemo(
		() => (skus ?? [])?.find((item) => item?.sku_name === sku) ?? skus[0] ?? {},
		[sku, skus],
	);

	const unit = useMemo(() => {
		if (data?.sku_type === SKU_TYPES.TRACKED) return null;
		return getMeasure(data?.unit)?.value ?? null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const total_sku_used = useMemo(() => {
		const total = Number(data?.total_sku_delivered ?? 0);
		const available = Number(data?.total_sku_available ?? 0);
		return total - available;
	}, [data]);

	return (
		<Stack className='content-section'>
			<H5 className='content-section-title'>{title}</H5>
			<Stack className='content-section-content'>
				<FlexBox className='headline-widget-input'>
					<FormControl fullWidth variant='filled'>
						<InputLabel id='sku' shrink>
							SKU
						</InputLabel>
						<Select
							native
							fullWidth
							label='SKU'
							labelId='sku'
							value={sku}
							onChange={(event) => setSku(event.target.value)}
						>
							{skus?.map(({ sku_name }) => (
								<option key={sku_name ?? 'none'} value={sku_name}>
									{sku_name}
								</option>
							))}
						</Select>
					</FormControl>
				</FlexBox>

				<Stack spacing={1}>
					<NumberWidget
						value={data?.total_sku_delivered ?? '0'}
						label='Delivered'
						unit={unit}
					/>
					<NumberWidget
						value={data?.total_sku_available ?? '0'}
						label='Available'
						unit={unit}
					/>
					<NumberWidget
						value={data?.total_sku_reserved ?? '0'}
						label='Reserved'
						unit={unit}
					/>
					<NumberWidget
						value={total_sku_used ?? '0'}
						label='Used'
						unit={unit}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
}

export default SKUWidget;
