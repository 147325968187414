import { LocalOfferOutlined, Scale } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import LiquidIcon from 'src/components/icons/Liquid';
import { MEASURE } from 'src/consts/measures';
import useMeasures from 'src/utilities/hooks/useMeasures';

function AmountDisplay({ label, amount, unit }) {
	const { getMeasure, to: cto } = useMeasures();

	if (!amount) return;

	return (
		<>
			{[MEASURE.l_s, MEASURE.l_m].includes(unit) && (
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						gap: '0.25rem',
					}}
				>
					<LiquidIcon style={{ width: '12px', height: '14px' }} />
					<Body3>
						<Bolder>{label ?? 'Amount'}</Bolder>
						&nbsp;
						{cto(amount, unit)}
						{getMeasure(unit)?.value}
					</Body3>
				</Box>
			)}
			{unit === MEASURE.alc && (
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						gap: '0.25rem',
					}}
				>
					<LiquidIcon style={{ width: '12px', height: '14px' }} />
					<Body3>
						{cto(amount, unit)}
						{getMeasure(unit)?.value}
					</Body3>
				</Box>
			)}
			{[MEASURE.w_s, MEASURE.w_m, MEASURE.w_l].includes(unit) && (
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						gap: '0.25rem',
					}}
				>
					<Scale style={{ width: '12px', height: 'auto' }} />
					<Body3>
						<Bolder>{label ?? 'Weight'}</Bolder>
						&nbsp;
						{cto(amount, unit)}
						{getMeasure(unit)?.value}
					</Body3>
				</Box>
			)}
			{(!unit || unit === MEASURE.count) && (
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						gap: '0.25rem',
					}}
				>
					<LocalOfferOutlined style={{ width: '12px', height: 'auto' }} />
					<Body3>
						<Bolder>{label ?? 'Items'}</Bolder>
						&nbsp;
						{amount}
					</Body3>
				</Box>
			)}
		</>
	);
}

export default AmountDisplay;
