import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssetListComponentV2 } from 'src/components/AssetListItem';
import { SearchAssetListItemComponent } from 'src/components/AssetListItem/SearchAssetListItemComponent';
import withSmallDialogCard from 'src/components/Cards/SmallDialogCard';
import { FlexBox } from 'src/components/Layout/FlexBox';
import Pagination from 'src/components/Pagination';
import ListItemSkeleton from 'src/components/sekeletons/ListItemSkeleton';
import { Body3, H5 } from 'src/components/Typography';
import {
	ASSET_EDIT_URL,
	ASSET_TYPES,
	ASSET_TYPES_REVERSE,
} from 'src/consts/asset';
import styles from 'src/styles/content.styles';
import { useCustomerAssetQueryPaginated } from 'src/utilities/hooks/useCustomerAssetQuery';

function CustomerAssets({ id, type, title = 'Customer Assets' }) {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		return {
			limit: Number(pageSize),
			id,
			type,
		};
	}, [id, pageSize, type]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useCustomerAssetQueryPaginated(reqParams);

	const goToItem = (params) => {
		const id = params?.path ?? params?.asset_id ?? 'new';
		const route = ASSET_EDIT_URL?.[params?.token_type];
		navigate(`${route}/${id}`);
	};

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<Stack spacing={1}>
				<H5>{title}</H5>
				{!isLoading && page && page.length > 0 && (
					<Stack spacing={0.5}>
						<Pagination
							hasNextPage={hasNext}
							hasPrevPage={hasPrev}
							onPressNextPage={loadNext}
							onPressPrevPage={loadPrev}
							onChangePageSize={setPageSize}
							defaultPageSize={pageSize}
						/>
						<Stack className='list'>
							{page.map((i) => (
								<AssetListComponentV2
									key={i.path}
									item={i}
									buttonIcon={<ArrowForward />}
									onClick={() => goToItem(i)}
								>
									<SearchAssetListItemComponent item={i} />
								</AssetListComponentV2>
							))}
						</Stack>
					</Stack>
				)}
				{!isLoading && (!page || page.length === 0) && (
					<Body3>Nothing yet.</Body3>
				)}
				{isLoading && (
					<Stack spacing={0.5}>
						<FlexBox justifyContent='flex-end' gap='2rem'>
							<Skeleton variant='rectangular' width={45} height={45} />
							<Skeleton variant='rectangular' width={60} height={35} />
						</FlexBox>
						<Stack className='list'>
							<ListItemSkeleton type={ASSET_TYPES_REVERSE[ASSET_TYPES.cask]} />
							<ListItemSkeleton type={ASSET_TYPES_REVERSE[ASSET_TYPES.cask]} />
							<ListItemSkeleton type={ASSET_TYPES_REVERSE[ASSET_TYPES.cask]} />
						</Stack>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}

export default withSmallDialogCard(CustomerAssets);
