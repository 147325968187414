import { useContext } from 'react';
import { FormikDatePicker, FormikTextField } from 'src/components/Forms/FormikForm';
import FormikContext from 'src/context/FormikContext';

export default function RequestID({ label }) {
	const { edit } = useContext(FormikContext);
	return (
		<>
			<FormikTextField
				name='rw_request_id'
				label={label}
				fullWidth
				disabled={edit}
			/>
			<FormikDatePicker name='request_created' label='Create Date' />
			<FormikDatePicker name='request_due' label='Due Date' />
		</>
	);
}
