import mapToOptions from 'src/utilities/mapToOptions';

export const FACILITY_TYPE = {
	WAREHOUSE: 'WAREHOUSE',
	YARD: 'YARD',
};

export const FACILITY_STATUS = {
	ACTIVE: 'active',
};

export const FACILITY_STATUS_OPTIONS = mapToOptions(FACILITY_STATUS);
export const FACILITY_TYPES_OPTIONS = mapToOptions(FACILITY_TYPE);

export const LOCATION_PRINT_OPTIONS = [
	{ label: 'All Locations', value: 'a' },
	{ label: 'Facilities Only', value: 'f' },
	{ label: 'Bays Only', value: 'b' },
	{ label: 'Rows Only', value: 'r' },
];

export const LOCATION_PRINT_MAP = {
	all: 'a',
	facilities: 'f',
	bays: 'b',
	rows: 'r',
};
