import { APP_API_GATEWAY } from 'src/consts/config';
import fetchPost from 'src/utilities/fetchPost';

const BASE = `${APP_API_GATEWAY}/pw/sensor`;
/** used to get sensor data by sensor id */
export const getSensorData = async (request, headers = {}) =>
	fetchPost(`${BASE}/data`, headers, request);
/** used to upsert sensor coverage locations */
export const upsertSensor = async (request, headers = {}) =>
	fetchPost(`${BASE}/upsert`, headers, request);
/** used to upsert gateway info */
export const upsertGateWay = async (request, headers = {}) =>
	fetchPost(`${BASE}/upsert-gateway`, headers, request);
/** use to list company gateway list with sensors */
export const listGateWay = async (request, headers = {}) =>
	fetchPost(`${BASE}/list`, headers, request);
