import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from './consts';

export const initialState = {
	loading: false, // used to request change
	isOnline: true, // committed state
};

export const slice = createSlice({
	name: SLICE,
	initialState,
	reducers: {
		initNetworkChange(state) {
			state.loading = true;
		},
		setNetwork(state, { payload }) {
			state.isOnline = payload;
			state.loading = false;
		},
	},
});

export const { reducer, actions } = slice;
export const { initNetworkChange, setNetwork } = actions;
