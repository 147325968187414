import { useContext } from 'react';
import { FormikDatePicker, FormikTextField } from 'src/components/Forms/FormikForm';
import FormikContext from 'src/context/FormikContext';

export default function AssetID({ label }) {
	const { edit } = useContext(FormikContext);

	return (
		<>
			<FormikTextField
				name='rw_asset_id'
				label={label}
				fullWidth
				disabled={edit}
				required
			/>
			<FormikDatePicker name='asset_checkin_date' label='Date' required />
		</>
	);
}
