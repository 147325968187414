import IconButton from '@mui/material/IconButton';
import React from 'react';

const styles = {
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justifyItems: 'center',
		'&:hover': {
			backgroundColor: 'palette.secondary.dark',
		},
	},
	small: {
		minWidth: '24px',
		maxWidth: '24px',
		maxHeight: '24px',
		minHeight: '24px',
	},
	medium: {
		minWidth: '36px',
		maxWidth: '36px',
		maxHeight: '36px',
		minHeight: '36px',
	},
	large: {
		minWidth: '48px',
		maxWidth: '48px',
		maxHeight: '48px',
		minHeight: '48px',
	},
};

function IconCircleButton({
	className,
	children,
	size = 'medium',
	onClick = () => {},
	help,
	color = 'primary',
	disabled = false,
	sx = {},
	...rest
}) {
	return (
		<IconButton
			sx={{ ...styles.root, ...styles[size], ...sx }}
			className={className}
			onClick={onClick}
			size={size}
			aria-label={help}
			color={color}
			disabled={disabled}
			{...rest}
		>
			{children}
		</IconButton>
	);
}

export default IconCircleButton;
