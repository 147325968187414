import { Add, ContentCopyOutlined, Delete, Edit } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import BayModal from 'src/components/admin/Facilities/BayModal';
import FilledButton from 'src/components/Buttons/FilledButton';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikSelect, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import FormWrapper from 'src/components/Layout/FormWrapper';
import { Body1, H5, Overline } from 'src/components/Typography';
import {
	FACILITY_STATUS,
	FACILITY_STATUS_OPTIONS,
	FACILITY_TYPE,
	FACILITY_TYPES_OPTIONS,
} from 'src/consts/facility';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'src/utilities/debounce';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useFacilitiesMutation from 'src/utilities/hooks/useFacilitiesMutation';
import * as yup from 'yup';

function BayItem({ item, remove, edit }) {
	const [bay, rows, levels, editing] = item;

	return (
		<Stack className='listItem'>
			<Box className='listContent'>
				<Stack className='listForm' direction='row' spacing='3rem'>
					<Stack alignItems='center'>
						<Body1>
							<strong>{bay}</strong>
						</Body1>
						<Overline>Bay</Overline>
					</Stack>
					<Stack alignItems='center'>
						<Body1>
							<strong>{rows}</strong>
						</Body1>
						<Overline>Rows</Overline>
					</Stack>
					<Stack alignItems='center'>
						<Body1>
							<strong>{levels}</strong>
						</Body1>
						<Overline>Levels</Overline>
					</Stack>
				</Stack>
			</Box>

			<Stack className='listButtons'>
				<IconCircleButton onClick={edit}>
					<Edit />
				</IconCircleButton>
				{!editing && (
					<IconCircleButton onClick={remove}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Stack>
	);
}

function Facility({ entity, users, onSave, cancel }) {
	const [AlertView, { setAlert }] = useAlertView();

	const editing = useMemo(() => !!entity?.facility_name, [entity]);
	const title = useMemo(() => (editing ? 'Update Facility' : 'New Facility'), [editing]);
	const bayList = useMemo(() => (entity?.bays ?? []).map((v) => [...v, 1]), [entity]);

	const userOptions = useMemo(
		() => users.map((u) => ({ label: u.account_name, value: u.account_id })),
		[users],
	);

	const [bays, setBays] = useState([]);
	const [bay, setBay] = useState(null);

	useEffect(() => setBays(bayList), [bayList]);

	const [mutate, { isLoading }] = useFacilitiesMutation();

	const changeSet = {
		facility_name: [
			entity?.facility_name ?? '',
			yup.string().min(1).required('Facility name is required!'),
		],
		facility_type: [entity?.facility_type ?? FACILITY_TYPE.WAREHOUSE, yup.string()],
		facility_status: [entity?.facility_status ?? FACILITY_STATUS.ACTIVE, yup.string()],
		facility_manager_id: [
			entity?.facility_manager_id ?? '',
			yup.string().required('Please select a designated facility manager!'),
		],
	};

	const handleSubmit = async (values, { resetForm }) => {
		console.log('Submitting', values);
		try {
			const response = await mutate({
				...values,
				bays,
			});
			console.log('Saved item', response);
			onSave(response);
			resetForm({ values: {} });
		} catch (err) {
			setAlert({
				severity: 'error',
				title: editing ? 'Failed to Update' : 'Failed to Add',
				content: err.message,
			});
		}
	};

	const upsertBay = useCallback(
		(entry, index) => {
			console.log('Upsert', entry, index, bays);
			const [_bay] = entry;
			const v = [...bays];
			if (index !== null && index !== undefined) {
				// updating
				const [eb, , , ed] = v[index];
				if (ed === 1) {
					if (eb !== _bay) {
						throw new Error('Bay cannot be updated!');
					}
				}
				v[index] = entry;
			} else {
				// inserting new, check bay isn't there
				const existing = v.find(([b]) => b === _bay);
				// if this exists
				if (existing) {
					throw new Error('Bay already exists!');
				}
				v.push(entry);
			}
			// v.sort((l, r) => l[0].localeCompare(r[0]));
			debounce(() => setBays(v), 50);
		},
		[bays],
	);

	const removeBay = (entry) => {
		const [_bay, , , _editing] = entry;
		if (_editing) return;
		setBays((v) => v.filter(([b]) => b !== _bay));
	};

	const clone = useCallback(() => {
		if (bays && bays.length > 0) {
			const v = [...bays];

			// Grab the last numeric entry - and add to it, else append a letter to the last bay..
			const lastNum = v.reverse().find((b) => {
				try {
					return !Number.isNaN(Number(b[0]));
				} catch (e) {
					return false;
				}
			});
			if (lastNum) {
				const nextBay = Number(lastNum[0]) + 1;
				upsertBay([`${nextBay}`, lastNum[1], lastNum[2], 0], null);
			} else {
				// Just get the last one and append a letter to it..
				const last = v[0];
				const bayName = last[0];

				const regex = /^(.*)_(\d+)$/;
				const [, prefix, lastN] = bayName.match(regex) ?? [];

				if (lastN) {
					const nextNum = Number(lastN) + 1;
					upsertBay([`${prefix}_${nextNum}`, last[1], last[2], 0], null);
					return;
				}

				if (prefix) {
					const regex2 = /^(.*?)_*$/;
					const [, cleanPrefix] = prefix.match(regex2) ?? [];
					upsertBay([`${cleanPrefix}_1`, last[1], last[2], 0], null);
				} else {
					upsertBay([`${bayName}_1`, last[1], last[2], 0], null);
				}
			}
		}
	}, [upsertBay]);

	return (
		<>
			<FormWrapper>
				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack className='form' spacing='2rem'>
						<H5>{title}</H5>
						<FlexBox alignItems='top'>
							<FormikTextField
								disabled={editing}
								label='Facility name'
								name='facility_name'
								fullWidth
							/>
							<FormikSelect
								label='Facility manager'
								name='facility_manager_id'
								options={userOptions}
								fullWidth
							/>
						</FlexBox>
						<FlexBox alignItems='top'>
							<FormikSelect
								disabled={editing}
								label='Facility type'
								name='facility_type'
								options={FACILITY_TYPES_OPTIONS}
								fullWidth
							/>
							<FormikSelect
								label='Facility status'
								name='facility_status'
								options={FACILITY_STATUS_OPTIONS}
								fullWidth
							/>
						</FlexBox>

						<Divider className='divider' />

						<FlexBox>
							<H5>Bays</H5>
							<Stack direction='row' justifyContent='flex-end'>
								{bays && bays.length > 0 && (
									<IconCircleButton onClick={clone}>
										<ContentCopyOutlined />
									</IconCircleButton>
								)}
								<IconCircleButton
									onClick={() =>
										setBay([])
									}
								>
									<Add />
								</IconCircleButton>
							</Stack>
						</FlexBox>

						<Stack spacing={0} className='list'>
							{bays.map(
								(_bay, index) =>
									!bay && (
										<BayItem
											index={index}
											item={_bay}
											remove={() => removeBay(_bay)}
											edit={() => setBay([_bay, index])}
											key={`${index}_${_bay[0]}`}
										/>
									),
							)}
						</Stack>

						<AlertView />

						<Box className='action-buttons'>
							{cancel && (
								<TextButton
									size='small'
									handleClick={cancel}
									color='secondary'
									disabled={isLoading}
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small' disabled={isLoading}>
								Save
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</FormWrapper>

			<BayModal open={!!bay} item={bay} upsert={upsertBay} onClose={() => setBay(null)} />
		</>
	);
}

export default Facility;
