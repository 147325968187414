import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import Address from 'src/components/Company/Address';
import { FormikCheckBox, FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { H5 } from 'src/components/Typography';
import { selectAccountLocation, setUser } from 'src/redux/containers/User';
import { createCompany } from 'src/services/company.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import * as yup from 'yup';

function Company({ reload }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useLoadingHook();
  const [AlertView, { setAlert }] = useAlertView();

  const accountLocation = useSelector(selectAccountLocation);
  const { country_code = '' } = accountLocation ?? {};

  const onSubmit = useCallback(async (values) => {
    console.log('Saving company', values);
    setLoading(true);
    try {
      const response = await createCompany(values);
      console.log('Created company', response);

      // This will have an updated set of companies..
      dispatch(setUser(response));

      reload();
    } catch (e) {
      console.log('Failed to create company!', e);
      setAlert({
        severity: 'error',
        title: 'Failed create',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  }, [reload]);

  const changeSet = {
    // company identifiers
    name: ['', yup.string().required('Company name is required!')],
    registration: ['', yup.string().required('Company registration is required!')],
    excise_id: ['', yup.string()],
    compliance_id: ['', yup.string()],
    wowgr: [false, yup.boolean()],
    duty_req: [false, yup.boolean()],
    // settings
    imperial_units: [false, yup.boolean()],
    //  address
    line1: ['', yup.string().required('Address is required!')],
    line2: ['', yup.string()],
    line3: ['', yup.string()],
    city: ['', yup.string().required('City is required!')],
    state: ['', yup.string()],
    country: [country_code, yup.string().required('Country is required!')],
    postCode: ['', yup.string().required('Post code is required!')],
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={onSubmit}
    >
      <Stack className="form" spacing="2rem">
        <Stack spacing="1.5rem">
          <H5>Details</H5>
          <FormikTextField name="name" label="Company name" fullWidth required />
          <FormikTextField name="registration" label="Registration id" required />
          <FormikTextField name="excise_id" label="Excise/Tax id" />
          <FormikTextField name="compliance_id" label="Compliance reporting id" />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Memberships</H5>
          <Instructions>
            If you have inventory in the UK, current regulations may require your company to registered under&nbsp;
            WOWGR (if you are UK based) or alternatively have Duty Representation.
            <br />
            Please indicate if you have or require this.
          </Instructions>
          <FormikCheckBox name="wowgr" label="Company is registered for WOWGR" />
          <FormikCheckBox name="duty_req" label="Require Duty Representation" />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Settings</H5>
          <Instructions>
            The platform uses Metric units by default, check this to enable Imperial Units (US) for display.
          </Instructions>
          <FormikCheckBox name="imperial_units" label="Imperial Units" />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Address</H5>
          <Address />
        </Stack>


        <AlertView />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={reload}
            color='secondary'
            disabled={isLoading}
          >
            Cancel
          </TextButton>

          <FilledButton type='submit' size='small' disabled={isLoading}>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default Company;
