import { useCallback, useContext, useMemo } from 'react';
import { clientFields } from 'src/components/Client/clientFields';
import { FormikTextField } from 'src/components/Forms/FormikForm';
import useInventorySelectorHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';
import * as yup from 'yup';

function Properties() {
	const { readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path='properties'>
				<FormikTextField name='invoice_id' label='Invoice Id' />
			</FormikProvider>
		)
	);
}

function propertyFields(fields = {}) {
	const { invoice_id = '' } = fields;

	return {
		invoice_id: [invoice_id, yup.string().required('Please enter invoice id')],
	};
}

function InvoiceForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();
	const source_filter = {
		sku_types: [SKU_TYPES.SERVICE],
	};

	const [[sourceSkus], , initSources, SourceInventory, SourceModals] =
		useInventorySelectorHook({
			title: 'Sources',
			filter: source_filter,
			submitForm,
			SKUModal: SourceSKUModal,
		});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
			properties: propertyFields(initialValues?.properties),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				skus: entity?.sku_sources,
			});
		},
		[initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_sources: sourceSkus,
			};
		},
		[sourceSkus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Invoice'
				requestType={REQUEST_TYPES.invoice}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />
				<Properties />
				<FormSubmitter />
			</RequestForm>
			<SourceModals />
		</>
	);
}

export default withAppLayout(InvoiceForm, { title: 'Invoice Form' });
