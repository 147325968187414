import { Box } from '@mui/material';
import { useState } from 'react';
import CustomerAssets from 'src/components/Company/admin/Customers/components/CustomerAssets';
import Partners from 'src/components/Company/admin/Partners';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { CLIENT_TYPE } from 'src/consts/contacts';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function CustomerPage() {
	const [partner, setPartner] = useState(null);
	usePageTitle('Manage Partners');
	return (
		<Box className='adaptive-content'>
			<Partners partner={partner} setPartner={setPartner} />{' '}
			{!!partner?.company_id && (
				<CustomerAssets
					type={CLIENT_TYPE.COMPANY}
					id={partner?.company_id}
					title='Partner Assets'
				/>
			)}
		</Box>
	);
}

export default withAppLayout(CustomerPage);
