import { Alert } from '@mui/material';
import { H4 } from 'src/components/Typography';
import React, { useCallback, useState } from 'react';

// this can be used as standalone component
export function AlertView({ alert, ...props }) {
	if (!alert) return;

	return (
		<Alert severity={alert.severity} {...props}>
			{alert.title && <H4 sx={{ mb: 1 }}>{alert.title}</H4>}
			{alert.content}
		</Alert>
	);
}

// and also can be used as hook
const useAlertView = () => {
	const [alert, setAlert] = useState(null);

	// memoize the component
	const Component = useCallback((props) => <AlertView alert={alert} {...props} />, [alert]);

	return [Component, { setAlert }];
};

export default useAlertView;
