import { enqueueSnackbar } from 'notistack';
import { setNetwork } from './slice';
/**
 * Listens for redux network change action and send it to service worker
 * so that we could sync state changes with service worker.
 *
 * @param {Object} action - The action object containing the payload.
 * @param {Object} listenerApi - The listener API object.
 * @return {Promise<void>} - A promise that resolves when the function completes.
 */
export const networkListener = async (action, listenerApi) => {
	// Can cancel other running instances
	listenerApi.cancelActiveListeners();
	const originalPayload = action.payload;
	// we can not go online without internet
	if (originalPayload && !window.navigator.onLine) {
		enqueueSnackbar('Internet connection was not found!', {
			variant: 'error',
		});
		action.payload = false;
	}
	// we can not go offline without service worker
	if (!originalPayload && !navigator?.serviceWorker?.controller) {
		enqueueSnackbar('Offline support is not enabled!', {
			variant: 'error',
		});
		action.payload = true;
	}
	if (navigator?.serviceWorker?.controller) {
		// service worker will update listeners
		navigator.serviceWorker.ready.then((registration) => {
			registration.active.postMessage(setNetwork(action.payload));
		});
	} else {
		listenerApi.dispatch(setNetwork(action.payload));
	}
};
