import withDialogCard from 'src/components/Cards/DialogCard';
import Roles from 'src/components/Company/admin/Roles';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function RolesPage() {
  usePageTitle('Manage Roles');

  return (
    <Roles />
  );
}

export default withAppLayout(withDialogCard(RolesPage));
