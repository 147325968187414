import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { applyChangeset, unflattenChanges } from 'json-diff-ts';
import set from 'lodash.set';
import { useCallback, useMemo, useState } from 'react';
import checkDifference from 'src/utilities/checkDifference';
import useSyncChangesMutation from 'src/utilities/hooks/useSyncChangesMutation';
import FilledButton from '../Buttons/FilledButton';
import { FlexBox } from '../Layout/FlexBox';
import { Body2, H4 } from '../Typography';

const styles = {
	marginTop: '0.5rem',
	'.radio-content': {
		background: '#FEECF1',
		padding: '1rem 1.8rem',

		'&.add': {
			background: '#d7eade',
		},
		'&.remove': {
			background: '#fffbeb',
		},
	},

	'.sync-changes__content': {
		position: 'relative',
	},

	'.sync-changes__name': {
		padding: '0 1.8rem 0.5rem',
	},

	'.sync-changes__to': {
		fontWeight: '600',
	},

	'.sync-changes__name:before': {
		position: 'absolute',
		content: '""',
		display: 'block',
		width: '1.5rem',
		height: '2px',
		top: '0.63rem',
		left: '0',
		background: 'red',
	},
	'.add.sync-changes__name:before': {
		background: '#29ab23',
	},
	'.remove.sync-changes__name:before': {
		background: '#e6b575',
	},
};

const Label = ({ name, user }) => (
	<Stack>
		<H4>{name}</H4>
		<Body2>Added by {user}</Body2>
	</Stack>
);

const Item = ({
	defaultValue,
	onChange,
	updated_user,
	created_user,
	oldValue,
	newValue,
}) => {
	const [value, setValue] = useState(defaultValue);
	const handleRadio = useCallback((val) => {
		setValue(val);
		onChange(val);
	}, []);
	return (
		<RadioGroup
			aria-labelledby='demo-controlled-radio-buttons-group'
			name='controlled-radio-buttons-group'
			defaultValue={defaultValue}
			value={value}
			sx={{ gap: '0.5rem' }}
			onChange={({ target }) => handleRadio(target.value)}
		>
			<FormControlLabel
				value={oldValue}
				control={<Radio sx={{ padding: '0 0.5rem' }} size='small' />}
				label={<Label name={oldValue?.toString()} user={created_user} />}
			/>
			<FormControlLabel
				value={newValue}
				control={<Radio sx={{ padding: '0 0.5rem' }} size='small' />}
				label={<Label name={newValue?.toString()} user={updated_user} />}
			/>
		</RadioGroup>
	);
};

const SubTitle = ({ item }) => {
	const { type, value, oldValue } = item;
	const _value = useMemo(() => {
		if (
			item?.path?.includes('$.child_assets') ||
			item?.path?.includes('$.sources') ||
			item?.path?.includes('$.destinations')
		)
			return value['rw_asset_id'];
		if (
			item?.path?.includes('$.sku_destinations') ||
			item?.path?.includes('$.sku_sources')
		)
			return value['sku_name'];
		if (item?.path?.includes('$.assignees')) {
			return value[1] ?? '';
		}
		return typeof value === 'object' ? '' : value?.toString();
	}, [value]);
	const _oldValue = useMemo(() => {
		return typeof oldValue === 'object' ? '' : oldValue?.toString();
	}, [value]);
	return (
		<>
			{type === 'UPDATE' && (
				<Body2>
					{_oldValue} <span className='sync-changes__to'>to</span> {_value}
				</Body2>
			)}
			{type === 'ADD' && <Body2>Add new value: {_value}</Body2>}
			{type === 'REMOVE' && <Body2>Remove: {_value}</Body2>}
		</>
	);
};

const ChangedItem = ({ item, created_user, updated_user, setData }) => {
	const onChange = useCallback(
		(val) => {
			console.log('val', val, item.type, item.value, String(item.value) === val);
			if (
				(item.type === 'REMOVE' && val === 'Remove') ||
				(item.type === 'ADD' && val === 'Add') ||
				(item.type === 'UPDATE' && String(item.value) === val)
			) {
				setData((data) => [...data, item]);
			} else if (
				(item.type === 'ADD' && val === 'Do not add') ||
				(item.type === 'REMOVE' && val === 'Do not remove') ||
				(item.type === 'UPDATE' && item.value !== val)
			) {
				setData((data) => [
					...data.filter((i) => !(i.type === item.type && i.key === item.key)),
				]);
			}
		},
		[setData],
	);

	const isUpdate = item.type === 'UPDATE';
	const isRemove = item.type === 'REMOVE';
	const isAdd = item.type === 'ADD';

	return (
		<Stack className='sync-changes__content'>
			<Stack
				className={`sync-changes__name ${isRemove ? 'remove' : isAdd ? 'add' : ''}`}
			>
				<H4 sx={{ color: isRemove ? '#dc965a' : isAdd ? '#29ab23' : 'red' }}>
					{item.path}
				</H4>
				<SubTitle item={item} />
			</Stack>
			<Stack
				spacing={1}
				className={`radio-content ${isRemove ? 'remove' : isAdd ? 'add' : ''}`}
			>
				<Body2>Please select the correct data to solve this conflict</Body2>
				<FormControl>
					{isUpdate && (
						<Item
							onChange={onChange}
							defaultValue={item.oldValue}
							created_user={created_user}
							updated_user={updated_user}
							oldValue={item.oldValue}
							newValue={item.value}
						/>
					)}
					{isAdd && (
						<Item
							defaultValue={'Do not add'}
							onChange={onChange}
							created_user={created_user}
							updated_user={updated_user}
							oldValue={'Do not add'}
							newValue={'Add'}
						/>
					)}
					{isRemove && (
						<Item
							onChange={onChange}
							defaultValue={'Do not remove'}
							created_user={created_user}
							updated_user={updated_user}
							oldValue={'Do not remove'}
							newValue={'Remove'}
						/>
					)}
				</FormControl>
			</Stack>
		</Stack>
	);
};

const asset_keys_to_remove = [
	'iteration',
	0,
	1,
	2,
	'accounts',
	'asset_checkin_date',
	'asset_uid',
	'audit_date_id',
	'blockchain_status',
	'chain',
	'errorMessage',
	'errorPayload',
	'error_message',
	'id',
	'local_status',
	'location',
	'platform',
	'rw_asset_key',
	'saveTemplate',
	'synced_with_tableau',
	'table',
	'templateName',
	'templateType',
	'update_on',
	'sku.sku_description',
	'sku.unit',
	'sku.size',
	'sku.sku_tags',
	'sku.capacity',
	'sku.sku_id',
	'sku.path',
	'sku.sku_image',
	'sku.create_date',
	'sku.count',
	'sku.creator_username',
	'sku.creator_id',
	'sku.abv',
	'sku.id',
	// 'value_code.enable',
	'update_account_id',
	'update_account_username',
	'creator_id',
	'creator_username',
];
const request_keys_to_remove = [
	'request_nonce',
	'address',
	'iteration',
	'platform',
	'chain',
	'local_status',
	'update_on',
	'synced_with_tableau',
	'blockchain_status',
	'w5_reported',
	'rw_request_key',
	'request_completed_on',
	'update_account_id',
	'update_account_username',
	'creator_id',
	'creator_username',
	'request_uid',
	'executed',
	'id',
	'path',
	'email',
	// 'value_code.enable',
];

export default function SyncChanges({ oldData, newData, refetchChangesets }) {
	const [changeset, setChangeset] = useState([]);
	const [postCommits] = useSyncChangesMutation();

	const changes = useMemo(() => {
		const values = checkDifference(oldData, newData);
		const keys_to_remove = newData?.asset_type
			? asset_keys_to_remove
			: request_keys_to_remove;
		return values.filter((i) => !keys_to_remove.includes(i.key));
	}, [oldData, newData]);

	console.log('changeset', changeset, oldData, newData);

	const onResolve = useCallback(() => {
		set(oldData, 'iteration', newData.iteration);
		const unflatten = unflattenChanges(changeset);
		const res = applyChangeset(oldData, unflatten);
		postCommits([res]).finally(() => refetchChangesets());
	}, [changeset]);

	return (
		<Stack className='sync-changes' spacing={2} sx={styles}>
			{changes?.length ? (
				<>
					<Body2 sx={{ padding: '0.5rem 1.8rem' }}>
						Updated Data on this action
					</Body2>
					{changes.map((i, ind) => (
						<ChangedItem
							item={i}
							key={i.key + '' + ind}
							created_user={oldData?.creator_username}
							updated_user={newData?.update_account_username}
							setData={setChangeset}
							data={changeset}
						/>
					))}
					<FlexBox sx={{ padding: '0 0.5rem', justifyContent: 'flex-end' }}>
						<FilledButton handleClick={onResolve} size='small'>
							Resolve
						</FilledButton>
					</FlexBox>
				</>
			) : null}
		</Stack>
	);
}
