import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
	initNetworkChange,
	networkListener,
} from 'src/redux/containers/NetworkSwitch';
import { setupNetworkListenerClient } from './setupNetworkListenerClient';

const listenerMiddleware = createListenerMiddleware();

// Listener setup fo initNetworkChange action
listenerMiddleware.startListening({
	actionCreator: initNetworkChange,
	effect: networkListener,
});

setupNetworkListenerClient();

export default listenerMiddleware;
