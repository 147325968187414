import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import FilledButton from 'src/components/Buttons/FilledButton';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import { FormikDatePicker, FormikForm } from 'src/components/Forms/FormikForm';
import Filter from 'src/components/icons/Filter';
import React, { useState } from 'react';
import * as yup from 'yup';

function DashboardFilter() {
	const changeSet = {
		startMonth: [Date.now(), yup.number()],
		endMonth: [Date.now(), yup.number()],
	};

	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpanded = () => setIsExpanded(!isExpanded);

	const handleSubmit = (values) => {
		console.log('Applying filter..', values);
	};

	return (
		<Stack className='filter-bar' spacing={1}>
			<IconCircleButton onClick={toggleExpanded} disabled>
				<Filter width='1.5em' height='1.5em' />
			</IconCircleButton>

			{isExpanded && (
				<Collapse in={isExpanded}>
					<FormikForm
						changeSet={changeSet}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						<Box className='filter-form'>
							<FormikDatePicker
								name='startMonth'
								label='Start'
								inputFormat='MMMM YYYY'
								fullWidth
							/>
							<FormikDatePicker
								name='endMonth'
								label='End'
								inputFormat='MMMM YYYY'
								fullWidth
							/>

							<FilledButton type='submit' size='small'>
								Apply
							</FilledButton>
						</Box>
					</FormikForm>
				</Collapse>
			)}
		</Stack>
	);
}

export default DashboardFilter;
