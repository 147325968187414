import Stack from '@mui/material/Stack';
import { FormikDatePicker, FormikTextField } from 'src/components/Forms/FormikForm';
import { TagSelect } from 'src/components/Forms/TagSelect';
import { FlexBox } from 'src/components/Layout/FlexBox';
import SKUField from 'src/components/SKUSelector/SKUField';
import { SKU_TYPES } from 'src/consts/sku';
import { mt2 } from 'src/styles/margins';

export default function LiquidSource({
	disabled = false,
	useLiquidSelector = true
}) {
	return (
		<Stack spacing={2}>
			{useLiquidSelector && (
				<SKUField title="Liquid" skuTypes={[SKU_TYPES.LIQUID]} tags="liquid" />
			)}
			<FlexBox alignItems="baseline">
				<FormikTextField
					disabled={disabled}
					name="batch"
					label="Batch"
					fullWidth
				/>
				<FormikDatePicker
					disabled={disabled}
					sx={{ ...mt2 }}
					name="date"
					label="Production Date"
					fullWidth
					required
				/>
			</FlexBox>
			<TagSelect
				disabled={disabled}
				name="liquid"
				label="Liquid properties"
				type="liquid"
				fullWidth
			/>
		</Stack>
	);
}
