import CameraIcon from '@mui/icons-material/Camera';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, ButtonBase, Dialog } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ImageDropZone from 'src/components/Camera/ImageDropZone';
import 'src/components/ScanOCR/polyfillGetUserMedia';
import { styles } from './styles';

export function ImagePickerModal({ onClose = () => { console.log('onClose'); }, onAdd = (v) => { console.log('onAdd', v);} }) {
	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [fileUploadToggle, setFileUploadToggle] = useState(false);

	const setData = (data) => {
		readFilesAsBase64(data)
			.then((updatedPhotos) => {
				onAdd(updatedPhotos);
			})
			.catch((error) => {
				console.error('Error reading files:', error);
			});
	};

	const readFilesAsBase64 = (files) => {
		return Promise.all(
			files.map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						const base64Data = reader.result;
						resolve({ dataUrl: base64Data, key: null });
					};
					reader.onerror = (error) => reject(error);
					reader.readAsDataURL(file);
				});
			}),
		);
	};

	const getVideo = () => {
		navigator.mediaDevices
			.getUserMedia({
				video: { width: 300, height: 300 },
			})
			.then((stream) => {
				let video = videoRef.current;
				video.srcObject = stream;
				video.play();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const closeVideo = useCallback(() => {
		let video = videoRef.current;
		if (video && video.srcObject) {
			let tracks = video.srcObject.getTracks();
			tracks.forEach((track) => track.stop());
			video.srcObject = null;
		}
	}, []);

	const takePhoto = useCallback(() => {
		const width = 300;
		const height = width / (16 / 9);

		let video = videoRef.current;
		const photo = document.createElement('canvas');
		photo.width = width;
		photo.height = height;
		let ctx = photo.getContext('2d');
		ctx.drawImage(video, 0, 0, photo.width, photo.height);
		closeVideo();
		onAdd([{ key: null, dataUrl: photo.toDataURL('image/png') }]);
	}, [closeVideo, onAdd]);

	const onToggle = useCallback(() => {
		setFileUploadToggle(!fileUploadToggle);
		if (fileUploadToggle) {
			getVideo();
		} else {
			closeVideo();
		}
	}, [closeVideo, fileUploadToggle]);

	useEffect(() => {
		getVideo();
	}, []);

	return (
		<Dialog sx={styles} className='dialog' open={true} fullWidth>
			<Box className='scanner'>
				<ButtonBase
					onClick={() => {
						closeVideo();
						onClose();
					}}
					className='modalButton dialogClose'
				>
					<CloseIcon />
				</ButtonBase>
				<ButtonBase onClick={onToggle} className='modalButton toggleFacingMode'>
					{!fileUploadToggle ? <UploadFileIcon /> : <CameraAltIcon />}
				</ButtonBase>

				{!fileUploadToggle && (
					<ButtonBase onClick={takePhoto} className='modalButton capture'>
						<CameraIcon />
					</ButtonBase>
				)}
				<section style={styles.containerStyle}>
					<div style={{ ...styles.container, ...styles.videoContainerStyle }}>
						{!fileUploadToggle && (
							<>
								<video
									muted
									playsInline
									autoPlay
									style={{ ...styles.video, ...styles.videoStyle }}
									ref={videoRef}
								/>
								<canvas
									style={{ ...styles.video, ...styles.videoStyle }}
									ref={canvasRef}
								/>
							</>
						)}
						{fileUploadToggle && (
							<Box style={{ ...styles.video, ...styles.videoStyle }}>
								<Box style={{ margin: '100px' }}>
									<ImageDropZone setData={setData} />
								</Box>
							</Box>
						)}
					</div>
				</section>
			</Box>
		</Dialog>
	);
}
