import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	KeyboardDoubleArrowLeft,
	KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import {
	FormikCheckBox,
	FormikForm,
	FormikMeasuresField,
	FormikNumberField,
	FormikTextField,
} from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { FlexBox } from 'src/components/Layout/FlexBox';
import Location from 'src/components/Location';
import { locationFields } from 'src/components/Location/locationFields';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import OcrFormFieldComponent from 'src/components/ScanOCR/OcrComponent';
import LinkedAssetItem from 'src/components/SKUSelector/items/LinkedAssetItem';
import LiquidSummary from 'src/components/SKUSelector/items/LiquidSummary';
import { H5, Overline } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { MEASURE } from 'src/consts/measures';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import FormikContext from 'src/context/FormikContext';
import styles from 'src/styles/modal.styles';
import toLocation from 'src/utilities/adapters/toLocation';
import toUniqueLocation from 'src/utilities/adapters/toUniqueLocation';
import { COMP, ID } from 'src/utilities/comp';
import debounce from 'src/utilities/debounce';
import useAlertView, { AlertView } from 'src/utilities/hooks/useAlertView';
import useItemListManager from 'src/utilities/hooks/useItemListManager';
import useMeasures from 'src/utilities/hooks/useMeasures';
import * as yup from 'yup';

function WeightsForm({ weightFactor }) {
	const { values, setFieldValue } = useContext(FormikContext);

	useEffect(() => {
		console.log('  --> Weight', values, weightFactor?.weightFactor);

		if (
			values?.enableWeight &&
			values?.tearWeight &&
			values?.grossWeight &&
			weightFactor?.weightFactor
		) {
			const netWeight =
				Number(values?.grossWeight ?? 0) - Number(values?.tearWeight ?? 0);
			const filled_bl = Number(weightFactor?.weightFactor ?? 0) * netWeight;
			console.log('Filled', netWeight, weightFactor?.weightFactor, filled_bl);
			debounce(() => setFieldValue('filled_bl', filled_bl.toFixed(2)), 25);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		values?.enableWeight,
		values?.tearWeight,
		values?.grossWeight,
		weightFactor,
	]);

	return (
		<Stack>
			<FormikCheckBox name='enableWeight' label='Use Weights' />
			<Collapse in={Boolean(values?.enableWeight)}>
				<FlexBox>
					<FormikMeasuresField
						label='Tear Weight'
						name='tearWeight'
						measure={MEASURE.w_m}
						fullWidth
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<InputAdornment position='end'>
									<OcrFormFieldComponent name='tearWeight' />
								</InputAdornment>
							),
						}}
					/>
					<FormikMeasuresField
						label='Gross Weight'
						name='grossWeight'
						measure={MEASURE.w_m}
						fullWidth
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<InputAdornment position='end'>
									<OcrFormFieldComponent name='grossWeight' />
								</InputAdornment>
							),
						}}
					/>
				</FlexBox>
			</Collapse>
		</Stack>
	);
}

export const calculateTransferDestinationLiquidUpdates = (values, cfrom) => {
	console.log('Calculating', values);

	const {
		bl = 0,
		la = 0,
		expected_bl,
		filled_bl,
		updated_abv,
		updated_tcf,
	} = values;

	// if (enableWeight && grossWeight && tearWeight) {
	// 	const { weightFactor } = weightProperties ?? {};
	//
	// 	if (updated_tcf && weightFactor && updated_abv) {
	// 		console.log('Calculating with weight properties', updated_tcf, weightFactor, updated_abv);
	// 		// Convert the weights to metric
	// 		const netWeight = Number(grossWeight) - Number(tearWeight);
	// 		const filledBl = Number(weightFactor) * netWeight;
	// 		const filledLa = filledBl * Number(updated_tcf) * Number(updated_abv / 100.0);
	// 		const updatedBl = Number(bl) + cfrom(filledBl, MEASURE.l_m);
	// 		const updatedLa = Number(la) + cfrom(filledLa, MEASURE.l_m);
	// 		return [
	// 			true,
	// 			{
	// 				expected_bl: cfrom(expected_bl, MEASURE.l_m),
	// 				actual_bl: cfrom(filledBl, MEASURE.l_m),
	// 				updated_bl: Math.max(updatedBl, 0),
	// 				updated_la: Math.max(updatedLa, 0),
	// 				updated_abv: cfrom(updated_abv, MEASURE.alc),
	// 				updated_tcf,
	// 			},
	// 		];
	// 	}
	// }

	// If the user has filled in the actual_bl
	if (filled_bl && Number(filled_bl) > 0) {
		const filledBl = Number(cfrom(filled_bl, MEASURE.l_m));
		const abv = cfrom(updated_abv, MEASURE.alc);
		const filledLa = cfrom(
			filled_bl * updated_tcf * (updated_abv / 100),
			MEASURE.l_m,
		);

		const updatedBl = Number(bl) + filledBl;
		const updatedLa = Number(la) + filledLa;

		return [
			true,
			{
				expected_bl: cfrom(expected_bl, MEASURE.l_m),
				updated_bl: updatedBl,
				updated_la: updatedLa,
				filled_bl,
				updated_abv: abv,
				updated_tcf,
			},
		];
	}

	return [false, values];
};

function TrackedAsset({
	item,
	// upsert,
	onClose,
	// sourceWeightProperties,
	// sourcesAbv = [0, 1],
	// sourcesLiquidType = '',
}) {
	const { enqueueSnackbar } = useSnackbar();

	const { to: cto, from: cfrom } = useMeasures();

	// We track the "amount" we want at the SKU Item level
	const { rw_asset_id, properties = {}, asset_type } = item ?? {};
	const { liquid = {}, reference = '' } = properties;
	const { level = {} } = liquid;

	console.log('Level', properties);
	const {
		bl,
		la,
		expected_bl,
		filled_bl,
		enableWeight,
		weightFactor,
		tearWeight,
		grossWeight,
		updated_bl,
		updated_abv,
		updated_tcf,
	} = level;

	// const [sourceAbv, sourceTcf] = sourcesAbv ?? [0, 1];

	const default_expected_bl = asset_type === ASSET_TYPES.cask ? 200 : '';
	const expected_value = expected_bl ?? default_expected_bl;

	const changeSet = useMemo(
		() => ({
			expected_bl: [
				Number(cto(expected_value ?? 0, MEASURE.l_m)).toFixed(2),
				yup.number().min(0, 'Must be positive!'),
			],
			filled_bl: [
				Number(cto(filled_bl ?? updated_bl ?? '', MEASURE.l_m)).toFixed(2),
				yup.number().min(0, 'Must be positive!'),
			],
			updated_abv: [
				updated_abv
					? Number(cto(updated_abv ?? 0, MEASURE.alc)).toFixed(2)
					: '',
				yup.number().min(0, 'Must be positive!'),
			],
			updated_tcf: [
				Number(updated_tcf ?? 1).toFixed(3),
				yup.number().min(0, 'Must be positive!'),
			],
			enableWeight: [enableWeight, yup.boolean()],
			tearWeight: [
				tearWeight ? Number(cto(tearWeight ?? 0, MEASURE.w_m)).toFixed(2) : '',
				yup.number().min(0, 'Must be positive!'),
			],
			grossWeight: [
				grossWeight
					? Number(cto(grossWeight ?? 0, MEASURE.w_m)).toFixed(2)
					: '',
				yup.number().min(0, 'Must be positive!'),
			],
			reference: [reference, yup.string()],
		}),
		[cto, filled_bl, bl, enableWeight, tearWeight, grossWeight, reference],
	);

	const handleSubmit = useCallback(
		(values) => {
			try {
				console.log('submitted', values);
				const [valid, updated_level] =
					calculateTransferDestinationLiquidUpdates(
						{ ...level, ...values },
						cfrom,
					);
				console.log('updated_level ', updated_level);
				// const le =
				// 	Number(updated_level?.filled_bl) > 0 || Number(level?.bl) > 0;
				onClose({
					...item,
					properties: {
						...properties,
						reference: values?.reference ?? properties?.reference,
						liquid: {
							...liquid,
							enable: Number(updated_level?.updated_bl) > 0,
							level: {
								...level,
								...updated_level,
								filled_date: Date.now(),
								enableWeight: values.enableWeight,
								...(values.enableWeight
									? {
											weightFactor,
											tearWeight: cfrom(values.tearWeight, MEASURE.w_m),
											grossWeight: cfrom(values.grossWeight, MEASURE.w_m),
										}
									: {}),
								enable: Number(updated_level?.updated_bl) > 0,
							},
						},
					},
					processed: valid
						? ASSET_PROCESSED_STATUS.CONFIRMED
						: item.processed
							? item.processed
							: ASSET_PROCESSED_STATUS.PENDING,
				});
			} catch (e) {
				console.log('Failed to update', e);
				enqueueSnackbar(
					`Cannot update liquid settings for ${rw_asset_id}: ${e.message}!`,
					{
						variant: 'error',
					},
				);
			}
		},
		[level, cfrom],
	);

	return (
		<Box
			sx={{
				overflowY: 'auto',
				height: 'auto',
				maxHeight: 'calc(95vh - 9rem)',
				gap: '1.5rem',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Instructions>Adjust fill settings.</Instructions>

			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={2}>
					{[ASSET_TYPES.cask].includes(asset_type) && (
						<>
							<FlexBox>
								<FormikTextField
									name='reference'
									label='Reference'
									fullWidth
								/>
							</FlexBox>
							<Divider />
						</>
					)}

					<LiquidDisplay label='Current' value={bl} la={la} />

					<FlexBox gap={2}>
						<FormikMeasuresField
							label='Expected'
							name='expected_bl'
							measure={MEASURE.l_m}
							fullWidth
						/>
						<FormikMeasuresField
							label='Filled'
							name='filled_bl'
							measure={MEASURE.l_m}
							fullWidth
						/>
					</FlexBox>

					<FlexBox gap={2}>
						<FormikMeasuresField
							name='updated_abv'
							measure={MEASURE.alc}
							label='Strength'
							fullWidth
							required
						/>
						<FormikNumberField name='updated_tcf' label='TCF' fullWidth />
					</FlexBox>

					<LiquidSummary
						level={{
							...level,
							abv: level.updated_abv,
							tcf: level.updated_tcf,
						}}
					/>

					<WeightsForm weightFactor={weightFactor} />

					<AlertView />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</Box>
	);
}

function ChildList({ label, items, update }) {
	const handleSelect = useCallback((item, selected) => {
		debounce(() => update({ ...item, selected }), 25);
	}, [update]);

	return (
		<Stack className="inventory" sx={{ width: '40%' }}>
			<Box className="inventory-header">
				<Overline>{label}</Overline>
			</Box>
			<Box className="inventory-contents">
				<Stack spacing={0.2}>
					{items.map((_item) => (
						<LinkedAssetItem key={_item.asset_id} asset={_item} selected={_item.selected} onSelect={(s) => handleSelect(_item, s)} />
					))}
				</Stack>
			</Box>
		</Stack>
	);
}

function Pallet({
	item,
	items,
	onClose,
}) {
	const { unique_location_id } = item ?? {};

	const [AlertView, { setAlert }] = useAlertView();

	const existingChildren = [...(item.children ?? [])];

	const [available, , , upsertAvailable, removeAvailable] = useItemListManager(
		ID.asset,
		COMP.asset,
		items.map((i) => ({ asset_id: i.asset_id ?? i.path, rw_asset_id: i.rw_asset_id, asset_type: i.asset_type, selected: false }))
	);

	// console.log('Available', available);

	const [selected, , , upsertSelected, removeSelected] = useItemListManager(
		ID.asset,
		COMP.asset,
		existingChildren.map((i) => ({ asset_id: i.asset_id ?? i.path, rw_asset_id: i.rw_asset_id, asset_type: i.asset_type, selected: false }))
	);

	// console.log('Selected', selected);

	// console.log('Delivery for', item);
	const changeSet = useMemo(
		() => ({
			location: locationFields(toLocation(unique_location_id)),
		}),
		[unique_location_id],
	);

	const handleSubmit = useCallback(
		(values) => {
			const { location } = values;
			const unique_location_id = toUniqueLocation(location || {});
			const empty_location = toUniqueLocation({});
			console.log('Location', unique_location_id, empty_location);
			if (unique_location_id !== empty_location) {
				// See if there is another asset with the same location id
				const existing = items?.find((i) => i.unique_location_id !== empty_location && i.unique_location_id === unique_location_id && i.asset_id !== item.asset_id);
				if (existing) {
					setAlert({
						severity: 'warning',
						title: 'Asset at location',
						content: `Asset ${existing.rw_asset_id} is already at this location!`,
					});
					return;
				}
			}

			const asset = { ...item, unique_location_id, children: selected };
			onClose(asset);
		},
		[item, onClose, selected],
	);

	const handleAdd = useCallback(() => {
		const selectedItems = available.filter((s) => s.selected).map((s) => ({ ...s, selected: false }));
		debounce(() => {
			removeAvailable(selectedItems);
			upsertSelected(selectedItems);
		}, 25);
	}, [available, selected]);

	const handleRemove = useCallback(() => {
		const selectedItems = selected.filter((s) => s.selected).map((s) => ({ ...s, selected: false }));
		debounce(() => {
			removeSelected(selectedItems);
			upsertAvailable(selectedItems);
		}, 25);
	}, [available, selected]);

	const handleAddAll = useCallback(() => {
		const selectedItems = available.map((s) => ({ ...s, selected: false }));
		debounce(() => {
			removeAvailable(available);
			upsertSelected(selectedItems);
		}, 25);
	}, [available, selected]);

	const handleRemoveAll = useCallback(() => {
		const selectedItems = selected.map((s) => ({ ...s, selected: false }));
		debounce(() => {
			removeSelected(selected);
			upsertAvailable(selectedItems);
		}, 25);
	}, [available, selected]);

	return (
		<Box
			sx={{
				overflowY: 'auto',
				height: 'auto',
				maxHeight: 'calc(95vh - 9rem)',
				gap: '1.5rem',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Instructions>Configure Pallet.</Instructions>

			<FormikForm
				changeSet={changeSet}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				<Stack spacing={2}>
					<Location name='location' />

					<H5>Assets</H5>
					<FlexBox alignItems="stretch" sx={{ position: 'relative' }}>
						<ChildList label="Available" items={available} update={upsertAvailable} />
						<Stack sx={{ width: '10%' }}>
							<IconButton
								onClick={handleAdd}
								disabled={!available.some((s) => s.selected)}
								aria-label='Add'
							>
								<KeyboardArrowRight />
							</IconButton>
							<IconButton
								onClick={handleRemove}
								disabled={!selected.some((s) => s.selected)}
								aria-label='Remove'
							>
								<KeyboardArrowLeft />
							</IconButton>
							<IconButton
								onClick={handleAddAll}
								disabled={!available.length}
								aria-label='Add All'
							>
								<KeyboardDoubleArrowRight />
							</IconButton>
							<IconButton
								onClick={handleRemoveAll}
								disabled={!selected.length}
								aria-label='Remove All'
							>
								<KeyboardDoubleArrowLeft />
							</IconButton>

						</Stack>
						<ChildList label="Selected" items={selected} update={upsertSelected} />
					</FlexBox>

					<AlertView />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small' disabled={!location}>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>

		</Box>
	);
}

function DestinationAssetModal({
	item,
	items,
	open,
	onClose,
}) {

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{item.rw_asset_id}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				{item.asset_type === ASSET_TYPES.pallet && (
					<Pallet
						item={item}
						items={items
							?.filter((i) => !i.parent_asset_id && ![ASSET_TYPES.pallet, ASSET_TYPES.container].includes(i.asset_type))
							.filter((i) => !item?.children?.find((s) => s.asset_id === i.asset_id))
						}
						onClose={onClose}
					/>
				)}
				{item.asset_type !== ASSET_TYPES.pallet && (
					<TrackedAsset
						item={item}
						onClose={onClose}
					/>
				)}
			</Stack>
		</Modal>
	)
}

export default DestinationAssetModal;
