import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import AmountDisplay from 'src/components/properties/AmountDisplay';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import StrengthDisplay from 'src/components/properties/StrengthDisplay';
import { MEASURE } from 'src/consts/measures';
import { SKU_TYPES } from 'src/consts/sku';
import { useCompanySKUs } from 'src/redux/containers/Companies';
import useLiquidSummaryCalculator from 'src/utilities/hooks/useLiquidSummaryCalculator';
import useMeasures from 'src/utilities/hooks/useMeasures';

function LiquidSummary({
	skus = [],
	assets = [],
	force = false,
	extendedSummary = false,
	dutyPaidSummary = false,
}) {
	const { scale } = useMeasures();

	const skuList = useCompanySKUs();

	// const sv = useMemo(() => {
	// 	let obl = 0;
	// 	let ola = 0;
	// 	let rbl = 0;
	// 	let rla = 0;
	// 	let sbl = 0;
	// 	let sla = 0;
	// 	let si = 0;
	// 	let sg = 0;
	// 	let nw = 0;
	// 	let gw = 0;
	// 	let ebl = 0;
	// 	let tbl = 0;
	// 	let tabv = 0;
	// 	let dpbl = 0;
	// 	let dpla = 0;
	// 	let dpsbl = 0;
	// 	let dpsla = 0;
	//
	// 	assets.forEach((asset) => {
	// 		const { properties = {} } = asset;
	// 		const { liquid = {}, sku_id } = properties;
	// 		const { level = {} } = liquid;
	// 		const {
	// 			bl,
	// 			la,
	// 			updated_la,
	// 			updated_bl,
	// 			abv,
	// 			updated_abv,
	// 			tcf,
	// 			updated_tcf,
	// 			duty_paid_bl,
	// 			duty_paid_la,
	// 		} = level;
	//
	// 		obl += Number(bl ?? 0);
	// 		ola += Number(la ?? 0);
	// 		rbl += Number(updated_bl ?? 0);
	// 		rla += Number(updated_la ?? 0);
	// 		dpbl += Number(duty_paid_bl ?? 0);
	// 		dpla += Number(duty_paid_la ?? 0);
	//
	// 		const tcfv = Number(updated_tcf ?? tcf ?? '1.000');
	// 		const abvv = Number(updated_abv ?? abv);
	//
	// 		// Scale up the bulk by the tcf
	// 		const bulk = Number(updated_bl ?? bl);
	// 		tbl += bulk;
	// 		ebl += bulk * tcfv;
	// 		tabv += bulk * abvv;
	//
	// 		// console.log(' --> ', bulk, tcfv, abvv);
	// 		// console.log('Asset', asset.asset_id, sku_id);
	//
	// 		if (sku_id) {
	// 			const fs = skuList.find((s) => s.sku_id === sku_id);
	// 			console.log('Matching SKU', sku_id, fs);
	// 			if (fs) {
	// 				const { net_weight = 0, gross_weight = 0 } = fs;
	// 				nw += Number(net_weight);
	// 				gw += Number(gross_weight);
	// 			}
	// 		}
	// 	});
	//
	// 	skus.forEach((sku) => {
	// 		if (sku.sku_type === SKU_TYPES.FINISHED) {
	// 			// console.log('Sku', sku);
	// 			const items = Number(sku.amount);
	// 			si += items;
	// 			sg += Math.ceil(items / Number(sku.count));
	//
	// 			let val_s = 0;
	// 			if (sku.size && sku.unit) {
	// 				const val = items * Number(sku.size);
	// 				val_s = scale(val, sku.unit, MEASURE.l_m);
	// 			}
	//
	// 			// console.log(' --> values', si, sg, val, sku.unit, sku.abv, val_s);
	//
	// 			sbl += Number(val_s);
	// 			sla += Number(val_s) * (sku.abv / 100);
	//
	// 			dpsbl += sbl;
	// 			dpsla += sla;
	//
	// 			nw += Number(sku.net_weight ?? 0) * items;
	// 			gw += Number(sku.gross_weight ?? 0) * items;
	// 		}
	// 	});
	//
	// 	// console.log('Totals', obl, ola, rbl, rla, sbl, sla, si, sg, nw, gw, ebl, tbl, tabv);
	// 	if (assets.length > 0 || si > 0) {
	// 		// Calculate the weighted average abv and the tcf
	// 		const wabv = tbl > 0 ? tabv / tbl : null;
	// 		const wtcf = tbl > 0 ? ebl / tbl : null;
	// 		return [
	// 			assets.length,
	// 			obl,
	// 			ola,
	// 			rbl,
	// 			rla,
	// 			sbl,
	// 			sla,
	// 			si,
	// 			sg,
	// 			nw,
	// 			gw,
	// 			ebl, // tcf adjusted bulk
	// 			wabv,
	// 			wtcf,
	// 			dpbl,
	// 			dpla,
	// 			dpsbl,
	// 			dpsla,
	// 		];
	// 	} else {
	// 		return [0];
	// 	}
	// }, [assets]);

	const {
		asset_count,
		original_bulk,
		original_la,
		updated_bulk,
		updated_la,
		sku_count,
		sku_bulk,
		sku_la,
		sku_groups,
		net_weight,
		gross_weight,
		tcf_bulk,
		weighted_abv,
		weighted_tcf,
		duty_paid_asset_bulk,
		duty_paid_asset_la,
		duty_paid_sku_bulk,
		duty_paid_sku_la,
	} = useLiquidSummaryCalculator(scale, skuList, assets, skus);

	// if (!assets || assets.length === 0) return;

	return (
		<Stack spacing={0.5}>
			{asset_count > 0 && !dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='Assets' amount={asset_count} />
					<LiquidDisplay label='Original' value={original_bulk} la={original_la} force={force} />
					<LiquidDisplay label='Final' value={updated_bulk} la={updated_la} force={force} />
					<LiquidDisplay
						label='Amount'
						value={Math.abs(updated_bulk - original_bulk)}
						la={Math.abs(updated_la - original_la)}
						force={force}
					/>
				</FlexBox>
			)}
			{sku_count > 0 && !dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='SKUs' amount={skus.length} />
					<AmountDisplay label='Items' amount={sku_count} />
					<AmountDisplay label='Groups' amount={sku_groups} />
					<LiquidDisplay label='Liquid' value={sku_bulk} la={sku_la} force={force} />
				</FlexBox>
			)}
			{extendedSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='Net Weight' amount={net_weight} unit={MEASURE.w_m} />
					<AmountDisplay label='Gross Weight' amount={gross_weight} unit={MEASURE.w_m} />
					<LiquidDisplay
						label='TCF Adjusted'
						value={Number(tcf_bulk) + Number(sku_bulk)}
						la={Number(updated_la) + Number(sku_la)}
						force={force}
					/>
					<StrengthDisplay label='ABV' value={weighted_abv} tcf={weighted_tcf} />
				</FlexBox>
			)}
			{asset_count > 0 && dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='Assets' amount={asset_count} />
					<LiquidDisplay
						label='Tax On'
						value={duty_paid_asset_bulk}
						la={duty_paid_asset_la}
						force={force}
					/>
				</FlexBox>
			)}
			{sku_count > 0 && dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='SKUs' amount={skus.length} />
					<AmountDisplay label='Items' amount={sku_count} />
					<AmountDisplay label='Groups' amount={sku_groups} />
					<LiquidDisplay label='Tax On' value={duty_paid_sku_bulk} la={duty_paid_sku_la} force={force} />
				</FlexBox>
			)}
		</Stack>
	);
}

export default LiquidSummary;
