import Box from '@mui/material/Box';
import React from 'react';
import TitleBar from 'src/components/TitleBar';
import styles from './app-layout.styles';
import AppHeader from './AppHeader';

export function EmptyLayout({ children }) {

	return (
		<Box sx={styles} className='root'>
			<AppHeader disable />

			<Box className='content'>
				<Box className='wrapper-content'>
					<TitleBar />
					<Box className='wrapper-box'>{children}</Box>
				</Box>
			</Box>
		</Box>
	);
}

export const withEmptyLayout = (WrappedComponent) =>
	function WithEmptyLayout(props) {
		return (
			<EmptyLayout>
				<WrappedComponent {...props} />
			</EmptyLayout>
		);
	};
