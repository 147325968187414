import { createSlice } from '@reduxjs/toolkit';
import { ACCOUNT_STATUS } from 'src/consts/account';
import { UNIT_FORMAT_TYPES } from 'src/consts/company';
import { SLICE } from './consts';

const initialState = {
  account_id: '',
  account_name: '',
  account_username: '',
  account_status: ACCOUNT_STATUS.PENDING,
  account_email: '',
  account_perms: '0b0',
  account_location: {},
  unit_format: UNIT_FORMAT_TYPES.Metric,
  account_last_access: 0,
  chain: null,
  platform: null,
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    clearUser() {
      return { ...initialState };
    },
    setUser(state, { payload }) {
      console.log('Setting user', payload);
      state.account_id = payload.account_id;
      state.account_name = payload.account_name;
      state.account_username = payload.account_username;
      state.account_status = payload.account_status ?? ACCOUNT_STATUS.PENDING;
      state.account_email = payload.account_email;
      state.account_perms = payload.account_perms;
      state.account_location = JSON.parse(payload.account_location ?? '{}');
      state.unit_format = payload.imperial_units
        ? UNIT_FORMAT_TYPES.Imperial
        : UNIT_FORMAT_TYPES.Metric;
      state.account_last_access = payload.account_last_access;
      state.platform = payload.platform;
      state.chain = payload.chain;
    },
  },
});

// const userPersistConfig = {
//   key: SLICE,
//   version: 1,
//   storage,
// 	timeout: 4 * 3600,
// };

// export const reducer =  persistReducer(userPersistConfig, slice.reducer);
export const { reducer, actions } = slice;
export const { clearUser, setUser } = actions;
