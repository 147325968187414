import { APP_API_GATEWAY } from 'src/consts/config';
import fetchPost from 'src/utilities/fetchPost';

const BASE = `${APP_API_GATEWAY}/pw`;

export const download = async (request) =>
	fetchPost(`${BASE}/offline/download`, {}, request);

export const getCommits = async (request = {}) =>
	fetchPost(`${BASE}/commit/list`, {}, request);

export const removeCommit = async (request) =>
	fetchPost(`${BASE}/commit/delete`, {}, request);

export const synchronize = async (request = {}) =>
	fetchPost(`${BASE}/sync`, {}, request);
