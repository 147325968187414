import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import toPairs from 'lodash.topairs';
import { useMemo } from 'react';
import { AssetListComponentV2 } from 'src/components/AssetListItem';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import RequestListComponent from 'src/components/RequestListItem';
import SKUItem from 'src/components/Search/items/SKUItem';
import { Body3, Overline } from 'src/components/Typography';
import IDDisplay from 'src/components/properties/IDDisplay';
import { ASSET_NAMES } from 'src/consts/asset';
import { REQUEST_NAMES } from 'src/consts/requests';
import { SearchAssetListItemComponent } from '../AssetListItem/SearchAssetListItemComponent';

function AssetEntry({ assetType, items, buttonIcon, onItemClick }) {
	return (
		<Stack>
			<Overline className='search-section-title'>
				{ASSET_NAMES[assetType]}
			</Overline>
			<Stack className='list'>
				{items.map((a) => (
					<AssetListComponentV2
						item={a}
						key={a.path}
						buttonIcon={buttonIcon}
						onClick={onItemClick}
					>
						<SearchAssetListItemComponent item={a} />
					</AssetListComponentV2>
				))}
			</Stack>
		</Stack>
	);
}

function AssetResults({ items, buttonIcon, onItemClick }) {
	return (
		<Stack className='search-section' spacing={0.75}>
			{toPairs(items).map(([t, l]) => (
				<AssetEntry
					key={t}
					assetType={t}
					items={l}
					buttonIcon={buttonIcon}
					onItemClick={onItemClick}
				/>
			))}
		</Stack>
	);
}

function RequestEntry({ requestType, items, buttonIcon, onItemClick }) {
	return (
		<Stack>
			<Overline className='search-section-title'>
				{REQUEST_NAMES[requestType]}
			</Overline>
			<Stack className='list'>
				{items.map((a) => (
					<RequestListComponent
						item={a}
						key={a.path}
						buttonIcon={buttonIcon}
						onItemClick={() => onItemClick(a)}
					/>
				))}
			</Stack>
		</Stack>
	);
}

function RequestResults({ items, buttonIcon, onItemClick }) {
	return (
		<Stack className='search-section'>
			{toPairs(items).map(([t, l]) => (
				<RequestEntry
					key={t}
					requestType={t}
					items={l}
					buttonIcon={buttonIcon}
					onItemClick={onItemClick}
				/>
			))}
		</Stack>
	);
}

function BatchListItem({ item, buttonIcon, onItemClick }) {
	return (
		<Stack spacing={0.5} className='listItem'>
			<Box className='listContent'>
				<IDDisplay id={item?.batch_name} onClick={() => onItemClick(item)} />
			</Box>

			<Stack className='listButtons'>
				<IconCircleButton onClick={() => onItemClick(item)}>
					{buttonIcon}
				</IconCircleButton>
			</Stack>
		</Stack>
	);
}

// function BatchResults({ items, buttonIcon, onItemClick }) {
// 	return (
// 		<Stack className='search-section'>
// 			<Overline className='search-section-title'>Batch</Overline>
// 			<Stack className='list'>
// 				{items.map((a) => (
// 					<BatchListItem
// 						item={a}
// 						key={a.path}
// 						buttonIcon={buttonIcon}
// 						onItemClick={onItemClick}
// 					/>
// 				))}
// 			</Stack>
// 		</Stack>
// 	);
// }
// BatchResults.propTypes = {
// 	items: PropTypes.arrayOf(PropTypes.object).isRequired,
// 	buttonIcon: PropTypes.node,
// 	onItemClick: PropTypes.func,
// };

function SKUResults({ items, onItemClick }) {
	return (
		<Stack className='search-section'>
			<Overline className='search-section-title'>SKU</Overline>
			<Stack className='list'>
				{items.map((a) => (
					<SKUItem item={a} key={a.path} onClick={() => onItemClick(a)} />
				))}
			</Stack>
		</Stack>
	);
}

function DisplayResults({
	assets = {},
	requests = {},
	batches = [],
	skus = [],
	...rest
}) {
	const assetCount = useMemo(
		() =>
			toPairs(assets)
				.map(([, l]) => l.length)
				.reduce((c, v) => c + v, 0),
		[assets],
	);
	const requestCount = useMemo(
		() =>
			toPairs(requests)
				.map(([, l]) => l.length)
				.reduce((c, v) => c + v, 0),
		[requests],
	);
	const results = useMemo(
		() => assetCount + requestCount + batches.length + skus.length,
		[assetCount, requestCount, batches, skus],
	);

	if (results === 0) {
		return <Body3>Nothing matches</Body3>;
	}

	return (
		<Box className='search-result-contents'>
			{skus.length > 0 && <SKUResults items={skus} {...rest} />}
			{assetCount > 0 && <AssetResults items={assets} {...rest} />}
			{requestCount > 0 && <RequestResults items={requests} {...rest} />}
		</Box>
	);
}

export default DisplayResults;
