import Invite from 'src/components/Account/Invite';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function PersonalInvitePage() {
	usePageTitle('Personal Invite');

	return <Invite />;
}

export default withAppLayout(withDialogCard(PersonalInvitePage));
