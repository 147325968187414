import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withEmptyLayout } from 'src/components/Layout/EmptyLayout';
import Loading from 'src/components/Loading/Loading';
import { clearUser } from 'src/redux/containers/User';
import { logout } from 'src/services/auth.service';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function Logout() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	usePageTitle('Logout');

	useEffect(() => {
		logout()
			.catch((e) => {
				console.log('Failed to logout', e);
			})
			.finally(() => {
				dispatch(clearUser());
				navigate('/');
			});
	}, []);

	return <Loading message='Logging out' />;
}

export default withEmptyLayout(withDialogCard(Logout));
