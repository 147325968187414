import { Email, Smartphone } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
	Box, ClickAwayListener, Divider, Grow, MenuList, Paper, Popper, Tooltip,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Body2 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import { useContext, useRef, useState } from 'react';
import { b__black_10, ba } from 'src/styles/border';
import { f5 } from 'src/styles/fonts';

const styles = {
	'&.root': {
		borderBottomLeftRadius: '5px',
		borderTopLeftRadius: '5px',
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		backgroundColor: '#F0AA29',
		borderTop: '1px solid #CEC9BF',
		borderLeft: '1px solid #CEC9BF',
		borderBottom: '1px solid #CEC9BF',
		display: 'flex',
		flexDirection: 'row',
		gap: '0.25rem',
		paddingLeft: '0.5rem',
		paddingRight: '0.25rem',

		'.text': {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			flexGrow: 1,
			gap: '0.25rem',
		},

		'.button': {
			minWidth: '24px',
			maxWidth: '24px',
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
		},

		minWidth: '125px',
		maxWidth: '125px',
	},

	paper: {
		'&.MuiPaper-root': {
			...ba,
			...b__black_10,
			backgroundColor: '#F0AA29',
			color: '#000',
			fill: '#000',
			//   '--shadow-color': '218deg 68% 31%',
			//   boxShadow: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
			// 0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
			// 2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
			// 5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36)`,
		},
		'.menu-item': {
			...f5,
		},
	},
};

const options = [{
	name: 'Email',
	key: 'email',
	icon: (<Email />),
}, {
	name: 'Phone',
	key: 'phone',
	icon: (<Smartphone />),
}];

//
// , {
//   name: 'Wechat',
//     key: 'wechat',
//     icon: (<Telegram />)
// }, {
//   name: 'Signal',
//     key: 'signal',
//     icon: (<Telegram />)
// }, {
//   name: 'Telegram',
//     key: 'telegram',
//     icon: (<Telegram />)
// }
function IdentitySelector({ name }) {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef();

	const { values, handleChange } = useContext(FormikContext);

	const handleMenuItemClick = (
		event,
		key,
	) => {
		handleChange({ target: { name, value: key } });
		setOpen(false);
	};

	const handleToggle = () => {
		console.log('Showing popup..');
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<Box
				sx={styles}
				className="root"
				ref={anchorRef}
				aria-label="split button"
			>
				<Box
					className="text"
				>
					{(options[values?.[name]] ?? options[0]).icon}
					<Body2>{(options[values?.[name]] ?? options[0]).name}</Body2>
				</Box>
				<Divider variant="fullWidth" orientation="vertical" />
				<Box
					className="button"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="Select identity provider"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<Tooltip title="Select the identity service">
						<ArrowDropDownIcon />
					</Tooltip>
				</Box>
			</Box>
			<Popper
				sx={{
					zIndex: 1000,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper sx={styles.paper}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={option.key}
											selected={option.key === values?.[name]}
											onClick={(event) => handleMenuItemClick(event, option.key)}
											className="menu-item"
										>
											{option.icon}
                      &nbsp;
											{option.name}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}

export default IdentitySelector;
