import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import { FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { H5 } from 'src/components/Typography';
import { useCompanyId } from 'src/redux/containers/Companies';
import { inviteUser } from 'src/services/company.service';
import styles from 'src/styles/content.styles';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import * as yup from 'yup';

function InviteUser() {
	const [isLoading, setLoading] = useLoadingHook();
	const [target, scroll] = useScrollTarget();
	const company_id = useCompanyId();

	const [AlertView, { setAlert }] = useAlertView();

	const changeSet = {
		name: ['', yup.string()],
		email: [
			'',
			yup.string().email('Invalid email!').required('Email required!'),
		],
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		setAlert(null);
		try {
			console.log('Sending invite', values);
			// Save the description
			const response = await inviteUser({ ...values, company_id });
			console.log('Created invite', response);
			setAlert({
				severity: 'success',
				title: 'User invited',
				content: 'Invite sent!',
			});
		} catch (e) {
			console.log('Failed to invite user!', e);
			setAlert({
				severity: 'error',
				title: 'Failed to invite',
				content: e.message,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => scroll(), []);

	return (
		<Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
			<H5>Send Invite</H5>

			<Instructions>Invite your family and friends to join!</Instructions>

			<FormikForm
				// readonly
				changeSet={changeSet}
				onSubmit={(values) => handleSubmit(values)}
			>
				<Stack className='form' spacing='1.2rem'>
					<FormikTextField
						name='name'
						label='Full name'
						autoComplete='name'
						required
					/>
					<FormikTextField
						name='email'
						label='Contact email'
						autoComplete='email'
						required
					/>

					<AlertView />

					<Box className='action-buttons'>
						<FilledButton type='submit' size='small' disabled={isLoading}>
							Invite
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</Stack>
	);
}

export default InviteUser;
