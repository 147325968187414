import {
	createCustomer,
	inviteCustomer, resetCustomerPassword,
	updateCustomer,
	uploadCustomers,
} from 'src/services/company.service';
import { useServiceCallHook } from 'src/utilities/hooks/genHooks';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';

const serviceFunction = (params) =>
	params.path ? updateCustomer(params) : createCustomer(params);
// eslint-disable-next-line react-hooks/rules-of-hooks
const useMutateCustomer = useServiceCallHook(serviceFunction, {
	useLoadingHook,
});
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useUploadCustomer = useServiceCallHook(uploadCustomers, {
	useLoadingHook,
});
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useInviteCustomer = useServiceCallHook(inviteCustomer, {
	useLoadingHook,
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useResetCustomerPassword = useServiceCallHook(resetCustomerPassword, {
	useLoadingHook,
});

export default useMutateCustomer;
