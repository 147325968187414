import { CheckBoxRounded } from '@mui/icons-material';
import { Avatar, AvatarGroup } from '@mui/material';
import Box from '@mui/material/Box';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import SourceDisplay from 'src/components/properties/SourceDisplay';
import { Body3, H5 } from 'src/components/Typography';
import { TASK_EDIT_URL_LOOKUP } from 'src/consts/tasks';
import { useCallback, useMemo } from 'react';
import { mr1 } from 'src/styles/margins';
import getInitialsFromString from 'src/utilities/getInitialsFromString';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import Bolder from 'src/components/Typography/Bolder';
import IdIcon from 'src/components/icons/Id';

function AssigneeUsers({ assignees = [] }) {
	return (
		<AvatarGroup max={4} className='assigneeList'>
			{assignees.map(([id, name]) => (
				<Avatar key={id} alt={name} className='avatar'>
					{name ? getInitialsFromString(name) : null}
				</Avatar>
			))}
		</AvatarGroup>
	);
}

function AssetSummary({ label, items }) {
	if (!items || !items.count) return null;

	const { bl, abv } = items;

	return <LiquidDisplay label={label} abv={abv} value={bl} />;
}

function ParentAsset({ parent_asset_id }) {
	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<IdIcon style={{ width: '12px', height: 'auto' }} />
			<Body3>
				<Bolder>{'Parent Asset: '}</Bolder>
				&nbsp;
				{parent_asset_id}
			</Body3>
		</Box>
	);
}

function TaskCard({ task, selected = false, onSelect }) {
	const navigate = useNavigate();

	const {
		id,
		type,
		batch,
		date,
		dueDate,
		action,
		assignees,
		path,
		// client,
		sources,
		destinations,
		parent_asset_id,
		// shipAddress,
	} = task;
	// console.log('Task', id, sources, destinations);

	const onClick = useCallback(
		(e) => {
			if (e.detail === 2) {
				if (!batch) {
					navigate(`${TASK_EDIT_URL_LOOKUP[type]}/${path}`);
				}
				e.stopPropagation();
			}
			if (e.detail === 1) {
				onSelect(!selected);
			}
		},
		[selected, batch, type, navigate, path],
	);

	const _id = useMemo(() => {
		const [, , , ...rest] = id.split('/');
		return batch ? rest.join('/') : id;
	}, [batch, id]);
	return !id ? null : (
		<Box
			sx={styles}
			className='root'
			action={action}
			id={path}
			onClick={onClick}
		>
			<Box className='tab'>
				{selected && <CheckBoxRounded className='check' sx={{ ...mr1 }} />}
			</Box>
			<Box className='task-card-content'>
				<Box className='header'>
					<Box sx={{ flexDirection: 'row', display: 'flex' }}>
						<H5 className='action' sx={{ flexGrow: 1 }}>
							{_id}
						</H5>
					</Box>
					<Body3 className='date'>{dueDate ?? date}</Body3>
				</Box>
				<Box className='body'>
					<SourceDisplay type={type} name={action} />

					{sources && <AssetSummary label='Source' items={sources} />}
					{destinations && (
						<AssetSummary label='Destination' items={destinations} />
					)}
					{parent_asset_id && <ParentAsset parent_asset_id={parent_asset_id} />}
				</Box>
				<Box className='footer'>
					<AssigneeUsers assignees={assignees} />
				</Box>
			</Box>
		</Box>
	);
}

export default TaskCard;
