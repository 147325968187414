import { useCallback, useMemo } from 'react';
import {
	IMPERIAL_LIQUID_OPTIONS,
	IMPERIAL_MEASURES,
	IMPERIAL_OPTIONS,
	LIQUID_OPTIONS,
	MEASURES,
	METRIC_MEASURES,
	METRIC_OPTIONS,
	toMeasure,
} from 'src/consts/measures';
import useIsUnitFormatImperial from './useIsUnitFormatImperial';

const useMeasures = () => {
	// Determine if imperial metrics are in application here
	const imperial = useIsUnitFormatImperial();
	// Grab the measure
	const measures = useMemo(
		() => (imperial ? IMPERIAL_MEASURES : METRIC_MEASURES),
		[imperial],
	);
	// Grab the measurement options
	const options = useMemo(
		() => (imperial ? IMPERIAL_OPTIONS : METRIC_OPTIONS),
		[imperial],
	);

	// Grab the measurement options
	const liquid_options = useMemo(
		() => (imperial ? IMPERIAL_LIQUID_OPTIONS : LIQUID_OPTIONS),
		[imperial],
	);

	const fixed = (num, digits) => num.toFixed(digits);

	const convertToImperial = useCallback((val, measure) => {
		let mul;
		if (typeof measure === 'string') {
			// This could be an old-style measure - should not happen in prod, but possible in dev
			// so be backwards compatible..
			const { t } = MEASURES[toMeasure(measure)];
			mul = t;
		} else {
			mul = measure.t;
		}
		return fixed(val * mul, 3);
	}, []);

	const convertToMetric = useCallback((val, measure) => {
		let mul;
		if (typeof measure === 'string') {
			// This could be an old-style measure - should not happen in prod, but possible in dev
			// so be backwards compatible..
			const { f } = MEASURES[toMeasure(measure)];
			mul = f;
		} else {
			mul = measure.f;
		}
		return fixed(val * mul, 3);
	}, []);

	const to = useCallback(
		(val, measure) => (imperial ? convertToImperial(val, measure) : val),
		[convertToImperial, imperial],
	);

	const from = useCallback(
		(val, measure) => (imperial ? convertToMetric(val, measure) : val),
		[convertToMetric, imperial],
	);

	const getMeasure = useCallback(
		(unit) => measures[toMeasure(unit)],
		[measures],
	);

	const scale = useCallback(
		(val, fromUnit, toUnit) => {
			const tu = getMeasure(fromUnit);
			const converter = tu.scale[toMeasure(toUnit)];
			if (converter) {
				return converter(Number(val));
			}
			return Number(val);
		},
		[measures, getMeasure],
	);

	return useMemo(
		() => ({
			imperial,
			getMeasure,
			options,
			to,
			from,
			scale,
			liquid_options,
		}),
		[imperial, getMeasure, options, liquid_options, to, from],
	);
};

export default useMeasures;
