import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import ScanQR from 'src/components/ScanQR';
import DisplayResults from 'src/components/Search/DisplayResults';
import { H5, H6 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import { useAssetLazyQuery } from 'src/utilities/hooks/useAssetQuery';
import { useGetSKUList } from 'src/utilities/hooks/useGetSKUListQuery';
import { useRequestLazyQuery } from 'src/utilities/hooks/useRequestQuery';
import { useSearchLazyQuery } from 'src/utilities/hooks/useSearchQuery';
import SearchField from './SearchField';

function GeneralSearch({
	title,
	sectionTitle,
	label,
	types,
	onItemClick,
	count,
	buttonIcon,
	scan = true,
	enable = true,
	initValue = null,
	disabled = false,
	multiple = true,
}) {
	const [fetchAsset] = useAssetLazyQuery();
	const [fetchRequest] = useRequestLazyQuery();
	const { enqueueSnackbar } = useSnackbar();
	const [show, setShow] = useState(false);
	const [search, {data: searchResults = {}, isLoading }] = useSearchLazyQuery({});
	const [skuSearch] = useGetSKUList();
	const [skuResults, setSkuResults] = useState([]);

	const {
		sku_types, ...rest
	} = types ?? {};

	const handleButtonClick = useCallback(
		(asset) => {
			if (onItemClick) {
				onItemClick(asset);
			}
			if (!multiple) {
				setShow(false);
			}
		},
		[multiple, onItemClick],
	);

	// https://test.metacask.com:4430/cask/edit/c/c-t/123 => [c,c-t,123]
	const handleOnQrRead = useCallback(
		async ({ text: qrString }) => {
			console.log('Read QR code', qrString);
			try {
				const [token_address, chain, platform] = qrString.split('/').reverse();
				const path = [platform, chain, token_address].join('/');
				let result;
				console.log('Path', path);
				if (Object.keys(ASSET_TYPES).some((item) => qrString.includes(item))) {
					result = await fetchAsset(path);
				} else {
					result = await fetchRequest(path);
				}
				if (
					result &&
					result.path &&
					(types?.asset_types?.includes(result?.asset_type) ||
						types?.request_types?.includes(result?.request_type))
				) {
					// Because this was done by QR code, then we can mark this as processed already...
					handleButtonClick({
						...result,
						processed: ASSET_PROCESSED_STATUS.CONFIRMED,
					});
				}
			} catch (error) {
				enqueueSnackbar('Asset not found', { variant: 'info' });
				console.log(error);
			}
		},
		[enqueueSnackbar, fetchAsset, fetchRequest, handleButtonClick, types?.asset_types, types?.request_types],
	);

	const handleClear = () => {
		setShow(false);
		// setSearching(false);
	};

	const handleSearch = useCallback(
		async (val) => {
			// Find all the matching SKU types
			console.log('Searching', val, sku_types, rest);

			let sv = false;
			if (sku_types) {
				const sr = skuSearch(sku_types, val);
				console.log('SKU matches', sr.length);
				if (sr.length > 0) {
					setSkuResults(sr);
					setShow(true);
				}
			}

			if (Object.keys(rest).length > 0) {
				console.log('Remote search', val);
				search({
					search: val,
					...rest,
				}).catch((e) => {
					console.log('Failed to search', e);
				}).finally(() => {
					setShow(true);
				});
			}
		},
		[search, types],
	);

	useEffect(() => {
		if (!multiple && count === 1) {
			setShow(false);
		}
	}, [setShow, multiple, count]);

	useEffect(() => {
		if (initValue) {
			handleSearch(initValue).finally(() => {});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initValue]);

	return (
		<Stack spacing={1} sx={{ width: '100%' }}>
			{title && <H5 className="section-header">{title}</H5>}
			{sectionTitle && <H6 className="section-title">{sectionTitle}</H6>}
			{enable && (
				<Stack className='search-results' spacing="1rem">
					<FlexBox gap={1}>
						{scan && (
							<ScanQR
								onSuccess={handleOnQrRead}
								// closeOnSuccess={!multiple}
								closeOnSuccess
								disabled={disabled || (!multiple && count === 1)}
							/>
						)}
						<SearchField
							label={label}
							id={label}
							initValue={initValue}
							disabled={disabled || (!multiple && count === 1)}
							searching={isLoading}
							onClear={handleClear}
							onSearch={handleSearch}
							fullWidth
						/>
					</FlexBox>
					{show && (
						<Collapse in={show}>
							<DisplayResults
								{...searchResults}
								skus={skuResults}
								buttonIcon={buttonIcon}
								onItemClick={onItemClick}
							/>
						</Collapse>
					)}
				</Stack>
			)}
		</Stack>
	);
}

export default GeneralSearch;
