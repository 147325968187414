import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import withEmptyCard from 'src/components/Cards/EmptyCard';
import Dashboard from 'src/components/Dashboard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { selectActiveCompanyId } from 'src/redux/containers/Companies';
import { useAllFacilitiesQuery } from 'src/utilities/hooks/useAllFacilitiesQuery';
import useOverviewQuery from 'src/utilities/hooks/useOverviewQuery';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function DashboardPage() {
	// const isCompanyActive = useSelector(selectIsCompanyActive);
	const activeCompanyId = useSelector(selectActiveCompanyId);
	console.log('Company is active', true, activeCompanyId);
	const { data: allFacilitiesMap = {} } = useAllFacilitiesQuery();
	const facilities = useMemo(() => {
		const optionMap = new Map();
		Object.entries(allFacilitiesMap).forEach(([id, facility]) => {
			// on warehouse level
			if (!optionMap.has(`${id}/0/0/0`)) {
				optionMap.set(`${id}/0/0/0`, `${facility.facility_name}`);
			}
			// on bay level
			// facility.bays.forEach(([facility_bay]) => {
			// 	if(!optionMap.has(`${id}/${facility_bay}/0/0`)){
			// 		optionMap.set(`${id}/${facility_bay}/0/0`, `${facility.facility_name} - ${facility_bay}`);
			// 	}
			// });
		});
		return Array.from(optionMap).map(([id, title]) => ({ id, title }));
	}, [allFacilitiesMap]);

	const { data = {}, refetch = () => {} } = useOverviewQuery(
		{},
		{ skip: false },
	);
	console.log('Loaded data', data);
	usePageTitle('Dashboard');
	return <Dashboard data={data} refetch={refetch} facilities={facilities} />;
}

export default withAppLayout(withEmptyCard(DashboardPage));
