import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNetwork } from 'src/redux/containers/NetworkSwitch';

const useIsNetworkOnline = () => {
	const [isOnline, setOnlineStatus] = useState(window.navigator.onLine);

	useEffect(() => {
		const toggleOnlineStatus = () => setOnlineStatus(window.navigator.onLine);

		window.addEventListener('online', toggleOnlineStatus);
		window.addEventListener('offline', toggleOnlineStatus);

		return () => {
			window.removeEventListener('online', toggleOnlineStatus);
			window.removeEventListener('offline', toggleOnlineStatus);
		};
	}, [isOnline]);

	return isOnline;
};

const useIsOnline = () => {
	const isOnline = useIsNetworkOnline();
	const isOnlineState = useSelector(selectNetwork);
	return {
		isOffline: !(isOnline && isOnlineState),
		isOnline: isOnline && isOnlineState,
	};
};

export default useIsOnline;
