import { selectAuthorizationToken } from 'src/redux/containers/Authorization';
import { selectCompanyId } from 'src/redux/containers/Companies';
import { selectAccountId } from 'src/redux/containers/User';
import { store } from 'src/redux/store';
import { checkResponseStatus } from './checkResponseStatus';

const fetchPut = async (url, headers = {}, body = {}) => {
	const state = store.getState();
	const response = await fetch(url, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': selectAuthorizationToken(state),
			'SW-Scope': JSON.stringify({
				'Company': selectCompanyId(state),
				'AccountId': selectAccountId(state),
			}),
			...headers,
		},
		body: JSON.stringify(body),
	});
	checkResponseStatus(response.status);
	const jsonResponse = await response.json();
	if (!response.ok) {
		throw jsonResponse;
	}
	return jsonResponse;
};

export default fetchPut;
