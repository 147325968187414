/* eslint-disable react-hooks/rules-of-hooks */
import { confirmPartnerRequest } from 'src/services/company.service';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import { useServiceCallHook } from './genHooks';

/** used to confirm partner request */
export const useConfirmPartnerMutation = useServiceCallHook(
	confirmPartnerRequest,
	{ useLoadingHook },
);
