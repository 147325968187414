/* eslint-disable react-hooks/rules-of-hooks */
import { createPartner, updatePartner } from 'src/services/company.service';
import { useServiceCallHook } from 'src/utilities/hooks/genHooks';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';

/** used to modify */
const usePartnerMutation = useServiceCallHook(
	(params) => (params.path ? updatePartner(params) : createPartner(params)),
	{ useLoadingHook },
);

export default usePartnerMutation;
