import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Body3 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import LiquidIcon from 'src/components/icons/Liquid';
import { MEASURE } from 'src/consts/measures';
import useMeasures from 'src/utilities/hooks/useMeasures';

function StrengthDisplay({ label = 'Strength', value, tcf }) {
	const { to, getMeasure } = useMeasures();

	const alc = useMemo(() => getMeasure(MEASURE.alc), [getMeasure]);

	const abvVal = useMemo(() => (value ? Number(to(value, MEASURE.alc)) : null), [to, value]);

	if (!value) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LiquidIcon style={{ width: '12px', height: '14px' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{abvVal?.toFixed(2)}
				<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{alc.value}</Bolder>
				{tcf && (
					<>
						{' | '}
						<Bolder>TCF:</Bolder>
						&nbsp;
						{Number(tcf).toFixed(3)}
					</>
				)}
			</Body3>
		</Box>
	);
}

export default StrengthDisplay;
