import { useCallback, useMemo } from 'react';
import Address from 'src/components/Address';
import { addressFields } from 'src/components/Address/addressFields';
import { clientFields } from 'src/components/Client/clientFields';
import { FormikCheckBox } from 'src/components/Forms/FormikForm';
import useSourceInventoryHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import Transport from 'src/components/Transport';
import { transportFields } from 'src/components/Transport/transportFields';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import { FormikProvider } from 'src/providers/FormikProvider';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { destination = {}, transport = {}, duty_paid = false } = fields;

	return {
		destination: addressFields(destination),
		transport: transportFields(transport),
		duty_paid: [duty_paid, yup.boolean()],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Address name='destination' label='Destination' />
			<Transport name='transport' label='Transport' />
			<FormikCheckBox name='duty_paid' label='Duty Paid' />
		</FormikProvider>
	);
}

function ShipForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Order',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.tanker,
				ASSET_TYPES.container,
			],
			request_types: [REQUEST_TYPES.pick],
			sku_types: [
				SKU_TYPES.WASTE,
				SKU_TYPES.FINISHED,
				SKU_TYPES.TRACKED,
				SKU_TYPES.SERVICE,
			],
		},
		submitForm,
		SKUModal: SourceSKUModal,
		extendedSummary: true,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
		},
		[initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
		}),
		[sourceSkus, sourceAssets],
	);

	return (
		<>
			<RequestForm
				requestLabel='Shipment'
				requestType={REQUEST_TYPES.ship}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />

				<Properties />
				{/*<FormSubmitter />*/}
			</RequestForm>

			<SourceModals />
		</>
	);
}

export default withAppLayout(ShipForm, { title: 'Shipments' });
