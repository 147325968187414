/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useMemo } from 'react';
import { CLIENT_TYPE } from 'src/consts/contacts';
import {
	listCustomers,
	searchPartnersAndRequestsRequest,
} from 'src/services/company.service';
import { useServiceCallHook } from 'src/utilities/hooks/genHooks';
import usePaginated from 'src/utilities/hooks/usePaginated';

export const useCustomersLazyQuery = useServiceCallHook(listCustomers);

export const useCustomersLazyQueryPaginated = (params) =>
	usePaginated(useCustomersLazyQuery(), params);

export const useCustomersPartnersSearchLazyQuery = useServiceCallHook(
	searchPartnersAndRequestsRequest,
);

export function useCustomersPartnersSearch() {
	const [getCustomersApi, { data, isLoading }] =
		useCustomersPartnersSearchLazyQuery();
	const customerOptions = useMemo(
		() =>
			(data?.customers ?? []).map((customer) => ({
				id: customer?.account_id,
				hash: customer?.account_hash,
				name: customer?.account_name ?? customer?.account_username,
				public_name: customer?.account_username,
				type: CLIENT_TYPE.ACCOUNT,
			})),
		[data],
	);
	const partnerOptions = useMemo(
		() =>
			(data?.partners ?? []).map((partner) => ({
				id: partner?.company_id,
				hash: partner?.company_key_hash,
				name: partner?.company_name,
				public_name: partner?.company_public_name ?? partner?.company_name,
				type: CLIENT_TYPE.COMPANY,
			})),
		[data],
	);
	const handleOnSearchDebounced = useCallback(
		(search = '') => getCustomersApi({ search, limit: 5 }),
		[getCustomersApi],
	);
	return [
		{ customerOptions, partnerOptions },
		handleOnSearchDebounced,
		isLoading,
	];
}
