import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import { FormikCheckBox, FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { H5, Overline } from 'src/components/Typography';
import { updateCompany } from 'src/services/company.service';
import styles from 'src/styles/content.styles';
import useAlertView from 'src/utilities/hooks/useAlertView';
import { useCompanyQuery } from 'src/utilities/hooks/useCompanyQuery';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import * as yup from 'yup';
import Address from './Address';

function CompanyForm({ company }) {
  const [isLoading, setLoading] = useLoadingHook()
  const [AlertView, { setAlert }] = useAlertView();

  const {
    company_compliance = {},
    company_settings = { imperial: false },
    company_address = {},
  } = company;

  console.log('Company', company);

  const onSubmit = async (values) => {
    console.log('Saving company', values);
    setLoading(true);
    try {
      const response = await updateCompany({
        id: company.id,
        path: company.path,
        ...values,
      });
      console.log('Updated company', response);

    } catch (e) {
      console.log('Failed to update company!', e);
      setAlert({
        severity: 'error',
        title: 'Failed create',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const changeSet = {
    // Set the editable properties
    excise_id: [company_compliance.excise_id ?? '', yup.string()],
    compliance_id: [company_compliance.compliance_id ?? '', yup.string()],
    wowgr: [company_compliance.wowgr, yup.boolean()],
    duty_req: [company_compliance.duty_req, yup.boolean()],
    // settings
    imperial_units: [company_settings.imperial, yup.boolean()],
    //  address
    line1: [company_address.address_1 ?? '', yup.string().required('Address is required!')],
    line2: [company_address.address_2 ?? '', yup.string()],
    line3: [company_address.address_3 ?? '', yup.string()],
    city: [company_address.city ?? '', yup.string().required('City is required!')],
    state: [company_address.state ?? '', yup.string()],
    country: [company_address.country ?? '', yup.string().required('Country is required!')],
    postCode: [company_address.post_code ?? '', yup.string().required('Post code is required!')],
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={onSubmit}
    >
      <Stack className="form" spacing="2rem">
        <Stack spacing="1.5rem">
          <Stack spacing="0.75rem">
            <Overline>Name</Overline>
            <H5>{company.company_name}</H5>
          </Stack>
          <Stack spacing="0.75rem">
            <Overline>Proofworks ID</Overline>
            <H5>{company.company_id}</H5>
          </Stack>
          <Stack spacing="0.75rem">
            <Overline>Registration</Overline>
            <H5>{company_compliance.registration_id}</H5>
          </Stack>
          <FormikTextField name="excise_id" label="Excise/Tax id" />
          <FormikTextField name="compliance_id" label="Compliance reporting id" />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Organization Memberships</H5>
          <Instructions>
            If you have inventory in the UK, current regulations may require your company to registered under&nbsp;
            WOWGR (if you are UK based) or alternatively have Duty Representation.
            <br />
            Please indicate if you have or require this.
          </Instructions>
          <FormikCheckBox name="wowgr" label="Company is registered for WOWGR" />
          <FormikCheckBox name="duty_req" label="Require Duty Representation" />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Organization Settings</H5>
          <Instructions>
            The platform uses Metric units by default, check this to enable Imperial Units (US) for display.
          </Instructions>
          <FormikCheckBox name="imperial_units" label="Imperial Units" />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Organization Address</H5>
          <Address />
        </Stack>

        <AlertView />

        <Box className='action-buttons'>
          <FilledButton type='submit' size='small' disabled={isLoading}>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

function Company() {
  const [target, scroll] = useScrollTarget();

  const { data: company } = useCompanyQuery();

  useEffect(() => scroll(), [company]);

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      {company && <CompanyForm company={company} />}
    </Stack>
  );
}

export default Company;
