import { ArrowForward, DownloadOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { ASSET_EDIT_URL, ASSET_TYPES_REVERSE } from 'src/consts/asset';
import { getAsset } from 'src/services/asset.service';
import downloadAssetCsv from 'src/utilities/downloadAssetCsv';
import useMeasures from 'src/utilities/hooks/useMeasures';
import pathToParams from 'src/utilities/pathToParams';

function ReportAssetTable({ assetIds }) {
	const { getMeasure, to: cto } = useMeasures();
	const [items, setItems] = useState([]);

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const loadAssets = useCallback(async () => {
		const arr = assetIds.map((id) => getAsset(pathToParams(id)));
		setLoading(true);
		const res = await Promise.all([...arr]);
		setLoading(false);

		setItems(
			res.map((i) => {
				const { path, rw_asset_id, properties = {}, unique_location_id, parent_asset } = i;
				const [
					type = '0',
					facility = '0',
					bay = '0',
					row = '0',
					locationLevel = '0',
				] = unique_location_id ? unique_location_id.split('/') : [];
				const assetType =
					ASSET_TYPES_REVERSE[i?.asset_type] ?? i?.asset_type ?? '';

				const {
					liquid = {}, sku: barrelSku, reference
				} = properties ?? {};

				const { level = {}, sku: liquidSku } = liquid;
				const { enable = false, bl, abv, la, expected_bl, tcf } = level;

				const liq = getMeasure('l_m');
				const alc = getMeasure('alc');

				const laVal = la ? Number(cto(la, liq)) : null;
				const bulkVal = bl ? Number(cto(bl, liq)) : null;
				const abvVal = abv ? Number(cto(abv, alc)) : null;

				const location =
					type !== '0'
						? `${type}/${facility}/${bay}/${row}/${locationLevel}`
						: 'No location';
				return {
					id: rw_asset_id,
					path,
					asset_type: i.asset_type,
					type: assetType,
					reference,
					bulk: enable && bulkVal ? `${bulkVal?.toFixed(2)}${liq.value}` : '',
					la: enable && laVal ? `${laVal?.toFixed(2)}${liq.value}` : '',
					abv: enable && abvVal ? `${abvVal?.toFixed(2)}${alc.value}` : '',
					tcf: enable && tcf ? Number(tcf)?.toFixed(3) : '1.000',
					barrelSku: barrelSku?.sku_name,
					liquidSku: liquidSku?.sku_name,
					parent: parent_asset?.rw_asset_id,
					location,
				};
			}),
		);
	}, [assetIds]);

	useEffect(() => {
		if (assetIds?.length) loadAssets();
		else setLoading(false);
	}, []);

	const goToItem = (params) => {
		const id = params?.path ?? params?.asset_id ?? 'new';
		const route = ASSET_EDIT_URL?.[params?.asset_type];
		navigate(`${route}/${id}`, { replace: true });
	};

	const onExport = useCallback(() => {
		downloadAssetCsv(items);
	}, [items]);

	return (
		<Stack sx={{ width: '100%' }} spacing={2}>
			<FlexBox sx={{ justifyContent: 'space-between' }}>
				<H5>Assets</H5>

				<TitledButton
					handleClick={onExport}
					variant='outlined'
					color='secondary'
					label='CSV'
					disabled={loading}
					sx={{ width: '42px', height: '52px' }}
				>
					<DownloadOutlined height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box sx={{ overflow: 'auto' }}>
				<Table sx={{ width: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Reference</TableCell>
							<TableCell align='right'>Type</TableCell>
							<TableCell align='right'>Bulk</TableCell>
							<TableCell align='right'>Alcohol</TableCell>
							<TableCell align='right'>Strength</TableCell>
							<TableCell align='right'>TCF</TableCell>
							<TableCell align='right'>Barrel</TableCell>
							<TableCell align='right'>Liquid</TableCell>
							<TableCell align='right'>Parent</TableCell>
							<TableCell align='right'>Location</TableCell>
							<TableCell align='right' />
						</TableRow>
					</TableHead>

					{loading && (
						<TableBody>
							<TableRow>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
								<TableCell><Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} /></TableCell>
							</TableRow>
						</TableBody>
					)}

					{!loading && (
						<TableBody>
							{items?.map((row) => (
								<TableRow
									key={row.path}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component='th' scope='row'>{row.id}</TableCell>
									<TableCell>{row.reference}</TableCell>
									<TableCell align='right'>{row.type}</TableCell>
									<TableCell align='right'>{row.bulk}</TableCell>
									<TableCell align='right'>{row.la}</TableCell>
									<TableCell align='right'>{row.abv}</TableCell>
									<TableCell align='right'>{row.tcf}</TableCell>
									<TableCell align='right'>{row.barrelSku}</TableCell>
									<TableCell align='right'>{row.liquidSku}</TableCell>
									<TableCell align='right'>{row.parent}</TableCell>
									<TableCell align='right'>{row.location}</TableCell>
									<TableCell align='right'>
										<IconCircleButton onClick={() => goToItem(row)}>
											<ArrowForward />
										</IconCircleButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</Table>
			</Box>
		</Stack>
	);
}

export default ReportAssetTable;
