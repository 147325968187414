import isEmpty from 'lodash.isempty';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { selectAccountId } from 'src/redux/containers/User';
import { useAccountHasPermission } from 'src/utilities/hooks/useAccountHasPermission';

export function AuthGuard({ children, permissions = [] }) {
	const params = useParams();
	const accountId = useSelector(selectAccountId);
	const location = useLocation();

	const isAuth = !isEmpty(accountId);
	const hasPermission = useAccountHasPermission(permissions);

	// console.debug('User', isAuth, hasPermission);

	// If not authenticated, then route to sign in
	if (!isAuth)
		return <Navigate to='/signin' replace state={{ from: location }} />;

	return [isAuth, hasPermission].every(Boolean) ? (
		<div key={JSON.stringify(params)}>{children}</div>
	) : (
		<Navigate to='/not-found' replace state={{ from: location }} />
	);
}

export default AuthGuard;
