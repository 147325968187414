import Stack from '@mui/material/Stack';
import { Overline } from 'src/components/Typography';
import GaugeComponent from 'react-gauge-component';
import SparkLines from './SparkLines';

const styles = {
	'&.root': {
		'.gauge': {
			width: '250px',
			height: '200px',
		},

		'.widget-label': {
			marginTop: '-1.5rem',
			backgroundColor: 'rgba(0, 0, 0, 0.02)',
			display: 'flex',
			width: 'min-content',
			textAlign: 'center',
			color: '#41424c',
			paddingTop: '0.35rem',
			paddingBottom: '0.35rem',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			borderRadius: '5px',
			overflow: 'hidden',
			'--shadow-color': '0deg 0% 63%',
			boxShadow: `
        0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
			border: '1px solid rgba(0, 0, 0, 0.15)',
		},
	},
};

function HumidityWidget({ data, last }) {
	return (
		<Stack sx={styles} className='root' alignItems='center'>
			<GaugeComponent
				className='gauge'
				value={last}
				type='radial'
				marginInPercent={0.04}
				labels={{
					valueLabel: {
						maxDecimalDigits: 1,
						style: {
							fill: '#41424c',
							textShadow: 'none',
						},
					},
					tickLabels: {
						type: 'inner',
						ticks: [
							{ value: 20 },
							{ value: 40 },
							{ value: 60 },
							{ value: 80 },
							{ value: 100 },
						],
					},
				}}
				arc={{
					colorArray: ['#1E1C1C', '#F0AA29'],
					// gradient: true,
					nbSubArcs: 25,
					padding: 0.01,
					width: 0.4,
					cornerRadius: 4,
				}}
				pointer={{
					animationDelay: 0,
					width: 4,
				}}
			/>
			<Overline className='widget-label'>Humidity</Overline>

			<SparkLines data={data} />
			<Overline style={{ marginTop: '0.5rem' }}>Last 2 days</Overline>
		</Stack>
	);
}

export default HumidityWidget;
