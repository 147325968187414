import mapToOptions from 'src/utilities/mapToOptions';

export const COMPANY_STATUS = {
	PENDING: 'pending',
	ACTIVE: 'active',
	SUSPENDED: 'suspended',
	READONLY: 'readonly',
};

export const UNIT_FORMAT_TYPES = {
	Imperial: 'Imperial',
	Metric: 'Metric',
};

export const UNIT_OPTIONS = mapToOptions(UNIT_FORMAT_TYPES);
