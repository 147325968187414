import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { Body3 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import LiquidIcon from 'src/components/icons/Liquid';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import StrengthDisplay from 'src/components/properties/StrengthDisplay';

/**
 * Renders a component that displays liquid-related information, including level and strength. and used for search results
 *
 * @param {Object} props - The component props.
 * @param {string} props.bl - The base level value.
 * @param {string} props.la - The lactic acid value.
 * @param {string} props.abv - The alcohol by volume value.
 * @param {string} props.tcf - The total concentration factor value.
 * @param {string} props.weightFactor - The weight factor value.
 * @returns {JSX.Element} The rendered component.
 */

function LiquidContentSearch({ bl, la, abv, tcf, weightFactor }) {
	return (
		<Stack spacing={0.5}>
			<LiquidDisplay empty={![bl, la].every(Boolean)} value={bl} la={la} />
			<StrengthDisplay value={abv} tcf={tcf} />

			{weightFactor && (
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						gap: '0.25rem',
					}}
				>
					<LiquidIcon style={{ width: '12px', height: 'auto' }} />
					<Body3>
						<Bolder>Weight Factor:</Bolder>
						&nbsp;
						{weightFactor}
					</Body3>
				</Box>
			)}
		</Stack>
	);
}
LiquidContentSearch.propTypes = {
	bl: PropTypes.string,
	la: PropTypes.string,
	abv: PropTypes.string,
	tcf: PropTypes.string,
	weightFactor: PropTypes.string,
};

export default LiquidContentSearch;
