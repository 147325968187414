import { useSelector } from 'react-redux';
import { selectAccountPerms } from 'src/redux/containers/User';
import { checkPermission } from 'src/utilities/checkPermission';
export function checkPermissions(permissions = [], accountPerms = []) {
	return permissions.some((permission) =>
		checkPermission(accountPerms, permission),
	);
}
export function useAccountHasPermission(permissions = []) {
	const accountPerms = useSelector(selectAccountPerms);
	permissions = Array.isArray(permissions) ? permissions : [permissions];
	if (permissions.length === 0) {
		return true;
	}
	return checkPermissions(permissions, accountPerms);
}

export default useAccountHasPermission;
