import ApproveModal from 'src/components/Tasks/TaskModal/ApproveModal';
import ArchiveModal from 'src/components/Tasks/TaskModal/ArchiveModal';
import AssignModal from 'src/components/Tasks/TaskModal/AssignModal';
import CancelModal from 'src/components/Tasks/TaskModal/CancelModal';

function TaskModal({ tasks, users, actionKey, onClose }) {
	if (!actionKey || !tasks || tasks.length === 0) {
		return null;
	}

	if (actionKey === 'approve_selected' || actionKey === 'reject_selected') {
		return <ApproveModal actionKey={actionKey} tasks={tasks} onClose={onClose} />;
	}

	if (actionKey === 'archive_selected') {
		return <ArchiveModal tasks={tasks} onClose={onClose} />;
	}

	if (actionKey === 'cancel_selected') {
		return <CancelModal tasks={tasks} onClose={onClose} />;
	}

	// Default
	return <AssignModal tasks={tasks} users={users} onClose={onClose} />;
}

export default TaskModal;
