import Stack from '@mui/material/Stack';
import { useCallback, useMemo } from 'react';
import { FormikSelect } from 'src/components/Forms/FormikForm';
import useInventorySelectorHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import LiquidProperties, {
	liquidPropertyFields,
} from 'src/components/Liquid/LiquidProperties';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import DestinationAssetModal from 'src/components/SKUSelector/modals/DestinationAssetModal';
import DestinationLiquidModal from 'src/components/SKUSelector/modals/DestinationLiquidModal';
import DestinationSKUModal from 'src/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { H4 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import { FormikProvider } from 'src/providers/FormikProvider';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';
import { useAssetByTypeQuery } from 'src/utilities/hooks/useAssetByTypeQuery';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';
import useMeasures from 'src/utilities/hooks/useMeasures';
import * as yup from 'yup';

function Properties() {
	return (
		<FormikProvider path='properties'>
			<LiquidProperties name='liquid' useWeight batchRequired useBulk={false} />
		</FormikProvider>
	);
}

function propertyFields(fields = {}, measures = {}) {
	const { liquid = {} } = fields;

	return {
		liquid: liquidPropertyFields(liquid, measures, true, true),
	};
}

function getTankId(entity) {
	const { destinations = [] } = entity ?? {};
	console.log('Tank id', destinations);
	if (destinations.length === 1) {
		return destinations[0].asset_id;
	}
	return '';
}

function ProductionRunForm(props) {
	const measures = useMeasures();
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [[sourceSkus], , initSources, SourceInventory, SourceModals] =
		useInventorySelectorHook({
			title: 'Input',
			filter: {
				request_types: [REQUEST_TYPES.pick],
				sku_types: [
					SKU_TYPES.RAW,
					SKU_TYPES.EXPIRING,
					SKU_TYPES.CONSUMABLE,
					SKU_TYPES.SERVICE,
					SKU_TYPES.WIP,
				],
			},
			submitForm,
			SKUModal: SourceSKUModal,
		});

	const [
		[destinationSkus],
		[destinationAssets],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Output',
		filter: {
			asset_types: [
				ASSET_TYPES.filling_tank,
			],
			sku_types: [SKU_TYPES.WIP, SKU_TYPES.WASTE, SKU_TYPES.SERVICE],
		},
		assetAdapter: toTaggedAsset,
		submitForm,
		SKUModal: DestinationSKUModal,
		AssetModal: DestinationLiquidModal,
	});
	//
	// const { data: tanks = [] } = useAssetByTypeQuery({
	// 	type: ASSET_TYPES.filling_tank,
	// });
	// console.log('Tanks', tanks);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			// properties: propertyFields(initialValues.properties, measures),
		}),
		[measures],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: entity?.sku_destinations,
			});
		},
		[initSources, initDestinations],
	);

	// On the way out, add the sku list in
	const handleBeforeSave = useCallback(
		(entity) => {
			// const dest_tank = toTaggedAsset(
			// 	tanks.find((t) => t.path === entity.tank_id),
			// );
			// /** get confirmed tanker asset */
			// if (destinationAssets?.[0]?.processed === '1')
			// 	dest_tank.processed = destinationAssets?.[0].processed;
			return {
				...entity,
				// tank_id: null,
				sku_sources: sourceSkus,
				destinations: destinationAssets,
				sku_destinations: destinationSkus,
			};
		},
		[destinationAssets, sourceSkus, destinationSkus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Production Run'
				requestType={REQUEST_TYPES.production_run}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />

				{/*<Stack spacing={2}>*/}
				{/*	<H4>Destination Tank</H4>*/}
				{/*	<FormikSelect*/}
				{/*		fullWidth*/}
				{/*		options={tanks.map((t) => ({*/}
				{/*			value: t.path,*/}
				{/*			label: t.rw_asset_id,*/}
				{/*		}))}*/}
				{/*		label='Production Tank'*/}
				{/*		name='tank_id'*/}
				{/*	/>*/}
				{/*</Stack>*/}

				<DestinationInventory />

				{/*<Properties />*/}
				<FormSubmitter />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
		</>
	);
}

export default withAppLayout(ProductionRunForm, { title: 'Liquid Production' });
