import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { ASSET_TYPES } from 'src/consts/asset';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import { getAsset } from 'src/services/asset.service';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';
import debounce from 'src/utilities/debounce';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';
import useConfirm from 'src/utilities/hooks/useConfirm';
import pathToParams from 'src/utilities/pathToParams';

const serviceFunction = (params) => getAsset(pathToParams(params));

export const [useAssetLazyQuery, useAssetQuery] =
	genServiceCallHooks(serviceFunction);


export const useAddAssetHook = (assets, upsert) => {
	const { enqueueSnackbar } = useSnackbar();
	const confirm = useConfirm();

	const [fetchAsset] = useAssetLazyQuery();

	const [parentAsset, setParentAsset] = useState(null);
	const [assetSelector, setAssetSelector] = useState(null);
	const [selectedAsset, setSelectedAsset] = useState(null);

	const linkParent = useCallback(
		(asset) => {
			if (!parentAsset) {
				return asset;
			}

			const parent_asset_id = parentAsset.path;
			const parent_asset = {
				rw_asset_id: parentAsset.rw_asset_id,
				asset_type: parentAsset.asset_type,
			};

			console.log('Adding child asset', parent_asset_id, asset.rw_asset_id);
			// Also update the parent pallet
			const parentPallet = assets.find((i) => i.asset_id === parent_asset_id);
			if (parentPallet) {
				// Get the existing children - and make sure this child is not in the list already..
				const exists = (parentPallet.children ?? []).find(
					(a) => a.rw_asset_id === asset.rw_asset_id,
				);
				if (!exists) {
					// New list of children
					const children = [
						...(parentPallet.children ?? []),
						{ rw_asset_id: asset.rw_asset_id, asset_type: asset.asset_type },
					];

					// One more check..
					const { sku } = parentPallet;
					if (sku) {
						const limit = Number(sku.capacity ?? 0);
						if (limit > 0 && children.length > limit) {
							// TODO: popup a confirmation dialog..
							confirm({
								title: 'Add to pallet',
								content: (
									<Body1>
										Looks like you have {children.length} assets on this pallet,
										add this additional asset?
									</Body1>
								),
							})
								.then(() => {
									const updatedParentAsset = {
										...parentPallet,
										children,
									};
									upsert(updatedParentAsset);
								})
								.catch(() => {});
							return;
						}
					}

					// Update
					const updatedParentAsset = {
						...parentPallet,
						children,
					};
					debounce(() => upsert(updatedParentAsset), 25);
				}
			}
			// Update the asset with the parent
			return { ...asset, parent_asset_id, parent_asset };
		},
		[confirm, assets, parentAsset, upsert],
	);

	const processAsset = useCallback(
		(asset_id, force = false) => {
			console.log('Processing asset', asset_id);

			const asset = assets.find((i) => i.asset_id === asset_id);
			if (asset) {
				console.log(
					'Existing asset',
					asset.asset_id,
					asset.asset_type,
					asset.rw_asset_id,
				);

				// Record the current parent asset against this scanned asset
				if (asset.asset_type === ASSET_TYPES.cask) {
					selectedAsset(linkParent(asset));
				} else if (
					[ASSET_TYPES.ibc, ASSET_TYPES.filling_tank, ASSET_TYPES.tanker, ASSET_TYPES.container].includes(asset.asset_type)
				) {
					selectedAsset(asset);
				} else {
					console.log('Parent', parentAsset?.path, asset_id);
					if (parentAsset && parentAsset.path === asset_id) {
						// Re-scanned the current parent, thereby confirming this is done..
						enqueueSnackbar(`${parentAsset.rw_asset_id} is complete!`, {
							variant: 'success',
						});
						upsert({
							...asset,
							processed: ASSET_PROCESSED_STATUS.CONFIRMED,
						});
						setParentAsset(null);
					} else {
						setParentAsset(asset);
						enqueueSnackbar(`${asset.rw_asset_id} is now the parent asset!`, {
							variant: 'info',
						});
					}
				}
				return;
			}

			// Asset does not exist in the list - fetch
			fetchAsset(asset_id)
				.then((loadedAsset) => {
					console.log('Loaded asset', loadedAsset, parentAsset?.path);
					// If it's a pallet, set this as the parent asset
					if (loadedAsset.asset_type === ASSET_TYPES.pallet) {
						// Add this pallet
						debounce(() => upsert(toTaggedAsset(loadedAsset, force)), 25);
						const { child_assets = [] } = loadedAsset;
						// Show asset selector window
						if (child_assets.length > 0) {
							setAssetSelector(child_assets);
						} else {
							enqueueSnackbar('No assets on this pallet!', {
								variant: 'warning',
							});
						}
					} else {
						// Extract the max capacity and set that as the expected..
						const { properties = {}, sku } = loadedAsset ?? {};
						const { capacity = '0' } = sku ?? {};
						// Update the properties
						const { liquid = {} } = properties;
						const { level = {} } = liquid;
						const { bl = '0' } = level;
						// Factor in existing bulk and capacity to compute what we can fill in this container
						loadedAsset.properties = {
							...properties,
							liquid: {
								...liquid,
								level: {
									...level,
									expected_bl:
										Number(capacity) > 0
											? Math.min(
													Number(capacity) - Number(bl),
													Number(capacity),
												)
											: null,
								},
							},
						};
						selectedAsset(toTaggedAsset(loadedAsset));
					}
				})
				.catch((e) => {
					console.log('Get asset', e);
					enqueueSnackbar('Failed to load the asset!', {
						variant: 'error',
					});
				});
		},
		[upsert, parentAsset],
	);

	return processAsset;
};

export default useAssetQuery;
