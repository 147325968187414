import { Box, Divider, Stack } from '@mui/material';
import { startRegistration } from '@simplewebauthn/browser';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FilledButton from 'src/components/Buttons/FilledButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FormikForm, FormikPasswordField } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import WithHelp from 'src/components/Instructions/WithHelp';
import { Body2, Body3, H5, H6 } from 'src/components/Typography';
import { registerCredentials, registrationOptions } from 'src/services/auth.service';
import { listPasskeys, updatePassword } from 'src/services/profile.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import PasskeyIcon from 'src/components/icons/Passkey';
import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup); // extend yup

// {
//   "counter": 0,
//   "path": "/t/IGX7cdQiyIG0t/u5lEIbp3V1zOEvi3WJ4f3ccbsny6I=",
//   "credential_public_key": "pQECAyYgASFYIN4muhJ2rI71Y9TGMaVoKUkrceJrG7SpTjhiRSAT4aIoIlggJ0kEodQbYgS6iA7BQsDQoJyQSGBMNEkz/H5a/l0Q090=",
//   "credential_device_type": "singleDevice",
//   "credential_backed_up": false,
//   "transports": "internal",
//   "id": "/u/email/nim@metacask.com"
// }

function Passkey({ passkey }) {
  console.log('Passkey', passkey);

  const credentialId = passkey.path.replace('/t/', '');

  return (
    <Box className="listItem">
      <Box className="listContent">
        <Body2>{credentialId}</Body2>
        <Body3>{passkey.credential_public_key}</Body3>
      </Box>
    </Box>
  );
}

function Security() {
  const [isLoading, setLoading] = useLoadingHook();

  const [AlertViewPassword, { setAlert: setAlertPassword }] = useAlertView();
  const [AlertViewPasskey, { setAlert: setAlertPasskey }] = useAlertView();

  const [passkeys, setPasskeys] = useState([]);

  useEffect(() => {
    setLoading(true);
    listPasskeys()
      .then((p) => {
        console.log('Loaded passkeys', p);
        setPasskeys(p);
      })
      .catch((e) => {
        console.log('Failed to load passkeys', e);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleRegister = async () => {
    setLoading(true);
    setAlertPasskey(null);

    try {
      const opt = await registrationOptions({});
      console.debug('Options', opt);
      const reg = await startRegistration(opt);
      console.debug('Registration', reg);
      const verify = await registerCredentials({
        ...reg,
      });
      console.debug('Verify', verify);

      setAlertPasskey({
        severity: 'success',
        title: 'Passkey Registered',
        content: 'Please check your email to verify this passkey!',
      });
    } catch (err) {
      console.log('Error', err);
      setAlertPasskey({
        severity: 'error',
        title: 'Failed to Register',
        content: `Failed to register this passkey! ${err.message}`,
      });
    }
    setLoading(false);
  };

  const changeSet = {
    password: [
      '',
      yup.string().password('Password').required('New password is required!'),
    ],
    confirmPassword: [
      '',
      yup.string()
        .min(8, 'Must be at least 8 characters')
        .minUppercase(1, 'Must contain at least 1 uppercase character')
        .minNumbers(1, 'Must contain at least 1 number')
        .minSymbols(1, 'Must contain at least 1 symbol (excluding space)')
        .oneOf([yup.ref('password'), null], ' Passwords must match'),
    ],
  };

  const handlePasswordChange = async (values) => {
    setLoading(true);
    setAlertPassword(null);
    try {
      // Save the description
      const response = await updatePassword({
        password: values.password,
      });
      console.log('Password changed!', response);
      setAlertPassword({
        severity: 'success',
        title: 'Password updated',
        content: 'Password successfully updated!',
      });
    } catch (e) {
      console.log('Failed to update public settings!', e);
      setAlertPassword({
        severity: 'error',
        title: 'Failed to update',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing="1.25rem">
      <H5>Change your password</H5>

      <Instructions>Change your password to keep your account secure.</Instructions>

      <Box className="form">
        <FormikForm
          readonly
          changeSet={changeSet}
          onSubmit={(values) => handlePasswordChange(values)}
        >
          <Stack spacing={3}>
            <WithHelp instruction="Password must contain at least one upper case letter, one number and one symbol.">
              <FormikPasswordField name="password" label="New Password" fullWidth required autoComplete="password" />
            </WithHelp>

            <FormikPasswordField name="confirmPassword" label="Confirm password" className="field" />

            <AlertViewPassword />

            <Box className='action-buttons'>
              <FilledButton type='submit' size='small' disabled={isLoading}>
                Update
              </FilledButton>
            </Box>
          </Stack>
        </FormikForm>
      </Box>

      <Divider className="divider" />

      <Box className="header">
        <H5>Passkeys</H5>

        <TitledButton
          handleClick={handleRegister}
          variant='outlined'
          color='secondary'
          label='Register'
          sx={{ width: '42px', height: '52px' }}
        >
          <PasskeyIcon height={24} width={24} />
        </TitledButton>
      </Box>

      <AlertViewPasskey />

      <Stack spacing={0} className="list">
        {passkeys.map((p) => <Passkey key={p.path} passkey={p} />)}
      </Stack>

      <Stack spacing={1} className="passkeyHelp">
        <Body3>
          <Link to="https://fidoalliance.org/passkeys/">Passkeys</Link>
          &nbsp;are an easier and safer alternative to passwords.
        </Body3>
        <Body3>With passkeys, your biometric information is never revealed to us. Biometric material never leaves your personal device.</Body3>
      </Stack>

    </Stack>
  );
}

export default Security;
