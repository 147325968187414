import { CheckCircle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import AssetTag from 'src/components/Tasks/AssetTag';
import styles from 'src/styles/modal.styles';
import { H5 } from 'src/components/Typography';
import { REQUEST_STATUS } from 'src/consts/requests';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import useRequestAdminMutation from 'src/utilities/hooks/useRequestAdminMutation';

function CancelModal({ tasks, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	const [mutate, { isLoading }] = useRequestAdminMutation();

	const cancelTasks = useCallback(() => {
		const requests = tasks.map((t) => ({ path: t.path }));

		mutate({ requests, request_status: REQUEST_STATUS.CANCELLED })
			.then(() => {
				enqueueSnackbar('Tasks cancelled!', { variant: 'success' });
				onClose(true, true);
			})
			.catch((err) => {
				const errorMessage = err?.message?.data
					? err?.message?.data[0]
					: 'Failed to cancel!';
				enqueueSnackbar(errorMessage, { variant: 'error' });
			});
	}, [enqueueSnackbar, mutate, onClose, tasks]);

	return (
		<Modal open onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Cancel Tasks</H5>
					<IconButton
						onClick={() => onClose(false, false)}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<FlexBox className='asset-container' justifyContent='start' spacing={1}>
					{tasks.map((task) => (
						<AssetTag
							key={task.id}
							id={task.id}
							action={task.action}
							type={task.type}
						/>
					))}
				</FlexBox>
				<FlexBox justifyContent='end'>
					<TextButton
						size='small'
						handleClick={() => onClose(false, true)}
						disabled={isLoading}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						size='small'
						icon={<CheckCircle />}
						iconPosition='start'
						handleClick={cancelTasks}
						loading={isLoading}
					>
						Confirm
					</FilledButton>
				</FlexBox>
			</Stack>
		</Modal>
	);
}

export default CancelModal;
