import { getRequest } from 'src/services/request.service';
import useLoading from 'src/utilities/hooks/useLoadingHook';
import pathToParams from 'src/utilities/pathToParams';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (id) => getRequest(pathToParams(id));
export const [useRequestLazyQuery, useRequestQuery] = genServiceCallHooks(
	serviceFunction
);

export default useRequestQuery;
