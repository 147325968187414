import { UploadOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilledButton from 'src/components/Buttons/FilledButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import NumberWidget from 'src/components/Dashboard/components/NumberWidget';
import StatsWidget from 'src/components/Dashboard/components/StatsWidget';
import { FormikDatePicker, FormikForm } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { MEASURE } from 'src/consts/measures';
import useMeasures from 'src/utilities/hooks/useMeasures';
import * as yup from 'yup';
import { useReportOverviewLazyQuery } from 'src/utilities/hooks/useReportOverviewQuery';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import Filter from 'src/components/icons/Filter';

function ReportWidget({ title }) {
	const navigate = useNavigate();
	const [fetchReportData, { data, isLoading }] = useReportOverviewLazyQuery();

	useEffect(() => {
		fetchReportData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpanded = () => setIsExpanded(!isExpanded);

	const { to: cto, getMeasure } = useMeasures();

	const unit = useMemo(() => getMeasure('l_m'), [getMeasure]);

	const {
		new_assets_count = 0,
		new_requests_count = 0,
		total_bl = 0,
		total_la = 0,
		total_la_in = 0,
		total_la_out = 0,
		total_bl_in = 0,
		total_bl_out = 0,
		total_bl_storage = 0,
		total_la_storage = 0,
		total_bl_operation = 0,
		total_la_operation = 0,
		start_date,
		end_date,
	} = data ?? {};

	const [tb, ta] = useMemo(() => {
		const a = !Number.isNaN(total_bl) ? cto(total_bl, MEASURE.l_m) : 0;
		const b = !Number.isNaN(total_la) ? cto(total_la, MEASURE.l_m) : 0;
		return [a, b];
	}, [total_bl, cto, total_la]);

	const [li, lo] = useMemo(() => {
		const a = !Number.isNaN(total_la_in) ? cto(total_la_in, MEASURE.l_m) : 0;
		const b = !Number.isNaN(total_la_out)
			? Math.abs(cto(total_la_out, MEASURE.l_m))
			: 0;
		return [a, b];
	}, [total_la_in, cto, total_la_out]);

	const [bi, bo] = useMemo(() => {
		const a = !Number.isNaN(total_bl_in) ? cto(total_bl_in, MEASURE.l_m) : 0;
		const b = !Number.isNaN(total_bl_out)
			? Math.abs(cto(total_bl_out, MEASURE.l_m))
			: 0;
		return [a, b];
	}, [cto, total_bl_in, total_bl_out]);

	const [tbs, tls] = useMemo(() => {
		const a = !Number.isNaN(total_bl_storage)
			? cto(total_bl_storage, MEASURE.l_m)
			: 0;
		const b = !Number.isNaN(total_la_storage)
			? cto(total_la_storage, MEASURE.l_m)
			: 0;
		return [a, b];
	}, [total_bl_storage, cto, total_la_storage]);

	const [tbo, tlo] = useMemo(() => {
		const a = !Number.isNaN(total_bl_operation)
			? cto(total_bl_operation, MEASURE.l_m)
			: 0;
		const b = !Number.isNaN(total_la_operation)
			? cto(total_la_operation, MEASURE.l_m)
			: 0;
		return [a, b];
	}, [total_bl_operation, cto, total_la_operation]);

	const changeSet = useMemo(() => {
		return {
			start_date: [
				start_date,
				yup.number().min(0, 'Invalid date!').required('Date is required!'),
			],
			end_date: [
				end_date,
				yup.number().min(0, 'Invalid date!').required('Date is required!'),
			],
		};
	}, [start_date, end_date]);

	const onSubmit = useCallback(
		(values) => {
			console.log('values ', values);
			fetchReportData({
				start_date: values?.start_date,
				end_date: values?.end_date,
			});
		},
		[fetchReportData],
	);

	return (
		<Stack className='content-section'>
			<Stack>
				<FlexBox>
					<H5 className='content-section-title'>{title}</H5>
					<IconCircleButton onClick={toggleExpanded}>
						<Filter width='1.5em' height='1.5em' />
					</IconCircleButton>
				</FlexBox>
				{isExpanded && (
					<FormikForm changeSet={changeSet} onSubmit={onSubmit}>
						<Stack spacing={2} alignItems='top'>
							<FormikDatePicker
								name='start_date'
								label='Start Date'
								fullWidth
								required
							/>
							<FormikDatePicker
								name='end_date'
								label='End Date'
								fullWidth
								required
							/>
							<FilledButton
								label='Apply'
								loading={isLoading}
								type='submit'
								size='small'
							/>
						</Stack>
					</FormikForm>
				)}
			</Stack>
			<Stack className='content-section-content' spacing={1}>
				<H5>Summary</H5>
				<NumberWidget value={new_assets_count} label='Asset Count' />
				<NumberWidget value={new_requests_count} label='Request Count' />
				<H5>Totals</H5>
				<StatsWidget
					primary={tb}
					secondary={ta}
					label='Total Liquid'
					unit={unit.value}
				/>
				<StatsWidget
					primary={bi}
					secondary={li}
					label='Bulk In'
					unit={unit.value}
				/>
				<StatsWidget
					primary={bo}
					secondary={lo}
					label='Bulk Out'
					unit={unit.value}
				/>
				<StatsWidget
					primary={tbs}
					secondary={tls}
					label='Storage'
					unit={unit.value}
				/>
				<StatsWidget
					primary={tbo}
					secondary={tlo}
					label='Operations'
					unit={unit.value}
				/>
			</Stack>

			<FlexBox className='content-section-footer'>
				<TitledButton
					handleClick={() => navigate(`/app/duty-submission`)}
					variant='outlined'
					color='secondary'
					label='Submit'
					// disabled={!isVisibleButton}
					sx={{ width: '72px', height: '55px' }}
				>
					<UploadOutlined height={24} width={24} />
				</TitledButton>
			</FlexBox>
		</Stack>
	);
}

export default ReportWidget;
