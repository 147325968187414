import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import AssetForm from 'src/components/AssetForm';
import assetIdFields from 'src/components/AssetID/assetIdFields';
import { TagSelect } from 'src/components/Forms/TagSelect';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import LiquidProperties, { liquidPropertyFields } from 'src/components/Liquid/LiquidProperties';
import templateFields from 'src/components/TemplateHandler/templateFields';
import { H5 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { FormikProvider } from 'src/providers/FormikProvider';
import processLiquid from 'src/utilities/adapters/processLiquid';
import toUniqueLocation from 'src/utilities/adapters/toUniqueLocation';
import useMeasures from 'src/utilities/hooks/useMeasures';
import usePermissions from 'src/utilities/hooks/usePermissions';
import * as yup from 'yup';

function propertyFields(fields = {}, measures = {}) {
	const { tags = [], imported = false, liquid = {} } = fields;

	return {
		tags: [typeof tags === 'string' ? tags?.split(',') : tags, yup.array().of(yup.string())],
		imported: [imported, yup.boolean()],
		liquid: liquidPropertyFields(liquid, measures, true),
	};
}

function Properties() {
	const { editLiquid = false } = usePermissions();

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Tanker Properties</H5>
				<TagSelect name='tags' label='Tanker properties' type='tags' fullWidth />
			</Stack>

			<LiquidProperties name='liquid' disabled={!editLiquid} useBulk={false} />
		</FormikProvider>
	);
}

function TankerForm(props) {
	const measures = useMeasures();

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			properties: propertyFields(initialValues.properties, measures),
		}),
		[],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
		};
	};

	return (
		<AssetForm
			assetLabel='Tanker'
			assetType={ASSET_TYPES.tanker}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</AssetForm>
	);
}

export default withAppLayout(TankerForm, { title: 'Tanker Management' });
