import { createSelector } from '@reduxjs/toolkit';
import { ACCOUNT_STATUS } from 'src/consts/account';
import getPermissions from 'src/utilities/getPermissions';
import { SLICE } from './consts';
import { selectIsCompanyActive } from '../Companies';

// Selectors
export const selectUser = (state) => state[SLICE];

export const selectAccountId = createSelector(
	selectUser,
	(user) => user?.account_id,
);
export const selectAccountName = createSelector(
	selectUser,
	(user) => user?.account_name,
);
export const selectAccountUsername = createSelector(
	selectUser,
	(user) => user?.account_username,
);
export const selectAccountEmail = createSelector(
	selectUser,
	(user) => user?.account_email,
);
export const selectAccountLocation = createSelector(
	selectUser,
	(user) => user?.account_location,
);
export const selectAccountLastAccess = createSelector(
	selectUser,
	(user) => user?.account_last_access,
);
export const selectAccountStatus = createSelector(
	selectUser,
	(user) => user?.account_status,
);
export const selectUnitFormat = createSelector(
	selectUser,
	(user) => user?.unit_format,
);
export const selectAccountPerms = createSelector(
	selectUser,
	(user) => user?.account_perms,
);
export const selectAccountPlatform = createSelector(
	selectUser,
	(user) => user?.platform,
);
export const selectAccountChain = createSelector(
	selectUser,
	(user) => user?.chain,
);

export const selectAccountRegistered = createSelector(
	selectAccountId,
	(id) => id && id.length > 0,
);

// export const selectCompanyId = createSelector(selectUser, (user) => user?.company_id);
//
// export const selectCompanyName = createSelector(selectUser, (user) => user?.company_name);
//
// export const selectCompanyCC = createSelector(selectUser, (user) => user?.company_cc);

export const selectIsAccountActive = createSelector(
	selectAccountStatus,
	(status) => status === ACCOUNT_STATUS.ACTIVE,
);

// export const selectIsCompanyActive = createSelector(
// 	selectUser,
// 	(user) => user?.company_active === 'true',
// );

export const selectCanEdit = createSelector(
	selectIsCompanyActive,
	selectIsAccountActive,
	(companyActive, accountActive) => companyActive && accountActive,
);

// export const selectIsUsUser = createSelector(selectUser, (user) => user?.company_cc === 'US');
//
// export const selectIsUnitFormatImperial = createSelector(selectUser, (user) => user.unit_format);
//
export const selectPermissions = createSelector(
	selectCanEdit,
	selectAccountPerms,
	getPermissions,
);

// export const selectCompanies = createSelector(
// 	selectUser,
// 	(user) => user?.companies,
// );
