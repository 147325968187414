function printElement({
	element,
	title = '',
	width = 768,
	height = 768,
	styles = '',
	pageTitle = 'PROOFWORKS',
}) {
	if (!element) {
		console.error('printElement called without element');
		return;
	}
	const printPopup = window.open(
		'',
		title,
		`left=0,top=0,width=${width},height=${height},toolbar=0,scrollbars=0,status=0`,
	);
	printPopup.document.write(element);
	printPopup.document.title = pageTitle;
	const style = printPopup.document.createElement('style');
	style.textContent = styles;
	printPopup.document.head.appendChild(style);
	printPopup.document.close();
	printPopup.focus();
	printPopup.print();
	printPopup.close();
}

export default printElement;
