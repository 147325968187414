import { Divider } from '@mui/material';
import React from 'react';
import AmountDisplay from 'src/components/properties/AmountDisplay';
import { SKU_TYPES } from 'src/consts/sku';
import SKUEntryItemInfo from './SKUEntryItemInfo';

function SKUItemInfo({ sku }) {
	// console.log('sku', sku);
	const { sku_type, amount, picked, unit, entries = [] } = sku ?? {};

	return (
		<>
			<AmountDisplay
				label='Required'
				amount={amount}
				unit={
					![
						SKU_TYPES.TRACKED,
						SKU_TYPES.FINISHED,
						SKU_TYPES.WIP,
						SKU_TYPES.CONSUMABLE,
					].includes(sku_type)
						? unit
						: undefined
				}
			/>
			<AmountDisplay
				label='Picked'
				amount={picked}
				unit={![SKU_TYPES.TRACKED].includes(sku_type) ? unit : undefined}
			/>
			{entries.map((e, index) => {
				return (
					<>
						<Divider
							variant='middle'
							sx={{ opacity: 0.6 }}
							key={`__div_${index}`}
						/>
						<SKUEntryItemInfo
							key={`entry_${index}`}
							item={e}
							unit={unit}
							sku_type={sku_type}
						/>
					</>
				);
			})}
		</>
	);
}

export default SKUItemInfo;
