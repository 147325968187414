import { AttachMoneyOutlined, CurrencyPound, EuroOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCompanyCC } from 'src/redux/containers/Companies';

function CostDisplay({ value }) {
	const cc = useSelector(selectCompanyCC);

	const icon = useMemo(() => {
		switch (cc?.toLowerCase()) {
		case 'gb':
		case 'uk':
		case 'gb-sct':
		case 'gb-eng':
		case 'gb-nir': {
			return <CurrencyPound style={{ width: '12px', height: 'auto' }} />;
		}
		case 'us':
		case 'ca':
		case 'sg': {
			return <AttachMoneyOutlined style={{ width: '12px', height: 'auto' }} />;
		}
		default: {
			return <EuroOutlined style={{ width: '12px', height: 'auto' }} />;
		}
		}
	}, [cc]);

	if (!value) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			{icon}
			<Body3>
				<Bolder>Cost:</Bolder>
				&nbsp;
				{value}
			</Body3>
		</Box>
	);
}

export default CostDisplay;
