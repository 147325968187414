import Stack from '@mui/material/Stack';
import { FlexBox } from 'src/components/Layout/FlexBox';
import AccountDisplay from 'src/components/properties/AccountDisplay';
import DateDisplay from 'src/components/properties/DateDisplay';
import { Overline } from 'src/components/Typography';

function CreatorProps({ entity }) {
	return (
		<FlexBox className='creator-view'>
			<Stack spacing={0.5} className='column'>
				<Overline>Created By</Overline>
				<AccountDisplay name={entity.creator_username} />
				{entity.create_date && <DateDisplay date={entity.create_date} />}
				{entity.asset_checkin_date && <DateDisplay date={entity.asset_checkin_date} />}
				{entity.request_created && <DateDisplay date={entity.request_created} />}
			</Stack>

			{entity.update_account_username && (
				<Stack spacing={0.5} className='column'>
					<Overline>Last Updated By</Overline>
					<AccountDisplay name={entity.update_account_username} />
					{entity.update_on && <DateDisplay date={entity.update_on} />}
					{entity.properties?.liquid?.level?.enable
						&& entity.properties?.liquid?.level?.filled_date 
						&& <DateDisplay label="Filled on" date={entity.properties?.liquid?.level?.filled_date } />}
				</Stack>
			)}
		</FlexBox>
	);
}

export default CreatorProps;
