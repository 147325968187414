import { APP_API_GATEWAY } from 'src/consts/config';
import fetchGet from 'src/utilities/fetchGet';
import fetchPost from 'src/utilities/fetchPost';
import objectToQueryString from 'src/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/sku`;

export const createSKU = async (sku, headers = {}) =>
	fetchPost(BASE, headers, sku);

export const cloneSKU = async (sku, headers = {}) =>
	fetchPost(`${BASE}/clone`, headers, sku);

export const getSKU = async (params, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const getSKUEntries = async (params, headers = {}) =>
	fetchGet(`${BASE}/entries${objectToQueryString(params)}`, headers);

export const getSKUList = async (request = {}, headers = {}) =>
	fetchGet(`${BASE}/list`, headers, request);

export const getSKUs = async (headers = {}) =>
	fetchGet(`${BASE}/list`, headers);
