function convertToCSV(array) {
  let csv = '';
  array.forEach((row) => {
    csv += `${row.join(',')}\n`;
  });
  return csv;
}

const downloadAssetCsv = (rows) => {
  // Create the CSV content
  const _rows = rows.map((asset) => [
    asset?.id,
    asset?.reference,
    asset?.type,
    asset?.bulk,
    asset?.la,
    asset?.abv,
    asset?.tcf,
    asset?.barrelSku,
    asset?.liquidSku,
    asset?.parent,
    asset?.location,
  ]);
  const csvContent = convertToCSV([['ID', 'Reference', 'Type', 'Bulk', 'Alcohol', 'Strength', 'TCF', 'Barrel', 'Liquid', 'Parent', 'Location'], ..._rows]);

  // Create a Blob containing the CSV file
  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a link element to download the CSV file
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'assets.csv';

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();
};

export default downloadAssetCsv;
