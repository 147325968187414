import { IPFS_GATEWAY } from 'src/consts/config';

const replaceIpfsGateway = (url) => {
	if (url) {
		if (IPFS_GATEWAY && IPFS_GATEWAY !== '') {
			// This needs to happen - except for a specific set of files
			if (url.endsWith('.gif')) {
				const bits = url.split('/');
				if (bits[bits.length - 1].startsWith('cask-')) {
					return url;
				}
			}

			return url.replace('https://ipfs.io', IPFS_GATEWAY);
		}
	}
	return url;
};

export default replaceIpfsGateway;
