import { getOverview } from 'src/services/stats.service';
import useLoading from 'src/utilities/hooks/useLoadingHook';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params) => getOverview(params);
export const [useOverviewLazyQuery, useOverviewQuery] = genServiceCallHooks(
	serviceFunction,
	{ useLoadingHook: useLoading },
);

export default useOverviewQuery;
