import ForgotPassword from 'src/components/Auth/ForgotPassword';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withEmptyLayout } from 'src/components/Layout/EmptyLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function RecoveryPage() {
	usePageTitle('Account Recovery');
	return (
		<ForgotPassword />
	);
}

export default withEmptyLayout(withDialogCard(RecoveryPage));
