import { Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import { FormikCheckBox } from 'src/components/Forms/FormikForm';
import { H5 } from 'src/components/Typography';
import { ASSET_ACCOUNT_TYPE_TO_LABEL } from 'src/consts/asset';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import { mt2 } from 'src/styles/margins';
import useIsOnline from 'src/utilities/hooks/useIsOnline';
import usePermissions from 'src/utilities/hooks/usePermissions';
import Client from '../Client';

export function AssetAccountsImp({
	label = 'Asset Accounts',
	disabled = false,
}) {
	const { values } = useContext(FormikContext);
	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				label={<H5>{label}</H5>}
				disabled={disabled}
			/>
			<Collapse in={!!values?.enable}>
				<Stack spacing={2} sx={{ ...mt2 }}>
					<Client
						name='bo'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['0']}`}
						disabled={disabled}
						withEnable={false}
					/>
					<Client
						name='mo'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['1']}`}
						disabled={disabled}
						withEnable={false}
						noCustomers={true}
					/>
					<Client
						name='ro'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['2']}`}
						disabled={disabled}
						withEnable={false}
						noCustomers={true}
					/>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function AssetAccounts({ name = 'accounts', ...rest }) {
	const { editOwners } = usePermissions();
	const { isOffline } = useIsOnline();
	const { readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path={name}>
				<AssetAccountsImp {...rest} disabled={!editOwners || isOffline} />
			</FormikProvider>
		)
	);
}
