import { AccountCircle } from '@mui/icons-material';
// import BarrelIcon from 'src/components/icons/Barrel';
import CaskIcon from 'src/components/icons/CaskIcon';
import IBCIcon from 'src/components/icons/Ibc';
import TankerIcon from 'src/components/icons/Tanker';
import PalletIcon from 'src/components/icons/Pallet';
import LocationIcon from 'src/components/icons/Location';
import Production from 'src/components/icons/Production';

import FillingTankIcon from 'src/components/icons/FillingTank';
import ListAlt from '@mui/icons-material/ListAlt';
import SvgShip from 'src/components/icons/Ship';
import SvgPick from 'src/components/icons/Pick';
import SvgTransfer from 'src/components/icons/Transfer';
import SvgSample from 'src/components/icons/Sample';
import SvgRegauge from 'src/components/icons/Regauge';

function SourceTypeIcon({ type, ...props }) {
	const types = {
		// cask: BarrelIcon,
		cask: CaskIcon,
		ibc: IBCIcon,
		tanker: TankerIcon,
		pallet: PalletIcon,
		filling_tank: FillingTankIcon,
		// Request
		ship: SvgShip,
		pick: SvgPick,
		sample: SvgSample,
		regauge: SvgRegauge,
		transfer: SvgTransfer,
		restock: LocationIcon,
		delivery: TankerIcon,
		production_run: Production,
		change_ownership: AccountCircle,
	};
	const Component = types[type] ?? ListAlt;

	return <Component {...props} />;
}

export default SourceTypeIcon;
