import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FormikCheckBox, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import { useContext } from 'react';
import { mt2 } from 'src/styles/margins';

function RequestChargeCodePropertiesImpl() {
	const { values } = useContext(FormikContext);

	return (
		<Stack>
			<FormikCheckBox name='enable' label={<H5>Charge Code</H5>} />

			<Collapse in={!!values?.enable}>
				<Stack spacing={2} sx={{ ...mt2 }}>
					<FlexBox>
						<FormikTextField label='Charge Code' name='code' fullWidth />
					</FlexBox>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function RequestChargeCodeProperties({ name }) {
	const { readonly } = useContext(FormikContext);

	return (
		!readonly && (
			<FormikProvider path={name}>
				<RequestChargeCodePropertiesImpl />
			</FormikProvider>
		)
	);
}
