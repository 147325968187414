import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useGetReportId = () => {
	let { type, nonce } = useParams();
	return useMemo(() => {
		if (type === 'new') return null;
		return [type, nonce].join('/');
	}, [type, nonce]);
};

export default useGetReportId;
