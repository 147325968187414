import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import SearchField from 'src/components/Search/SearchField';
import { H5, H6 } from 'src/components/Typography';
import { useGetSKUList, useGetSKUListLazyQuery } from 'src/utilities/hooks/useGetSKUListQuery';
import SKUItem from './items/SKUItem';

function SearchSKU({ title, sectionTitle, label, onItemClick, itemFilter, filter, disabled = false }) {
	const [search] = useGetSKUList();
	const [show, setShow] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	// const searchResults = useMemo(() =>{
	// 	console.log('Items', data, filter);
	// 	if (data){
	// 		if (filter && filter.length > 0) {
	// 			return data.filter((i) => filter.includes(i.sku_type));
	// 		}
	// 		return data;
	// 	}
	// 	return [];
	// },[data, filter]);

	const handleButtonClick = useCallback(
		(sku) => {
			if (onItemClick) {
				onItemClick(sku);
			}
		},
		[onItemClick],
	);

	const handleSearch = (val) => {
		const results = search(filter, val, itemFilter);
		setSearchResults(results);
		setShow(results.length > 0);
	};

	const handleClear = () => {
		setShow(false);
	};

	useEffect(() => setShow(false), [disabled]);

	// // https://test.metacask.com:4430/admin/sku/edit/test_sku => /test_sku
	// const handleOnQrRead = async ({ text: qrString }) => {
	//   console.log(qrString);
	//   const path = qrString.replace('/', '');
	//   console.log('Search');
	//   handleSearch(path);
	// };

	return (
		<Stack spacing={2}>
			{title && <H5 className="section-header">{title}</H5>}
			{sectionTitle && <H6 className="section-title">{sectionTitle}</H6>}

			<Stack className='search-results' spacing={2}>
				<FlexBox>
					<SearchField
						label={label}
						id={label}
						disabled={disabled}
						onClear={handleClear}
						onSearch={handleSearch}
						fullWidth
					/>
				</FlexBox>
				{show && (
					<Collapse in={show}>
						<Stack className='list'>
							{searchResults.map((item) => (
								<SKUItem
									item={item}
									key={item.path}
									onClick={() => handleButtonClick(item)}
								/>
							))}
						</Stack>
					</Collapse>
				)}
			</Stack>
		</Stack>
	);
}

export default SearchSKU;
