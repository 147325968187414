import { Edit, TaskOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import AmountDisplay from 'src/components/properties/AmountDisplay';
import DateDisplay from 'src/components/properties/DateDisplay';
import DutyPaidDisplay from 'src/components/properties/DutyPaidDisplay';
import IDDisplay from 'src/components/properties/IDDisplay';
import NameDisplay from 'src/components/properties/NameDisplay';
import { ASSET_NAMES } from 'src/consts/asset';

function AssetSKUStorageListItem({ item, onEdit, display, selected = false }) {
	const { sku_entry_id, sku_name, entry_quantity, expiry, unit, duty_paid } =
		item;
	const cardStyle = useMemo(
		() => (onEdit ? '' : selected ? 'card-tab-confirmed' : 'card-tab-pending'),
		[selected, onEdit],
	);
	return (
		<Box className='listItem'>
			{selected && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack>
					<IDDisplay id={sku_entry_id} />
					<NameDisplay label='SKU' name={sku_name} />
					<AmountDisplay amount={entry_quantity} unit={unit} />

					<DutyPaidDisplay value={duty_paid} />
					<DateDisplay date={expiry} />
					{display && display(item)}
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

export default AssetSKUStorageListItem;
