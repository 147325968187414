import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from './consts';

const initialState = {
  company_id: '',
  companies: [],
  users: [],
  skus: [],
  facilities: [],
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    setCompanyUsers(state, { payload }) {
      state.users = payload;
    },
    setCompanySKUs(state, { payload }) {
      state.skus = payload;
    },
    setCompanyFacilities(state, { payload }) {
      state.facilities = payload;
    },
    clearCompanies() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase('USER/setUser', (state, { payload }) => {
      state.companies = payload.companies ?? state.companies ?? [];
      state.company_id = payload.company_id ?? state.company_id ?? '';
    });
    builder.addCase('USER/clearUser', () => {
      return { ...initialState };
    });
  },
});

export const { reducer, actions } = slice;
export const { clearCompanies, setCompanyUsers, setCompanySKUs, setCompanyFacilities } = actions;
