import { upsertFacility } from 'src/services/facility.service';
import { useServiceCallHook } from 'src/utilities/hooks/genHooks';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';

const serviceFunction = (params) => upsertFacility(params);
const useFacilitiesMutation = useServiceCallHook(serviceFunction, {
	useLoadingHook,
});

export default useFacilitiesMutation;
