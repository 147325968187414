import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import DateDisplay from 'src/components/properties/DateDisplay';
import IDDisplay from 'src/components/properties/IDDisplay';
import SourceDisplay from 'src/components/properties/SourceDisplay';
import { fromReportId } from 'src/utilities/format';

export default function ReportListItem({ item, onItemClick, buttonIcon }) {
	const { report_type, nonce } = fromReportId(item?.path);

	const date = new Date(nonce);

	return (
		<Box className='listItem'>
			<Box className='listContent' onClick={onItemClick}>
				<Stack
					spacing={0.5}
					sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}
				>
					<DateDisplay date={date} />
					<IDDisplay id={item?.acknowledgement_number} />
					<SourceDisplay type={item?.type} name={report_type?.toUpperCase()} />
				</Stack>
			</Box>

			<Stack className='listButtons'>
				<IconCircleButton onClick={onItemClick}>{buttonIcon}</IconCircleButton>
			</Stack>
		</Box>
	);
}
