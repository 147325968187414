import { Stack } from '@mui/material';
import styles from './AccountPayments.styles';
import AccountPaymentsRow from './AccountPaymentsRow';

function AccountPayments(props) {
  const { accountPayments } = props;
  if (!accountPayments?.length) return null;

  return (
    <Stack sx={styles} className="root">
      {accountPayments.map((row) => (
        <AccountPaymentsRow key={row.path} row={row} />
      ))}
    </Stack>
  );
}
export default AccountPayments;
