import { Assessment } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitledButton from 'src/components/Buttons/TitledButton';
import useGetId from 'src/utilities/hooks/useGetId';
import ReportModal from './ReportModal';

function ReportAssetList({ assets }) {
	const [report, setReport] = useState(false);
	const navigate = useNavigate();
	const { path } = useGetId();

	const onReport = useCallback(
		(selectedAssets) => {
			setReport(false);
			const str = JSON.stringify(selectedAssets.map((i) => i?.asset_id));
			const base = Buffer.from(str).toString('base64');
			// const types = Object.entries(selectedTypes)
			// 	.filter(([, value]) => value)
			// 	.map(([key]) => key);
			navigate(`/app/asset-table?sources=${base}`, {
				replace: true,
			});
		},
		[path, navigate],
	);

	return (
		<>
			<TitledButton
				handleClick={() => setReport(true)}
				label='Report'
				variant='outlined'
				color='secondary'
				sx={{ width: '72px', height: '55px' }}
			>
				<Assessment height={24} width={24} />
			</TitledButton>
			{!!report && (
				<ReportModal
					open={!!report}
					assets={assets}
					onReport={onReport}
					onClose={() => setReport(false)}
				/>
			)}
		</>
	);
}

export default ReportAssetList;
