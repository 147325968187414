import { Box } from '@mui/material';
// import ProofworksIcon from 'src/components/icons/ProofworksIcon';
import QRCode from 'react-qr-code';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { styles } from 'src/components/QRImage/styles';
import CircleLogo from 'src/components/icons/CircleLogo';

function QRImage({
	size = 512,
	boxSize = 165,
	value,
	title,
	isLogoVisible = true,
	...props
}) {
	const logoSize = boxSize / 3.5;

	return (
		<FlexBox justifyContent='center' style={styles.wrapper} {...props}>
			<QRCode
				style={{
					width: boxSize,
					height: boxSize,
				}}
				value={value}
				size={size}
				level='Q'
				title={title}
			/>

			{isLogoVisible && (
				<Box
					style={styles.logo}
					display='flex'
					alignItems='center'
					justifyContent='center'
					className='logoWrapper'
				>
					<CircleLogo height={logoSize} width={logoSize} />
				</Box>
			)}
		</FlexBox>
	);
}

export default QRImage;
