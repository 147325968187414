import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidProperties, { liquidPropertyFields } from 'src/components/Liquid/LiquidProperties';
import { H5 } from 'src/components/Typography';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import styles from 'src/styles/modal.styles';
import processLiquid from 'src/utilities/adapters/processLiquid';
import { AlertView } from 'src/utilities/hooks/useAlertView';
import useMeasures from 'src/utilities/hooks/useMeasures';

export const calculateProductionDestinationLiquidUpdates = (values) => {
	console.log('Calculating', values);

	const { liquid = {} } = values;
	const { enable, level = {} } = liquid;

	if (!enable) {
		return liquid;
	}

	// Adjust the level settings..
	const {
		bl,
		la,
		abv,
		tcf,
		action_date,
	} = level;

	return {
		...liquid,
		level: {
			...level,
			filled_date: action_date,
			updated_bl: bl,
			updated_la: la,
			updated_abv: abv,
			updated_tcf: tcf,
		},
	};
};

function DestinationLiquidModal({
	item,
	open,
	// upsert,
	onClose,
	// sourceWeightProperties,
	// sourcesAbv = [0, 1],
	// sourcesLiquidType = '',
}) {
	const { enqueueSnackbar } = useSnackbar();

	const measures = useMeasures();

	// We track the "amount" we want at the SKU Item level
	const { rw_asset_id, properties = {}, asset_type } = item ?? {};
	const { liquid = {} } = properties;

	const changeSet = useMemo(
		() => ({
			liquid: liquidPropertyFields(liquid, measures, true, true),
		}),
		[liquid, measures],
	);

	const handleSubmit = useCallback(
		(values) => {
			try {
				const liquid = calculateProductionDestinationLiquidUpdates(values);
				console.log('submitted', values, liquid);

				onClose(processLiquid({
					...item,
					properties: {
						...properties,
						liquid,
					},
					processed: ASSET_PROCESSED_STATUS.CONFIRMED,
				}));
			} catch (e) {
				console.log('Failed to update', e);
				enqueueSnackbar(
					`Cannot update liquid settings for ${rw_asset_id}: ${e.message}!`,
					{
						variant: 'error',
					},
				);
			}
		},
		[item],
	);

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{rw_asset_id}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
						gap: '1.5rem',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Instructions>Adjust fill settings.</Instructions>

					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={2}>
							<LiquidProperties name='liquid' useWeight batchRequired useBulk={false} />

							<AlertView />

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small'>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default DestinationLiquidModal;
