import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import Address from 'src/components/Company/Address';
import { FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { H5 } from 'src/components/Typography';
import useAlertView from 'src/utilities/hooks/useAlertView';
import usePartnerMutation from 'src/utilities/hooks/useMutatePartner';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import * as yup from 'yup';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import useCopyToClipboard from 'src/utilities/hooks/useCopyToClipboard';
// import { IconButton, InputAdornment, Stack } from '@mui/material';

// next = '',
// status = '',
// created = 0,
// company_id = '',
// company_name = '',
// company_key = '',
// company_key_hash = '',
// excise_id = '',
// address_1 = '',
// address_2 = '',
// address_3 = '',
// city = '',
// state = '',
// post_code = '',
// country = '',
// id = '',
// path = '',

function PartnerForm({ partner, onSubmit, onCancel, readonly = false }) {
	const [target, scroll] = useScrollTarget();
	const [mutatePartnerApi, { isLoading }] = usePartnerMutation();
	const [AlertView, { setAlert }] = useAlertView();
	// const [handleCopy] = useCopyToClipboard();

	const changeSet = useMemo(
		() => ({
			// Set the editable properties
			company_name: [
				partner?.company_name ?? '',
				yup.string().required('Company Name is required'),
			],
			// company_key: [
			// 	partner?.company_key ?? '',
			// 	yup.string().required('Company Key is required'),
			// ],
			excise_id: [partner?.excise_id ?? '', yup.string()],
			//  address
			line1: [
				partner?.address_1 ?? '',
				yup.string().required('Address is required!'),
			],
			line2: [partner?.address_2 ?? '', yup.string()],
			line3: [partner?.address_3 ?? '', yup.string()],
			city: [partner?.city ?? '', yup.string().required('City is required!')],
			state: [partner?.state ?? '', yup.string()],
			country: [
				partner?.country ?? '',
				yup.string().required('Country is required!'),
			],
			postCode: [
				partner?.post_code ?? '',
				yup.string().required('Post code is required!'),
			],
		}),
		[
			partner?.address_1,
			partner?.address_2,
			partner?.address_3,
			partner?.city,
			partner?.company_name,
			partner?.country,
			partner?.excise_id,
			partner?.post_code,
			partner?.state,
		],
	);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			try {
				const response = await mutatePartnerApi({
					...values,
					post_code: values.postCode,
					path: partner?.path,
				});
				setAlert(null);
				onSubmit && onSubmit(response);
			} catch (e) {
				console.log('Failed to create customer!', e);
				setAlert({
					severity: 'error',
					title: 'Failed to mutate customer!',
					content: e.message,
				});
			}
		},
		[mutatePartnerApi, onSubmit, partner?.path, setAlert],
	);

	useEffect(() => {
		scroll();
		setAlert(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partner]);

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			readonly={readonly}
		>
			<Stack className='form' spacing='1.2rem' ref={target}>
				<H5>{partner?.path ? 'Update' : 'Create'} partners visit card</H5>

				<Instructions>
					Allow your partners to access their assets and use their visit cards.
				</Instructions>

				<Stack className='form' spacing='2rem'>
					<Stack spacing='1.5rem'>
						<FormikTextField
							name='company_name'
							label='Company Name'
							disabled={!!partner?.path}
						/>
						{/*<FormikTextField*/}
						{/*	name='company_key'*/}
						{/*	label='Company PublicKey'*/}
						{/*	InputProps={{*/}
						{/*		endAdornment: partner?.company_key ? (*/}
						{/*			<InputAdornment position='end'>*/}
						{/*				<IconButton*/}
						{/*					aria-label='Copy PublicKey'*/}
						{/*					onClick={() => handleCopy(partner?.company_key)}*/}
						{/*					edge='end'*/}
						{/*				>*/}
						{/*					<ContentCopyIcon />*/}
						{/*				</IconButton>*/}
						{/*			</InputAdornment>*/}
						{/*		) : null,*/}
						{/*	}}*/}
						{/*/>*/}
						<FormikTextField name='excise_id' label='Excise/Tax id' />
					</Stack>

					<Stack spacing='1.5rem'>
						<H5>Organization Address</H5>
						<Address />
					</Stack>

					<AlertView />
				</Stack>
				<Box
					className='action-buttons'
					display={'flex'}
					justifyContent={'flex-end'}
				>
					<TextButton
						size='small'
						handleClick={onCancel}
						color='secondary'
						disabled={isLoading}
					>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small' disabled={isLoading}>
						{partner?.path ? 'Update' : 'Create'}
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

PartnerForm.propTypes = {
	partner: PropTypes.object,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	readonly: PropTypes.bool,
};

export default PartnerForm;
