import { ExpandLess, ExpandMore, Group, LogoutOutlined, SupervisedUserCircle } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from 'src/components/Avatar';
import CompanySelect from 'src/components/CompanySelect';
import Admin from 'src/components/icons/Admin';
import Liquid from 'src/components/icons/Liquid';
import Overview from 'src/components/icons/Overview';
import Production from 'src/components/icons/Production';
import QrScan3 from 'src/components/icons/QrScan3';
import Reports from 'src/components/icons/Reports';
import Shipments from 'src/components/icons/Shipments';
import Sku from 'src/components/icons/Sku';
import Warehousing from 'src/components/icons/Warehousing';
import { FlexBox } from 'src/components/Layout/FlexBox';
import Menu from 'src/components/Layout/SideBar/Menu/Menu';
import MenuGroup from 'src/components/Layout/SideBar/Menu/MenuGroup';
import { Body3, H4, Overline } from 'src/components/Typography';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import useIsOnline from 'src/utilities/hooks/useIsOnline';
import usePermissions from 'src/utilities/hooks/usePermissions';
import { NetworkSwitchMobile } from '../AppHeader/NetworkSwitch';
import { MenuHeader } from './Menu/MenuHeader';

function ActiveMenu({ active, companyName }) {
	const {
		companyAdmin = false,
		canMangeSensors = false,
		userAdmin = false,
		facilityAdmin = false,
		skuAdmin = false,
	} = usePermissions();
	const { isOffline } = useIsOnline();

	const [toggle, setToggle] = useState(false);

	const onHandleToggle = useCallback(() => {
		setToggle(!toggle);
	}, [toggle]);

	const onHandleSelect = useCallback(
		(func, value) => {
			if (isOffline) return;
			func(value).then(() => {
				onHandleToggle();
			});
		},
		[onHandleToggle, isOffline],
	);

	const menu = useMemo(
		() => ({
			overview: [
				{ tag: '/app/overview', label: 'Dashboard', disabled: isOffline },
				{ tag: '/app/tasks', label: 'Tasks', disabled: !active },
				{ tag: '/app/sync', label: 'Sync', disabled: !active },
				{
					tag: '/app/explorer',
					label: 'Explorer',
					disabled: !active || isOffline,
				},
			],
			assets: [
				{ tag: '/app/cask', label: 'Casks' },
				{ tag: '/app/container', label: 'Containers' },
				{ tag: '/app/tank', label: 'Filling Tanks' },
				{ tag: '/app/ibc', label: 'IBCs' },
				{ tag: '/app/pallet', label: 'Pallets' },
				{ tag: '/app/tanker', label: 'Tankers' },
			],
			warehousing: [
				{ tag: '/app/inventory-audit', label: 'Audit' },
				{ tag: '/app/delivery', label: 'Delivery' },
				{ tag: '/app/pick', label: 'Pick' },
				{ tag: '/app/restock', label: 'Restock' },
				{ tag: '/app/change-ownership', label: 'Transfer Ownership' },
			],
			liquid: [
				{ tag: '/app/regauge', label: 'Regauge' },
				{ tag: '/app/sample', label: 'Sample' },
				{ tag: '/app/transfer', label: 'Transfer' },
			],
			production: [{ tag: '/app/production-run', label: 'Production' }],
			fulfilment: [
				{ tag: '/app/duty-transfer', label: 'Tax Payment' },
				{ tag: '/app/ship', label: 'Shipment' },
			],
			reporting: [
				// { tag: '/app/reports', label: 'Reports', disabled: isOffline },
				{
					tag: '/app/duty-submission',
					label: 'Tax Submission',
					disabled: isOffline,
				},
				{
					tag: '/app/invoice',
					label: 'Invoice',
					disabled: isOffline,
				},
			],
			crm: [
				{
					disabled: isOffline,
					tag: '/admin/customers',
					label: 'Customers',
				},
				{
					disabled: isOffline,
					tag: '/admin/partners',
					label: 'Partners',
				},
			],
			settings: [
				{
					disabled: isOffline || !companyAdmin,
					tag: '/admin/company',
					label: 'Information',
				},
				{ disabled: isOffline || !skuAdmin, tag: '/admin/sku', label: 'SKUs' },
				{
					disabled: isOffline || !facilityAdmin,
					tag: '/admin/facilities',
					label: 'Facilities',
				},
				{
					tag: '/admin/sensors',
					label: 'Sensors',
					disabled: !canMangeSensors || isOffline,
				},
				// {
				// 	tag: '/admin/contracts',
				// 	label: 'Contracts',
				// 	disabled: true,
				// },
			],
			users: [
				{
					disabled: isOffline || !userAdmin,
					tag: '/admin/users',
					label: 'Users',
				},
				{
					disabled: isOffline || !userAdmin,
					tag: '/admin/teams',
					label: 'Teams',
				},
				{
					disabled: isOffline || !userAdmin,
					tag: '/admin/roles',
					label: 'Roles',
				},
			],
		}),
		[
			active,
			canMangeSensors,
			companyAdmin,
			facilityAdmin,
			isOffline,
			skuAdmin,
			userAdmin,
		],
	);

	const hideSettingsMenu = useMemo(
		() => menu.settings.every((item) => item.disabled),
		[menu.settings],
	);

	return (
		<>
			<CompanySelect>
				{({ companyOptions, selectedCompanyId, handleSelect }) => (
					<Stack spacing={0} className='company-header'>
						<Box className='company-name'>
							<Box className='avatar'>
								<Avatar onClick={() => onHandleToggle()} alt={companyName} />
							</Box>
							<Box className='label'>
								<Stack className='label-content'>
									<H4 className='text'>{companyName}</H4>
									{active && <Overline>ACTIVE</Overline>}
									{!active && <Overline>NOT ENABLED</Overline>}
								</Stack>
								{toggle && (
									<ExpandLess
										onClick={() => onHandleToggle()}
										className='icon'
									/>
								)}
								{!toggle && (
									<ExpandMore
										onClick={() => onHandleToggle()}
										className='icon'
									/>
								)}
							</Box>
						</Box>
						<Collapse in={toggle}>
							<Stack spacing={0.15} className='company-menu'>
								{companyOptions.map(({ label, value }) => (
									<FlexBox
										key={value}
										className={`company-name ${value === selectedCompanyId ? 'selected' : ''} ${isOffline ? 'disabled' : ''}`}
										onClick={() => onHandleSelect(handleSelect, value)}
									>
										{label}
									</FlexBox>
								))}
							</Stack>
						</Collapse>
					</Stack>
				)}
			</CompanySelect>
			<Menu>
				<NetworkSwitchMobile />
				<MenuGroup
					title='Overview'
					show
					icon={<Overview />}
					options={menu.overview}
				/>

				<MenuGroup
					title='Assets'
					show={active}
					icon={<QrScan3 />}
					options={menu.assets}
				/>

				<MenuGroup
					title='Warehousing'
					show={active}
					icon={<Warehousing />}
					options={menu.warehousing}
				/>

				<MenuGroup
					title='Liquid Operations'
					show={active}
					icon={<Liquid />}
					options={menu.liquid}
				/>

				<MenuGroup
					title='Production'
					show={active}
					icon={<Production />}
					options={menu.production}
				/>

				<MenuGroup
					title='Fulfilment'
					show={active}
					icon={<Shipments />}
					options={menu.fulfilment}
				/>

				<MenuGroup
					title='Reporting'
					show={active}
					icon={<Reports />}
					options={menu.reporting}
				/>
				{hideSettingsMenu ? null : (
					<MenuGroup
						title='CRM'
						show={active}
						icon={<SupervisedUserCircle />}
						options={menu.crm}
					/>
				)}
				{hideSettingsMenu ? null : (
					<MenuGroup
						title='Users'
						show={active}
						icon={<Group />}
						options={menu.users}
					/>
				)}
				{hideSettingsMenu ? null : (
					<MenuGroup
						title='Company'
						show={active}
						icon={<Sku />}
						options={menu.settings}
					/>
				)}
			</Menu>
		</>
	);
}

ActiveMenu.propTypes = {
	active: PropTypes.bool,
	companyName: PropTypes.string,
};

function AccountMenu() {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const { isOffline } = useIsOnline();

	const menu = {
		account: [
			{ tag: '/account/settings', label: 'Settings', disabled: isOffline },
			{
				tag: '/account/portfolio',
				label: 'Portfolio',
				disabled: true || isOffline,
			},
			{
				tag: '/account/activity',
				label: 'Activity',
				disabled: true || isOffline,
			},
			{
				tag: '/account/organizations',
				label: 'Organizations',
				disabled: isOffline,
			},
			// { tag: '/account/invite', label: 'Personal Invite', disabled: isOffline },
		],
	};

	return (
		<Menu>
			<MenuGroup
				title='Account'
				show
				icon={<Admin />}
				options={menu.account}
			/>
			{isMobile && (
				<Stack className='menu-group' spacing={0.15}>
					<MenuHeader
						icon={<LogoutOutlined />}
						label='Logout'
						xs={false}
						onClick={() => navigate('/logout')}
						disabled={isOffline}
					/>
				</Stack>
			)}
		</Menu>
	);
}

function SideBar({ active, companyName, toggleMenu }) {
	return (
		<Stack className={`menu ${toggleMenu ? 'menu-show' : 'menu-hide'}`}>
			<Stack className='menu-box'>
				{!isEmpty(companyName) && (
					<ActiveMenu active={active} companyName={companyName} />
				)}
				<AccountMenu />
			</Stack>
			<Stack className='menu-footer'>
				<Body3 className='copy-right'>{`v1.1.0 | © ${new Date().getFullYear()} CaskStore AG. ALL RIGHTS RESERVED`}</Body3>
			</Stack>
		</Stack>
	);
}

SideBar.propTypes = {
	active: PropTypes.bool,
	companyName: PropTypes.string,
	toggleMenu: PropTypes.func,
};

export default SideBar;
