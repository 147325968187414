import { AddCircle } from '@mui/icons-material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Box, Collapse, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TitledButton from 'src/components/Buttons/TitledButton';
import Instructions from 'src/components/Instructions';
import Pagination from 'src/components/Pagination';
import { H5 } from 'src/components/Typography';
import ListItemSkeleton from 'src/components/sekeletons/ListItemSkeleton';
import styles from 'src/styles/content.styles';
import { usePartnersLazyQueryPaginated } from 'src/utilities/hooks/useListPartnersLazyQuery';
import usePartnerMutation from 'src/utilities/hooks/useMutatePartner';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import { useToggleState } from 'src/utilities/hooks/useToggleState';
import { ConfirmPartnersInfoDialog } from './components/ConfirmPartnersInfoDialog';
import PartnerForm from './components/PartnerForm';
import { PartnerItem } from './components/PartnerItem';
import { ShareWithPartnerDialog } from './components/ShareWithPartnerDialog';
import { useCompanyId } from 'src/redux/containers/Companies';
import { Status } from 'src/consts/admin';
import withDialogCard from 'src/components/Cards/DialogCard';

function Partners({ setPartner, partner }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [target, scroll] = useScrollTarget();
	const [pageSize, setPageSize] = useState('5');
	const [approval, setApproval] = useState(false);
	const [open, toggleShareDialog] = useToggleState(false);
	const company_id = useCompanyId();
	// calculated
	const reqParams = useMemo(
		() => ({ limit: Number(pageSize), company_id }),
		[company_id, pageSize],
	);
	// apis
	const [
		isLoading,
		page,
		hasPrev,
		loadPrev,
		hasNext,
		loadNext,
		refetchCurrent,
	] = usePartnersLazyQueryPaginated(reqParams);
	// effects
	useEffect(() => scroll(), [scroll]);
	useEffect(() => setPartner(), [company_id]);

	const [mutatePartnerApi] = usePartnerMutation();
	useEffect(() => {
		const token = searchParams.get('qq');
		if (token)
			mutatePartnerApi({ token })
				.catch((error) => {
					enqueueSnackbar(error?.message ?? 'Invalid Token', {
						variant: 'error',
					});
				})
				.then(setApproval)
				.finally(() => setSearchParams({}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
			<Box className='header'>
				<H5>Manage Partners</H5>
				<Box display={'flex'} justifyContent='flex-end' alignItems='center'>
					<TitledButton
						handleClick={toggleShareDialog}
						variant='outlined'
						color='secondary'
						label='Invite'
						sx={{ width: '42px', height: '52px' }}
					>
						<ScheduleSendIcon height={24} width={24} />
					</TitledButton>
					<TitledButton
						handleClick={() => {
							setPartner({});
							scroll();
						}}
						variant='outlined'
						color='secondary'
						label='Add'
						sx={{ width: '42px', height: '52px' }}
					>
						<AddCircle height={24} width={24} />
					</TitledButton>
				</Box>
			</Box>
			<Instructions>
				Manage Partners who have access to assets managed by your organization.
			</Instructions>
			<Collapse in={!!partner}>
				<PartnerForm
					partner={partner}
					readonly={[Status.REJECTED, Status.PENDING].includes(partner?.status)}
					onCancel={() => {
						setPartner(undefined);
						scroll();
					}}
					onSubmit={() => {
						setPartner(undefined);
						refetchCurrent();
					}}
				/>
			</Collapse>
			<Stack spacing={0.5}>
				<Pagination
					hasNextPage={hasNext}
					hasPrevPage={hasPrev}
					onPressNextPage={loadNext}
					onPressPrevPage={loadPrev}
					onChangePageSize={setPageSize}
				/>
				<Stack spacing={0} className='list'>
					{isLoading
						? Array.from({ length: pageSize }).map((_, i) => (
								<ListItemSkeleton key={i} />
							))
						: page.map((p) => (
								<PartnerItem
									key={p.path}
									item={p}
									onEdit={setPartner}
									openApproval={setApproval}
								/>
							))}
				</Stack>
			</Stack>
			{open ? (
				<ShareWithPartnerDialog open={open} onClose={toggleShareDialog} />
			) : null}
			{approval ? (
				<ConfirmPartnersInfoDialog
					open={approval}
					onClose={() => {
						setApproval(false);
						refetchCurrent();
					}}
				/>
			) : null}
		</Stack>
	);
}

export default withDialogCard(Partners);
