import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useAssetLogLazyQuery } from 'src/utilities/hooks/useAssetLogQuery';
import { H5 } from '../Typography';
import BasicTimeline from './BasicTimeline';

export function AssetLog({ path = '' }) {
	// const [expanded, setExpanded] = useState(false);
	const expanded = true;
	const [fetchReportData, { data, isLoading }] = useAssetLogLazyQuery();

	useEffect(() => {
		fetchReportData({ asset_id: path }).finally();
	}, []);

	if (isLoading) return null;

	if (!data) return null;

	return (
		<div>
			<Box className='section'>
				<H5 className='section-header'>Asset Logs</H5>
				{/*<FlexBox sx={{ height: '50px' }}>
					{!expanded ? (
						<Timeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex: 0.2,
								},
							}}
						>
							<SingleTimeline item={data.res[0]} title={true} />
						</Timeline>
					) : (
						<span></span>
					)}
					 <Box onClick={() => setExpanded((prev) => !prev)}>
						{!expanded ? <KeyboardArrowRightIcon /> : <ExpandMoreIcon />}
					</Box>
				</FlexBox>*/}
				{expanded && <BasicTimeline items={data.res} />}
			</Box>
		</div>
	);
}
