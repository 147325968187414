import { Box, Skeleton } from '@mui/material';
import IdIcon from 'src/components/icons/Id';
import { Body3 } from 'src/components/Typography';
import React from 'react';

function IDDisplay({ id, onClick }) {
	if (!id) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
				cursor: onClick ? 'pointer' : 'arrow',
			}}
			onClick={onClick}
		>
			<IdIcon style={{ width: '12px', height: 'auto' }} />
			<Body3>{id}</Body3>
		</Box>
	);
}

export function IDDisplaySkeleton() {
	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<IdIcon style={{ width: '12px', height: 'auto' }} />
			<Skeleton variant={'text'} width={100} />
		</Box>
	);
}

export default IDDisplay;
