/**
 * Convert from SKU to SKUItem
 * @param sku
 */
// xport interface SKUConfigurations {
//   // Configuration parameters

import { INVENTORY_STATUS, SKU_TYPES } from 'src/consts/sku';

// }
//
// /**
//  * Tracks SKUs in the system by company id
//  * id:   /c/<company_id>
//  * path: /s/<sku_id>
//  */
// export interface SKU extends DynamoEntry, CreatorBase, UpdatedByBase, SKUConfigurations {
//   sku_id: string;
//   company_id: string;
//   sku_name: string;
//   sku_type: string;
//   sku_description: string;
//   sku_image?: string;
//   sku_tags: string[];
//   create_date: number;
//   count?: number;
//   size?: number;
//   abv?: number;
//   capacity?: number;
//   unit?: string;
// }

// export interface SKUStorageItem {
//   amount: number;
//   asset_type: string;
//   asset_id: string;
//   rw_asset_id: string;
// }
//
// // Each entry we have recorded
// // See SKUEntry for id/path
// export interface SKUEntryItem extends DynamoEntry, UpdatedByBase {
//   sku_entry_id?: string;
//   expiry?: number;
//   vendor?: string;             // company provider id
//   total_cost?: number;
//   amount: number;
//   storage: SKUStorageItem[];
// }

const toSKUItem = (sku) => {
	// Inbound SKU details
	const {
		id,
		path,
		sku_id,
		sku_name,
		sku_type,
		sku_description,
		count,
		size,
		abv,
		capacity,
		asset_type,
		prefix,
		last_number,
		unit,
		gross_weight,
		net_weight,
		tax_code,
		entries = [],
	} = sku ?? {};
	// Return the SKUItem
	return {
		id,
		path,
		sku_id,
		sku_name,
		sku_type,
		sku_description,
		count,
		size,
		abv,
		capacity,
		unit,
		asset_type,
		prefix,
		last_number,
		entries,
		gross_weight,
		net_weight,
		tax_code,
		// // TODO: this is hack for now for tracked items, eventually, once we generate the ids, then we should mark as done
		processed: [SKU_TYPES.TRACKED, SKU_TYPES.SERVICE].includes(sku_type)
			? INVENTORY_STATUS.CONFIRMED
			: INVENTORY_STATUS.PENDING,
	};
};

export default toSKUItem;
