import { useCallback, useState } from 'react';
import { getAccountTokens } from 'src/services/account.service';

const useAccountTokenQuery = () => {
  const limits = { limit: 24, max: 24 };
  const [data, setData] = useState({ filter: { ...limits, page: undefined } });
  const [tokens, setTokens] = useState([]);
  const [more, setMore] = useState(false);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);

  const load = useCallback(async (username) => {
    setLoading(true);
    try {
      const res = await getAccountTokens({ ...data, username });

      setData({ filter: { ...limits, page: res.pages } });
      setTokens((v) => {
        // First extract the tokens
        const tokens = res.tokens ?? {};
        for (const key of Object.keys(tokens)) {
          console.log('Account', key);
          if (tokens[key].length > 0) {
            v.push(...(tokens[key].map((t) => ({ ...t, account: key }))));
          }
        }
        return v;
      });
      setMore(!!res.pages);
    } catch (e) {
      console.log('Failed to load tokens', e);
      setError(e);
      return e;
    } finally {
      setLoading(false);
    }
  }, [data]);

  return [load, {
    tokens, more, loading, error: !!error,
  }];
};

export default useAccountTokenQuery;
