import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useMemo } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LoadingSmall from 'src/components/Loading/LoadingSmall';
import SearchButton from 'src/components/Search/SearchButton';
import { Body2 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import useMeasures from 'src/utilities/hooks/useMeasures';

function InventorySearch({
	name,
	loading = false,
	className = 'field',
	measure,
	handler,
	searching,
	InputLabelProps = {},
	...props
}) {
	const { values, withErrors, handleChange, readonly } = useContext(FormikContext);
	const { getMeasure } = useMeasures();
	const m = useMemo(() => (measure ? getMeasure(measure) : null), [getMeasure, measure]);

	return (
		<TextField
			variant='filled'
			type='number'
			InputLabelProps={{ ...InputLabelProps, shrink: true }}
			InputProps={{
				disableUnderline: true,
				endAdornment: (
					<InputAdornment position='end'>
						<FlexBox>
							{searching && <LoadingSmall message='' />}
							{!searching && (
								<>
									{m && (
										<Tooltip title={m.label}>
											<Body2
												sx={{ marginLeft: '0.75rem', fontWeight: '600' }}
											>
												{m.value}
											</Body2>
										</Tooltip>
									)}
									<SearchButton
										onClick={() => handler(values?.[name])}
										color='secondary'
									/>
								</>
							)}
						</FlexBox>
					</InputAdornment>
				),
				readOnly: readonly,
			}}
			className={className}
			name={name}
			value={values?.[name] ?? ''}
			onChange={handleChange}
			loading={loading || 'false'}
			{...withErrors(name)}
			{...props}
		/>
	);
}

export default InventorySearch;
