import { Stack } from '@mui/material';
import React from 'react';
import styles from 'src/styles/content.styles';
import AccountPayments from './AccountPayments';

function Activity() {

  return (
    <Stack sx={styles} className="root" spacing="1.25rem">
      <AccountPayments accountPayments={[]} />
    </Stack>
  );
}

export default Activity;
