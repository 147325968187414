export const REPORT_STATUS = {
	PENDING: 'pending',
	APPROVED: 'approved',
};

export const REPORT_TYPES = {
	MONTHLY: 'm',
	INSTANT: 'i',
};

export const REPORT_OPTIONS = [
	{ label: 'Monthly', value: 'm' },
	{ label: 'Instant', value: 'i' },
];

export const REPORT_EDIT_URL = {
	m: '/app/reports/monthly',
	i: '/app/reports/instant',
};
