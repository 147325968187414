import React, { useMemo } from 'react';
import RequestQRCode from 'src/components/QRImage/RequestQRCode';
import Bolder from 'src/components/Typography/Bolder';
import { ASSET_TYPES_REVERSE } from 'src/consts/asset';
import { MEASURE } from 'src/consts/measures';
import { SKU_TYPE_REVERSE } from 'src/consts/sku';
import useLiquidSummaryCalculator from 'src/utilities/hooks/useLiquidSummaryCalculator';

const styles = {
	container: {
		width: '100%',
		display: 'flex',
		// alignItems: 'center',
		flexDirection: 'column',
	},
	title: {
		marginTop: '2rem',
		marginBottom: '1rem',
		fontSize: '1rem',
		fontWeight: '600',
		textAlign: 'left',
	},
	card: {
		padding: '0.75rem',
		gap: '.75rem',
	},
};

function AssetRow({ asset, getMeasure, cto }) {
	const { properties = {} } = asset;
	const assetType = ASSET_TYPES_REVERSE[asset?.asset_type] ?? asset?.asset_type ?? '';
	const { level = {} } = properties?.liquid ?? {};
	const { enable = false, bl, abv } = level;

	const liq = getMeasure('l_m');
	const alc = getMeasure('alc');

	const liqVal = bl ? Number(cto(bl, liq)) : null;
	const abvVal = abv ? Number(cto(abv, alc)) : null;

	return (
		<tr>
			<td>{asset?.rw_asset_id}</td>
			<td>{assetType?.toUpperCase()}</td>
			<td>
				{!enable || !bl
					? 'EMPTY'
					: ` ${liqVal?.toFixed(2)}${liq.value} ${
						abv ? `@ ${abvVal?.toFixed(2)}${alc.value}` : ''
					}`}
			</td>
		</tr>
	);
}

function SKURow({ sku }) {
	return (
		<tr>
			<td>{sku?.sku_name}</td>
			<td>{SKU_TYPE_REVERSE[sku?.sku_type]}</td>
			<td>{sku?.sku_description}</td>
		</tr>
	);
}
function RequestSummaryHTML({ entity, scale, getMeasure, cto, skus }) {
	const liq = useMemo(() => getMeasure(MEASURE.l_m), [getMeasure]);
	const abv = useMemo(() => getMeasure(MEASURE.alc), [getMeasure]);

	const {
		asset_count,
		original_bulk,
		original_la,
		sku_count,
		sku_bulk,
		sku_la,
		sku_groups,
		net_weight,
		gross_weight,
		tcf_bulk,
		weighted_abv,
		weighted_tcf,
		duty_paid_asset_bulk,
		duty_paid_asset_la,
		duty_paid_sku_bulk,
		duty_paid_sku_la,
	} = useLiquidSummaryCalculator(scale, skus, entity?.sources, entity?.sku_sources);

	const disp_bulk = Number(cto(original_bulk ?? 0, MEASURE.l_m));
	const disp_la =Number(cto(original_la ?? 0, MEASURE.l_m));

	const disp_sku_bulk = Number(cto(sku_bulk ?? 0, MEASURE.l_m));
	const disp_sku_la = Number(cto(sku_la ?? 0, MEASURE.l_m));

	const disp_tcf_bulk = Number(cto(Number(tcf_bulk ?? 0) + Number(sku_bulk ?? 0), MEASURE.l_m));
	const disp_tcf_la = Number(cto(Number(original_la ?? 0) + Number(sku_la ?? 0), MEASURE.l_m));

	const disp_duty_paid_asset_bulk = Number(cto(duty_paid_asset_bulk ?? 0, MEASURE.l_m));
	const disp_duty_paid_asset_la = Number(cto(duty_paid_asset_la ?? 0, MEASURE.l_m));

	const disp_duty_paid_sku_bulk = Number(cto(duty_paid_sku_bulk ?? 0, MEASURE.l_m));
	const disp_duty_paid_sku_la = Number(cto(duty_paid_sku_la ?? 0, MEASURE.l_m));

	const disp_weighted_abv = Number(cto(weighted_abv ?? 0, MEASURE.alc));

	return (
		<div style={styles.container}>
			<RequestQRCode request={entity} fullScreen={false} />
			<p style={styles.title}>Assets</p>
			<table>
				<tr>
					<th>ID</th>
					<th>Type</th>
					<th>Liquid</th>
				</tr>
				{entity?.sources?.map((asset) => (
					<AssetRow asset={asset} key={asset?.path} getMeasure={getMeasure} cto={cto} />
				))}
			</table>

			<p style={styles.title}>SKUs</p>
			<table>
				<tr>
					<th>ID</th>
					<th>Type</th>
					<th>Description</th>
				</tr>
				{entity?.sku_sources?.map((sku) => (
					<SKURow sku={sku} key={sku?.path} />
				))}
			</table>

			<p style={styles.title}>Summary</p>
			<table>
				<tbody>
				{asset_count > 0 && (
					<>
						<tr>
							<th>Assets</th>
							<td>{asset_count}</td>
						</tr>
						<tr>
							<th>Content</th>
							<td>
								{disp_bulk?.toFixed(2)}
								{disp_la && (
									<>
										{' | '}
										<Bolder>{disp_la?.toFixed(2)}</Bolder>
									</>
								)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{liq.value}</Bolder>
							</td>
						</tr>
						<tr>
							<th>Tax On</th>
							<td>
								{disp_duty_paid_asset_bulk?.toFixed(2)}
								{disp_duty_paid_asset_la && (
									<>
										{' | '}
										<Bolder>{disp_duty_paid_asset_la?.toFixed(2)}</Bolder>
									</>
								)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{liq.value}</Bolder>
							</td>
						</tr>
					</>
				)}
				{sku_count > 0 && (
					<>
						<tr>
							<th>SKUs</th>
							<td>{entity?.sku_sources?.length}</td>
						</tr>
						<tr>
							<th>Items</th>
							<td>{sku_count}</td>
						</tr>
						<tr>
							<th>Groups</th>
							<td>{sku_groups}</td>
						</tr>
						<tr>
							<th>Content</th>
							<td>
								{disp_sku_bulk?.toFixed(2)}
								{disp_sku_la && (
									<>
										{' | '}
										<Bolder>{disp_sku_la?.toFixed(2)}</Bolder>
									</>
								)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{liq.value}</Bolder>
							</td>
						</tr>
						<tr>
							<th>Tax On</th>
							<td>
								{disp_duty_paid_sku_bulk?.toFixed(2)}
								{disp_duty_paid_sku_la && (
									<>
										{' | '}
										<Bolder>{disp_duty_paid_sku_la?.toFixed(2)}</Bolder>
									</>
								)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{liq.value}</Bolder>
							</td>
						</tr>
					</>
				)}
				<tr>
					<th>Net Weight</th>
					<td>
						{cto(net_weight, MEASURE.w_m)}
						{getMeasure(MEASURE.w_m)?.value}
					</td>
				</tr>
				<tr>
					<th>Gross Weight</th>
					<td>
						{cto(gross_weight, MEASURE.w_m)}
						{getMeasure(MEASURE.w_m)?.value}
					</td>
				</tr>
				<tr>
					<th>Adjusted Content</th>
					<td>
						{disp_tcf_bulk?.toFixed(2)}
						{disp_tcf_la && (
							<>
								{' | '}
								<Bolder>{disp_tcf_la?.toFixed(2)}</Bolder>
							</>
						)}
						<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{liq.value}</Bolder>
					</td>
				</tr>
				<tr>
					<th>Strength</th>
					<td>
						{disp_weighted_abv?.toFixed(2)}
						<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{abv.value}</Bolder>
						{weighted_tcf && (
							<>
								{' | '}
								<Bolder>TCF:</Bolder>
								&nbsp;
								{Number(weighted_tcf).toFixed(3)}
							</>
						)}
					</td>
				</tr>

				</tbody>
			</table>


		</div>
	);
}

export default RequestSummaryHTML;
