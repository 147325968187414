import { useCallback, useMemo } from 'react';
import { clientFields } from 'src/components/Client/clientFields';
import useSourceInventoryHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';

function PickForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Materials',
		filter: {
			asset_types: [ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.pallet, ASSET_TYPES.container],
			sku_types: [SKU_TYPES.FINISHED, SKU_TYPES.TRACKED, SKU_TYPES.RAW, SKU_TYPES.WASTE, SKU_TYPES.CONSUMABLE, SKU_TYPES.EXPIRING, SKU_TYPES.SERVICE],
		},
		submitForm,
		SKUModal: SourceSKUModal,
	});

	const changeSetGenerator = useMemo(() => (initialValues) => ({
		...requestIdFields(initialValues),
		client: clientFields(initialValues?.client),
	}), []);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback((entity) => {
		initSources({ ...entity, assets: entity?. sources, skus: entity?.sku_sources });
	}, [initSources]);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
		}),
		[sourceSkus, sourceAssets],
	);

  return (
    <>
      <RequestForm
        requestLabel="Pick"
        requestType={REQUEST_TYPES.pick}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
				<SourceInventory />
				<FormSubmitter />
      </RequestForm>

			<SourceModals />
    </>
  );
}

export default withAppLayout(PickForm, { title: 'Picks' });
