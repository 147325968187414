import {
	AccountCircleRounded,
	Edit,
	PendingActions,
	Send,
} from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box, Stack } from '@mui/material';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { Body3, H5, Overline } from 'src/components/Typography';

export function CustomerItem({ customer, onInvite, onEdit, onResetPassword }) {
	const { account_username, account_name, invited, joined } =
		customer;

	return (
		<Box className='listItem'>
			<FlexBox>
				<Box className='listContent'>
					<Stack direction='row' spacing='0.75rem'>
						{joined ? (
							<AccountCircleRounded />
						) : (
							<PendingActions title='Pending' aria-label='Pending' />
						)}
						<Stack spacing={0.25}>
							<H5>{account_name}</H5>
							<Body3>{account_username}</Body3>
							{invited || joined ? (
								<Overline>
									{new Date(joined ?? invited).toLocaleString()}
								</Overline>
							) : null}
						</Stack>
					</Stack>
				</Box>
				<Stack className='listButtons' flexDirection={'row'}>
					{joined ? (
						<IconCircleButton
							onClick={() => onResetPassword(customer)}
							help='Reset password'
						>
							<LockResetIcon />
						</IconCircleButton>
					) : null}
					<IconCircleButton
						onClick={() => onEdit(customer)}
						help='Edit customer'
					>
						<Edit />
					</IconCircleButton>
					{!joined ? (
						<IconCircleButton
							onClick={() => onInvite(customer)}
							help='Invite customer'
						>
							<Send />
						</IconCircleButton>
					) : null}
				</Stack>
			</FlexBox>
		</Box>
	);
}
