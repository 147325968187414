import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import Address from 'src/components/Company/Address';
import {
	FormikCheckBox,
	FormikForm,
	FormikPhoneField,
	FormikTextField,
	RenderIF,
} from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { H5 } from 'src/components/Typography';
import { UK_COUNTRY_CODES } from 'src/consts/admin';
import {
	useCompanyCountry,
	useCompanyId,
} from 'src/redux/containers/Companies';
import generateUsername from 'src/utilities/generateUsername';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useMutateCustomer from 'src/utilities/hooks/useMutateCustomer';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import * as yup from 'yup';
// import "yup-phone";
import gPhoneNumber from 'google-libphonenumber';
import * as Yup from 'yup';
var b = gPhoneNumber.PhoneNumberUtil.getInstance();
Yup.addMethod(Yup.string, 'phone', function (a, d, c) {
	void 0 === d && (d = !1);
	void 0 === c && (c = '');
	return this.test(
		'phone',
		'string' === typeof c && c
			? c
			: 'string' === typeof a && 2 === a.length
				? '${path} must be a valid phone number for region ' + a
				: '${path} must be a valid phone number.',
		function (f) {
			if ('string' !== typeof a || 2 !== a.length) (a = 'IN'), (d = !1);
			try {
				var e = b.parseAndKeepRawInput(f, a);
				if (!b.isPossibleNumber(e)) return !1;
				var g = b.getRegionCodeForNumber(e);
				return d
					? b.isValidNumberForRegion(e, a)
					: b.isValidNumberForRegion(e, g);
			} catch (h) {
				return !1;
			}
		},
	);
});

function CustomerForm({ user, onSubmit, onCancel }) {
	const [target, scroll] = useScrollTarget();
	const [mutateCustomerApi, { isLoading }] = useMutateCustomer();
	const [AlertView, { setAlert }] = useAlertView();
	const company_id = useCompanyId();
	const company_cc = useCompanyCountry();

	const {
		path,
		name = '',
		email = '',
		username = '',
		country,
		wowgr = false,
		duty_req = false,
	} = user ?? {};

	console.log('User', user);

	const changeSet = useMemo(
		() => ({
			name: [name, yup.string()],
			username: [
				username || generateUsername(),
				yup.string().required('Username is required!'),
			],
			email: [
				email,
				yup.string().email('Invalid email!').required('Email required!'),
			],
			wowgr: [wowgr, yup.boolean()],
			duty_req: [duty_req, yup.boolean()],
			//  address
			line1: [
				user?.address?.address_1 ?? '',
				yup.string().required('Address is required!'),
			],
			line2: [user?.address?.address_2 ?? '', yup.string()],
			line3: [user?.address?.address_3 ?? '', yup.string()],
			city: [
				user?.address?.city ?? '',
				yup.string().required('City is required!'),
			],
			state: [user?.address?.state ?? '', yup.string()],
			country: [
				country ?? company_cc ?? '',
				yup.string().required('Country is required!'),
			],
			postCode: [
				user?.address?.post_code ?? '',
				yup.string().required('Post code is required!'),
			],
			/** phone  */
			telephone: [user?.telephone ?? '', yup.string().phone()],
		}),
		[
			name,
			username,
			email,
			wowgr,
			duty_req,
			user?.address?.address_1,
			user?.address?.address_2,
			user?.address?.address_3,
			user?.address?.city,
			user?.address?.state,
			user?.address?.post_code,
			user?.telephone,
			country,
			company_cc,
		],
	);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			try {
				console.log('Sending customer create', values);
				// Save the description
				const payload = {
					...values,
					company_id,
					path,
				};
				const response = await mutateCustomerApi(payload);
				console.log('customer created', response);
				setAlert(null);
				onSubmit && onSubmit(response);
			} catch (e) {
				console.log('Failed to create customer!', e);
				setAlert({
					severity: 'error',
					title: 'Failed to mutate customer!',
					content: e.message,
				});
			}
		},
		[company_id, mutateCustomerApi, onSubmit, path, setAlert],
	);

	useEffect(() => {
		scroll();
		setAlert(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={(values) => handleSubmit(values)}
			enableReinitialize
		>
			<Stack className='form' spacing='1.2rem' ref={target}>
				<H5>{path ? 'Update Customer' : 'Create Customer'}</H5>

				<Instructions>
					Allow your customers to access their assets.
				</Instructions>

				<FormikTextField
					name='name'
					label='Full name'
					autoComplete='name'
					required
				/>

				<FormikTextField name='username' label='User name' required />

				<FormikTextField
					name='email'
					label='Contact email'
					autoComplete='email'
					required
					disabled={Boolean(path)}
				/>
				<Address />
				<FormikPhoneField name='telephone' label='Phone' fullWidth />
				<RenderIF check={() => UK_COUNTRY_CODES.includes(company_cc)}>
					<Instructions>
						Current regulations may require the customer to be registered
						under&nbsp; WOWGR (if they are UK based) or alternatively have Duty
						Representation.
						<br />
						Please indicate if they have or require this.
					</Instructions>
					<RenderIF
						check={({ values }) =>
							values.country && UK_COUNTRY_CODES.includes(values.country)
						}
					>
						<FormikCheckBox
							name='wowgr'
							label='Customer is registered for WOWGR'
						/>
					</RenderIF>
					<FormikCheckBox name='duty_req' label='Require Duty Representation' />
				</RenderIF>
				<AlertView />
				<Box
					className='action-buttons'
					display={'flex'}
					justifyContent={'flex-end'}
				>
					<TextButton
						size='small'
						handleClick={onCancel}
						color='secondary'
						disabled={isLoading}
					>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small' disabled={isLoading}>
						{path ? 'Update' : 'Create'}
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

CustomerForm.propTypes = {
	user: PropTypes.object,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
};

export default CustomerForm;
