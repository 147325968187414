import { SortByAlphaTwoTone } from '@mui/icons-material';
import { Box, MenuItem, Select } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import { SearchFieldSmall } from 'src/components/Search/SearchField';
import { getAssetAttrs, getSkuAttrs } from 'src/utilities/adapters/getAttrs';
import debounce from 'src/utilities/debounce';

export function Filters({
	showFilter,
	setFilter,
	setSort,
	setDirection,
	options = [],
}) {
	if (!showFilter) return;
	// console.log('Filter')
	return (
		<Box className='filter-form'>
			<SearchFieldSmall onSearch={setFilter} />
			{options.length > 0 && (
				<>
					<Select
						size='small'
						variant='outlined'
						defaultValue='none'
						inputProps={{
							sx: {
								fontSize: '0.75rem',
								paddingTop: '0.35rem',
								paddingBottom: '0.35rem',
								minWidth: 'min-content',
							},
							name: 'sort',
						}}
						onChange={(e) => debounce(() => setSort(e.target.value), 25)}
					>
						{options.map(({ value, label }) => (
							<MenuItem value={value} key={value}>
								{label}
							</MenuItem>
						))}
					</Select>
					<IconCircleButton
						aria-label='Sort Direction'
						onClick={() => debounce(() => setDirection((x) => !x), 25)}
						edge='end'
					>
						<SortByAlphaTwoTone />
					</IconCircleButton>
				</>
			)}
		</Box>
	);
}

function useFilters(showFilter, skuList, assetList, options) {
	const [filter, setFilter] = useState('');
	// True: Ascending, False: Descending
	const [direction, setDirection] = useState(true);
	const [sort, setSort] = useState('none');

	const [sortedSkuList, setSortedSkuList] = useState(skuList);
	const [sortedAssetList, setSortedAssetList] = useState(assetList);

	useEffect(() => {
		if (!showFilter) {
			debounce(() => {
				setFilter('');
				setDirection(true);
				setSort('none');
			}, 25);
		}
	}, [showFilter]);

	// Apply the filtering and sorting
	useEffect(() => {
		// console.log('SKUs', skuList.length, 'Assets', assetList.length, 'Filter', filter, 'Direction', direction, 'Sort', sort);
		let allSkus = [...skuList];
		let allAssets = [...assetList];

		if (filter) {
			const sf = filter.trim().toLowerCase();
			// Filter the SKUs
			allSkus = skuList.filter((sku) => getSkuAttrs(sku).includes(sf));
			allAssets = assetList.filter((asset) =>
				getAssetAttrs(asset).includes(sf),
			);
		}
		if (sort && sort !== 'none') {
			const sf = options.find((o) => o.value === sort);
			if (sf) {
				allSkus.sort((a, b) => {
					const av = sf.getter(a);
					const bv = sf.getter(b);
					if (!av || !bv) {
						return 0;
					}
					return av < bv ? 1 : -1;
				});
				allAssets.sort((a, b) => {
					const av = sf.getter(a);
					const bv = sf.getter(b);
					if (!av || !bv) {
						return 0;
					}
					return av < bv ? 1 : -1;
				});
				if (!direction) {
					allSkus.reverse();
					allAssets.reverse();
				}
			}
		}
		debounce(() => {
			setSortedSkuList(allSkus);
			setSortedAssetList(allAssets);
		}, 25);
	}, [skuList, assetList, filter, direction, sort]);

	return useMemo(
		() => [sortedAssetList, sortedSkuList, [setFilter, setSort, setDirection]],
		[sortedAssetList, sortedSkuList],
	);
}

export default useFilters;
