import { useMemo } from 'react';
import { useCompanyId } from 'src/redux/containers/Companies';

const useCheckCompany = (entity) => {
	const companyId = useCompanyId();

	const sameCompany = useMemo(() => !entity || (entity.company_id && companyId === entity.company_id), [companyId, entity]);

	return [ sameCompany ];
};

export const useIsSameCompany = () => {
	const companyId = useCompanyId();

	const sameCompany = (company_id) => !company_id || companyId === company_id;

	return sameCompany;
};

export default useCheckCompany;
