import { Print } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import FormikContext from 'src/context/FormikContext';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import CreatorProps from 'src/components/CreatorProps';
import {
	FormikDatePicker,
	FormikForm,
	FormikNumberField,
	FormikSelect,
	FormikTextField,
} from 'src/components/Forms/FormikForm';
import { TagSelect } from 'src/components/Forms/TagSelect';
import { FlexBox } from 'src/components/Layout/FlexBox';
import FormWrapper from 'src/components/Layout/FormWrapper';
import SkuQRCode from 'src/components/QRImage/SkuQRCode';
import { Body2, H5 } from 'src/components/Typography';
import { ASSET_OPTIONS } from 'src/consts/asset';
import { MEASURE, toMeasure } from 'src/consts/measures';
import { SKU_LIQUID_TYPES, SKU_OPTIONS, SKU_TYPES } from 'src/consts/sku';
import { selectCompanyName } from 'src/redux/containers/Companies';
import { mt2 } from 'src/styles/margins';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useIsUsUser from 'src/utilities/hooks/useIsUsUser';
import useMeasures from 'src/utilities/hooks/useMeasures';
import useSkuMutation from 'src/utilities/hooks/useSkuMutation';
import printElement from 'src/utilities/printElement';
import * as yup from 'yup';

//   TRACKED: 't',             // tracked inventory (each individual asset is tracked)
//   STANDARD: 's',            // standard quantised inventory

//   FINISHED: 'f',            // finished goods, not individually tracked

//   CONSUMABLE: 'c',          // general stock (dry goods etc.)
//   WIP: 'i',                 // Work-in-progress

//   RAW: 'r',                 // raw ingredients
//   EXPIRING: 'e',            // expiring ingredients
//   WASTE: 'w',               // can be used for potentially tracking waste

function Properties({ taxCodes = [] }) {
	const isUS = useIsUsUser();
	const { values } = useContext(FormikContext);
	console.log('Form values', values);
	const type = useMemo(() => values.sku_type, [values.sku_type]);
	const { getMeasure, options, liquid_options } = useMeasures();
	return (
		<>
			{[SKU_TYPES.TRACKED].includes(type) && (
				<Stack spacing='1.5rem'>
					<H5>Volume</H5>
					<FlexBox>
						<FormikNumberField
							label='Capacity'
							name='capacity'
							InputProps={{
								endAdornment: (
									<FormikSelect
										label='Unit'
										name='unit'
										options={options}
										sx={{
											marginRight: '-0.4rem',
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
									/>
								),
							}}
							fullWidth
						/>
					</FlexBox>
					<H5>Weight</H5>
					<FlexBox spacing={2}>
						<FormikNumberField
							label='Gross Weight'
							name='gross_weight'
							fullWidth
							InputProps={{
								endAdornment: <Body2>{getMeasure(MEASURE.w_m)?.value}</Body2>,
							}}
						/>
						<FormikNumberField
							label='Net Weight'
							name='net_weight'
							fullWidth
							InputProps={{
								endAdornment: <Body2>{getMeasure(MEASURE.w_m)?.value}</Body2>,
							}}
						/>
					</FlexBox>
					<H5>Asset Properties</H5>
					<FlexBox>
						<FormikSelect
							fullWidth
							options={ASSET_OPTIONS}
							label='Asset Type'
							name='asset_type'
						/>
					</FlexBox>
					<FlexBox spacing={2}>
						<FormikTextField
							label='Prefix'
							name='prefix'
							fullWidth
							required
							autoComplete='prefix'
						/>
						<FormikNumberField
							label='Last Number'
							name='last_number'
							required
							fullWidth
						/>
					</FlexBox>
				</Stack>
			)}
			{type === SKU_TYPES.FINISHED && (
				<Stack spacing={2}>
					<H5>Finished Goods</H5>
					<FormikTextField label='Product' name='product' fullWidth />
					<FormikTextField label='Lot Code' name='lot_code' fullWidth />
					<FormikSelect
						label={isUS ? 'Tax Category ' : 'Tax Code'}
						name='tax_code'
						options={taxCodes}
						fullWidth
					/>
					<FlexBox>
						<FormikNumberField label='Item Grouping' name='count' fullWidth />
						<FormikNumberField
							label='Item Size'
							name='size'
							InputProps={{
								endAdornment: (
									<FormikSelect
										label='Unit'
										name='unit'
										options={liquid_options}
										sx={{
											marginRight: '-0.4rem',
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
									/>
								),
							}}
							fullWidth
						/>
						<FormikNumberField
							label='ABV'
							name='abv'
							fullWidth
							InputProps={{
								endAdornment: <Body2>{getMeasure(MEASURE.alc)?.value}</Body2>,
							}}
						/>
					</FlexBox>
					<H5>Weight</H5>
					<FlexBox>
						<FormikNumberField
							label='Gross Weight'
							name='gross_weight'
							fullWidth
							InputProps={{
								endAdornment: <Body2>{getMeasure(MEASURE.w_m)?.value}</Body2>,
							}}
						/>
						<FormikNumberField
							label='Net Weight'
							name='net_weight'
							fullWidth
							InputProps={{
								endAdornment: <Body2>{getMeasure(MEASURE.w_m)?.value}</Body2>,
							}}
						/>
					</FlexBox>
				</Stack>
			)}
			{[SKU_TYPES.WASTE, SKU_TYPES.RAW, SKU_TYPES.EXPIRING].includes(type) && (
				<Stack spacing={1}>
					<H5>Measure</H5>
					<FormikSelect label='Unit' name='unit' options={options} />
				</Stack>
			)}
			{type === SKU_TYPES.LIQUID && (
				<Stack spacing={2}>
					<H5>Liquid</H5>
					<FlexBox>
						<FormikTextField
							label='Inventory Price'
							name='price_code'
							fullWidth
						/>
						<FormikTextField
							label='Growth Factor'
							name='growth_factor'
							fullWidth
						/>
					</FlexBox>
					<FormikSelect
						label='Liquid type'
						name='liquid_type'
						options={SKU_LIQUID_TYPES}
						fullWidth
					/>
					<FormikSelect
						label={isUS ? 'Tax Category ' : 'Tax Code'}
						name='tax_code'
						options={taxCodes}
						fullWidth
					/>
				</Stack>
			)}
			{type === SKU_TYPES.SERVICE && (
				<Stack spacing={2}>
					<H5>Service</H5>
					<FormikTextField label='Unit Price' name='price_code' fullWidth />
				</Stack>
			)}
			{type === SKU_TYPES.WIP && (
				<Stack spacing={2}>
					<H5>Sales Link</H5>
					<FormikTextField
						label='Marketplace Collection'
						name='collection_id'
						fullWidth
					/>
					<FlexBox>
						<FormikSelect
							label='Status'
							name='sales_status'
							options={[
								{ label: 'Active', value: 'active' },
								{ label: 'Paused', value: 'paused' },
								{ label: 'Completed', value: 'completed' },
							]}
							fullWidth
						/>
						<FormikDatePicker
							sx={{ ...mt2 }}
							name='listing_date'
							label='Listing Date'
							fullWidth
							required
						/>
					</FlexBox>
					<FlexBox>
						<FormikNumberField
							label='List Price'
							name='sales_price'
							fullWidth
						/>
						<FormikNumberField
							label='Storage Price'
							name='storage_price'
							fullWidth
						/>
					</FlexBox>
				</Stack>
			)}
		</>
	);
}

function SKU({ entity, onSave, cancel, taxCodes = [], selectedSkuType }) {
	const printRef = useRef();
	const isUS = useIsUsUser();
	const companyName = useSelector(selectCompanyName);

	const [AlertView, { setAlert }] = useAlertView();
	const { getMeasure, to: cto, from: cfrom } = useMeasures();

	// Nim: Leaving this editable for now
	const editing = false; // useMemo(() => !!entity?.path, [entity]);

	const [mutate, { isLoading }] = useSkuMutation();

	const changeSet = useMemo(
		() => ({
			sku_name: [
				entity?.sku_name ?? '',
				yup.string().required('SKU name is required!'),
			],
			sku_type: [
				entity?.sku_type ??
					SKU_OPTIONS[selectedSkuType]?.value ??
					SKU_TYPES.CONSUMABLE,
				yup.string().required('SKU type is required!'),
			],
			sku_description: [
				entity?.sku_description ?? '',
				yup.string().required('SKU description is required!'),
			],
			lot_code: [entity?.lot_code ?? '', yup.string()],
			sku_tags: [entity?.sku_tags ?? [], yup.array().of(yup.string())],
			count: [entity?.count ?? '', yup.number()],
			size: [
				entity?.size ? cto(entity?.size, toMeasure(entity?.unit ?? '')) : '',
				yup.number(),
			],
			abv: [
				entity?.abv ? cto(entity?.abv ?? 0, MEASURE.alc) : '',
				yup.number(),
			],
			capacity: [
				entity?.capacity
					? cto(entity?.capacity ?? 0, toMeasure(entity?.unit ?? ''))
					: '',
				yup.number(),
			],
			unit: [toMeasure(entity?.unit ?? ''), yup.string()],
			product: [entity?.product ?? '', yup.string()],
			asset_type: [
				entity?.asset_type ?? '',
				yup.string().when('sku_type', {
					is: (sku_type) => sku_type === SKU_TYPES.TRACKED,
					then: (schema) => schema.required('Asset type is required!'),
					otherwise: (schema) => schema,
				}),
			],
			prefix: [
				entity?.prefix ?? '',
				yup.string().when('sku_type', {
					is: (sku_type) => sku_type === SKU_TYPES.TRACKED,
					then: (schema) => schema.required('Prefix is required!'),
					otherwise: (schema) => schema,
				}),
			],
			last_number: [
				entity?.last_number ?? '0',
				yup.string().when('sku_type', {
					is: (sku_type) => sku_type === SKU_TYPES.TRACKED,
					then: (schema) => schema.required('Last Number is required!'),
					otherwise: (schema) => schema,
				}),
			],
			gross_weight: [
				entity?.gross_weight ? cto(entity?.gross_weight ?? 0, MEASURE.w_m) : '',
				yup.number(),
			],
			net_weight: [
				entity?.net_weight ? cto(entity?.net_weight ?? 0, MEASURE.w_m) : '',
				yup.number(),
			],
			price_code: [
				entity?.price_code ?? '',
				yup.number().when('sku_type', {
					is: (sku_type) =>
						[SKU_TYPES.LIQUID, SKU_TYPES.SERVICE].includes(sku_type),
					then: (schema) => schema.required('Price is required!'),
					otherwise: (schema) => schema,
				}),
			],
			growth_factor: [entity?.growth_factor ?? '', yup.number()],
			liquid_type: [
				entity?.liquid_type ?? '',
				yup.string().when('sku_type', {
					is: (sku_type) => sku_type === SKU_TYPES.LIQUID,
					then: (schema) => schema.required('Liquid Type is required!'),
					otherwise: (schema) => schema,
				}),
			],
			collection_id: [entity?.collection_id ?? '', yup.string()],
			sales_status: [entity?.sales_status ?? '', yup.string()],
			listing_date: [entity?.listing_date ?? '', yup.number()],
			sales_price: [entity?.sales_price ?? '', yup.number()],
			storage_price: [entity?.storage_price ?? '', yup.number()],
			tax_code: [
				entity?.tax_code ?? '',
				yup.string().when('sku_type', {
					is: (sku_type) =>
						[SKU_TYPES.LIQUID, SKU_TYPES.FINISHED].includes(sku_type),
					then: (schema) =>
						schema.required(
							isUS ? 'Tax Category is required!' : 'Tax Code is required!',
						),
					otherwise: (schema) => schema,
				}),
			],
		}),
		[cto, entity, isUS],
	);

	const handleSubmit = async (values) => {
		console.log('Saving sku', values, entity);
		try {
			let {
				// eslint-disable-next-line prefer-const
				size,
				abv,
				capacity,
				unit,
				gross_weight,
				net_weight,
				...rest
			} = values;
			// Adjust the quantities..
			if (size && unit) {
				size = cfrom(size, unit);
			}
			if (abv) {
				abv = cfrom(abv, MEASURE.alc);
			}
			if (capacity && unit) {
				capacity = cfrom(capacity, unit);
			}
			if (gross_weight) {
				gross_weight = cfrom(gross_weight, MEASURE.w_m);
			}
			if (net_weight) {
				net_weight = cfrom(net_weight, MEASURE.w_m);
			}
			console.log(
				' -->sku',
				size,
				abv,
				capacity,
				unit,
				gross_weight,
				net_weight,
			);

			const response = await mutate({
				...entity,
				size,
				abv,
				capacity,
				unit,
				gross_weight,
				net_weight,
				...rest,
			});
			console.log('Saved item', response);
			onSave(response);
			setAlert(null);
		} catch (err) {
			setAlert({
				severity: 'error',
				title: editing ? 'Failed to Update' : 'Failed to Save',
				content: err.message,
			});
		}
	};

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
	};

	const qrValue = useMemo(() => {
		const obj = {
			id: entity?.sku_id,
			lc: entity?.lot_code,
			c: companyName,
			p: entity?.product,
			a: cto(entity?.abv ?? 0, MEASURE.alc),
			s: entity?.size,
			i: entity?.count,
		};
		return JSON.stringify(obj);
	}, [companyName, entity]);

	return (
		<FormWrapper>
			<FormikForm
				changeSet={changeSet}
				onSubmit={handleSubmit}
				readonly={editing}
			>
				<Stack className='form' spacing='2rem'>
					<Stack spacing='1.5rem'>
						<FlexBox justifyContent='space-between'>
							<H5>SKU Identity</H5>
							{editing && [SKU_TYPES.FINISHED].includes(entity?.sku_type) ? (
								<TitledButton
									handleClick={() => handlePrint(printRef)}
									variant='outlined'
									color='secondary'
									label='Print'
									sx={{ width: '72px', height: '72px' }}
								>
									<Print />
								</TitledButton>
							) : null}
						</FlexBox>
						{editing && [SKU_TYPES.FINISHED].includes(entity?.sku_type) ? (
							<Box className='summary-qr'>
								<Box ref={printRef} className='qr'>
									<SkuQRCode
										abv={cto(entity?.abv ?? 0, MEASURE.alc)}
										sku={entity}
										companyName={companyName}
										qrValue={qrValue}
										unit={getMeasure(entity?.unit)?.value}
										abvUnit={getMeasure(MEASURE.alc)?.value}
									/>
								</Box>
							</Box>
						) : null}
						<FlexBox alignItems='top'>
							<FormikTextField
								disabled={editing}
								label='Name'
								name='sku_name'
								fullWidth
							/>
							<FormikSelect
								disabled={editing}
								label='Type'
								name='sku_type'
								options={SKU_OPTIONS}
								fullWidth
							/>
						</FlexBox>
						<FormikTextField
							label='Description'
							name='sku_description'
							fullWidth
						/>
					</Stack>

					{entity?.path && <CreatorProps entity={entity} />}

					<Stack spacing='1.5rem'>
						<H5>Properties</H5>
						<TagSelect name='sku_tags' label='Tags' type='sku_tags' fullWidth />
					</Stack>

					<Properties editing={!!entity?.path} taxCodes={taxCodes} />

					<AlertView />

					<Box className='action-buttons'>
						{cancel && (
							<TextButton
								size='small'
								handleClick={cancel}
								color='secondary'
								disabled={isLoading}
							>
								Cancel
							</TextButton>
						)}
						<FilledButton type='submit' size='small' disabled={isLoading}>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</FormWrapper>
	);
}

export default SKU;
