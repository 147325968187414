import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import StrengthDisplay from 'src/components/properties/StrengthDisplay';
import { MEASURE } from 'src/consts/measures';
import FormikContext from 'src/context/FormikContext';
import useMeasures from 'src/utilities/hooks/useMeasures';

function LiquidSummary({ level }) {
	const { bl = 0 } = level || {};
	const { values } = useContext(FormikContext);

	const [state, setState] = useState(null);

	const measures = useMeasures();

	const { to: cto, from: cfrom, imperial } = measures;

	useEffect(() => {
		const { expected_bl, filled_bl, updated_bl, updated_abv, updated_tcf } =
			values;

		console.log('Values', level, values);

		// Expected
		let rbl = 0;
		let abv = 0;
		let nla = 0;
		let gain = 0;

		if (filled_bl) {
			if (updated_abv && updated_tcf) {
				rbl = Number(bl) + Number(cfrom(filled_bl, MEASURE.l_m));
				abv = Number(cfrom(updated_abv, MEASURE.alc)) * Number(updated_tcf);
				nla = Number(rbl) * (cto(abv, MEASURE.alc) / 100.0);
			}
		} else {
			if (updated_bl && updated_abv && updated_tcf) {
				rbl = Math.max(Number(bl) - Number(cfrom(expected_bl, MEASURE.l_m)), 0);
				abv = Number(cfrom(updated_abv, MEASURE.alc)) * Number(updated_tcf);
				nla = Number(updated_bl) * (cto(abv, MEASURE.alc) / 100.0);
				gain = cfrom(updated_bl, MEASURE.l_m) - rbl;
			}
		}

		setState([
			Number(rbl).toFixed(2), // expected
			Number(abv).toFixed(2), // updated abv
			Number(nla).toFixed(2), // computed alcohol based on entered remainder
			gain ? Number(gain).toFixed(2) : null, // gain or loss between expected remainder and entered remainder
		]);
	}, [values]);

	if (!state) return;

	return (
		<Stack spacing={2}>
			<FlexBox gap={2}>
				<StrengthDisplay
					label={imperial ? 'Strength @ 60°F' : 'Strength @ 20°C'}
					value={state[1]}
				/>
				<LiquidDisplay label='Alcohol' value={state[2]} />
			</FlexBox>
			<FlexBox gap={2}>
				<LiquidDisplay label='Total' value={state[0]} />
				{state[3] && <LiquidDisplay label='Gain' value={state[3]} />}
			</FlexBox>
		</Stack>
	);
}

export default LiquidSummary;
