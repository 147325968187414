import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import AssetForm from 'src/components/AssetForm';
import assetIdFields from 'src/components/AssetID/assetIdFields';
import { FormikCheckBox } from 'src/components/Forms/FormikForm';
import { TagSelect } from 'src/components/Forms/TagSelect';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import LiquidProperties, { liquidPropertyFields } from 'src/components/Liquid/LiquidProperties';
import templateFields from 'src/components/TemplateHandler/templateFields';
import { Body1, H5 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { FormikProvider } from 'src/providers/FormikProvider';
import processLiquid from 'src/utilities/adapters/processLiquid';
import useMeasures from 'src/utilities/hooks/useMeasures';
import * as yup from 'yup';

function propertyFields(fields = {}, measures = {}) {
	const { is_production_tank = false, tags = [], liquid = {} } = fields;

	return {
		is_production_tank: [is_production_tank, yup.bool()],
		tags: [typeof tags === 'string' ? tags?.split(',') : tags, yup.array().of(yup.string())],
		liquid: liquidPropertyFields(liquid, measures, true),
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack>
				<FormikCheckBox name='is_production_tank' label={<Body1>Production Tank</Body1>} />
			</Stack>
			<Stack spacing={2}>
				<H5>Filling Tank Properties</H5>
				<TagSelect
					name='tags'
					label='Filling Tank properties'
					type='fillingTank'
					fullWidth
				/>
			</Stack>
			<LiquidProperties name='liquid' useWeight useBulk={false} />
		</FormikProvider>
	);
}

function TankForm(props) {
	const measures = useMeasures();

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			properties: propertyFields(initialValues.properties, measures),
		}),
		[],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
		};
	};

	return (
		<AssetForm
			assetLabel='Filling Tank'
			assetType={ASSET_TYPES.filling_tank}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</AssetForm>
	);
}

export default withAppLayout(TankForm, { title: 'Filling Tank Management' });
