import { AddCircle } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TitledButton from 'src/components/Buttons/TitledButton';
import Instructions from 'src/components/Instructions';
import { Body3, H5 } from 'src/components/Typography';
import { useCompanyId } from 'src/redux/containers/Companies';
import { listRoles } from 'src/services/company.service';
import styles from 'src/styles/content.styles';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import Role from './RoleForm';

function RoleItem({ role }) {
  const roleName = role.path.replace('/r/', '');

  return (
    <Box className="listItem">
      <Box className="listContent">
        <H5>{roleName}</H5>
        <Body3>{role.description}</Body3>
      </Box>
    </Box>
  );
}

function Roles() {
  const [, setLoading] = useLoadingHook();
  const [target, scroll] = useScrollTarget();

  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState(false);

  const company_id = useCompanyId();

  useEffect(() => {
    setLoading(true);
    console.log('Loading roles...');
    listRoles({ company_id })
      .then((rls) => setRoles(rls))
      .catch((e) => {
        console.log('Failed to load roles!', e);
      })
      .finally(() => {
        setLoading(false);
        scroll();
      });
  }, []);

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      <Box className="header">
        <H5>Manage Roles</H5>

        <TitledButton
          handleClick={() => {
            setNewRole(true);
            scroll();
          }}
          variant='outlined'
          color='secondary'
          label='New'
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>Configure the roles for users in your organization.</Instructions>

      <Collapse in={!!newRole}>
        <Role
          setRoles={(r) => {
            setRoles(r);
            setNewRole(false);
            scroll();
          }}
          cancel={() => {
            setNewRole(false);
            scroll();
          }}
        />
      </Collapse>

      <Stack spacing={0} className="list">
        {roles.map((p) => <RoleItem key={p.path} role={p} />)}
      </Stack>

    </Stack>
  );
}

export default Roles;
