/**
 * We will use normalised units in the system
 * l_s, small liquid unit
 * l_m, medium liquid unit
 * w_s, small weight unit
 * w_m, medium weight unit
 * w_l, large liquid unit
 * alc alcohol unit
 *
 * t: conversion factor from metric -> imperial (simply multiply)
 * f: conversion factor from imperial -> metric (simply multiply)
 * @type {{l_s: {il: string, t: number, f: number, i: string, m: string, ml: string}, l_m: {il: string, t: number, f: number, i: string, m: string, ml: string}}}
 */

export const MEASURE = {
	l_s: 'l_s',
	l_m: 'l_m',
	w_s: 'w_s',
	w_m: 'w_m',
	w_l: 'w_l',
	alc: 'alc',
	count: 'count',
};

export const MEASURES = {
	l_s: {
		m: 'ml',
		i: 'fl oz',
		ml: 'Milliliters',
		il: 'Fluid Ounces',
		t: 1 / 29.57,
		f: 29.57,
	},
	l_m: {
		m: 'l',
		i: 'gal',
		ml: 'Liters',
		il: 'Gallons',
		t: 1 / 3.78544,
		f: 3.78544,
	},
	w_s: {
		m: 'g',
		i: 'oz',
		ml: 'Grams',
		il: 'Ounces',
		t: 1 / 28.35,
		f: 28.35,
	},
	w_m: {
		m: 'kg',
		i: 'lb',
		ml: 'Kilograms',
		il: 'Pounds',
		t: 2.205,
		f: 1 / 2.205,
	},
	w_l: {
		m: 't',
		i: 't',
		ml: 'Tonnes',
		il: 'Tons',
		t: 1.102,
		f: 1 / 1.102,
	},
	alc: {
		m: '%',
		i: '°',
		ml: '% ABV',
		il: 'Proof',
		t: 2,
		f: 1 / 2,
	},
	count: {
		m: '',
		i: '',
		ml: 'Count',
		il: 'Count',
		t: 1,
		f: 1,
	},
};

export const IMPERIAL_MEASURES = {
	l_s: {
		value: 'fl oz',
		label: 'Fluid Ounces',
		scale: {
			l_s: (v) => v,
			l_m: (v) => v / 128.0,
		},
	},
	l_m: {
		value: 'gal',
		label: 'Gallons',
		scale: {
			l_m: (v) => v,
			l_s: (v) => v * 128.0,
		}
	},
	w_s: {
		value: 'oz',
		label: 'Ounces',
	},
	w_m: {
		value: 'lb',
		label: 'Pounds',
	},
	w_l: {
		value: 't',
		label: 'Tons',
	},
	alc: {
		value: '°',
		label: 'Proof',
	},
	count: {
		value: '',
		label: 'Count',
	},
};

export const METRIC_MEASURES = {
	l_s: {
		value: 'ml',
		label: 'Milliliters',
		scale: {
			l_s: (v) => v,
			l_m: (v) => v / 1000.0,
		},
	},
	l_m: {
		value: 'l',
		label: 'Liters',
		scale: {
			l_m: (v) => v,
			l_s: (v) => v * 1000.0,
		}
	},
	w_s: {
		value: 'g',
		label: 'Grams',
	},
	w_m: {
		value: 'kg',
		label: 'Kilograms',
	},
	w_l: {
		value: 't',
		label: 'Tonnes',
	},
	alc: {
		value: '%',
		label: '% ABV',
	},
	count: {
		value: '',
		label: 'Count',
	},
};

export const IMPERIAL_LIQUID_OPTIONS = [
	{
		value: 'l_s',
		label: 'Fluid Ounces',
	},
	{
		value: 'l_m',
		label: 'Gallons',
	},
];

export const IMPERIAL_OPTIONS = [
	...IMPERIAL_LIQUID_OPTIONS,
	{
		value: 'w_s',
		label: 'Ounces',
	},
	{
		value: 'w_m',
		label: 'Pounds',
	},
	{
		value: 'w_l',
		label: 'Tons',
	},
	{
		value: 'alc',
		label: 'Proof',
	},
	{
		value: 'count',
		label: 'Items',
	},
];

export const LIQUID_OPTIONS = [
	{
		value: 'l_s',
		label: 'Milliliters',
	},
	{
		value: 'l_m',
		label: 'Liters',
	},
];

export const METRIC_OPTIONS = [
	...LIQUID_OPTIONS,
	{
		value: 'w_s',
		label: 'Grams',
	},
	{
		value: 'w_m',
		label: 'Kilograms',
	},
	{
		value: 'w_l',
		label: 'Tonnes',
	},
	{
		value: 'alc',
		label: '% ABV',
	},
	{
		value: 'count',
		label: 'Items',
	},
];

export const MEASURES_LIST = Object.keys(MEASURES);

export const validateMeasure = (unit) => {
	if (!MEASURES_LIST.includes(unit)) {
		throw new Error('Invalid measure: ', unit);
	}
};

export const UNIT_TO_MEASURE = {
	g: 'w_s',
	kg: 'w_m',
	t: 'w_l',
	ml: 'l_s',
	l: 'l_m',
	'%': 'alc',
	oz: 'w_s',
	lb: 'w_m',
	tn: 'w_l',
	'fl oz': 'l_s',
	gal: 'l_m',
	proof: 'alc',
	count: 'count',
};

export const toMeasure = (unit) => {
	if (MEASURES_LIST.includes(unit)) {
		return unit;
	}
	if (UNIT_TO_MEASURE.hasOwnProperty(unit)) {
		return UNIT_TO_MEASURE[unit];
	}
	return '';
};
