import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { useCallback, useEffect } from 'react';

import styles from 'src/styles/modal.styles';
import * as yup from 'yup';
import useItemListManager from 'src/utilities/hooks/useItemListManager';
import { COMP, ID } from 'src/utilities/comp';
import ImagePicker from '../Forms/ImagePicker';

// eslint-disable-next-line react/prop-types
function DefectModal({ item, open, upsert, onClose }) {
	const [entry, index] = item ?? [['', null, null, null], undefined];
	const {
		defect_type = '',
		description = '',
		editing = true,
	} = entry ?? { editing: false };

	const [images, initImages, addImage, , removeImage] = useItemListManager(
		ID.image,
		COMP.image,
	);
	// const title = useMemo(() => (editing || index >= 0 ? 'Edit Bay' : 'New Bay'), [editing]);

	const changeSet = {
		defect_type: [
			defect_type,
			yup.string().required('Defect type is required!'),
		],
		description: [
			description,
			yup.string().required('Defect description is required!'),
		],
	};

	useEffect(() => {
		initImages(entry?.images ?? []);
	}, [open]);

	const handleSubmit = useCallback(
		({ defect_type, description }) => {
			upsert({ images, defect_type, description }, index);
			onClose();
		},
		[index, images],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Defect</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing={1}>
						<FormikTextField label='Defect Type' name='defect_type' fullWidth />
						<FormikTextField label='Description' name='description' fullWidth />
						<ImagePicker
							title='Defect Images'
							onAdd={addImage}
							onRemove={removeImage}
							images={images}
						/>
						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								{editing ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

export default DefectModal;
