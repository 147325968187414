import { createSKU } from 'src/services/sku.service';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import { useServiceCallHook } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params) => createSKU(params);
const useSkuMutation = useServiceCallHook(serviceFunction, {
  useLoadingHook
});

export default useSkuMutation;
