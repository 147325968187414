import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikSelect, FormikTextField } from 'src/components/Forms/FormikForm';
import { H5 } from 'src/components/Typography';
import { useCompanyId } from 'src/redux/containers/Companies';
import { inviteUser, listRoles, listTeams } from 'src/services/company.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import * as yup from 'yup';

function User({ user, addUser }) {
  const [isLoading, setLoading] = useLoadingHook();

  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);

  const [AlertView, { setAlert }] = useAlertView();

  const company_id = useCompanyId();

  const onSubmit = useCallback(
    async (values) => {
      console.log('Inviting user', values);
      setLoading(true);
      setAlert(null);
      try {
        const response = await inviteUser({
          company_id,
          corporate: true, // corporate account
          ...values,
        });
        console.log('Invited user', response);
        addUser(response);
      } catch (e) {
        console.log('Failed to invite user!', e);
        setAlert({
          severity: 'error',
          title: 'Failed to invite',
          content: e.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addUser],
  );

  const fetchTeamsAndRoles = async () => {
    try {
      setLoading(true);
      console.log('Loading teams and roles...');
      const [fetchedTeams, fetchedRoles] = await Promise.all([listTeams({ company_id }), listRoles({ company_id })]);
      setTeams(fetchedTeams);
      setRoles(fetchedRoles);
    } catch (error) {
      console.log('Failed to load teams or roles!', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamsAndRoles().finally();
  }, []);

  const teamOptions = useMemo(() => {
    return [...teams.map((team) => ({
      label: team.path.replace('/t/', ''),
      value: team.path,
    }))];
  }, [teams]);

  const roleOptions = useMemo(() => {
    return [...roles.map((role) => ({
      label: role.name ?? role.path.replace('/r/', ''),
      value: role.path,
    }))];
  }, [roles]);

  const changeSet = {
    // User details
    name: [user?.name ?? '', yup.string().required('Name is required!')],
    email: [user?.email ?? '', yup.string().email().required('Email is required!')],
    role: [user?.role_id ?? '', yup.string()],
    team: [user?.team_id ?? '', yup.string()],
  };

  return (
    <FormikForm changeSet={changeSet} onSubmit={onSubmit}>
      <Stack className="form" spacing="2rem">
        <H5>User</H5>
        <FormikTextField name="name" label="Full name" fullWidth required />
        <FormikTextField name="email" label="Email" required />
        <FormikSelect name="role" label="Role" options={roleOptions} />
        <FormikSelect name="team" label="Team" options={teamOptions} />

        <AlertView />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={addUser}
            color='secondary'
            disabled={isLoading}
          >
            Cancel
          </TextButton>

          <FilledButton type='submit' size='small' disabled={isLoading}>
            Invite
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default User;
