import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withDialogCard from 'src/components/Cards/DialogCard';
import ReportWidget from 'src/components/Dashboard/components/ReportWidget';
import LatestAddedReports from 'src/components/LatestAdded/LatestAddedReports';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import widgetStyles from 'src/styles/widget.styles';
import usePageTitle from 'src/utilities/hooks/usePageTitle';
import { useRecentReportQuery } from 'src/utilities/hooks/useRecentReportQuery';

const styles = {
	'.section-title': {
		width: 'min-content',
		whiteSpace: 'nowrap',
		paddingBottom: '1.5rem',
	},
	'.section-footer': {
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
		justifyContent: 'flex-start',
		marginTop: '1.5rem',
	},
	'.section-content': {
		marginTop: '1.5rem',
	},
};

const ReportsContent = withDialogCard(() => {
	return (
		<Stack sx={{ ...widgetStyles, ...styles }} className='root' spacing={3}>
			<ReportWidget title='Report' />
		</Stack>
	);
});

function Reports() {
	const { data } = useRecentReportQuery();

	usePageTitle('Reports');

	return (
		<Box sx={{ display: 'flex', gap: '2rem' }}>
			<ReportsContent data={data?.overview ?? {}} />
			<LatestAddedReports items={data?.items || []} />
		</Box>
	);
}

export default withAppLayout(Reports);
