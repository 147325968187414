import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

function FilledButton({
	label = '',
	icon = '',
	iconPosition = 'end',
	size = 'medium',
	handleClick = () => {},
	loading = false,
	disabled = false,
	type,
	sx = {},
	className = '',
	color = 'primary',
	children,
}) {
	return (
		<Button
			variant='contained'
			sx={sx}
			disabled={loading || disabled}
			onClick={handleClick}
			className={className}
			size={size}
			{...(iconPosition === 'start' && { startIcon: icon })}
			{...(iconPosition === 'end' && { endIcon: icon })}
			type={type}
			color={color}
		>
			{label}
			{children}
			{loading && (
				<CircularProgress
					size={size === 'small' ? 16 : 24}
					sx={{ marginLeft: '0.5rem' }}
				/>
			)}
		</Button>
	);
}

export default FilledButton;
