export const ORIGINS = [
	'https://dev-api.metacask.com',
	'https://dev-api.proofworks.com',
	'https://api.proofworks.com',
	'https://api.metacask.com',
	'https://trakr-api.metacask.com',
];
export const API_CACHE = 'api-cache';
export const ASSET_ID = '/t/';
export const REQUEST_ID = '/r/';

export const LOCAL_STATUS = {
	CREATED: 'create',
	UPDATED: 'update',
};
