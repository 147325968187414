import { Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import HeadlineWidgetChart from 'src/components/Dashboard/components/HeadlineWidgetChart';
import { FormikDatePicker, FormikForm } from 'src/components/Forms/FormikForm';
import { Body3, H5 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import Filter from 'src/components/icons/Filter';
import QrScan3 from 'src/components/icons/QrScan3';
import useAssetsOverviewQuery from 'src/utilities/hooks/useAssetsOverviewQuery';
import * as yup from 'yup';
import AssetCountsWidget from './AssetCountsWidget';
import RecentAssetsWidget from './RecentAssetsWidget';
import SKUWidget from './SKUWidget';

export default function AssetsSection({ casks = [], skus = [] }) {
	const [isVisibleFilter, setIsVisibleFilter] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const {
		refetch,
		data: assets = {},
		isLoading = false,
	} = useAssetsOverviewQuery({});

	const changeSet = {
		start_date: [startDate, yup.number()],
		end_date: [endDate, yup.number()],
	};

	const onFilter = (values) => {
		const { start_date, end_date } = values;
		refetch({ start_date, end_date }).then((res) => {
			if (res) {
				setStartDate(start_date);
				setEndDate(end_date);
			}
		});
	};

	return (
		<Box className='content-row'>
			<Box className='content-header'>
				<Box className='title-box'>
					<QrScan3 height={24} width={24} />
					<Box>
						<H5>Assets</H5>
						{!!startDate && !!endDate && (
							<Body3 sx={{ alignItems: 'center' }}>
								<Bolder style={{ paddingLeft: '0.25ch', fontWeight: '500' }}>
									fr.
								</Bolder>
								{new Date(startDate).toLocaleDateString()}
								<Bolder style={{ paddingLeft: '0.25ch', fontWeight: '500' }}>
									to.
								</Bolder>
								{new Date(endDate).toLocaleDateString()}
							</Body3>
						)}
					</Box>
				</Box>
				<IconCircleButton onClick={() => setIsVisibleFilter(!isVisibleFilter)}>
					{isVisibleFilter ? (
						<Close width='1em' height='1em' sx={{ color: '#898989' }} />
					) : (
						<Filter width='1em' height='1em' fill='#898989' />
					)}
				</IconCircleButton>
			</Box>

			<Collapse in={isVisibleFilter}>
				<FormikForm changeSet={changeSet} onSubmit={onFilter}>
					<Stack gap={1.5} pt={2} pl={1.5} pr={2}>
						<Box display='flex' flexBasis='row' gap={1}>
							<FormikDatePicker name='start_date' label='From' fullWidth />
							<FormikDatePicker name='end_date' label='To' fullWidth />
						</Box>
						<Box display='flex' justifyContent='flex-end'>
							<FilledButton
								sx={{ maxWidth: 'fit-content' }}
								type='submit'
								size='small'
								label='Apply'
								loading={isLoading}
							/>
						</Box>
					</Stack>
				</FormikForm>
			</Collapse>

			<Box className='content-body content-3col'>
				<Stack spacing={2}>
					<AssetCountsWidget
						data={assets?.overview ?? {}}
						title='Total Assets'
					/>
					<HeadlineWidgetChart
						title='Casks'
						data={[
							{
								id: 0,
								label: 'IN',
								value:
									Number(assets?.overview?.total_casks_out ?? 0) +
									Number(assets?.overview?.total_casks ?? 0),
								color: '#41424c',
							},
							{
								id: 1,
								label: 'OUT',
								value: Math.abs(assets?.overview?.total_casks_out),
								color: '#F0AA29',
							},
						]}
					/>
				</Stack>
				<RecentAssetsWidget data={casks} title='Recent Casks' />
				{!!skus?.length && <SKUWidget title='SKU' skus={skus ?? []} />}
			</Box>
		</Box>
	);
}

AssetsSection.propTypes = {
	casks: PropTypes.array,
	skus: PropTypes.array,
};
