import { useContext, useEffect } from 'react';
import SKUSelector from 'src/components/SKUSelector/index';
import FormikContext from 'src/context/FormikContext';
import { COMP, ID } from 'src/utilities/comp';
import debounce from 'src/utilities/debounce';
import useItemListManager from 'src/utilities/hooks/useItemListManager';

function SKUField({ title, skuTypes, tags, itemFilter }) {
	const { values, setFieldValue } = useContext(FormikContext);

	const [sku, , addSku, , removeSku] = useItemListManager(
		ID.sku,
		COMP.sku,
		values?.['sku_id'] && values?.['sku']?.['sku_name']
			? [{ ...values['sku'], sku_id: values['sku_id'] }]
			: [],
		{
			onRemove: () => {
				debounce(() => {
					if (tags) {
						setFieldValue(tags, []);
					}
					setFieldValue('sku', {});
					setFieldValue('sku_id', null);
				}, 50);
			},
			onAdd: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							sku_tags,
							sku_id,
							sku_name,
							sku_type,
							sku_description,
							tax_code,
						} = items[0];
						if (tags) {
							setFieldValue(tags, sku_tags);
						}
						setFieldValue('sku', {
							sku_name,
							sku_type,
							sku_description,
							sku_id,
							tax_code,
						});
						setFieldValue('sku_id', sku_id);
					}, 50);
				}
			},
			onUpsert: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							sku_tags,
							sku_id,
							sku_name,
							sku_type,
							sku_description,
							tax_code,
						} = items[0];
						if (tags) {
							setFieldValue(tags, sku_tags);
						}
						setFieldValue('sku', {
							sku_name,
							sku_type,
							sku_description,
							sku_id,
							tax_code,
						});
						setFieldValue('sku_id', sku_id);
					}, 50);
				}
			},
		},
	);

	return (
		<SKUSelector
			sectionTitle={title}
			list={sku}
			onAdd={(i) => addSku(i, true)}
			onRemove={(i) => removeSku(i, true)}
			filter={skuTypes}
			itemFilter={itemFilter}
			single
			summary={false}
		/>
	);
}

export default SKUField;
