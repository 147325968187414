import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAllCompanies, useCompanyId } from 'src/redux/containers/Companies';
import { setUser } from 'src/redux/containers/User';
import { refreshToken } from 'src/services/auth.service';
import useLoading from 'src/utilities/hooks/useLoadingHook';

function CompanySelect({ children }) {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [, setIsLoading ]= useLoading();

	const companies = useAllCompanies();

	const companyOptions = useMemo(() => {
		if (companies) {
			return companies.map((company) => ({
				label: company.company_name,
				value: company.company_id,
			}));
		}
		return [];
	}, [companies]);

	const company_id = useCompanyId();

	const handleSelect = useCallback(
		async (company_id) => {
			setIsLoading(true);

			return await refreshToken({}, { company_id })
				.then((response) => {
					dispatch(setUser(response));
				})
				.catch((error) => {
					enqueueSnackbar('Failed to change company!', { variant: 'error' });
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[dispatch, setIsLoading],
	);

	return (
		<>
			{children({
				handleSelect,
				companyOptions,
				company_id,
			})}
		</>
	);
}

export default CompanySelect;
