/* eslint-disable react-hooks/rules-of-hooks */
import { listPartners } from 'src/services/company.service';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import { useServiceCallHook } from './genHooks';
import usePaginated from './usePaginated';
import { useMemo, useEffect, useRef } from 'react';
import { Status } from 'src/consts/admin';
import useDebouncedCallback from './useDebouncedCallback';

/** used to list partners options */
export const useListPartnersLazyQuery = useServiceCallHook(listPartners, {
	useLoadingHook,
});

/** used in admin page */
export const usePartnersLazyQueryPaginated = (params) =>
	usePaginated(useListPartnersLazyQuery(), params);

export function usePartnerSearch() {
	const cache = useRef(new Map());
	const [getCustomersApi, { data = [] }] = useListPartnersLazyQuery();
	const customerOptions = useMemo(
		() =>
			(data?.items ?? data ?? []).map((partner) => ({
				id: partner.company_id,
				hash: partner.company_key,
				username: partner.company_name,
			})),
		[data],
	);
	const handleOnSearchDebounced = useDebouncedCallback(async (search = '') => {
		if (!cache.current.has(search) && search.length > 1) {
			cache.current.set(
				search,
				await getCustomersApi({ search, limit: 5, status: Status.APPROVED }),
			);
		}
		return cache.current.get(search);
	}, 300);
	useEffect(() => () => {
		cache.current.clear();
	});
	return [customerOptions, handleOnSearchDebounced];
}
