import { downloadFacilityAssets } from 'src/services/asset.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';
import useLoading from 'src/utilities/hooks/useLoadingHook';

const serviceFunction = (params) => downloadFacilityAssets(params);
export const [
	useDownloadFacilityAssetsLazyQuery,
	useDownloadFacilityAssetsQuery,
] = genServiceCallHooks(serviceFunction, { useLoadingHook: useLoading });

export default useDownloadFacilityAssetsLazyQuery;
