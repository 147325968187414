import swapKeyValue from 'src/utilities/swapKeyValue';
import mapToOptions from 'src/utilities/mapToOptions';

export const ACCOUNT_STATUS = {
	PENDING: 'pending',
	ACTIVE: 'active',
	SUSPENDED: 'suspended',
	REVIEW: 'review',
};

export const ACCOUNT_STATUS_OPTIONS = mapToOptions(ACCOUNT_STATUS);

export const ACCOUNT_STATUS_TEXT_MAPPING = swapKeyValue(ACCOUNT_STATUS);

export const IDENTITY = {
	EMAIL: 'email',
	PHONE: 'phone',
};
