import * as yup from 'yup';

export default function skuFields(sku_id = '', sku = {}) {
  return {
    sku_id: [sku_id, yup.string().nullable()],
    sku: [sku, yup.object({
      sku_id: yup.string(),
      sku_name: yup.string(),
      sku_type: yup.string(),
      sku_description: yup.string(),
    }).optional()],
  };
}
