import { BoltRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { Body3 } from 'src/components/Typography';

function ActionDisplay({ color, label }) {
	if (!label) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<BoltRounded style={{ width: '12px', height: 'auto' }} color={color} />
			<Body3>{label}</Body3>
		</Box>
	);
}

export default ActionDisplay;
