import { useMemo } from 'react';
import { H2 } from 'src/components/Typography';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function TitleBar({ title }) {
	const [pageTitle] = usePageTitle();

	const displayTitle = useMemo(() => pageTitle ?? title, [pageTitle, title]);

	if (!displayTitle) {
		return null;
	}

	return <H2 className='app-title-bar'>{displayTitle}</H2>;
}

export default TitleBar;
