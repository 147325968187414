function SvgCask2(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M52.37 26.72h-1.02c-.15-2.75-.45-5.28-.83-7.53-1.07-6.34-2.82-11.34-3.97-14.16h1.35c1.52 0 2.75-1.13 2.75-2.52S49.42 0 47.9 0H12.1c-1.52 0-2.75 1.13-2.75 2.52s1.23 2.52 2.75 2.52h1.35c-1.14 2.82-2.9 7.82-3.97 14.16-.38 2.24-.68 4.78-.83 7.53H7.62c-1.52 0-2.75 1.13-2.75 2.52s1.23 2.52 2.75 2.52h.95c.17 7.16 1.57 15.37 5.43 23.75h-.88c-1.52 0-2.75 1.13-2.75 2.52s1.23 2.52 2.75 2.52h33.74c1.52 0 2.75-1.13 2.75-2.52s-1.23-2.52-2.75-2.52h-.87c3.87-8.38 5.26-16.59 5.43-23.75h.95c1.52 0 2.75-1.13 2.75-2.52s-1.23-2.52-2.75-2.52zm-2.98-7.33c.37 2.19.66 4.65.81 7.33h-7.05a93.14 93.14 0 00-3.26-21.68h5.44c1.11 2.68 2.96 7.79 4.06 14.35zM30.07 34.74c-.18 0-.35-.01-.52-.02h-.12c-2.93-.29-5.21-2.56-5.21-5.31s2.28-5.02 5.21-5.31.08 0 .11 0c.17-.01.34-.02.52-.02 3.23 0 5.85 2.39 5.85 5.35s-2.62 5.35-5.85 5.35zm-.64-13c-3.33.21-6.12 2.22-7.2 4.97h-4.22c.23-7.29 1.33-14.56 3.29-21.68h8.13v16.71zM22.2 31.75c1.06 2.8 3.87 4.84 7.23 5.05v18.69h-7.75a92.212 92.212 0 01-3.7-23.75h4.21zm8.38 5.05c3.36-.21 6.17-2.26 7.23-5.05h4.21a92.279 92.279 0 01-3.7 23.75h-7.75V36.81zm7.2-10.09c-1.08-2.76-3.87-4.77-7.2-4.97V5.04h8.13A92.136 92.136 0 0142 26.72h-4.22zm-27.16-7.33c1.1-6.56 2.95-11.67 4.06-14.35h5.44a93.14 93.14 0 00-3.26 21.68H9.81c.15-2.68.44-5.14.81-7.33zm-.88 12.37h7.11c.19 7.99 1.4 15.96 3.66 23.75h-5.23c-3.94-8.38-5.36-16.59-5.53-23.75zM44.75 55.5h-5.23a93.535 93.535 0 003.66-23.75h7.11c-.17 7.15-1.59 15.37-5.53 23.75z' />
		</svg>
	);
}
export default SvgCask2;
