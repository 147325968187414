import { Stack, useMediaQuery } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { MenuItem } from 'src/components/Layout/SideBar/Menu/MenuItem';
import { useState } from 'react';
import { MenuHeader } from './MenuHeader';

function MenuGroup({ title, icon, show, options }) {
	const xs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const [toggle, setToggle] = useState(false);

	if (!show) return;

	return (
		<Stack className='menu-group' spacing={0.15}>
			{title && (
				<MenuHeader
					icon={icon}
					label={title}
					xs={true}
					toggle={toggle}
					setToggle={setToggle}
				/>
			)}
			<Collapse in={toggle}>
				{options.map((v) => (
					<MenuItem isSelected={() => setToggle(true)} key={v.tag} {...v} />
				))}
			</Collapse>
		</Stack>
	);
}

export default MenuGroup;
