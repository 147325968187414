import ConfirmationDialog from 'src/components/Dialogs/ConfirmationDialog';
import ConfirmationContext from 'src/context/ConfirmationContext';
import { useCallback, useState } from 'react';

let confirmGlobal;

function ConfirmationProvider({ children }) {
	const [options, setOptions] = useState({});
	const [resolveReject, setResolveReject] = useState([]);
	const [key, setKey] = useState(0);
	const [resolve, reject] = resolveReject;

	const confirm = useCallback(
		(_options = {}) =>
			new Promise((_resolve, _reject) => {
				setKey((_key) => _key + 1);
				setOptions(_options);
				setResolveReject([_resolve, _reject]);
			}),
		[],
	);

	const handleClose = useCallback(() => {
		setResolveReject([]);
	}, []);

	const handleCancel = useCallback(() => {
		if (reject) {
			reject();
			handleClose();
		}
	}, [reject, handleClose]);

	const handleConfirm = useCallback(() => {
		if (resolve) {
			resolve();
			handleClose();
		}
	}, [resolve, handleClose]);

	confirmGlobal = confirm;

	return (
		<>
			<ConfirmationContext.Provider value={confirm}>{children}</ConfirmationContext.Provider>
			<ConfirmationDialog
				key={key}
				open={resolveReject.length === 2}
				options={options}
				onClose={handleClose}
				onCancel={handleCancel}
				onConfirm={handleConfirm}
			/>
		</>
	);
}

export default ConfirmationProvider;
export { confirmGlobal as confirm };
