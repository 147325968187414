import {
	CheckCircle,
	CloudDownloadOutlined,
	Edit,
	ImportantDevicesRounded,
	Print,
	TaskOutlined,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SKU from 'src/components/admin/SKUs/SKU';
import FilledButton from 'src/components/Buttons/FilledButton';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TextButton from 'src/components/Buttons/TextButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FormikForm, FormikSelect } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import AssetSelectorModal from 'src/components/InventorySelector/AssetSelectorModal';
import { FlexBox } from 'src/components/Layout/FlexBox';
import FormWrapper from 'src/components/Layout/FormWrapper';
import DescriptionDisplay from 'src/components/properties/DescriptionDisplay';
import IDDisplay from 'src/components/properties/IDDisplay';
import NameDisplay from 'src/components/properties/NameDisplay';
import { Body1, H5 } from 'src/components/Typography';
import { MEASURE, toMeasure } from 'src/consts/measures';
import { SKU_OPTIONS, SKU_TYPE_REVERSE, SKU_TYPES } from 'src/consts/sku';
import { setCompanySKUs, useCompanySKUs } from 'src/redux/containers/Companies';
import { cloneSKU } from 'src/services/sku.service';

import styles from 'src/styles/modal.styles';
import { COMP, ID } from 'src/utilities/comp';
import debounce from 'src/utilities/debounce';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useConfirm from 'src/utilities/hooks/useConfirm';
import useItemListManager from 'src/utilities/hooks/useItemListManager';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import useTaxCodesQuery from 'src/utilities/hooks/useTaxCodesQuery';
import * as yup from 'yup';

function SKUMapping({ sku, editSku }) {
	// const dispatch = useDispatch();
	//
	// const { enqueueSnackbar } = useSnackbar();
	//
	const { sku_name, sku_type, sku_description, mapped_sku } = sku;

	// const skus = useCompanySKUs();

	// const importSKU = () => {
	// 	setLoading(true);
	// 	// Trigger back-end import operation of the SKU, this will copy the SKU details across
	// 	cloneSKU({ sku_name: name, company_id: companyId })
	// 		.then((s) => {
	// 			enqueueSnackbar(`SKU ${s.sku_name} imported!`, {
	// 				variant: 'success',
	// 			});
	// 			upsert({ ...sku, mapped_sku: s });
	//
	// 			// Update Company SKUs
	// 			const xs = [...skus];
	// 			const index = xs.findIndex((x) => x.sku_name === s.sku_name);
	// 			if (index >= 0) {
	// 				xs[index] = { ...xs[index], ...s };
	// 			} else {
	// 				xs.push(s);
	// 			}
	// 			dispatch(setCompanySKUs(xs));
	// 		})
	// 		.catch((error) => {
	// 			enqueueSnackbar(`SKU ${name} could not be imported: ${error.message}`, {
	// 				variant: 'error',
	// 			});
	// 		})
	// 		.finally(() => setLoading(false));
	// };

	return (
		<Box className='listItem'>
			{mapped_sku && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack spacing={0.5}>
					<NameDisplay name={`${sku_name} [${SKU_TYPE_REVERSE[sku_type]}]`} />
					<DescriptionDisplay value={sku_description} />
					{mapped_sku && (
						<>
							<Divider />
							<IDDisplay
								id={`${mapped_sku.sku_id} [${SKU_TYPE_REVERSE[sku_type]}]`}
							/>
						</>
					)}
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{!mapped_sku && (
					<IconCircleButton onClick={() => editSku(sku)}>
						<Edit />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

function SKUEditor({ open, item, onClose }) {
	const dispatch = useDispatch();
	const { data: taxCodes = [] } = useTaxCodesQuery();
	const skus = useCompanySKUs();

	const handleSave = (sku) => {
		const copy = [...(skus ?? [])];
		copy.push(sku);
		dispatch(setCompanySKUs(copy));
		onClose(sku);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>SKU Editor</H5>
					<IconButton onClick={() => onClose(null)} className='close-btn' aria-label='Close'>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<SKU
						entity={item}
						onSave={handleSave}
						cancel={() => onClose()}
						taxCodes={taxCodes}
					/>
				</Box>
			</Stack>
		</Modal>
	);
}

function SKUMapper({ companyId, sku, onClose }) {
	let { sku_name, sku_type, sku_description, mapped_sku } = sku;

	const [AlertView, { setAlert }] = useAlertView();
	const confirm = useConfirm();

	const [isLoading, setIsLoading] = useState(false);
	const [editSku, setEditSku] = useState(null);

	const allSkus = useCompanySKUs();
	const filteredSkus = (allSkus ?? []).filter((s) => s.sku_type === sku_type);

	const isImported = filteredSkus.find((s) => s.sku_name.toLowerCase() === mapped_sku?.sku_name.toLowerCase());

	const changeSet = useMemo(
		() => ({
			sku_id: [
				mapped_sku?.sku_id ?? '',
				yup.string().required('SKU is required!'),
			],
		}),
		[mapped_sku],
	);

	const importSku = () => {
		confirm({
			title: 'Import SKU',
			content: <Body1>{`Import the SKU ${sku_name}?`}</Body1>,
		})
		.then(() => {
			setIsLoading(true);
			// Trigger back-end import operation of the SKU, this will copy the SKU details across
			cloneSKU({ sku_name, company_id: companyId })
			.then((sku) => {
				// SKU imported, now popup a dialog to allow the user to configure the SKU before it's imported..
				setEditSku(sku);
			})
			.catch((e) => {
				setAlert({
					severity: 'error',
					title: 'Failed to import',
					content: 'Failed to import the SKU!',
				});
			});
		})
		.catch(() => {});
	};

	const handleSkuImportComplete = (newSku) => {
		if (newSku) {
			console.log('Added SKU', newSku);
			onClose({ ...sku, mapped_sku: newSku });
		}
		setEditSku(null);
		setIsLoading(false);
	};

	const handleSubmit = (values) => {
		console.log('Saving sku mapping', values);
		const mappedSku = filteredSkus.find((s) => s.sku_id === values.sku_id);
		if (!mappedSku) {
			setAlert({
				severity: 'error',
				title: 'Invalid Mapped SKU',
				content: 'Failed to find selected SKU!',
			});
		} else {
			onClose({ ...sku, mapped_sku: mappedSku });
		}
	};

	return (
		<Stack spacing={1}>
			<H5>{sku_name}</H5>

			<Instructions>
				{sku_description}
			</Instructions>

			<FormWrapper>
				<FormikForm
					changeSet={changeSet}
					onSubmit={handleSubmit}
				>
					<FlexBox>
						<FormikSelect
							label='Mapped SKU'
							name='sku_id'
							options={filteredSkus.map((s) => ({ label: s.sku_name, value: s.sku_id }))}
							fullWidth
						/>
						{!isImported && (
							<IconCircleButton onClick={importSku}>
								<ImportantDevicesRounded />
							</IconCircleButton>
						)}
					</FlexBox>

					<AlertView />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
							disabled={isLoading}
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small' disabled={isLoading}>
							Apply
						</FilledButton>
					</Box>

				</FormikForm>
			</FormWrapper>

			{!!editSku && (
				<SKUEditor
					open={!!editSku}
					item={editSku}
					onClose={handleSkuImportComplete}
				/>
			)}
		</Stack>
	);
}

function ImportSKUModal({
	skus,
	companyId,
	open,
	handleImportComplete,
	onClose,
}) {
	const [isLoading] = useLoadingHook();

	const [skuList, , , upsert] = useItemListManager(ID.sku, COMP.sku, skus);

	const [editSku, setEditSku] = useState(null);

	const confirmMapping = useCallback(() => {
		handleImportComplete(skuList);
	}, [skuList]);

	const handleMappedSku = (mappedSku) => {
		if (mappedSku) {
			debounce(() => upsert(mappedSku), 25);
		}
		setEditSku(null);
	}

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>SKU Mapper</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Instructions>
					Please select the mappings for the SKUs in the shipment
				</Instructions>

				{!!editSku && (
					<Collapse in={!!editSku}>
						<SKUMapper
							companyId={companyId}
							sku={editSku}
							onClose={handleMappedSku}
						/>
					</Collapse>
				)}


				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<Box className='inventory'>
						<Box className='inventory-contents'>
							<Stack className='inventory-section'>
								<Stack className='list'>
									{skuList.map((sku) => (
										<SKUMapping
											key={sku.sku_id}
											sku={sku}
											editSku={setEditSku}
										/>
									))}
								</Stack>
							</Stack>
						</Box>
					</Box>
				</Box>

				<FlexBox justifyContent='end'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						disabled={isLoading}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						size='small'
						icon={<CheckCircle />}
						iconPosition='start'
						handleClick={confirmMapping}
						loading={isLoading}
					>
						Confirm
					</FilledButton>
				</FlexBox>
			</Stack>
		</Modal>
	);
}

export default ImportSKUModal;
