import { useCallback, useRef } from 'react';

const useScrollTarget = () => {
	const ref = useRef();

	const scroll = useCallback(() => {
		const { current } = ref;
		if (current !== null) {
			current.scrollIntoView({
				block: 'start',
				inline: 'nearest',
				behavior: 'smooth',
			});
		}
	}, [ref]);

	return [ref, scroll];
};

export default useScrollTarget;
