import { APP_API_GATEWAY } from 'src/consts/config';
import fetchGet from 'src/utilities/fetchGet';
import objectToQueryString from 'src/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/report`;

export const getReport = async (params, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const getReportStatus = async (params, headers = {}) =>
	fetchGet(`${BASE}/status${objectToQueryString(params)}`, headers);

export const getReportSummary = async (params, headers = {}) =>
	fetchGet(`${BASE}/summary${objectToQueryString(params)}`, headers);

export const recent = async (params, headers = {}) =>
	fetchGet(`${BASE}/recent${objectToQueryString(params)}`, headers);
