import { getRequestsList } from 'src/services/request.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';

const serviceFunction = (params = {}) =>
	getRequestsList(params).then(({ items = [] }) => items);
export const [useRequestsListLazyQuery, _] = genServiceCallHooks(
	serviceFunction,
	{ useLoadingHook: useLoadingHook },
);
