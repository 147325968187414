import withDialogCard from 'src/components/Cards/DialogCard';
import Users from 'src/components/Company/admin/Users';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function UsersPage() {
  usePageTitle('Manage Users');

  return (
    <Users />
  );
}

export default withAppLayout(withDialogCard(UsersPage));
