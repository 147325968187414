import { useSelector } from 'react-redux';
import {
	activeCompany,
	selectAllCompanies,
	selectCompanyCC,
	selectCompanyId,
	selectCompanyLiquidTypes,
	selectCompanyName,
	selectCompanySKUs,
	selectCompanyUsers,
} from 'src/redux/containers/Companies/selectors';

export const useActiveCompany = () => useSelector(activeCompany);
export const useCompanyCountry = () => useSelector(selectCompanyCC);
export const useCompanyId = () => useSelector(selectCompanyId);
export const useCompanyName = () => useSelector(selectCompanyName);
export const useCompanyUsers = () => useSelector(selectCompanyUsers);
export const useAllCompanies = () => useSelector(selectAllCompanies);
export const useCompanySKUs = () => useSelector(selectCompanySKUs);
export const useCompanyLiquidTypes = () =>
	useSelector(selectCompanyLiquidTypes);
