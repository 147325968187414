import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { useCallback, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import Loading from 'src/components/Loading/Loading';
import { H4, H6 } from 'src/components/Typography';
import { REPORT_STATUS } from 'src/consts/reports';
import { fromReportId, toDate } from 'src/utilities/format';
import useGetReportId from 'src/utilities/hooks/useGetReportId';
import useReportQuery from 'src/utilities/hooks/useReportQuery';
import { useReportStatusLazyQuery } from 'src/utilities/hooks/useReportStatusQuery';

function ReportDetail() {
	const id = useGetReportId();
	const {
		data,
		isLoading,
		refetch: refetchReportDetails,
	} = useReportQuery({ path: id });

	const [refetchReportStatus, { isLoading: isLoadingStatus }] =
		useReportStatusLazyQuery({ path: id });

	const { report_type, nonce } = fromReportId(data?.path);
	const date = new Date(nonce);

	const details = useMemo(
		() => (data?.summary ? data?.summary : {}),
		[data?.summary],
	);

	const getLatestStatus = useCallback(async () => {
		const response = await refetchReportStatus({
			path: id,
		});
		if (response?.status) refetchReportDetails({ path: id });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack spacing={4}>
			{isLoading ? (
				<Loading message='Loading report details...' />
			) : (
				<Stack spacing={2}>
					<Box>
						<List>
							<ListItem>
								<H4>Report Type:</H4>
								<ListItemSecondaryAction>
									{report_type?.toUpperCase()}
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
							<ListItem>
								<H4>Report Status:</H4>
								<ListItemSecondaryAction>
									{data?.report_status?.toUpperCase() ?? REPORT_STATUS.PENDING}
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
							<ListItem>
								<H4>Report Date:</H4>
								<ListItemSecondaryAction>
									{toDate({ date, format: 'DD.MM.YYYY' })}
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
							<ListItem>
								<H4>Acknowledgement Number:</H4>
								<ListItemSecondaryAction>
									{data?.acknowledgement_number}
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
							{details?.alcohols?.map((liquid) => (
								<>
									<ListItem>
										<H6>Volume:</H6>
										<ListItemSecondaryAction>
											{liquid?.volume}
										</ListItemSecondaryAction>
									</ListItem>
									<Divider />
									<ListItem>
										<H6>Tax Code:</H6>
										<ListItemSecondaryAction>
											{liquid?.tax_type}
										</ListItemSecondaryAction>
									</ListItem>
									<Divider />
								</>
							))}
							<ListItem>
								<H4>VAT:</H4>
								<ListItemSecondaryAction>
									{details?.vat}
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
							<Divider />
							<ListItem>
								<H4>Payment Reference:</H4>
								<ListItemSecondaryAction>
									{details?.payment_reference}
								</ListItemSecondaryAction>
							</ListItem>
						</List>
						{data?.report_status === REPORT_STATUS.PENDING && (
							<FilledButton
								label='Get Report Latest Status'
								handleClick={getLatestStatus}
								loading={isLoadingStatus}
							/>
						)}
					</Box>
				</Stack>
			)}
		</Stack>
	);
}

export default withAppLayout(withDialogCard(ReportDetail), {
	title: 'Report Details',
});
