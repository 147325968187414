import { Stack } from '@mui/material';
import { Body1, H5 } from 'src/components/Typography';

function Instructions({ instruction, children }) {
	return (
		<Stack gap={1}>
			<H5>{instruction}</H5>
			<Body1>{children}</Body1>
		</Stack>
	);
}

export default Instructions;
