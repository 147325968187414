import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { FIREBASE_CONFIG } from 'src/consts/firebase';
import AppRoutes from 'src/pages/AppRoutes';
import ConfirmProvider from 'src/providers/ConfirmationProvider';
import LoadingProvider from 'src/providers/LoadingProvider';
import PageProvider from 'src/providers/PageProvider';
import { persistor, store } from 'src/redux/store';
import theme from 'src/styles/theme';
import createEmotionCache from 'src/utilities/createEmotionCache';

const clientSideEmotionCache = createEmotionCache();

function App() {
	useEffect(() => {
		console.log('Initializing Firebase...');
		initializeApp(FIREBASE_CONFIG);
	}, []);
	return (
		<StoreProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<CacheProvider value={clientSideEmotionCache}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<SnackbarProvider
							maxSnack={3}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						>
							<LoadingProvider>
								<ConfirmProvider>
									<PageProvider>
										<AppRoutes />
									</PageProvider>
								</ConfirmProvider>
							</LoadingProvider>
						</SnackbarProvider>
					</ThemeProvider>
				</CacheProvider>
			</PersistGate>
		</StoreProvider>
	);
}

export default App;
