import withDialogCard from 'src/components/Cards/DialogCard';
import Company from 'src/components/Company';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { useCompanyName } from 'src/redux/containers/Companies';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function CompanyPage() {
  const company = useCompanyName();

  usePageTitle(`Organization ${company}`);

  return (
    <Company />
  );
}

export default withAppLayout(withDialogCard(CompanyPage));
