import { Done } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import TaskCard from 'src/components/Tasks/TaskCard';
import TaskModal from 'src/components/Tasks/TaskModal';
import { H5 } from 'src/components/Typography';
import React, { useState } from 'react';
import { useCompanyUsers } from 'src/redux/containers/Companies';
import { EQUAL } from 'src/utilities/comp';
import usePermissions from 'src/utilities/hooks/usePermissions';
import useSelections from 'src/utilities/hooks/useSelections';

function TasksWidget({ data = [], refetch }) {
	const [selection = [], , toggleSelection, isSelected] = useSelections([], EQUAL.request);
	const { canApprove = false } = usePermissions();
	const [actionKey, setActionKey] = useState(null);

	const users = useCompanyUsers();

	return data.length ? (
		<Stack className='content-section'>
			<H5 className='content-section-title'>Tasks</H5>
			<Stack gap={1} className='content-section-content list recent-list task-content'>
				{data.map((t) => (
					<TaskCard
						key={t.id}
						task={t}
						selected={isSelected(t)}
						onSelect={() => toggleSelection(t)}
					/>
				))}
			</Stack>
			{canApprove && (
				<FlexBox className='content-section-footer'>
					<TitledButton
						handleClick={() => setActionKey('approve_selected')}
						label='Approve'
						variant='outlined'
						color='secondary'
						disabled={!selection.length}
						sx={{ width: '72px', height: '55px' }}
					>
						<Done height={24} width={24} />
					</TitledButton>
					<TaskModal
						users={users}
						tasks={selection}
						onClose={(forceRefresh) => {
							setActionKey(null);
							if (forceRefresh) {
								refetch();
							}
						}}
						actionKey={actionKey}
					/>
				</FlexBox>
			)}
		</Stack>
	) : null;
}

export default TasksWidget;
