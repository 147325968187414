import { FlipCameraIos } from '@mui/icons-material';
import CameraIcon from '@mui/icons-material/Camera';
import CloseIcon from '@mui/icons-material/Close';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, ButtonBase, CircularProgress, Dialog, Tooltip } from '@mui/material';
import { clamp } from '@mui/x-data-grid/internals';
import { useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from 'react';
import FormikContext from 'src/context/FormikContext';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import { useToggleState } from 'src/utilities/hooks/useToggleState';
import { sleep } from 'src/utilities/sleep';
import { createWorker, OEM, PSM } from 'tesseract.js';
import './polyfillGetUserMedia';
import { styles } from './styles';

let worker = null;
/** Usage
<FormikTextField
	name='ocr'
	label='Ocr Value'
	fullWidth
	InputProps={{
		disableUnderline: true,
		endAdornment: (
			<InputAdornment position='end'>
				<SevenSegOcrComponent name='ocr' />
			</InputAdornment>
		),
	}}
/>
 */

function stopMediaStream(stream) {
	if (stream) {
		if (stream.getVideoTracks && stream.getAudioTracks) {
			stream.getVideoTracks().map((track) => {
				stream.removeTrack(track);
				track.stop();
			});
			stream.getAudioTracks().map((track) => {
				stream.removeTrack(track);
				track.stop();
			});
		} else {
			stream.stop();
		}
	}
}

function useWebCam({ facingMode, ...constraints }) {
	const videoRef = useRef(null);
	const streamRef = useRef(null);
	const trackRef = useRef(null);

	const [hasUserMedia, setHasUserMedia] = useState(false);
	const [src, setSrc] = useState('');

	const handleUserMedia = useCallback(async (err, stream) => {
		if (err || !stream) {
			setHasUserMedia(false);
			return;
		}
		streamRef.current = stream;
		try {
			if (videoRef.current) {
				videoRef.current.srcObject = stream;
			}
			setHasUserMedia(true);
		} catch (error) {
			setHasUserMedia(true);
			setSrc(window.URL.createObjectURL(stream));
		}

		if (!streamRef?.current?.getVideoTracks) {
			return false;
		}
		let _videoTrack = null;
		while (!_videoTrack && streamRef?.current?.getVideoTracks) {
			[_videoTrack] = streamRef.current.getVideoTracks();
			await sleep(100);
		}
		trackRef.current = _videoTrack;
	}, []);

	const stopAndCleanup = useCallback(() => {
		if (hasUserMedia) {
			stopMediaStream(streamRef.current);
			if (src) {
				window.URL.revokeObjectURL(src);
			}
		}
	}, [hasUserMedia, src]);

	const requestUserMedia = useCallback(() => {
		const sourceSelected = (videoConstraints) => {
			const constraints = {
				video:
					typeof videoConstraints !== 'undefined' ? videoConstraints : true,
			};

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					handleUserMedia(null, stream);
				})
				.catch((e) => {
					handleUserMedia(e);
				});
		};

		if ('mediaDevices' in navigator) {
			sourceSelected({ ...constraints, facingMode });
		} else {
			const optionalSource = (id) => ({ optional: [{ sourceId: id }] });

			const constraintToSourceId = (constraint) => {
				const { deviceId } = constraint;

				if (typeof deviceId === 'string') {
					return deviceId;
				}

				if (Array.isArray(deviceId) && deviceId.length > 0) {
					return deviceId[0];
				}

				if (typeof deviceId === 'object' && deviceId.ideal) {
					return deviceId.ideal;
				}

				return null;
			};

			// @ts-ignore: deprecated api
			MediaStreamTrack.getSources((sources) => {
				let videoSource;
				sources.forEach((source) => {
					if (source.kind === 'video') {
						videoSource = source.id;
					}
				});
				const videoSourceId = constraintToSourceId({
					...constraints,
					facingMode,
				});
				if (videoSourceId) {
					videoSource = videoSourceId;
				}
				sourceSelected(optionalSource(videoSource));
			});
		}
	}, [constraints, facingMode, handleUserMedia]);

	useEffect(() => {
		requestUserMedia();
		return () => {
			stopAndCleanup();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facingMode]);

	return { videoRef, src, streamRef, trackRef };
}

const facingModeMap = ['environment', 'user'];

export function VideoModal({
	constraints = {
		facingMode: 'user',
		video: { pan: true, tilt: true, zoom: true },
	},
	onClose = (v) => {
		console.log('Close', v);
	},
	ratio = 3.5,
	left = 120,
	top = 200,
	width = 400,
}) {
	const canvasRef = useRef(null);
	const id = useId();

	const [facingMode, setFacingMode] = useState(0);
	const { videoRef, src, trackRef } = useWebCam({
		...constraints,
		facingMode: facingModeMap[facingMode],
	});

	const [loading, setLoading] = useState(false);

	const zoomNativeSupport = useMemo(async () => {
		const capabilities = trackRef?.current?.getCapabilities?.() ?? {};
		return 'zoom' in capabilities;
	}, [trackRef]);

	const zoomIn = useCallback(async () => {
		const capabilities = trackRef?.current?.getCapabilities?.() ?? {};
		if (
			'zoom' in capabilities &&
			trackRef?.current?.applyConstraints &&
			trackRef?.current?.getSettings
		) {
			const { zoom } = trackRef.current.getSettings();
			const step = (capabilities.zoom.max - capabilities.zoom.min) / 10;
			const value = zoom + step;
			await trackRef.current.applyConstraints({
				advanced: [
					{
						zoom: clamp(
							value,
							capabilities.zoom.min,
							capabilities.zoom.max,
						).toFixed(1),
					},
				],
			});
		}
	}, [trackRef]);

	const zoomOut = useCallback(async () => {
		const capabilities = trackRef?.current?.getCapabilities?.() ?? {};
		if (
			'zoom' in capabilities &&
			trackRef?.current?.applyConstraints &&
			trackRef?.current?.getSettings
		) {
			const { zoom } = trackRef.current.getSettings();
			const step = (capabilities.zoom.max - capabilities.zoom.min) / 10;
			const value = zoom - step;
			await trackRef.current.applyConstraints({
				advanced: [
					{
						zoom: clamp(
							value,
							capabilities.zoom.min,
							capabilities.zoom.max,
						).toFixed(1),
					},
				],
			});
		}
	}, [trackRef]);

	const isMobile = useIsMobile();

	const rectangle = useMemo(() => {
		const _width = isMobile ? width * 0.9 : width;
		const _left = isMobile ? left * 0.6 : left;
		return {
			left: _left,
			top,
			width: _width,
			height: _width / ratio,
		};
	}, [isMobile, left, ratio, top, width]);

	const getCandidateText = useCallback(async () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');
		context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
		const imageData = canvas.toDataURL('image/png');
		await worker.setParameters({
			tessedit_char_whitelist: '0123456789.,:',
			tessedit_pageseg_mode: PSM.SINGLE_LINE,
		});
		const {
			data: { text = '' },
		} = await worker.recognize(
			imageData,
			{ rotateAuto: true, rectangle },
			{ imageColor: true, imageGrey: true, imageBinary: true },
		);
		console.log(text);
		return text.trim();
	}, [rectangle, videoRef]);

	const captureImage = useCallback(async () => {
		setLoading(true);
		const recognitions = [];
		while (recognitions.length < 1) {
			const text = await getCandidateText();
			text && recognitions.push(text);
		}
		//find most occured text
		const mostOccuredText = recognitions
			.sort(
				(a, b) =>
					recognitions.filter((v) => v === a).length -
					recognitions.filter((v) => v === b).length,
			)
			.pop();
		mostOccuredText && onClose(mostOccuredText);
		setLoading(false);
	}, [getCandidateText, onClose]);

	useEffect(() => {
		const initTesseract = async () => {
			try {
				// init tesseract worker
				worker = await createWorker(['7seg', 'LCDDot_FT_500'], OEM.LSTM_ONLY, {
					langPath: '..',
					logger: (m) => console.log(m),
					gzip: false,
				});
			} catch (err) {
				console.error('Error accessing media devices.', err);
			}
		};
		initTesseract();
		return () => {
			worker?.terminate?.();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		function drawFrame() {
			const FPS = 30;
			let begin = Date.now();
			if (
				canvasRef?.current &&
				videoRef?.current?.readyState === videoRef.current.HAVE_ENOUGH_DATA
			) {
				const context = canvasRef.current.getContext('2d');
				// Set the canvas size to match the video
				canvasRef.current.width = videoRef.current.videoWidth;
				canvasRef.current.height = videoRef.current.videoHeight;
				const { left: x, top: y, width, height } = rectangle;
				context.beginPath();
				context.rect(x, y, width, height);
				context.lineWidth = 2;
				context.strokeStyle = 'black';
				context.stroke();
			}
			// Request the next frame
			let delay = 1000 / FPS - (Date.now() - begin);
			setTimeout(requestAnimationFrame(drawFrame), delay);
		}
		// Start the loop
		drawFrame();
	}, [rectangle, videoRef]);

	const toggleFacingMode = () => {
		setFacingMode(facingMode === 0 ? 1 : 0);
	};

	return (
		<Dialog sx={styles} className='dialog' open={true} fullWidth>
			<Box className='scanner'>
				<ButtonBase
					onClick={() => onClose(false)}
					className='modalButton dialogClose'
				>
					<CloseIcon />
				</ButtonBase>
				<ButtonBase
					onClick={toggleFacingMode}
					className='modalButton toggleFacingMode'
				>
					<FlipCameraIos />
				</ButtonBase>
				{zoomNativeSupport ? (
					<Box className='zoomContainer'>
						<ButtonBase onClick={zoomIn} className='modalButton zoomIn'>
							<ZoomInIcon />
						</ButtonBase>
						<ButtonBase onClick={zoomOut} className='modalButton zoomOut'>
							<ZoomOutIcon />
						</ButtonBase>
					</Box>
				) : null}

				<ButtonBase onClick={captureImage} className='modalButton capture'>
					{loading ? <CircularProgress size={24} /> : <CameraIcon />}
				</ButtonBase>
				<section style={styles.containerStyle}>
					<div
						style={{
							...styles.container,
							...styles.videoContainerStyle,
						}}
					>
						<video
							muted
							playsInline
							autoPlay
							style={{
								...styles.video,
								...styles.videoStyle,
								transform: `scaleX(${facingModeMap[facingMode] === 'user' ? -1 : 1})`,
							}}
							src={src}
							ref={videoRef}
							id={id}
						/>
						<canvas
							style={{
								...styles.video,
								...styles.videoStyle,
							}}
							ref={canvasRef}
						/>
					</div>
				</section>
			</Box>
		</Dialog>
	);
}

function OcrFormFieldComponent({ name, title = 'Read from Camera' }) {
	const [open, toggle] = useToggleState(false);
	const { handleChange } = useContext(FormikContext);
	const handleChangeRec = useCallback(
		(value) => {
			if (value) {
				handleChange({ target: { name, value } });
			}
			toggle();
		},
		[handleChange, name, toggle],
	);
	return (
		<>
			<Tooltip title={title}>
				<Box onClick={toggle} sx={{ cursor: 'pointer' }}>
					<DocumentScannerIcon className='icon' />
				</Box>
			</Tooltip>
			{open && <VideoModal onClose={handleChangeRec} />}
		</>
	);
}

export default OcrFormFieldComponent;
