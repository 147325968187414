/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveCompanyId } from 'src/redux/containers/Companies';

export const useServiceCallHook =
	(serviceFunction, { useLoadingHook = useState } = {}) =>
	(initialData) => {
		// If company changes - need to re-evaluate
		const activeCompanyId = useSelector(selectActiveCompanyId);

		const [isLoading, setIsLoading] = useLoadingHook(false);
		const [data, setData] = useState(initialData);
		const [error, setError] = useState(false);

		// const refetch = useCallback((...params) => {
		// 	setIsLoading(true);
		// 	setError(null);

		// 	serviceFunction(...params)
		// 		.then(setData)
		// 		.catch(setError)
		// 		.finally(() => setIsLoading(false));
		// }, [setIsLoading, activeCompanyId]);

		const refetch = useCallback(
			async (...params) => {
				try {
					setError(false);
					setIsLoading(true);
					const res = await serviceFunction(...params);
					console.log('Result', res);
					setData(res);
					return res;
				} catch (error) {
					setError(error);
					throw error;
				} finally {
					setIsLoading(false);
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			},
			[setIsLoading, activeCompanyId],
		);

		return useMemo(
			() => [
				refetch,
				{
					data,
					error,
					refetch,
					isLoading,
					isError: !!error,
					// eslint-disable-next-line react-hooks/exhaustive-deps
				},
			],
			[data, error, refetch, isLoading, activeCompanyId],
		);
	};

export const genAutoServiceCallHook =
	(lazyHook) =>
	(params, { skip = false, initialData } = {}) => {
		const [refetch, res] = lazyHook(initialData);

		useEffect(() => {
			console.log('Fetching', params, skip);
			if (!skip) {
				refetch(params).catch((e) => console.error('Failed to fetch', e));
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [refetch]);

		return res;
	};

export const genServiceCallHooks = (
	serviceFunction,
	{ useLoadingHook = useState } = {},
) => {
	const lazyHook = useServiceCallHook(serviceFunction, { useLoadingHook });
	const queryHook = genAutoServiceCallHook(lazyHook);
	return [lazyHook, queryHook];
};
