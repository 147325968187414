import { Box } from '@mui/material';
import { Body3 } from 'src/components/Typography';
import React from 'react';
import Bolder from '../Typography/Bolder';
import LiquidIcon from 'src/components/icons/Liquid';

function TaxCodeDisplay({ label = 'Tax Code', id }) {
	if (!id) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LiquidIcon style={{ width: '12px', height: '14px' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{id}
			</Body3>
		</Box>
	);
}
export default TaxCodeDisplay;
