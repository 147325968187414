import AccountSettings from 'src/components/Account';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function AccountSettingsPage() {
  usePageTitle('Account Settings');

  return (
    <AccountSettings />
  );
}

export default withAppLayout(withDialogCard(AccountSettingsPage));
