import { Print } from '@mui/icons-material';
import TitledButton from 'src/components/Buttons/TitledButton';
import PrintModal from 'src/components/InventorySelector/PrintModal';
import React, { useState } from 'react';

function PrintAssetList({ assets }) {
	const [print, setPrint] = useState(false);

	// console.log('Printable Assets:', assets);

	return (
		<>
			<TitledButton
				handleClick={() => setPrint(true)}
				label='Print'
				variant='outlined'
				color='secondary'
				sx={{ width: '72px', height: '55px' }}
			>
				<Print height={24} width={24} />
			</TitledButton>
			{!!print && (
				<PrintModal open={!!print} assets={assets} onClose={() => setPrint(false)} />
			)}
		</>
	);
}

export default PrintAssetList;
