import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import FilledButton from 'src/components/Buttons/FilledButton';
import AssetSKUStorageListItem from 'src/components/SKUSelector/items/AssetSKUStorageListItem';
import { AssetLinks } from 'src/components/SKUSelector/items/AssetSummaryList';
import { INVENTORY_STATUS } from 'src/consts/sku';
import styles from 'src/styles/modal.styles';
import { H5, Overline } from 'src/components/Typography';
import CloseIcon from '@mui/icons-material/Close';
import TextButton from 'src/components/Buttons/TextButton';
import { ArrowForward } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { AssetListComponentV2 } from 'src/components/AssetListItem';
import debounce from 'src/utilities/debounce';
import { COMP, ID } from 'src/utilities/comp';
import useItemListManager from 'src/utilities/hooks/useItemListManager';
import ReallocateModal from './ReallocateModal';
import { ASSET_STATUS } from 'src/consts/asset';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import { FlexBox } from 'src/components/Layout/FlexBox';

function DefectAssetModal({ item, open, onClose }) {
	const [asset, setAsset] = useState(null);
	const [storage, setStorage] = useState(null);

	const [skuStorage, initSkuStorage, , upsertSkuStorage] = useItemListManager(
		ID.sku_entry_id,
		COMP.sku_entry_id,
	);
	const [childAssets, init, , upsertAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
	);
	const { enqueueSnackbar } = useSnackbar();

	const onCancelHolder = () => {
		setAsset(null);
		setStorage(null);
	};

	const onSelectHolder = useCallback(
		({ path, rw_asset_id, asset_status, asset_type, unique_location_id, processed }) => {

			if (asset_status === ASSET_STATUS.DEFECTED) {
				enqueueSnackbar('Defective assets cannot be selected!', {
					variant: 'error',
				});
				return;
			}

			if (path === item?.path) {
				enqueueSnackbar('Please select valid asset!', {
					variant: 'error',
				});
				return;
			}

			if (storage) {
				upsertSkuStorage({
					...storage,
					asset_type,
					asset_id: path,
					rw_asset_id,
					processed: INVENTORY_STATUS.CONFIRMED,
				});
			} else if (asset) {
				const parent_asset = { asset_id: path, rw_asset_id, asset_type, processed, unique_location_id };

				upsertAsset({
					...asset,
					unique_location_id,
					processed: ASSET_PROCESSED_STATUS.CONFIRMED,
					parent_asset_id: path,
					parent_asset,
				});
			}
			onCancelHolder();
		},
		[
			asset,
			enqueueSnackbar,
			item?.path,
			storage,
			upsertAsset,
			upsertSkuStorage,
		],
	);

	const onUpdate = useCallback(() => {
		const isAllChildAssetsConfirmed =
			childAssets.filter(
				({ processed }) => processed === ASSET_PROCESSED_STATUS.CONFIRMED,
			).length === childAssets.length;
		const isAllContentConfirmed =
			skuStorage.filter(
				({ processed }) => processed === ASSET_PROCESSED_STATUS.CONFIRMED,
			).length === skuStorage.length;

		onClose({
			...item,
			child_assets: childAssets,
			sku_storage: skuStorage,
			processed:
				isAllChildAssetsConfirmed && isAllContentConfirmed
					? ASSET_PROCESSED_STATUS.CONFIRMED
					: ASSET_PROCESSED_STATUS.PENDING,
		});
	}, [childAssets, item, onClose, skuStorage]);

	useEffect(() => {
		const { child_assets = [], sku_storage = [] } = item ?? {};
		sku_storage.sort(COMP.sku);
		debounce(() => initSkuStorage(sku_storage), 50);
		child_assets.sort(COMP.asset);
		debounce(() => init(child_assets), 50);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayLinks = (item) =><AssetLinks asset={item} />;

	return (
		<>
			<Modal open={open} onClose={() => onClose()}>
				<Stack sx={styles} className='root' spacing={2}>
					<FlexBox>
						<H5>Reallocate Content</H5>
						<IconButton
							onClick={() => onClose()}
							className='close-btn'
							aria-label='Close'
						>
							<CloseIcon />
						</IconButton>
					</FlexBox>
					<Stack spacing={2} className='search-results'>
						<Box className='search-result-contents'>
							{(!!childAssets.length > 0 || !!skuStorage.length) && (
								<>
									{!!childAssets.length && (
										<Stack className='search-section'>
											<Overline className='search-section-title'>Holding</Overline>
											<Stack spacing={0} className='list'>
												{childAssets.map((childAsset) => (
													<AssetListComponentV2
														key={childAsset.path}
														item={childAsset}
														onEdit={setAsset}
														selected={({ processed }) =>
															processed === ASSET_PROCESSED_STATUS.CONFIRMED
														}
														display={displayLinks}
													/>
												))}
											</Stack>
										</Stack>
									)}
									{!!skuStorage.length && (
										<Stack className='search-section'>
											<Overline className='search-section-title'>Content</Overline>
											<Stack spacing={0} className='list'>
												{skuStorage.map((storage) => (
													<AssetSKUStorageListItem
														key={storage.sku_entry_id}
														item={storage}
														buttonIcon={<ArrowForward />}
														onEdit={setStorage}
														selected={
															storage?.processed ===
															ASSET_PROCESSED_STATUS.CONFIRMED
														}
														display={displayLinks}
													/>
												))}
											</Stack>
										</Stack>
									)}
								</>
							)}
						</Box>
					</Stack>
					<Box className='action-buttons'>
						<TextButton size='small' handleClick={() => onClose()} color='secondary'>
							Cancel
						</TextButton>
						<FilledButton handleClick={onUpdate} size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</Modal>
			{(asset || storage) && (
				<ReallocateModal
					onCancel={onCancelHolder}
					onSelect={onSelectHolder}
					storage={storage}
				/>
			)}
		</>
	);
}

export default DefectAssetModal;
