import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikNumberField, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { useCallback, useEffect, useMemo } from 'react';

import styles from 'src/styles/modal.styles';
import useAlertView from 'src/utilities/hooks/useAlertView';
import * as yup from 'yup';

function BayModal({ item, open, upsert, onClose }) {
	const [AlertView, { setAlert }] = useAlertView();
	const [entry, index] = item ?? [['', null, null, null], undefined];
	const [bay, rows, levels, editing] = entry ?? ['', null, null, false];

	// const title = useMemo(() => (editing || index >= 0 ? 'Edit Bay' : 'New Bay'), [editing]);

	const changeSet = {
		bay: [bay ?? '', yup.string().min(1).required('Bay is required!')],
		rows: [rows ?? '', yup.number().positive().integer().required('Rows are required!')],
		levels: [levels ?? '', yup.number().positive().integer().required('Levels are required!')],
	};

	useEffect(() => setAlert(null), []);

	const handleSubmit = useCallback(
		(values) => {
			setAlert(null);
			try {
				console.log('Values', values);
				upsert([values.bay, values.rows, values.levels, editing], index);
				onClose();
			} catch (err) {
				setAlert({
					severity: 'error',
					title: editing ? 'Failed to Update' : 'Failed to Add',
					content: err.message,
				});
			}
		},
		[editing, index],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Bay</H5>
					<IconButton onClick={() => onClose()} className='close-btn' aria-label='Close'>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing={1}>
						<FormikTextField
							label='Bay'
							name='bay'
							fullWidth
						/>
						<FlexBox>
							<FormikNumberField label='Rows' name='rows' fullWidth />
							<FormikNumberField label='Levels' name='levels' fullWidth />
						</FlexBox>

						<AlertView />

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								{editing ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

export default BayModal;
