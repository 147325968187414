import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { Body1, Body3, Overline } from 'src/components/Typography';
import formatNumber from 'src/utilities/formatNumber';

function StatsWidget({ primary, secondary, unit, label }) {
	return (
		<Stack spacing={1} className='widget stats'>
			<Box className='widget-content stats-content'>
				<Body1>{label}</Body1>
				<FlexBox style={{ width: 'auto' }}>
					<Box className='widget-text'>
						<Body3 className='widget-text-secondary'>{formatNumber(secondary)}</Body3>/
						<Body1 className='widget-text-primary'>{formatNumber(primary)}</Body1>
						{unit && <Overline>{unit}</Overline>}
					</Box>
				</FlexBox>
			</Box>
		</Stack>
	);
}

export default StatsWidget;
