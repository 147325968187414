import Activity from 'src/components/Account/Activity';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function ActivityPage() {
  usePageTitle('Your Activity');

  return (
    <Activity />
  );
}

export default withAppLayout(withDialogCard(ActivityPage));
