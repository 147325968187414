import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';

const toTaggedAsset = (asset, forceComplete = false) => ({
	id: asset?.id,
	path: asset?.path,
	asset_id: asset?.path,
	asset_type: asset?.asset_type,
	rw_asset_id: asset?.rw_asset_id,
	sku_id: asset?.sku_id,
	sku: asset?.sku,
	parent_asset_id: asset?.parent_asset_id,
	parent_asset: asset?.parent_asset,
	unique_location_id: asset?.unique_location_id,
	asset_nonce: asset?.asset_nonce, // nonce when we get this asset..
	processed: forceComplete ? ASSET_PROCESSED_STATUS.CONFIRMED : (asset?.processed ?? ASSET_PROCESSED_STATUS.PENDING),
	accounts: asset?.accounts,
	// processed:
	// 	[ASSET_TYPES.tanker, ASSET_TYPES.filling_tank].includes(asset?.asset_type) &&
	// 	!asset?.processed
	// 		? ASSET_PROCESSED_STATUS.CONFIRMED
	// 		: ASSET_PROCESSED_STATUS.PENDING,
	// processed: ASSET_PROCESSED_STATUS.PENDING,
	properties: asset?.properties,
	// client?: [string,string];   // ?
	// update_account_id?: user.account_identity,    // will store identity of the account not email
	// update_account_username?: string;
	// update_on?: number;
	asset_status: asset?.asset_status,
	children: asset?.children,
	child_assets: (asset?.child_assets ?? [])?.map(toTaggedAsset),
	sku_storage: asset?.sku_storage,
});

export default toTaggedAsset;
