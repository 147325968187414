import { configureStore } from '@reduxjs/toolkit';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { IS_PROD } from 'src/consts/config';
import { SLICE as notificationSliceName } from '../containers/Notifications';
import listenerMiddleware from './listenerMiddleware';
import rootReducer from './rootReducer';

//
// export const configureStoreWithPersist = () => {
//   const store = configureStore({
// 		reducer: rootReducer,
// 		middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },}),
// 		devTools: !IS_PROD,
// });
//   // enable hot reloading
//   // if (envSelect({default: true, production: false}) && module.hot) {
//   //   module.hot.accept(() => store.replaceReducer(combineReducers(reducers)));
//   // }
//   const persistor = persistStore(store);
//   return {
//     store,
//     persistor,
//   };
// };
//
// export const {persistor, store} = configureStoreWithPersist();

const persistConfig = {
	key: 'root',
	version: 1,
	timeout: 4 * 3600,
	storage,
	blocklist: [notificationSliceName],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: !IS_PROD,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		})
			.prepend(listenerMiddleware.middleware)
			.concat(thunk),
});

export const persistor = persistStore(store);
