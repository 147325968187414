import { Box, Skeleton } from '@mui/material';
import SourceTypeIcon from 'src/components/Liquid/SourceTypeIcon';
import { Body3 } from 'src/components/Typography';
import React from 'react';

function SourceDisplay({ type, name }) {
	if (!type || !name) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<SourceTypeIcon type={type} style={{ width: '12px', height: '16px' }} />
			<Body3>{name}</Body3>
		</Box>
	);
}

export function SourceDisplaySkeleton({ type }) {
	if (!type) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<SourceTypeIcon type={type} style={{ width: '12px', height: '16px' }} />
			<Skeleton variant={'text'} width={100} />
		</Box>
	);
}

export default SourceDisplay;
