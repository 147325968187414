import Box from '@mui/material/Box';
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
	'&.root': {
		color: '#fff',
		fill: '#fff',
		zIndex: 9999,

		'.box': {
			width: '84px',
			height: '84px',
			borderRadius: '10px',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
			backdropFilter: 'blur(5px)',

			'.spinner': {
				margin: 'auto',
			},
		},
	},
};

export default function Loading({ loading }) {
	return (
		<Backdrop sx={styles} className='root' open={loading}>
			<Box className='box'>
				<CircularProgress className='spinner' color='inherit' />
			</Box>
		</Backdrop>
	);
}
