import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';

// Selectors
export const selectAppState = (state) => state[SLICE];

export const selectActiveItem = createSelector(
	selectAppState,
	(state) => state?.active_item,
);

