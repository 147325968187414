import Button from '@mui/material/Button';
import React from 'react';

function TextButton({
	label,
	icon,
	iconPosition,
	size,
	handleClick,
	disabled = false,
	type,
	className,
	color = 'primary',
	children,
	...props
}) {
	return (
		<Button
			variant='text'
			disabled={disabled}
			onClick={handleClick}
			className={className}
			size={size}
			{...(iconPosition === 'start' && { startIcon: icon })}
			{...(iconPosition === 'end' && { endIcon: icon })}
			type={type}
			color={color}
			{...props}
		>
			{label}
			{children}
		</Button>
	);
}

export default TextButton;
