import { clearUser } from 'src/redux/containers/User';
import { store } from 'src/redux/store';

export function checkResponseStatus(status) {
	console.log('Checking response status', status);
	if (status === 401) {
		// clean state
		store.dispatch(clearUser());
	}
}
