import { CheckCircle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import AssetTag from 'src/components/Tasks/AssetTag';
import { H5 } from 'src/components/Typography';
import { ASSET_STATUS } from 'src/consts/asset';
import { REQUEST_STATUS } from 'src/consts/requests';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import styles from 'src/styles/modal.styles';
import useAssetAdminMutation from 'src/utilities/hooks/useAssetAdminMutation';
import useRequestAdminMutation from 'src/utilities/hooks/useRequestAdminMutation';

function ApproveModal({ actionKey, tasks, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	const [mutateAsset, { isLoading }] = useAssetAdminMutation();
	const [mutateRequest, { isLoading: isRequestLoading }] =
		useRequestAdminMutation();

	const approve = useMemo(() => actionKey === 'approve_selected', [actionKey]);
	const title = useMemo(
		() =>
			actionKey === 'approve_selected' ? 'Approve Changes' : 'Reject Changes',
		[actionKey],
	);
	const assetStatus = useMemo(
		() =>
			actionKey === 'approve_selected'
				? ASSET_STATUS.ACTIVE
				: ASSET_STATUS.REJECT_UPDATE,
		[actionKey],
	);
	const requestStatus = useMemo(
		() =>
			actionKey === 'approve_selected'
				? REQUEST_STATUS.DONE
				: REQUEST_STATUS.REJECTED,
		[actionKey],
	);

	const saveAssetChanges = useCallback(() => {
		const request_changes = tasks
			.filter((task) => !task.isAsset)
			.map((t) => ({ path: t.path, batch: t.batch }));
		const asset_changes = tasks
			.filter((task) => task.isAsset)
			.map((t) => ({ path: t.path, batch: t.batch }));

		if (asset_changes.length) {
			mutateAsset({ assets: asset_changes, asset_status: assetStatus })
				.then(() => {
					enqueueSnackbar(approve ? 'Asset approved!' : 'Asset rejected!', {
						variant: approve ? 'success' : 'warning',
					});
					onClose(true, true);
				})
				.catch((err) => {
					console.log('Error', err);
					enqueueSnackbar(err?.message ?? 'Failed to approve asset changes!', {
						variant: 'error',
					});
				});
		}

		if (request_changes.length) {
			mutateRequest({
				requests: request_changes,
				request_status: requestStatus,
			})
				.then(() => {
					enqueueSnackbar(approve ? 'Request approved!' : 'Request rejected!', {
						variant: approve ? 'success' : 'warning',
					});
					onClose(true, true);
				})
				.catch((err) => {
					console.log('Error', err);
					enqueueSnackbar(err?.message ?? 'Failed to approve asset changes!', {
						variant: 'error',
					});
				});
		}
	}, [
		approve,
		assetStatus,
		enqueueSnackbar,
		mutateAsset,
		mutateRequest,
		onClose,
		requestStatus,
		tasks,
	]);

	return (
		<Modal open onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{title}</H5>
					<IconButton
						onClick={() => onClose(false, false)}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<FlexBox
					className='asset-container'
					justifyContent='start'
					gap='0.75rem'
				>
					{tasks.map((task) => (
						<AssetTag
							key={task.id}
							id={task.id}
							action={task.action}
							type={task.type}
						/>
					))}
				</FlexBox>
				<FlexBox justifyContent='end'>
					<TextButton
						size='small'
						handleClick={() => onClose(false, true)}
						disabled={isLoading || isRequestLoading}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						size='small'
						icon={<CheckCircle />}
						iconPosition='start'
						handleClick={() => saveAssetChanges()}
						loading={isLoading || isRequestLoading}
					>
						Confirm
					</FilledButton>
				</FlexBox>
			</Stack>
		</Modal>
	);
}

export default ApproveModal;
