import { Close } from '@mui/icons-material';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import QRImage from 'src/components/QRImage';
import { PUBLIC_APP_BASE_URL } from 'src/consts/config';
import { useCompanyId } from 'src/redux/containers/Companies';
import useAlertView from 'src/utilities/hooks/useAlertView';
import { useShareWithPartnerMutation } from 'src/utilities/hooks/useShareWithPartnerMutation';
import * as yup from 'yup';

export function ShareWithPartnerDialog({ open, onClose }) {
	const printRef = useRef();
	const [AlertView, { setAlert }] = useAlertView();
	const companyId = useCompanyId();
	const qq = btoa(companyId);
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const qrUrl = `${origin}/admin/partners?qq=${qq}`;

	const [shareWithPartnerApi, { isLoading, data }] =
		useShareWithPartnerMutation();
	const changeSet = useMemo(
		() => ({
			name: ['', yup.string().required('Full name required!')],
			email: [
				'',
				yup.string().email('Invalid email!').required('Email required!'),
			],
		}),
		[],
	);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			try {
				await shareWithPartnerApi(values);
				setAlert(null);
			} catch (e) {
				setAlert({
					severity: 'error',
					title: 'Failed to mutate partner!',
					content: e.message,
				});
			}
		},
		[setAlert, shareWithPartnerApi],
	);
	const handleOnClose = useCallback(() => onClose(false), [onClose]);
	// const printHandler = useCallback(() => {
	// 	const element = printRef?.current?.innerHTML;
	// 	printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
	// }, []);

	return (
		<Dialog open={open} onClose={handleOnClose} maxWidth='xs' scroll='paper'>
			<IconButton
				aria-label='close'
				onClick={handleOnClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<DialogTitle>Invite New Partner</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<Stack alignItems={'center'} spacing={1}>
							<Box ref={printRef} className='qr'>
								<QRImage value={qrUrl} />
							</Box>
							{/* <FilledButton size='small' handleClick={printHandler}>
								<Print />
							</FilledButton> */}
						</Stack>
						<Instructions>
							Share your company info with your partner.
						</Instructions>
						<FormikTextField
							name='name'
							label='Full name'
							autoComplete='name'
							required
						/>
						<FormikTextField
							name='email'
							label='Contact email'
							autoComplete='email'
							required
						/>
						<AlertView />
					</Stack>
				</DialogContent>
				<DialogActions sx={{ m: 2 }}>
					<TextButton
						size='small'
						handleClick={handleOnClose}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						type='submit'
						size='small'
						isLoading={isLoading}
						disabled={data}
					>
						Invite
					</FilledButton>
				</DialogActions>
			</FormikForm>
		</Dialog>
	);
}

ShareWithPartnerDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};
