import Box from '@mui/material/Box';
import { Body1 } from 'src/components/Typography';
import { useMemo } from 'react';
import debounce from 'src/utilities/debounce';
import { Link, useLocation } from 'react-router-dom';

export function MenuItem({ tag, label, disabled, isSelected }) {
	let location = useLocation();

	const selected = useMemo(() => {
		const s = location.pathname?.startsWith(tag);
		if (s) {
			debounce(() => isSelected(s), 25);
		}
		return s;
	}, [location.pathname, tag, isSelected]);
	return (
		<Link to={disabled ? '#' : tag} className='menu-link'>
			<Box
				className={`menu-item ${
					selected ? 'menu-item-selected' : 'menu-item-default'
				} ${disabled ? 'menu-item-disabled' : ''}`}
			>
				<Body1 className='text'>{label}</Body1>
			</Box>
		</Link>
	);
}
