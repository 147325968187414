import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FormikCheckBox, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import { useContext } from 'react';

function TransportImpl({ label }) {
	const { values } = useContext(FormikContext);

	return (
		<Stack>
			<FormikCheckBox name='enable' label={<H5>{label}</H5>} />

			<Collapse in={Boolean(values?.enable)}>
				<Stack spacing={2}>
					<FlexBox alignItems='top'>
						<FormikTextField
							title='transportType'
							name='transportType'
							label='Type'
							fullWidth
						/>
						<FormikTextField
							title='registration'
							name='registration'
							label='Registration'
							fullWidth
						/>
					</FlexBox>
					<FormikTextField title='sealId' name='sealId' label='Seal ID' fullWidth />
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function Transport({ name, label }) {
	return (
		<FormikProvider path={name}>
			<TransportImpl label={label} />
		</FormikProvider>
	);
}
