import { Edit, PendingActions } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import formatCompanyAddress from 'src/components/Administration/formatCompanyAddress';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { Body3, H5, Overline } from 'src/components/Typography';
import { Status } from 'src/consts/admin';
import { useCompanyId } from 'src/redux/containers/Companies';
import match from 'src/utilities/match';

export function PartnerItem({ item, onEdit, openApproval }) {
	const { status = '', created = 0, company_name = '', id } = item;
	const companyId = useCompanyId();
	return (
		<Box className='listItem'>
			<FlexBox>
				<Box className='listContent'>
					<Stack direction='row' spacing='0.75rem'>
						{match(status, {
							[Status.APPROVED]: () => <BusinessIcon />,
							[Status.PENDING]: () => <PendingActions />,
							[Status.REJECTED]: () => <DomainDisabledIcon />,
							_: () => null,
						})}
						<Stack spacing={0.25}>
							<H5>{company_name}</H5>
							<Body3>{formatCompanyAddress(item)}</Body3>
							<Overline>
								{status}: {new Date(created).toLocaleString()}
							</Overline>
						</Stack>
					</Stack>
				</Box>
				<Stack className='listButtons' flexDirection={'row'}>
					<IconCircleButton onClick={() => onEdit(item)} help='Edit customer'>
						<Edit />
					</IconCircleButton>
					{status !== Status.APPROVED && `/cc/${companyId}` === id ? (
						<IconCircleButton
							onClick={() => openApproval(item)}
							help='confirm pending'
						>
							<ConnectWithoutContactIcon />
						</IconCircleButton>
					) : null}
				</Stack>
			</FlexBox>
		</Box>
	);
}

PartnerItem.propTypes = {
	item: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	openApproval: PropTypes.func.isRequired,
};
