import { Divider, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/containers/User';
import styles from 'src/styles/content.styles';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import Settings from './Settings';
import Security from './Security';

function AccountSettings() {
  const [target, scroll] = useScrollTarget();

  const user = useSelector(selectUser);

  useEffect(() => scroll(), [user]);

  return (
    <Stack sx={styles} className="root" spacing="2rem" ref={target}>
      <Settings />
      <Divider className="divider" />
      <Security />
    </Stack>
  );
}

export default AccountSettings;
