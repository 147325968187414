import Register from 'src/components/Auth/Register';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withEmptyLayout } from 'src/components/Layout/EmptyLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function RegisterPage() {
	usePageTitle('Register');
	return (
		<Register />
	);
}

export default withEmptyLayout(withDialogCard(RegisterPage));
