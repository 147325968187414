const getDropZoneBorder = (color) => {
    return {
        bgGradient: `repeating-linear(0deg, ${color}, ${color} 10px, transparent 10px, transparent 20px, ${color} 20px), repeating-linear-gradient(90deg, ${color}, ${color} 10px, transparent 10px, transparent 20px, ${color} 20px), repeating-linear-gradient(180deg, ${color}, ${color} 10px, transparent 10px, transparent 20px, ${color} 20px), repeating-linear-gradient(270deg, ${color}, ${color} 10px, transparent 10px, transparent 20px, ${color} 20px)`,
        backgroundSize: "2px 100%, 100% 2px, 2px 100% , 100% 2px",
        backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
        backgroundRepeat: "no-repeat",
        borderRadius: "4px",
    };
};

export { getDropZoneBorder };
