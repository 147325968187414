import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from './consts';

export const initialState = {
	count: {
		assignedRequests: 0,
		pendingAssets: 0,
		totalCount: 0,
	},
};

export const slice = createSlice({
	name: SLICE,
	initialState,
	reducers: {
		setNotificationCount(state, { payload }) {
			state.count = payload;
		},
	},
});

export const { reducer, actions } = slice;
export const { setNotificationCount } = actions;
