import Box from '@mui/material/Box';
import Id from 'src/components/icons/Id';
import styles from './styles';

function AssetTag({ id, type, action }) {
	return (
		<Box sx={styles} className='root' action={action}>
			<Box className='tab' />
			<Box className='content'>
				<Box className='asset-id'>
					<Id />
					&nbsp;
					{`${id} [${type}]`}
				</Box>
			</Box>
		</Box>
	);
}

export default AssetTag;
