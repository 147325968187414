import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { Body1 } from 'src/components/Typography';

export function ResetPasswordDialog({
	item,
	handleClose,
	handleResetPassword,
}) {
	const handleSubmit = () => {
		handleResetPassword(item);
		handleClose();
	};

	return (
		<Dialog
			open={!!item}
			onClose={handleClose}
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth='xs'
		>
			<DialogTitle>Reset Password</DialogTitle>
			<DialogContent>
				<Body1 variant='body1'>Full name: {item?.account_name}</Body1>
				<Body1 variant='body1'>Contact email: {item?.account_email}</Body1>
				<Box sx={{ mt: 4 }} />
			</DialogContent>
			<DialogActions sx={{ m: 2 }}>
				<TextButton size='small' handleClick={handleClose} color='secondary'>
					Cancel
				</TextButton>
				<FilledButton type='submit' size='small' handleClick={handleSubmit}>
					Confirm
				</FilledButton>
			</DialogActions>
		</Dialog>
	);
}

ResetPasswordDialog.propTypes = {
	item: PropTypes.obj,
	handleClose: PropTypes.func,
	email: PropTypes.string,
	name: PropTypes.string,
	handleResetPassword: PropTypes.func,
};
