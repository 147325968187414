import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Overline } from 'src/components/Typography';
import React from 'react';

function TitledButton({
	type,
	label = '',
	size = 'medium',
	handleClick = undefined,
	onClick = undefined,
	loading = false,
	disabled = false,
	sx = {},
	className = '',
	variant = 'contained',
	color = 'primary',
	extraContent = null,
	children,
}) {
	return (
		<>
			<Button
				variant={variant}
				sx={sx}
				disabled={loading || disabled}
				onClick={onClick ?? handleClick}
				className={className}
				size={size}
				type={type}
				color={color}
			>
				<Stack
					spacing={1}
					sx={{ justifyContent: 'center', alignItems: 'center' }}
				>
					{children}
					{label && <Overline sx={{ fontWeight: 500 }}>{label}</Overline>}
					{loading && (
						<CircularProgress size={24} sx={{ marginLeft: '0.5rem' }} />
					)}
				</Stack>
			</Button>
			{extraContent}
		</>
	);
}

export default TitledButton;
