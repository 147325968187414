import React, { useCallback, useMemo } from 'react';
import { explorerLocationFields } from 'src/components/ExplorerLocation/explorerLocationFields';
import useSourceInventoryHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import Location from 'src/components/Location';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import { FormikProvider } from 'src/providers/FormikProvider';

function propertyFields(fields = {}) {
  const { location = {} } = fields;
  return {
    location: explorerLocationFields(location),
  };
}

function RestockForm(props) {
  const [
    [sourceSkus],
    [sourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Assets',
    filter: {
      sku_types: [SKU_TYPES.SERVICE],
      asset_types: [ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.pallet, ASSET_TYPES.container],
    },
    SKUModal: SourceSKUModal,
  });

  const changeSetGenerator = useMemo(() => (initialValues) => ({
    ...requestIdFields(initialValues),
    properties: propertyFields(initialValues?.properties),
  }), []);

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback((entity) => {
    initSources({ ...entity, assets: entity?. sources, skus: entity?.sku_sources });
  }, [initSources]);

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sku_sources: sourceSkus,
      sources: sourceAssets,
    }),
    [sourceSkus, sourceAssets],
  );

  return (
    <>
      <RequestForm
        requestLabel="Restock"
        requestType={REQUEST_TYPES.restock}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />

        <FormikProvider path="properties">
          <Location name="location" />
        </FormikProvider>
      </RequestForm>

      <SourceModals />
    </>
  );
}

export default withAppLayout(RestockForm, { title: 'Restock' });
