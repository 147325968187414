import { enqueueSnackbar } from 'notistack';
import { getCommits } from 'src/services/sync.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params)=>getCommits(params).then(({items = []})=>items).catch(()=>{
    enqueueSnackbar('Error on getting local data', { variant: 'error' });
    return [];
});
export const [useGetChangesetsOfflineLazyQuery, useGetChangesetsOfflineQuery] = genServiceCallHooks(serviceFunction);

export default useGetChangesetsOfflineQuery;
