import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import React, { useCallback, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import {
	FormikForm,
	FormikMeasuresField,
	FormikNumberField,
} from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import LiquidSummary from 'src/components/SKUSelector/items/LiquidSummary';
import { H5 } from 'src/components/Typography';
import { MEASURE } from 'src/consts/measures';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import styles from 'src/styles/modal.styles';
import { AlertView } from 'src/utilities/hooks/useAlertView';
import useMeasures from 'src/utilities/hooks/useMeasures';
import * as yup from 'yup';

function SourceAssetModal({ item, open, onClose }) {
	const { to: cto, from: cfrom } = useMeasures();

	const { rw_asset_id, properties = {} } = item ?? {};
	const { liquid = {} } = properties;
	const { level = {} } = liquid;
	const {
		bl,
		la,
		abv,
		tcf,
		expected_bl,
		updated_bl,
		updated_abv,
		updated_tcf,
	} = level;

	const expected_value = expected_bl ?? bl;

	const changeSet = useMemo(
		() => ({
			expected_bl: [
				Number(cto(expected_value ?? 0, MEASURE.l_m)).toFixed(2),
				yup.number().min(0, 'Must be positive!'),
			],
			updated_bl: [
				updated_bl ? Number(cto(updated_bl ?? 0, MEASURE.l_m)).toFixed(2) : '',
				yup.number().min(0, 'Must be positive!'),
			],
			updated_abv: [
				updated_abv ?? abv
					? Number(cto(updated_abv ?? abv, MEASURE.alc)).toFixed(2)
					: '',
				yup.number().min(0, 'Must be positive!'),
			],
			updated_tcf: [
				updated_tcf ?? tcf ?? '1.000',
				yup.number().min(0, 'Must be positive!'),
			],
		}),
		[cto, bl, updated_bl, expected_value],
	);

	const calculateUpdates = useCallback(
		(values) => {
			const { bl, expected_bl, updated_bl, updated_abv, updated_tcf } =
				values ?? {};
			console.log('Calculating from values', values);
			if (updated_bl) {
				const rla = cfrom(
					updated_bl * ((Number(updated_abv) * Number(updated_tcf)) / 100),
					MEASURE.l_m,
				);
				const rbl = cfrom(updated_bl, MEASURE.l_m);
				const abv = cfrom(updated_abv, MEASURE.alc);

				const isEmpty = rbl === 0;
				return [
					true,
					{
						expected_bl: cfrom(expected_bl, MEASURE.l_m), // how much we wanted to take out
						actual_bl: Math.max(bl - rbl, 0), // how much we took out
						updated_bl: rbl, // how much is left
						updated_la: isEmpty ? 0 : rla,
						updated_abv: isEmpty ? 0 : abv,
						updated_tcf: isEmpty ? 0 : updated_tcf,
					},
				];
			}

			return [false, values];
		},
		[cfrom, level],
	);

	const handleSubmit = (values) => {
		console.log('submitted', values);
		const [valid, updated_level] = calculateUpdates({ ...level, ...values });
		console.log(' --> updated', updated_level);
		// const le = Number(updated_level?.updated_bl) > 0;

		onClose({
			...item,
			properties: {
				...properties,
				liquid: {
					...liquid,
					// enable: Number(updated_level?.updated_bl) > 0,
					level: {
						...level,
						...updated_level,
						filled_date: Date.now(),
						// enable: Number(updated_level?.updated_bl) > 0,
					},
				},
			},
			processed: valid
				? ASSET_PROCESSED_STATUS.CONFIRMED
				: item.processed
					? item.processed
					: ASSET_PROCESSED_STATUS.PENDING,
		});
	};

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{rw_asset_id}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
						gap: '1.5rem',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Instructions>Adjust the disgorging settings.</Instructions>

					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={2}>
							<LiquidDisplay label='Available' value={bl} la={la} />

							<FlexBox gap={2}>
								<FormikMeasuresField
									label='Extract'
									name='expected_bl'
									measure={MEASURE.l_m}
									fullWidth
								/>
								<FormikMeasuresField
									label='Remaining'
									name='updated_bl'
									measure={MEASURE.l_m}
									fullWidth
								/>
							</FlexBox>

							<FlexBox gap={2}>
								<FormikMeasuresField
									name='updated_abv'
									measure={MEASURE.alc}
									label='Strength'
									fullWidth
									required
								/>
								<FormikNumberField name='updated_tcf' label='TCF' fullWidth />
							</FlexBox>

							<LiquidSummary level={level} />

							<AlertView />

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small' disabled={!bl}>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default SourceAssetModal;
