import { Alert, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useContext, useMemo } from 'react';
import FormikContext from 'src/context/FormikContext';

function getNestedErrors(err) {
  const errorList = [];
  for (const nestedErr of Object.values(err)) {
    // console.log('Error', nestedErr);

    if (typeof nestedErr === 'string') {
      errorList.push(nestedErr);
    } else if (typeof nestedErr === 'object') {
      errorList.push(...getNestedErrors(nestedErr));
    }
  }
  return errorList;
}

function Errors() {
  const { errors } = useContext(FormikContext);

  const errorList = useMemo(() => {
    const errorList = [];
    // console.log('Errors', errors);

    for (const err of Object.values(errors)) {
      // console.log('Error', err);
      if (typeof err === 'string') {
        errorList.push(err);
      } else if (typeof err === 'object') {
        errorList.push(...getNestedErrors(err));
      }
    }
    return errorList;
  }, [errors]);

  if (errorList.length === 0) {
    return null;
  }

  return (
    <Alert severity="error">
      <Stack spacing="0.25rem">
        {errorList.map((error, index) => (
          <Box key={index}>
            {'- '}
            {error}
          </Box>
        ))}
      </Stack>
    </Alert>
  );
}

export default Errors;
