import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { H5 } from 'src/components/Typography';
import styles from 'src/styles/content.styles';
import useAccountTokenQuery from 'src/utilities/hooks/useAccountTokenQuery';
import useUser from 'src/utilities/hooks/useUser';

function Portfolio() {
  const [load,
    // {tokens, more, loading, error,}
  ] = useAccountTokenQuery();
  const { account_username } = useUser();

  console.log('%cindex.js line:18 account_username', 'color: white; background-color: #007acc;', account_username);
  // const { store, props } = storeWrapper.useWrappedStore(rest);

  // const tokens = [
  //   {
  //     token_name: 'Annandale 35 Yrs',
  //     token_description: 'Annandale 35 Yrs',
  //     token_uid: '123123uuid',
  //     token_id: 'XXXXXXX',
  //     token_image: 'https://www.htfw.com/media/catalog/product/cache/eb03fb83d059c87eddc7a56651d2b8cf/l/p/lp15276_5_1.jpg',
  //   },
  // ];
  // const { token = {} } = props;
  // const { account_key } = token;

  useEffect(() => {
    console.log('Loading initial tokens...', account_username);
    load(account_username).finally();
  }, [account_username]);

  return (
    <Stack sx={styles} className="root" spacing="1.25rem">
      <H5>Portfolio</H5>

      {/* {!loading && (
        <>
          {tokens && tokens.length > 0 && <ListBase title="Personal collection" tokens={tokens}
          loadFunction={() => load(account_username)}
          loading={loading} more={more} error={error} />}
          {(!tokens || tokens.length === 0) && (
            <Section customStyles={{ root: { zIndex: 10 } }}>
              <DialogCard>
                <H3>Nothing here yet, time to start collecting... </H3>
              </DialogCard>
            </Section>
          )}
        </>
      )} */}
    </Stack>
  );
}

export default Portfolio;
