import {
	AddCircle,
	ContentCopyOutlined,
	Edit,
	KeyboardArrowRightOutlined,
} from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import SKU from 'src/components/admin/SKUs/SKU';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import withDialogCard from 'src/components/Cards/DialogCard';
import Instructions from 'src/components/Instructions';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { Body3, H5, Overline } from 'src/components/Typography';
import { SKU_TYPE_REVERSE } from 'src/consts/sku';
import { setCompanySKUs, useCompanySKUs } from 'src/redux/containers/Companies';
import styles from 'src/styles/content.styles';
import debounce from 'src/utilities/debounce';
import usePageTitle from 'src/utilities/hooks/usePageTitle';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import { useTaxCodesQuery } from 'src/utilities/hooks/useTaxCodesQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetSKULazyQuery } from 'src/utilities/hooks/useGetSKUQuery';
import SKUItemInfo from 'src/components/SKUSelector/items/SKUItemInfo';
import { FlexBox } from 'src/components/Layout/FlexBox';

function SKUItem({ entry, editSku }) {
	const { sku_name, sku_type, sku_description } = entry;
	const [expanded, setExpanded] = useState(false);
	const [fetchSKU, { data: skuData }] = useGetSKULazyQuery();

	useEffect(() => {
		if (expanded) {
			fetchSKU({ sku_name });
		}
	}, [expanded]);

	return (
		<Stack className='listItem'>
			<Box sx={{ width: '100%' }}>
				<FlexBox>
					<Box className='listContent'>
						<Stack direction='row' spacing='0.75rem'>
							<Box>
								<H5>{sku_name}</H5>
								<Overline>{SKU_TYPE_REVERSE[sku_type]}</Overline>
								<Body3>{sku_description}</Body3>
							</Box>
						</Stack>
					</Box>

					<Stack className='listButtons'>
						<IconCircleButton onClick={() => editSku(entry)}>
							<Edit />
						</IconCircleButton>
						<IconCircleButton
							onClick={() =>
								editSku({
									...entry,
									id: undefined,
									path: undefined,
									sku_id: undefined,
								})
							}
						>
							<ContentCopyOutlined />
						</IconCircleButton>
						<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
							{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
						</IconCircleButton>
					</Stack>
				</FlexBox>
				{expanded && (
					<Box sx={{ paddingLeft: '0.5rem', paddingBottom: '0.5rem' }}>
						<SKUItemInfo sku={skuData} />
					</Box>
				)}
			</Box>
		</Stack>
	);
}

function TabPanel({ type, skus, editSku, value, index }) {
	const skuList = skus.filter((sku) => sku.sku_type === type);

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			sx={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}
		>
			{value === index && (
				<Stack spacing={0} className='list'>
					{skuList &&
						skuList.map((entry) => (
							<SKUItem key={entry.sku_id} entry={entry} editSku={editSku} />
						))}
				</Stack>
			)}
		</Box>
	);
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

function SKUDisplay({ skus, editSku, selectedSkuType, setSelectedSkuType }) {
	const handleChange = useCallback(
		(e, newValue) => {
			setSelectedSkuType(newValue);
		},
		[setSelectedSkuType],
	);

	return (
		<>
			<Tabs
				value={selectedSkuType}
				onChange={handleChange}
				variant='scrollable'
				scrollButtons='auto'
				allowScrollButtonsMobile
				aria-label='SKU Types'
			>
				{Object.keys(SKU_TYPE_REVERSE).map((type, index) => (
					<Tab
						key={type}
						label={SKU_TYPE_REVERSE[type]}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>

			<SwipeableViews
				axis='x'
				index={selectedSkuType}
				onChangeIndex={setSelectedSkuType}
			>
				{Object.keys(SKU_TYPE_REVERSE).map((type, index) => (
					<TabPanel
						key={type}
						type={type}
						value={selectedSkuType}
						index={index}
						skus={skus}
						editSku={editSku}
					/>
				))}
			</SwipeableViews>
		</>
	);
}

//
// <TabPanel value={value} index={0} dir={theme.direction}>
// 	Item One
// </TabPanel>
// <TabPanel value={value} index={1} dir={theme.direction}>
// 	Item Two
// </TabPanel>
// <TabPanel value={value} index={2} dir={theme.direction}>
// 	Item Three
// </TabPanel>

const SKUEntries = withDialogCard(({ sku }) => {
	return (
		<Stack sx={styles} className='root'>
			<H5>SKU Entries</H5>
			<SKUItemInfo sku={sku} />
		</Stack>
	);
});

const SKUManagement = withDialogCard(({ setSku, sku }) => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const id = searchParams.get('id');
	// const [list, setList] = useState([]);
	const [target, scroll] = useScrollTarget();
	// const [fetchSKUs, { data: skuList }] = useGetSKUsLazyQuery([]);
	const { data: taxCodes = [] } = useTaxCodesQuery();
	const skus = useCompanySKUs();

	const [selectedSkuType, setSelectedSkuType] = useState(0);

	const handleSave = useCallback(
		(mutatedSku) => {
			setSku(null);

			const xs = [...skus];
			const index = xs.findIndex((x) => x.sku_name === mutatedSku.sku_name);
			if (index >= 0) {
				xs[index] = { ...xs[index], ...mutatedSku };
			} else {
				xs.push(mutatedSku);
			}
			dispatch(setCompanySKUs(xs));
			scroll();
		},
		[scroll, skus],
	);

	const editSku = useCallback(
		(s) => {
			setSku(s);
			scroll();
		},
		[scroll, setSku],
	);

	useEffect(() => {
		if (id && skus && skus.length > 0) {
			const _sku = skus.find((l) => id === l.sku_id);
			if (_sku) {
				debounce(() => {
					setSku(_sku);
				}, 50);
			}
		}
	}, [id, skus]);

	usePageTitle('SKU');

	useEffect(() => {
		scroll();
	}, [scroll, target]);

	return (
		<FlexBox>
			<Stack sx={styles} className='root' spacing='1.25rem'>
				<Box className='header' ref={target}>
					<H5>SKU Management</H5>

					<TitledButton
						handleClick={() => editSku({})}
						variant='outlined'
						color='secondary'
						label='New'
						sx={{ width: '42px', height: '52px' }}
					>
						<AddCircle height={24} width={24} />
					</TitledButton>
				</Box>

				<Instructions>
					You can manage the SKUs for your organization here.
				</Instructions>

				{!!sku && (
					<Collapse in={!!sku} onExited={scroll}>
						<SKU
							entity={sku}
							onSave={handleSave}
							cancel={() => editSku(null)}
							taxCodes={taxCodes}
							selectedSkuType={selectedSkuType}
						/>
					</Collapse>
				)}

				{(!skus || skus.length === 0) && (
					<Alert severity='warning'>No SKUs configured.</Alert>
				)}

				{skus && skus.length > 0 && (
					<SKUDisplay
						skus={skus}
						editSku={editSku}
						selectedSkuType={selectedSkuType}
						setSelectedSkuType={setSelectedSkuType}
					/>
				)}
			</Stack>
		</FlexBox>
	);
});

function SKUPage() {
	const [sku, setSku] = useState(null);
	const [fetchSKU, { data: skuData }] = useGetSKULazyQuery();

	useEffect(() => {
		if (sku && sku?.sku_name) {
			fetchSKU({ sku_name: sku.sku_name });
		}
	}, [sku]);

	return (
		<FlexBox sx={{ alignItems: 'flex-start' }}>
			<SKUManagement setSku={setSku} sku={sku} />
			{sku && <SKUEntries sku={skuData} />}
		</FlexBox>
	);
}

export default withAppLayout(SKUPage);
