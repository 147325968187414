import { useMemo } from 'react';
import QRImage from 'src/components/QRImage';
import { LOCATION_PRINT_MAP } from 'src/consts/facility';
import { encodeLocationQrCode } from 'src/utilities/hooks/useEncodeLocationQrCode';
import useOrigin from 'src/utilities/hooks/useOrigin';

const styles = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '4rem',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyItems: 'start',
		pageBreakInside: 'none',
		pageBreadAfter: 'auto',
	},
	qrDetails: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		display: 'inline-block',
		textTransform: 'uppercase',
		textAlign: 'right',
		fontSize: '0.7rem',
		minWidth: '1.5rem',
		fontFamily:
			'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
	},
	text: {
		fontSize: '0.7rem',
		fontWeight: '600',
		whiteSpace: 'nowrap',
		fontFamily:
			'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
	},
};

const randomString = (length) => {
	const chars =
		'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let result = '';
	for (let i = length; i > 0; i -= 1) {
		result += chars[Math.floor(Math.random() * chars.length)];
	}
	return result;
};

const generateAllLocations = ({
	filter = LOCATION_PRINT_MAP.all,
	origin,
	facility_id = '',
	bay = '',
	rows = [],
	levels = [],
	facilities = [],
}) => {
	const list = [];
	if (!facility_id || filter === LOCATION_PRINT_MAP.facilities) {
		return Object.entries(facilities).map(([k]) => ({
			facility_id: k,
			id: k,
			value: encodeLocationQrCode(origin, 'dt', [k]),
		}));
	}
	if (!bay || filter === LOCATION_PRINT_MAP.bays) {
		return facilities[facility_id].bays?.map(([bay]) => ({
			facility_id: facility_id,
			bay,
			id: `${facility_id}-${bay}`,
			value: encodeLocationQrCode(origin, 'dt', [facility_id, bay]),
		}));
	}
	if (filter === LOCATION_PRINT_MAP.rows) {
		rows.forEach((row) => {
			list.push({
				facility_id,
				bay,
				row,
				id: `${facility_id}-${bay}-${row?.value}-${randomString(5)}`, // used as key
				value: encodeLocationQrCode(origin, 'dt', [facility_id, bay, row]),
			});
		});
	}
	if (filter === LOCATION_PRINT_MAP.all) {
		rows.forEach((row) => {
			levels.forEach((level) => {
				list.push({
					facility_id,
					bay,
					row,
					level,
					id: `${facility_id}-${bay}-${row?.value}-${level.value}-${randomString(5)}`, // used as key
					value: encodeLocationQrCode(origin, 'dt', [
						facility_id,
						bay,
						row,
						level,
					]),
				});
			});
		});
	}
	return list;
};

function LocationQRCodes({
	filter = LOCATION_PRINT_MAP.all,
	facility_id = '',
	bay = '',
	rows = [],
	levels = [],
	facilities = [],
}) {
	const origin = useOrigin();
	const locationList = useMemo(() => {
		return generateAllLocations({
			filter,
			facilities,
			origin,
			facility_id,
			bay,
			rows,
			levels,
		});
	}, [facilities, origin, facility_id, bay, rows, levels, filter]);

	return (
		<div style={styles.rootFullScreen}>
			{locationList.map((location) => (
				<div style={styles.content} key={location?.id}>
					<QRImage
						boxSize={120}
						value={location?.value}
						sx={{ maxWidth: 'min-content' }}
					/>
					<div style={styles.qrDetails}>
						<div style={styles.row}>
							<span style={styles.title}>F</span>
							<span style={styles.text}>{location?.facility_id}</span>
						</div>
						{location?.bay && (
							<div style={styles.row}>
								<span style={styles.title}>B</span>
								<span style={styles.text}>{location?.bay}</span>
							</div>
						)}
						{location?.row && (
							<div style={styles.row}>
								<span style={styles.title}>R</span>
								<span style={styles.text}>{location?.row}</span>
							</div>
						)}
						{location?.level && (
							<div style={styles.row}>
								<span style={styles.title}>L</span>
								<span style={styles.text}>{location?.level}</span>
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

export default LocationQRCodes;
