import { useCallback } from 'react';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import { INVENTORY_STATUS, SKU_TYPES } from 'src/consts/sku';
import debounce from 'src/utilities/debounce';
import isEqual from 'lodash.isequal';

const useSkuConfirmHook = (skus, upsert) => {
	return useCallback(
		(asset_id, ...properties) => {
			let found = false;
			const updatedSkus = skus
				.map((item) => {
					let {
						sku_type,
						processed = INVENTORY_STATUS.PENDING,
						entries,
						...rest
					} = item;

					let pending = false;

					entries = entries?.map((entry) => {
						const storage = entry.storage?.map((st) => {
							console.log('Checking storage', item, asset_id, properties);
							if (st.asset_id !== asset_id) {
								if (
									!st.processed ||
									st.processed !== ASSET_PROCESSED_STATUS.CONFIRMED
								) {
									pending = true;
								}
								return st;
							}
							found = true;
							return {
								...st,
								processed: ASSET_PROCESSED_STATUS.CONFIRMED,
								...properties,
							};
						});
						return {
							...entry,
							storage,
						};
					});

					if (![SKU_TYPES.TRACKED].includes(sku_type)) {
						entries = entries?.map((entry) => {
							const storage = entry.storage?.map((st) => {
								console.log('Checking storage', item, asset_id, properties);
								if (st.asset_id !== asset_id) {
									if (
										!st.processed ||
										st.processed !== ASSET_PROCESSED_STATUS.CONFIRMED
									) {
										pending = true;
									}
									return st;
								}
								found = true;
								return {
									...st,
									processed: ASSET_PROCESSED_STATUS.CONFIRMED,
									...properties,
								};
							});
							return {
								...entry,
								storage,
							};
						});

						// console.log('SKU Status', pending);
						processed = !pending ? INVENTORY_STATUS.CONFIRMED : processed;
					}

					const newSku = { ...rest, sku_type, processed, entries };
					if (!isEqual(newSku, item)) {
						// console.log('SKU updated', newSku, item);
						return newSku;
					}
					return null;
				})
				.filter((x) => x !== null);

			console.log('SKU found status', found, asset_id, updatedSkus.length);
			if (updatedSkus.length > 0) {
				debounce(() => upsert(updatedSkus), 50);
			}
			return found;
		},
		[skus, upsert],
	);
};

export default useSkuConfirmHook;
