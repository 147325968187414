import { useMemo } from 'react';
import SourceTypeIcon from 'src/components/Liquid/SourceTypeIcon';
import QRImage from 'src/components/QRImage/index';
import { Body2 } from 'src/components/Typography';
import LongLogo from 'src/components/icons/LongLogo';
import { ASSET_EDIT_URL, ASSET_TYPES_REVERSE } from 'src/consts/asset';
import { PUBLIC_APP_BASE_URL } from 'src/consts/config';

const styles = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		pageBreakInside: 'none',
		pageBreadAfter: 'auto',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		pageBreakInside: 'none',
		pageBreadAfter: 'auto',
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	footer: {
		borderTop: '1px solid',
		paddingTop: '0.25rem',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	qrDetails: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	qrIcon: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		// border: '2px solid',
		padding: '5px',
		height: '144px',
		width: '144px',
		borderRadius: '50%',
	},
	type: {
		textTransform: 'uppercase',
		fontSize: '0.8rem',
		marginTop: '.25rem',
	},
	title: {
		fontSize: '12pt',
		fontWeight: '600',
		fontFamily:
			'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
	},
};

function QrDetails({ item }) {
	return (
		<div style={styles.qrDetails}>
			<div style={styles.qrIcon}>
				<SourceTypeIcon
					type={ASSET_TYPES_REVERSE[item?.asset_type] ?? item?.asset_type}
					height={84}
					width={84}
				/>
			</div>
		</div>
	);
}

function QrCode({ asset, fullScreen = true }) {
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const route = ASSET_EDIT_URL[asset?.asset_type];
	const qrValue = useMemo(
		() => `${origin}${route}/${asset?.path}`,
		[asset, origin],
	);

	return (
		<div style={fullScreen ? styles.rootFullScreen : styles.root}>
			<div style={styles.content}>
				<QRImage value={qrValue} />
				<QrDetails item={asset} />
			</div>
			<div style={styles.footer}>
				{asset?.rw_asset_id && (
					<Body2 style={styles.title}>{asset?.rw_asset_id}</Body2>
				)}
				<LongLogo height={24} width={108} />
			</div>
		</div>
	);
}

export default QrCode;
