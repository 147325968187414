import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthToken } from 'src/redux/containers/Authorization';
import { setCompanyUsers, useCompanyId } from 'src/redux/containers/Companies';
import { listUsers } from 'src/services/company.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params) => listUsers(params);

export const [useCompanyUsersLazyQuery] = genServiceCallHooks(serviceFunction);

export function useCompanyUsersQuery(...params) {
  const dispatch = useDispatch();

  const [refetch, res] = useCompanyUsersLazyQuery(...params);

  const companyId = useCompanyId();
  const token = useAuthToken();

  useEffect(() => {
    if (token && companyId) {
      refetch({ id: companyId }).then((response) => {
        dispatch(setCompanyUsers(response ?? []));
      });
    }
  }, [companyId, dispatch, refetch, token]);

  return res;
}
