import { AddBox } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import DescriptionDisplay from 'src/components/properties/DescriptionDisplay';
import IDDisplay from 'src/components/properties/IDDisplay';
import NameDisplay from 'src/components/properties/NameDisplay';
import { SKU_TYPE_REVERSE } from 'src/consts/sku';

function SKUInfo({ item, onClick }) {
	return (
		<Stack spacing={0.5} onClick={onClick}>
			<IDDisplay id={`${item?.sku_id} [${SKU_TYPE_REVERSE[item.sku_type]}]`} />
			<NameDisplay name={item?.sku_name} />
			<DescriptionDisplay value={item?.sku_description} />
		</Stack>
	);
}

function SKUItem({ item, buttonIcon = <AddBox />, onClick }) {
	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<SKUInfo item={item} onClick={onClick} />
			</Box>

			{onClick && (
				<Stack className='listButtons'>
					<IconCircleButton onClick={onClick}>{buttonIcon}</IconCircleButton>
				</Stack>
			)}
		</Box>
	);
}

export default SKUItem;
