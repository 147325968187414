import { Box } from '@mui/material';
import Bottle2 from 'src/components/icons/Bottle2';
import Cask2 from 'src/components/icons/Cask2';
// import AssetLink from 'components/AssetLink';
import Image from 'src/components/Image';
// import Table from 'components/Table';
import { Body1 } from 'src/components/Typography';
// import Link from 'components/Link';
import { toPrice } from 'src/utilities/format';
import replaceIpfsGateway from 'src/utilities/replaceIpfsGateway';
// import getPCAFromAddress from 'utilities/getPCAFromAddress';
// import getAssetUrl from 'utilities/getAssetUrl';

function AccountPaymentsRow({ row }) {
  const {
    description,
    image,
    status,
    created,
    payment_shipping_status,
    amount,
    payment_currency,
    recipient,
  } = row;
  return (
    <Box className="item">
      <Box className="img">
        {image ? (
          <Image
            src={replaceIpfsGateway(image)}
            alt={description}
            layout="fill"
            objectFit="contain"
          />
        ) : (
          <Box>
            <Bottle2 />
            <Cask2 />
          </Box>
        )}
      </Box>
      <Box className="sub-rows">
        <Box className="sub-row first">
          <Box className="property">
            <Body1 className="label">Purchase of</Body1>
            <Body1 className="value">{description}</Body1>
          </Box>
          <Box className="property total">
            <Body1 className="label">Total Payment</Body1>
            <Body1 className="value">
              {toPrice({
                rawPrice: amount,
                currencyCode: payment_currency.toUpperCase(),
              })}
            </Body1>
          </Box>
        </Box>
        <Box className="sub-row second">
          <Box className="property">
            <Body1 className="label">Order Status</Body1>
            <Body1 className="value">{status}</Body1>
          </Box>
          {payment_shipping_status && (
            <Box className="property">
              <Body1 className="label">Shipping Status</Body1>
              <Body1 className="value">{payment_shipping_status}</Body1>
            </Box>
          )}
          <Box className="property">
            <Body1 className="label">Date</Body1>
            <Body1 className="value">{new Date(created * 1000).toLocaleDateString()}</Body1>
          </Box>
          {/* <Box className="property">
            <Body1 className="label">Currency</Body1>
            <Body1 className="value">{payment_currency}</Body1>
          </Box> */}
          {/* <Box className="property">
            <Body1 className="label">Status</Body1>
            <Body1 className="value">-- what field ?? --</Body1>
          </Box> */}
          <Box className="property">
            <Body1 className="label">Sold By</Body1>
            <Body1 className="value">{recipient}</Body1>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default AccountPaymentsRow;
