import { jsx, jsxs } from "react/jsx-runtime";
import { createIcon, forwardRef, omitThemingProps, useColorModeValue, useMediaQuery, useMultiStyleConfig, chakra, Flex, Spinner, Collapse } from "@chakra-ui/react";
import * as React from "react";
import React__default from "react";
import { dataAttr } from "@chakra-ui/utils";
import { mode, anatomy } from "@chakra-ui/theme-tools";
import { motion, AnimatePresence } from "framer-motion";
import { createIcon as createIcon$1 } from "@chakra-ui/icon";
import { createContext } from "@chakra-ui/react-context";
import { cx } from "@chakra-ui/shared-utils";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
const StepsContext = React.createContext({
  activeStep: 0
});
const useStepsContext = () => React.useContext(StepsContext);
const StepsProvider = ({
  value,
  children
}) => {
  const isError = value.state === "error";
  const isLoading = value.state === "loading";
  return jsx(StepsContext.Provider, {
    value: {
      ...value,
      isError,
      isLoading
    },
    children
  });
};
const CheckIcon = createIcon({
  viewBox: "0 0 14 14",
  path: jsx("g", {
    fill: "currentColor",
    children: jsx("polygon", {
      points: "5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"
    })
  })
});
createIcon({
  displayName: "CloseIcon",
  d: "M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
});
const WarningIcon = createIcon$1({
  d: "M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z",
  displayName: "WarningIcon"
});
const [StylesProvider, useStyles] = createContext({
  name: "StepsStyleContext",
  errorMessage: `useStepsStyles returned 'undefined'. Seems you forgot to wrap the components in "<Steps />" `
});
const useStepsStyles = useStyles;
const Steps = forwardRef((props, ref) => {
  const {
    className,
    activeStep,
    children,
    orientation: orientationProp,
    state,
    responsive,
    checkIcon,
    errorIcon,
    onClickStep,
    mobileBreakpoint,
    trackColor: trackColorProp,
    ...rest
  } = omitThemingProps(props);
  const trackColor = trackColorProp || useColorModeValue("gray.200", "gray.700");
  const childArr = React.Children.toArray(children);
  const stepCount = childArr.length;
  const renderHorizontalContent = () => {
    if (activeStep <= childArr.length) {
      return React.Children.map(childArr[activeStep], (node) => {
        if (!React.isValidElement(node))
          return;
        return React.Children.map(node.props.children, (childNode) => childNode);
      });
    }
    return null;
  };
  const [isMobile] = useMediaQuery(`(max-width: ${mobileBreakpoint || "768px"})`);
  const clickable = !!onClickStep;
  const orientation = isMobile && responsive ? "vertical" : orientationProp;
  const isVertical = orientation === "vertical";
  const styles = useMultiStyleConfig("Steps", {
    ...props,
    orientation,
    stepCount,
    isVertical,
    trackColor
  });
  const stepsStyles = {
    ...styles.root
  };
  return jsx(StylesProvider, {
    value: styles,
    children: jsxs(StepsProvider, {
      value: {
        activeStep,
        orientation,
        state,
        responsive,
        checkIcon,
        errorIcon,
        onClickStep,
        clickable,
        colorScheme: props.colorScheme,
        stepCount,
        trackColor,
        isVertical,
        variant: props.variant || "circles"
      },
      children: [jsx(chakra.div, {
        ref,
        __css: {
          justifyContent: stepCount === 1 ? "flex-end" : "space-between",
          flexDir: orientation === "vertical" ? "column" : "row",
          ...stepsStyles
        },
        className: cx("cui-steps", className),
        ...rest,
        children: React.Children.map(children, (child, i) => {
          var _a;
          const isCompletedStep = (_a = React.isValidElement(child) && child.props.isCompletedStep) != null ? _a : i < activeStep;
          const isLastStep = i === stepCount - 1;
          const isCurrentStep = i === activeStep;
          const stepProps = {
            index: i,
            isCompletedStep,
            isCurrentStep,
            isLastStep
          };
          if (React.isValidElement(child)) {
            return React.cloneElement(child, stepProps);
          }
          return null;
        })
      }), orientation === "horizontal" && renderHorizontalContent()]
    })
  });
});
Steps.defaultProps = {
  activeStep: 0,
  colorScheme: "green",
  orientation: "horizontal",
  responsive: true
};
const MotionFlex = motion(Flex);
const AnimatedWarningIcon = motion(WarningIcon);
const AnimatedSpan = motion(chakra.span);
const animationConfig = {
  transition: {
    duration: 0.25
  },
  exit: {
    scale: 0.5,
    opacity: 0
  },
  initial: {
    scale: 0.5,
    opacity: 0
  },
  animate: {
    scale: 1,
    opacity: 1
  }
};
const StepIcon = forwardRef((props, ref) => {
  const {
    icon,
    iconLabel,
    label
  } = useStepsStyles();
  const {
    isCompletedStep,
    isCurrentStep,
    isError,
    isLoading,
    isKeepError,
    icon: CustomIcon,
    index,
    checkIcon: CustomCheckIcon,
    errorIcon: CustomErrorIcon
  } = props;
  const labelStyles = {
    fontWeight: "medium",
    color: mode(`gray.900`, `gray.100`)(props),
    textAlign: "center",
    fontSize: "md",
    ...label
  };
  const Icon = React__default.useMemo(() => CustomIcon ? CustomIcon : null, [CustomIcon]);
  const ErrorIcon = React__default.useMemo(() => CustomErrorIcon ? CustomErrorIcon : null, [CustomErrorIcon]);
  const Check = React__default.useMemo(() => CustomCheckIcon ? CustomCheckIcon : CheckIcon, [CustomCheckIcon]);
  return React__default.useMemo(() => {
    if (isCompletedStep) {
      if (isError && isKeepError) {
        return jsx(AnimatedWarningIcon, {
          ...animationConfig,
          style: icon
        }, "icon");
      }
      return jsx(MotionFlex, {
        ...animationConfig,
        children: jsx(Check, {
          color: "white",
          style: icon
        })
      }, "check-icon");
    }
    if (isCurrentStep) {
      if (isError && ErrorIcon) {
        return jsx(MotionFlex, {
          ...animationConfig,
          children: jsx(ErrorIcon, {
            color: "white",
            style: icon
          })
        }, "error-icon");
      }
      if (isError) {
        return jsx(AnimatedWarningIcon, {
          ...animationConfig,
          style: icon
        }, "icon");
      }
      if (isLoading)
        return jsx(Spinner, {
          width: icon.width,
          height: icon.height
        });
    }
    if (Icon)
      return jsx(MotionFlex, {
        ...animationConfig,
        children: jsx(Icon, {
          style: icon
        })
      }, "step-icon");
    return jsx(AnimatedSpan, {
      ref,
      style: iconLabel,
      __css: labelStyles,
      ...animationConfig,
      children: (index || 0) + 1
    }, "label");
  }, [isCompletedStep, isCurrentStep, isError, isLoading, Icon, icon]);
});
const StepIconContainer = ({
  isCurrentStep,
  isCompletedStep,
  children,
  isError,
  isLoading: isLoadingProp
}) => {
  const {
    stepIconContainer
  } = useStepsStyles();
  const {
    clickable,
    isLoading: isLoadingContext
  } = useStepsContext();
  const isLoading = isLoadingProp || isLoadingContext;
  return jsx(chakra.div, {
    __css: stepIconContainer,
    className: "cui-steps__step-icon-container",
    "aria-current": isCurrentStep ? "step" : void 0,
    "data-invalid": dataAttr(isError && (isCurrentStep || isCompletedStep)),
    "data-highlighted": dataAttr(isCompletedStep),
    "data-clickable": dataAttr(clickable),
    "data-loading": dataAttr(isLoading && (isCurrentStep || isCompletedStep)),
    children: jsx(AnimatePresence, {
      mode: "wait",
      children
    })
  });
};
const StepLabel = ({
  isCurrentStep,
  opacity,
  label,
  description
}) => {
  const {
    labelContainer,
    label: labelStyles,
    description: descriptionStyles
  } = useStepsStyles();
  const shouldRender = !!label || !!description;
  return shouldRender ? jsxs(chakra.div, {
    "aria-current": isCurrentStep ? "step" : void 0,
    __css: labelContainer,
    children: [!!label && jsx(chakra.span, {
      __css: {
        opacity,
        ...labelStyles
      },
      children: label
    }), !!description && jsx(chakra.span, {
      __css: {
        opacity,
        ...descriptionStyles
      },
      children: description
    })]
  }) : null;
};
const HorizontalStep = React.forwardRef((props, ref) => {
  const {
    step,
    stepContainer
  } = useStepsStyles();
  const {
    isError,
    isLoading,
    onClickStep,
    variant,
    clickable,
    checkIcon: checkIconContext,
    errorIcon: errorIconContext
  } = useStepsContext();
  const {
    index,
    isCompletedStep,
    isCurrentStep,
    hasVisited,
    icon,
    label,
    description,
    isKeepError,
    state,
    checkIcon: checkIconProp,
    errorIcon: errorIconProp
  } = props;
  const localIsLoading = isLoading || state === "loading";
  const localIsError = isError || state === "error";
  const opacity = hasVisited ? 1 : 0.8;
  const highlighted = variant === "simple" ? isCompletedStep || isCurrentStep : isCompletedStep;
  const checkIcon = checkIconProp || checkIconContext;
  const errorIcon = errorIconProp || errorIconContext;
  return jsx(chakra.div, {
    "aria-disabled": !hasVisited,
    className: "cui-steps__horizontal-step",
    "data-highlighted": dataAttr(highlighted),
    "data-invalid": dataAttr(localIsError),
    "data-clickable": dataAttr(clickable),
    onClick: () => onClickStep == null ? void 0 : onClickStep(index || 0),
    ref,
    __css: step,
    children: jsxs(chakra.div, {
      className: "cui-steps__horizontal-step-container",
      __css: stepContainer,
      children: [jsx(StepIconContainer, {
        ...{
          ...props,
          isError: localIsError,
          isLoading: localIsLoading
        },
        children: jsx(StepIcon, {
          ...{
            index,
            isCompletedStep,
            isCurrentStep,
            isError: localIsError,
            isKeepError,
            isLoading: localIsLoading
          },
          icon,
          checkIcon,
          errorIcon
        })
      }), jsx(StepLabel, {
        label,
        description,
        ...{
          isCurrentStep,
          opacity
        }
      })]
    })
  });
});
const VerticalStep = React__default.forwardRef((props, ref) => {
  const {
    children,
    index,
    isCompletedStep,
    isCurrentStep,
    label,
    description,
    icon,
    hasVisited,
    state,
    checkIcon: checkIconProp,
    errorIcon: errorIconProp
  } = props;
  const {
    checkIcon: checkIconContext,
    errorIcon: errorIconContext,
    isError,
    isLoading,
    variant,
    onClickStep,
    clickable
  } = useStepsContext();
  const {
    step,
    stepIconContainer
  } = useStepsStyles();
  const opacity = hasVisited ? 1 : 0.8;
  const localIsLoading = isLoading || state === "loading";
  const localIsError = isError || state === "error";
  const highlighted = variant === "simple" ? isCompletedStep || isCurrentStep : isCompletedStep;
  const checkIcon = checkIconProp || checkIconContext;
  const errorIcon = errorIconProp || errorIconContext;
  return jsxs(chakra.div, {
    ref,
    className: "cui-steps__vertical-step",
    "data-highlighted": dataAttr(highlighted),
    "data-clickable": dataAttr(clickable),
    "data-invalid": dataAttr(localIsError),
    onClick: () => onClickStep == null ? void 0 : onClickStep(index || 0),
    __css: step,
    children: [jsxs(Flex, {
      className: "cui-steps__vertical-step-container",
      children: [jsx(StepIconContainer, {
        ...{
          isLoading: localIsLoading,
          isError: localIsError,
          ...props
        },
        children: jsx(StepIcon, {
          ...{
            index,
            isError: localIsError,
            isLoading: localIsLoading,
            isCurrentStep,
            isCompletedStep
          },
          icon,
          checkIcon,
          errorIcon
        })
      }), jsx(StepLabel, {
        label,
        description,
        ...{
          isCurrentStep,
          opacity
        }
      })]
    }), jsx(chakra.div, {
      className: "cui-steps__vertical-step-content",
      __css: {
        minH: "8px",
        ps: `calc(${stepIconContainer.width})`
      },
      children: jsx(Collapse, {
        style: {
          width: "100%"
        },
        in: isCurrentStep,
        children: (isCurrentStep || isCompletedStep) && children
      })
    })]
  });
});
const Step = forwardRef((props, ref) => {
  const {
    children,
    description,
    icon,
    state,
    checkIcon,
    errorIcon,
    index,
    isCompletedStep,
    isCurrentStep,
    isLastStep,
    isKeepError,
    label
  } = props;
  const {
    isVertical,
    isError,
    isLoading,
    clickable
  } = useStepsContext();
  const hasVisited = isCurrentStep || isCompletedStep;
  const sharedProps = {
    isLastStep,
    isCompletedStep,
    isCurrentStep,
    index,
    isError,
    isLoading,
    clickable,
    label,
    description,
    hasVisited,
    icon,
    isKeepError,
    checkIcon,
    state,
    errorIcon
  };
  const renderStep = () => {
    switch (isVertical) {
      case true:
        return jsx(VerticalStep, {
          ref,
          ...sharedProps,
          children
        });
      default:
        return jsx(HorizontalStep, {
          ref,
          ...sharedProps
        });
    }
  };
  return renderStep();
});
function useSteps({ initialStep }) {
  const [activeStep, setActiveStep] = React.useState(initialStep);
  const nextStep = () => {
    setActiveStep((prev) => prev + 1);
  };
  const prevStep = () => {
    setActiveStep((prev) => prev - 1);
  };
  const reset = () => {
    setActiveStep(initialStep);
  };
  const setStep = (step) => {
    setActiveStep(step);
  };
  return { nextStep, prevStep, reset, setStep, activeStep };
}
const getCirclesHorizontalBaseStyles = ({ colorScheme: c, trackColor: t, clickable }) => ({
  display: "flex",
  alignItems: "center",
  "&:not(:last-child)": {
    flex: 1
  },
  "&:not(:last-child):after": {
    content: '""',
    flex: 1,
    height: "2px",
    backgroundColor: `${t}`,
    mx: 3,
    transition: "background-color .2s ease"
  },
  _highlighted: {
    "&:not(:last-child):after": {
      backgroundColor: `${c}.500`,
      backgroundPosition: "left",
      transition: "background-position .2s ease-in-out"
    }
  },
  _hover: {
    cursor: clickable ? "pointer" : "default"
  }
});
const getCirclesAlternateBaseStyles = ({ colorScheme, trackColor, clickable, inactiveColor, stepHeight }) => ({
  ...getCirclesHorizontalBaseStyles({ colorScheme, trackColor, clickable }),
  justifyContent: "flex-start",
  flexDirection: "column",
  flex: 1,
  "&:not(:last-child):after": {
    content: '""',
    backgroundColor: trackColor || inactiveColor,
    height: "2px",
    order: -1,
    insetInline: "50%",
    position: "relative",
    top: `calc(${stepHeight} / 2)`,
    transition: "background-color .2s ease",
    width: `calc(100% - ${stepHeight} - calc(8px * 2))`
  }
});
const getCirclesVerticalBaseStyles = ({ stepHeight, colorScheme, trackColor, clickable }) => ({
  position: "relative",
  gap: 4,
  pb: 4,
  display: "flex",
  flexDir: "column",
  "--size": stepHeight,
  "--gap": "8px",
  _highlighted: {
    pb: 4,
    "&:not(:last-child):after": {
      backgroundColor: `${colorScheme}.500`,
      backgroundPosition: "left",
      transition: "background-position .2s ease-in-out"
    }
  },
  "&:not(:last-child):after": {
    content: '""',
    backgroundColor: trackColor,
    bottom: "var(--gap)",
    insetInline: "calc(var(--size) / 2)",
    position: "absolute",
    top: "calc(var(--size) + var(--gap))",
    width: "2px"
  },
  _hover: {
    cursor: clickable ? "pointer" : "default"
  }
});
const getCirclesStepIconContainerBaseStyles = ({ inactiveColor, activeColor }) => ({
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  bg: inactiveColor,
  borderColor: inactiveColor,
  _activeStep: {
    borderColor: activeColor,
    _invalid: {
      bg: "red.400",
      borderColor: "red.400"
    }
  },
  _highlighted: {
    bg: activeColor,
    borderColor: activeColor,
    _invalid: {
      bg: "red.400",
      borderColor: "red.400"
    }
  },
  "&[data-clickable]:hover": {
    borderColor: activeColor
  }
});
const parts = anatomy("steps").parts("description", "icon", "iconLabel", "label", "labelContainer", "step", "stepContainer", "stepIconContainer", "root");
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);
const baseStyleIcon = {
  strokeWidth: "2px"
};
const baseStyleLabel = (props) => ({
  color: mode(`gray.900`, `gray.100`)(props),
  textAlign: "center",
  fontSize: "md",
  fontWeight: "bold"
});
const baseStyleDescription = (props) => ({
  color: mode(`gray.800`, `gray.200`)(props),
  mt: "-2px",
  textAlign: "center",
  fontSize: "sm"
});
const baseStyleRoot = ({ stepCount, orientation }) => ({
  justifyContent: stepCount === 1 ? "flex-end" : "space-between",
  flexDir: orientation === "vertical" ? "column" : "row",
  fontFamily: "heading",
  textAlign: "center",
  width: "100%",
  display: "flex",
  flex: 1,
  flexWrap: "wrap"
});
const baseStyleStepContainer = () => ({
  display: "flex",
  alignItems: "center"
});
const baseStyleStep = {
  display: "flex",
  position: "relative"
};
const baseStyleLabelContainer = () => {
  return {
    display: "flex",
    flexDir: "column",
    justifyContent: "center"
  };
};
const baseStyle = definePartsStyle((props) => ({
  description: baseStyleDescription(props),
  icon: baseStyleIcon,
  iconLabel: baseStyleLabel(props),
  label: baseStyleLabel(props),
  labelContainer: baseStyleLabelContainer(),
  step: baseStyleStep,
  stepContainer: baseStyleStepContainer(),
  root: baseStyleRoot(props)
}));
const sizes = {
  sm: definePartsStyle({
    stepIconContainer: {
      width: "32px",
      height: "32px",
      borderWidth: "2px"
    },
    icon: {
      width: "14px",
      height: "14px"
    },
    label: {
      fontWeight: "medium",
      textAlign: "center",
      fontSize: "sm"
    },
    description: {
      fontWeight: "300",
      textAlign: "center",
      fontSize: "xs"
    }
  }),
  md: definePartsStyle({
    stepIconContainer: {
      width: "40px",
      height: "40px",
      borderWidth: "2px"
    },
    icon: {
      width: "18px",
      height: "18px"
    },
    label: {
      fontWeight: "medium",
      textAlign: "center",
      fontSize: "md"
    },
    description: {
      fontWeight: "300",
      textAlign: "center",
      fontSize: "sm"
    }
  }),
  lg: definePartsStyle({
    stepIconContainer: {
      width: "48px",
      height: "48px",
      borderWidth: "2px"
    },
    icon: {
      width: "22px",
      height: "22px"
    },
    label: {
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "lg"
    },
    description: {
      fontWeight: "300",
      textAlign: "center",
      fontSize: "md"
    }
  })
};
const variantCircles = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  const inactiveColor = mode("gray.200", "gray.700")(props);
  const activeColor = `${c}.500`;
  const stepHeight = sizes[props.size].stepIconContainer.height;
  return {
    stepIconContainer: getCirclesStepIconContainerBaseStyles({
      inactiveColor,
      activeColor
    }),
    labelContainer: {
      flexDir: "column",
      alignItems: "flex-start",
      ms: 3
    },
    step: props.orientation === "horizontal" ? getCirclesHorizontalBaseStyles({ ...props, stepHeight }) : getCirclesVerticalBaseStyles({ ...props, stepHeight })
  };
});
const variantCirclesAlt = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  const inactiveColor = mode("gray.200", "gray.700")(props);
  const activeColor = `${c}.500`;
  const stepHeight = sizes[props.size].stepIconContainer.height;
  return {
    stepIconContainer: {
      ...getCirclesStepIconContainerBaseStyles({
        inactiveColor,
        activeColor
      }),
      flexDir: "column"
    },
    stepContainer: {
      flexDir: "column"
    },
    labelContainer: {
      flexDir: "column",
      alignItems: props.orientation === "horizontal" ? "center" : "flex-start",
      ms: props.orientation === "horizontal" ? 0 : 3
    },
    step: props.orientation === "horizontal" ? getCirclesAlternateBaseStyles({ ...props, stepHeight }) : getCirclesVerticalBaseStyles({ ...props, stepHeight })
  };
});
const variantSimple = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  const inactiveColor = mode("gray.200", "gray.700")(props);
  const activeColor = `${c}.500`;
  const isVertical = props.orientation === "vertical";
  return {
    stepIconContainer: {
      opacity: 0,
      pointerEvents: "none",
      borderWidth: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      _loading: {
        opacity: 1,
        pointerEvents: "auto"
      },
      _invalid: {
        opacity: 1,
        pointerEvents: "auto"
      }
    },
    stepContainer: {
      flex: 1,
      py: 2,
      flexDir: "row-reverse",
      alignItems: "center",
      justifyContent: "space-between"
    },
    label: {
      mx: 0,
      fontWeight: "bold"
    },
    labelContainer: {
      flexDir: "column",
      alignItems: "flex-start"
    },
    description: {
      mx: 0
    },
    root: {
      gap: 4
    },
    step: {
      flexDir: "column",
      position: "relative",
      flex: 1,
      borderTopWidth: isVertical ? 0 : 3,
      borderColor: (props == null ? void 0 : props.trackColor) || inactiveColor,
      "&:not(:last-child):after": {
        display: "none"
      },
      transition: "border-color .2s ease",
      _activeStep: {
        _invalid: {
          borderColor: "red.400",
          "& .cui-steps__vertical-step-container": {
            borderColor: "red.400"
          }
        }
      },
      _highlighted: {
        transition: "border-color .2s ease",
        borderColor: activeColor,
        "& .cui-steps__vertical-step-container": {
          borderColor: activeColor
        },
        _invalid: {
          borderColor: "red.400",
          "& .cui-steps__vertical-step-container": {
            borderColor: "red.400"
          }
        }
      },
      "& .cui-steps__vertical-step-container": {
        borderInlineStartWidth: 3,
        py: 2,
        ps: 3,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row-reverse",
        _invalid: {
          borderColor: "red.400",
          "& .cui-steps__vertical-step-container": {
            borderColor: "red.400"
          }
        }
      },
      "& .cui-steps__vertical-step-content": {
        ps: 0,
        py: 0
      },
      "&[data-clickable]:hover": {
        borderColor: activeColor,
        cursor: "pointer",
        "& .cui-steps__vertical-step-container": {
          borderColor: activeColor
        }
      }
    }
  };
});
const variants = {
  circles: variantCircles,
  "circles-alt": variantCirclesAlt,
  simple: variantSimple
};
const StepsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: "md",
    colorScheme: "blue",
    variant: "circles"
  }
});
export { Step, Steps, StepsTheme, useSteps };
