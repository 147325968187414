import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import debounce from 'src/utilities/debounce';

const useAssetConfirmHook = (assets, upsert) => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    const newAssets = assets.map((item) => ({
      ...item,
      processed: ASSET_PROCESSED_STATUS.CONFIRMED,
    }));
    debounce(() => upsert(newAssets), 50);
    enqueueSnackbar('All assets confirmed!', { variant: 'success' });
  }, [assets, upsert]);
};

export default useAssetConfirmHook;
