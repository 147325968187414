import { selectAuthorizationToken } from 'src/redux/containers/Authorization';
import { selectCompanyId } from 'src/redux/containers/Companies';
import { selectAccountId } from 'src/redux/containers/User';
import { store } from 'src/redux/store';
import { checkResponseStatus } from './checkResponseStatus';

const fetchPost = async (url, headers = {}, body = {}) => {
	try {
		const state = store.getState();
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': selectAuthorizationToken(state),
				'SW-Scope': JSON.stringify({
					'Company': selectCompanyId(state),
					'AccountId': selectAccountId(state),
				}),
				...headers,
			},
			body: JSON.stringify(body),
		});
		checkResponseStatus(response.status);
		const jsonResponse = await response.json();
		if (!response.ok) {
			throw jsonResponse;
		}
		return jsonResponse;
	} catch (e) {
		console.log('Caught exception', e);
		throw e;
	}
};

export default fetchPost;
