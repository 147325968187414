import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Body3 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import LiquidIcon from 'src/components/icons/Liquid';
import { MEASURE } from 'src/consts/measures';
import useMeasures from 'src/utilities/hooks/useMeasures';

function LiquidDisplay({ label = 'Liquid', empty, value, la, force= false }) {
	const { to, getMeasure } = useMeasures();

	const liq = useMemo(() => getMeasure(MEASURE.l_m), [getMeasure]);

	const fv = force ? 0 : null;

	const liqVal = useMemo(() => (value ? Number(to(value, MEASURE.l_m)) : fv), [to, value]);
	const laVal = useMemo(() => (la ? Number(to(la, MEASURE.l_m)) : fv), [to, value]);

	if (empty) {
		return (
			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					gap: '0.25rem',
				}}
			>
				<LiquidIcon style={{ width: '12px', height: '14px' }} />
				<Body3>
					<Bolder>EMPTY</Bolder>
				</Body3>
			</Box>
		);
	}

	if (!force && !value) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LiquidIcon style={{ width: '12px', height: '14px' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{liqVal?.toFixed(2)}
				{la && (
					<>
						{' | '}
						<Bolder>{laVal?.toFixed(2)}</Bolder>
					</>
				)}
				<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{liq.value}</Bolder>
			</Body3>
		</Box>
	);
}

export default LiquidDisplay;
