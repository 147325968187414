import { useSearchParams } from 'react-router-dom';
import withExtendedDialogCard from 'src/components/Cards/ExtendedDialogCard';
import ReportAssetTable from 'src/components/InventorySelector/ReportAssetTable';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function AssetsTable() {
	usePageTitle('Asset List');
	let [searchParams] = useSearchParams();

	const sources = searchParams.get('sources');
	const cv = Buffer.from(`${sources}`, 'base64').toString('utf-8');
	const assetIds = JSON.parse(cv ?? []);
	// console.log('asset-table', cv, assetIds);

	return <ReportAssetTable assetIds={assetIds} />;
}

export default withAppLayout(withExtendedDialogCard(AssetsTable));
