import { MEASURE } from 'src/consts/measures';
import * as yup from 'yup';

export default function liquidLevelFields(level = {}, measures = {}) {
	const { enable = false, bl = '', la = '', abv = '', tcf = '1.000', action_date, filled_date = Date.now() } = level;

	// The to function here will convert to imperial based on the passed in measure (l_m - medium liquid measure)
	const { to: cto, imperial } = measures;

	const avbMinValue = imperial ? 2 : 1;
	const avbMaxValue = imperial ? 180 : 90;

	return {
		enable: [enable, yup.bool()],
		bl: [
			bl ? Number(cto(bl, MEASURE.l_m) ?? 0)?.toFixed(2) : '',
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0, 'Invalid!').required('Bulk is required!'),
				otherwise: (schema) => schema,
			}),
		],
		la: [
			la ? Number(cto(la, MEASURE.l_m) ?? 0)?.toFixed(2) : '',
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0, 'Invalid!'),
				otherwise: (schema) => schema,
			}),
		],
		abv: [
			abv ? Number(cto(abv, MEASURE.alc) ?? 0)?.toFixed(2) : '',
			yup.number().when('enable', {
				is: true,
				then: (schema) =>
					schema
						.min(avbMinValue, 'Invalid!')
						.max(avbMaxValue, 'Invalid!')
						.required('Strength is required!'),
				otherwise: (schema) => schema,
			}),
		],
		tcf: [
			tcf ?? '1.000',
			yup.number().when('enable', {
				is: true,
				then: (schema) =>
					schema
					.min(0.5, 'Invalid!')
					.max(1.5, 'Invalid!'),
				otherwise: (schema) => schema,
			}),
		],
		action_date: [
			action_date ?? filled_date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.required('Date is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
