import { useCallback, useEffect, useState } from 'react';
import { getAssets } from 'src/services/asset.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params) => getAssets(params);
export const [useAssetsLazyQuery, _] = genServiceCallHooks(serviceFunction);

export const useAssetsLazyQueryPaginated = (params) => {
	const [fetchAssets, { isLoading }] = useAssetsLazyQuery();
	const [pages, setPages] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);

	const load = useCallback(
		async (p) => {
			try {
				const res = await fetchAssets(p);
				setPages((v) => [...v, res]);
			} catch (e) {
				setPages([]);
				setCurrentPage(0);
			}
		},
		[fetchAssets],
	);

	useEffect(() => {
		setPages([]);
		setCurrentPage(0);
		load(params).finally();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const prev = useCallback(() => {
		console.log('Prev', currentPage);
		if (currentPage) {
			setCurrentPage(currentPage - 1);
		}
	}, [currentPage]);

	const next = useCallback(async () => {
		if (currentPage + 1 < pages.length) {
			setCurrentPage(currentPage + 1);
		} else {
			if (pages.length > 0) {
				const cp = pages[pages.length - 1]?.page;
				if (cp) {
					await load({ ...params, page: cp }).finally();
					setCurrentPage(currentPage + 1);
				}
			}
		}
	}, [currentPage, pages, load, params]);

	return [
		isLoading,
		pages[currentPage] && pages[currentPage].items
			? pages[currentPage].items
			: [],
		currentPage > 0,
		prev,
		currentPage + 1 < pages.length ||
			(pages.length > 0 && pages[pages.length - 1]?.page),
		next,
	];
};
