import { Google } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import FilledButton from 'src/components/Buttons/FilledButton';
import { verifySocials } from 'src/services/auth.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoading from 'src/utilities/hooks/useLoadingHook';

function SocialRegister({ invite, showRegistration }) {
  const [ ,setLoading] = useLoading();

  const [AlertView, { setAlert }] = useAlertView();

  const login = async (type) => {
    setLoading(true);

    const auth = getAuth();
    auth.useDeviceLanguage();

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    // const defaultEmail = window?.localStorage?.getItem('social-user');
    // provider.setCustomParameters({
    //   'login_hint': defaultEmail
    // });

    try {
      // Google account select..
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log('Result', result, credential);
      const { user } = result;
      const { email, stsTokenManager } = user;

      // setIdentity([type, email]);

      // Hit the back-end to authenticate this user
      const response = await verifySocials({
        type,
        token: stsTokenManager.accessToken,
        invite,
      });
      console.log('Response', response);

      // Response contains signed identity and name
      showRegistration({ type, ident: email, ...response });
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      console.log('Error', error, credential, errorCode, errorMessage);

      if (errorCode) {
        switch (errorCode) {
          case 'auth/popup-closed-by-user': {
            setAlert({
              severity: 'error',
              title: 'Failed to register',
              content: 'You did not select an account!',
            });
            break;
          }
          default: {
            setAlert({
              severity: 'error',
              title: 'Failed to register',
              content: `There was an error registering, see more details here [${errorCode}, ${errorMessage}]!`,
            });
            break;
          }
        }
      } else {
        setAlert({
          severity: 'error',
          title: 'Failed to register',
          content: error.message ?? 'There was a problem registering, please try again!',
        });
      }
    }

    setLoading(false);
  };

  return (
    <Stack spacing={2}>
      <AlertView />
      <FilledButton icon={<Google />} iconPosition="start" label="Google" handleClick={() => login('google')} />
    </Stack>
  );
}

export default SocialRegister;
