import { Add, Edit } from '@mui/icons-material';
import { Alert, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SensorModal from 'src/components/admin/Sensors/SensorModal';
import FilledButton from 'src/components/Buttons/FilledButton';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikSelect, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import FormWrapper from 'src/components/Layout/FormWrapper';
import { H5, Overline } from 'src/components/Typography';
import { useMemo, useState } from 'react';
import { COMP, ID } from 'src/utilities/comp';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useGatewayMutation from 'src/utilities/hooks/useGatewayMutation';
import useItemListManager from 'src/utilities/hooks/useItemListManager';
import * as yup from 'yup';
// interface GatewayUpsertRequest extends Gateway {
//   status: 'active' | 'inactive';
//   thing_id: string; // aws iot thing id
//   gateway_id?: string; // optional, can be used on create
// }

// export interface Sensor extends DynamoEntry {
//   // id: companyPK(company_id);
//   // path: /sensor/0013A2004237EB6A
//   thing_id: string; // aws
//   gateway_id: string; // /gateway/40:F5:20:BA:0F:90 TODO: we will need to make index here to be able to update sensor
// }

function SensorItemItem({ item, edit }) {
	const { path = '' } = item;
	return (
		<Stack className='listItem'>
			<Box className='listContent'>
				<Stack className='listForm' direction='row' spacing='3rem'>
					<Stack alignItems='center'>
						<H5>{path.replace('/sensor/', '')}</H5>
						<Overline>Sensor id</Overline>
					</Stack>
				</Stack>
			</Box>
			<Stack className='listButtons'>
				<IconCircleButton onClick={edit}>
					<Edit />
				</IconCircleButton>
			</Stack>
		</Stack>
	);
}

function Gateway({ entity, onSave, cancel }) {
	const [AlertView, { setAlert }] = useAlertView();
	const [mutate, { isLoading }] = useGatewayMutation();

	const editing = useMemo(() => !!entity?.path, [entity]);
	const title = useMemo(() => (editing ? 'Update Gateway' : 'New Gateway'), [editing]);
	const sensorList = useMemo(() => entity?.sensors ?? [], [entity]);
	const statusOptions = useMemo(
		() => [
			{ label: 'Active', value: 'active' },
			{ label: 'Inactive', value: 'inactive' },
		],
		[],
	);

	const [sensorState, , , upsertSensor] = useItemListManager(ID.sensor, COMP.sensor, sensorList);
	// useEffect(() => debounce(() => initSensors(sensorList), 50), [sensorList]);

	const [sensor, setSensor] = useState(null);

	const changeSet = useMemo(
		() => ({
			path: [
				entity?.path?.replace('/gateway/', '') ?? '',
				yup.string().min(1).required('Gateway address is required!'),
			],
			status: [entity?.status ?? 'active', yup.string().required('Please select a status!')],
			thing_id: [entity?.thing_id ?? '', yup.string().required('Thing id is required!')],
		}),
		[entity?.path, entity?.status, entity?.thing_id],
	);

	const handleSubmit = async (values, { resetForm }) => {
		try {
			const response = await mutate(values);
			onSave(response);
			resetForm({ values: {} });
		} catch (err) {
			setAlert({
				severity: 'error',
				title: editing ? 'Failed to Update' : 'Failed to Add',
				content: err.message,
			});
		}
	};

	return (
		<>
			<FormWrapper>
				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack className='form' spacing='2rem'>
						<H5>{title}</H5>
						<FlexBox alignItems='top'>
							<FormikTextField
								disabled={editing}
								label='Gateway address'
								name='path'
								fullWidth
							/>
						</FlexBox>
						<FlexBox alignItems='top'>
							<FormikTextField label='Iot thing id' name='thing_id' fullWidth />
							<FormikSelect
								label='Gateway Status'
								name='status'
								options={statusOptions}
								fullWidth
							/>
						</FlexBox>

						<AlertView />

						<Box className='action-buttons'>
							{cancel && (
								<TextButton
									size='small'
									handleClick={cancel}
									color='secondary'
									disabled={isLoading}
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small' disabled={isLoading}>
								Save
							</FilledButton>
						</Box>

						{editing && (
							<>
								<Divider className='divider' />
								<FlexBox>
									<H5>Sensors</H5>
									<Stack direction='row' justifyContent='flex-end'>
										<IconCircleButton
											onClick={() =>
												setSensor([
													sensorState && sensorState.length > 0
														? sensorState[sensorState.length - 1]
														: ['', 0, 0, 0],
													null,
												])
											}
										>
											<Add />
										</IconCircleButton>
									</Stack>
								</FlexBox>
							</>
						)}

						<Stack spacing={0} className='list'>
							{sensorState.length === 0 && (
								<Alert severity='warning'>No sensors configured.</Alert>
							)}
							{sensorState.map(
								(item, index) =>
									!sensor && (
										<SensorItemItem
											item={item}
											edit={() => setSensor(item)}
											key={`${item?.path ?? index}`}
										/>
									),
							)}
						</Stack>
					</Stack>
				</FormikForm>
			</FormWrapper>

			{!!sensor && (
				<SensorModal
					gateway_id={entity?.path}
					open={!!sensor}
					entity={sensor}
					upsert={upsertSensor}
					onClose={() => setSensor(null)}
				/>
			)}
		</>
	);
}

export default Gateway;
