import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import DefectItem from 'src/components/Defect/DefectItem';
import DefectModal from 'src/components/Defect/DefectModal';
import DefectAssetModal from 'src/components/InventorySelector/DefectAssetModal';
import useSourceInventoryHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { FlexBox } from 'src/components/Layout/FlexBox';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import { H5 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';
import debounce from 'src/utilities/debounce';
import { useAssetLazyQuery } from 'src/utilities/hooks/useAssetQuery';

function ReportDefectForm() {
	const [defect, setDefect] = useState(null);
	const [defects, setDefects] = useState([]);
	let [searchParams] = useSearchParams();
	const asset = searchParams.get('asset');
	const [fetchAsset] = useAssetLazyQuery();

	const [
		,
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Assets',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.container,
				ASSET_TYPES.filling_tank,
			],
		},
		assetFilter: (asset) =>
			(asset.child_assets && asset.child_assets.length > 0) ||
			(asset.sku_storage && asset.sku_storage.length > 0),
		AssetModal: DefectAssetModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => {
			return {
				...requestIdFields(initialValues),
			};
		},
		[],
	);

	const upsertDefect = useCallback(
		(entry, index) => {
			console.log('Upsert', entry, index, defects);
			const v = [...defects];
			if (index !== null && index !== undefined) {
				// updating
				v[index] = entry;
			} else {
				v.push(entry);
			}
			// v.sort((l, r) => l[0].localeCompare(r[0]));
			debounce(() => setDefects(v), 50);
		},
		[defects],
	);

	const removeDefect = useCallback(
		(index) => {
			const v = [...defects];
			if (index !== null && index !== undefined) {
				// removing
				v.splice(index, 1);
			}
			debounce(() => setDefects(v), 50);
		},
		[defects],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			setDefects(entity?.properties?.defects ?? []);
		},
		[initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sources: sourceAssets,
			properties: { defects },
		}),
		[defects, sourceAssets],
	);

	useEffect(() => {
		if (asset) {
			fetchAsset(asset).then((a) => {
				debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
			});
		}
	}, [asset, initSourceAssets, fetchAsset]);

	return (
		<>
			<RequestForm
				requestLabel='Defect Report'
				requestType={REQUEST_TYPES.report_defect}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
			>
				<SourceInventory />
				<FlexBox>
					<H5>Defects</H5>
					<Stack direction='row' justifyContent='flex-end'>
						<IconCircleButton onClick={() => setDefect([])}>
							<Add />
						</IconCircleButton>
					</Stack>
				</FlexBox>
				<Stack spacing={0} className='list'>
					{defects.map((d, i) => (
						<DefectItem
							index={i}
							item={d}
							remove={() => removeDefect(i)}
							edit={() => setDefect([d, i])}
							key={`${i}`}
						/>
					))}
				</Stack>
			</RequestForm>
			<DefectModal
				open={!!defect}
				item={defect}
				upsert={upsertDefect}
				onClose={() => setDefect(null)}
			/>
			<SourceModals />
		</>
	);
}

export default withAppLayout(ReportDefectForm, { title: 'Report Defect' });
