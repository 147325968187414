import { Stack } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import PasswordRegister from 'src/components/Auth/PasswordRegister';
import { Body3 } from 'src/components/Typography';
import styles from './auth.styles';

function Register() {
  return (
    <Stack spacing={4} sx={styles} className="root">
      <PasswordRegister />

      <Body3 sx={{ textAlign: 'center' }}>
        By continuing, you are indicating that you accept our
        {' '}
        <Link to="https://metacask.com/terms">Terms of Service</Link>
        {' '}
        and
        {' '}
        <Link to="https://metacask.com/privacy">Privacy Policy</Link>
        {' '}
        .
      </Body3>
    </Stack>
  );
}

export default Register;
