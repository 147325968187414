/* eslint-disable react/no-unescaped-entities */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'src/utilities/debounce';
import { useCreateTemplateMappingMutation } from 'src/utilities/hooks/useCreateTemplateMappingMutation';
import { useListTemplateMappingQuery } from 'src/utilities/hooks/useListTemplateMappingQuery';

export function MappingInput({ assetType, mappings, loadMapping }) {
	const [save, { isLoading: isSaving }] = useCreateTemplateMappingMutation();

	const { enqueueSnackbar } = useSnackbar();
	const {
		data: options = [],
		refetch,
		isLoading,
	} = useListTemplateMappingQuery({ asset_type: assetType });

	const [value, setValue] = useState({});
	const [inputValue, setInputValue] = useState('');

	const handleSave = useCallback(() => {
		const params = {
			template_name: inputValue,
			asset_type: assetType,
			properties: mappings,
		};
		console.log('Saving template', params, assetType);
		save(params)
			.then((v) => {
				console.log('Saved template', v);
				setValue(v);
				enqueueSnackbar(`${inputValue} mapping saved!`, { variant: 'success' });
			})
			.catch((e) => {
				enqueueSnackbar(
					`${inputValue} mapping could not be saved due to: ${e.message}!`,
					{ variant: 'error' }
				);
			});
	}, [mappings, enqueueSnackbar, inputValue, assetType, save]);

	const handleInputChange = (event, newValue) => setInputValue(newValue);

	const handleSelectChange = useCallback((event, newValue) => {
		setValue(newValue);
		event.preventDefault();
	}, []);

	useEffect(() => {
		if (value?.data) {
			loadMapping(value?.data);
			// dispatch(loadMapping(value?.data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		console.log('Input value change', inputValue);
		if (inputValue && inputValue.length > 2) {
			debounce(
				() => refetch({ asset_type: assetType, template_name: inputValue }),
				50
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue]);

	return (
		<Box>
			<Autocomplete
				disablePortal
				loading={isLoading || isSaving}
				className="autocomplete"
				noOptionsText={
					<ButtonBase variant="text" onClick={handleSave} size="small">
						Save mapping as "{inputValue}"
					</ButtonBase>
				}
				value={value}
				onChange={handleSelectChange}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				options={options}
				getOptionLabel={(option) => option?.name ?? ''}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Mapping Name"
						size="small"
						variant="outlined"
					/>
				)}
			/>
		</Box>
	);
}
