import { getSKU } from 'src/services/sku.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';

const serviceFunction = (params) => getSKU(params);
export const [useGetSKULazyQuery, useGetSKUQuery] = genServiceCallHooks(
	serviceFunction,
	{
		useLoadingHook,
	},
);

export default useGetSKUQuery;
