import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { Body1, Body3, H4, Subtitle1 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import { REPORT_STATUS } from 'src/consts/reports';
import { mt2 } from 'src/styles/margins';
import useGetReportId from 'src/utilities/hooks/useGetReportId';
import useReportQuery from 'src/utilities/hooks/useReportQuery';
import { useReportStatusLazyQuery } from 'src/utilities/hooks/useReportStatusQuery';

const styles = {
	marginTop: '1rem',
	border: '1px solid rgba(0,0,0,0.15)',
	borderRadius: '5px',
	padding: '0.5rem',
};

function ReportQuantity({
	label = 'Adjustment Stock',
	cases = 0,
	casks = 0,
	litres = 0,
	reason,
}) {
	return (
		<Box style={styles}>
			<Subtitle1>{label}</Subtitle1>
			<FlexBox>
				<Body3>
					<Bolder>Cases:</Bolder> {`${cases} | `}
					<Bolder>Casks: </Bolder> {`${casks} | `}
					<Bolder>LPA: </Bolder> {`${litres}`}
					{!!reason && ` | ${(<Bolder>Reason: </Bolder>)}${reason}`}
				</Body3>
			</FlexBox>
		</Box>
	);
}

function MonthlyReport() {
	const id = useGetReportId();
	const { data: reportData, refetch: refetchReportDetails } = useReportQuery(
		{ path: id },
		{ skip: !id },
	);
	const [refetchReportStatus, { isLoading: isLoadingStatus }] =
		useReportStatusLazyQuery({ path: id });

	const getLatestStatus = useCallback(async () => {
		const response = await refetchReportStatus();
		if (response?.status) refetchReportDetails();
	}, []);

	return (
		<Stack spacing={3} sx={{ ...mt2 }}>
			{reportData?.report_status && (
				<FlexBox spacing={2} alignItems='top'>
					<H4>Report Status:</H4>
					<Body1>{reportData?.report_status?.toUpperCase()}</Body1>
				</FlexBox>
			)}
			<ReportQuantity label='Opening Stock' {...reportData?.opening_stock} />
			<ReportQuantity label='Closing Stock' {...reportData?.closing_stock} />
			<ReportQuantity label='Adjustment' {...reportData?.adjustment} />
			<ReportQuantity
				label='Receipts From Importation'
				{...reportData?.receipts_from_importation}
			/>
			<ReportQuantity
				label='Receipts from UK and Other Warehouses'
				{...reportData?.receipts_from_uk_and_other_warehouses}
			/>
			<ReportQuantity
				label='Gains In Storage'
				{...reportData?.gains_in_storage}
			/>
			<ReportQuantity
				label='Increases From Operations'
				{...reportData?.increases_from_operations}
			/>
			<ReportQuantity
				label='Removals to Home Use'
				{...reportData?.removals_to_home_use}
			/>
			<ReportQuantity
				label='Removals to Exportation'
				{...reportData?.removals_to_exportation}
			/>
			<ReportQuantity
				label='Removals to other UK Warehouses'
				{...reportData?.receipts_from_uk_and_other_warehouses}
			/>
			<ReportQuantity
				label='Removals to other Duty Free Uses'
				{...reportData?.removals_to_other_duty_free_uses}
			/>
			<ReportQuantity
				label='Losses in Storage'
				{...reportData?.losses_in_storage}
			/>
			<ReportQuantity
				label='Losses from Operations'
				{...reportData?.losses_from_operations}
			/>

			{reportData?.report_status === REPORT_STATUS.PENDING && (
				<FilledButton
					label='Get Report Latest Status'
					handleClick={getLatestStatus}
					loading={isLoadingStatus}
				/>
			)}
		</Stack>
	);
}

export default withAppLayout(withDialogCard(MonthlyReport), {
	title: 'Monthly Report',
});
