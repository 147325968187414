/* eslint-disable react-hooks/rules-of-hooks */
import { shareWithPartner } from 'src/services/company.service';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import { useServiceCallHook } from './genHooks';

/** used to create sharable link */
export const useShareWithPartnerMutation = useServiceCallHook(
	shareWithPartner,
	{ useLoadingHook },
);
