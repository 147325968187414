import dayjs from 'dayjs';
import { ASSET_TYPES } from 'src/consts/asset';
import { BATCH_TYPE } from 'src/consts/batch';
import { DEFAULT_CURRENCY } from 'src/consts/config';
import { REQUEST_TYPES_REVERSE } from 'src/consts/requests';
import { TASK_TABLE_STATUS, TASK_TYPES } from 'src/consts/tasks';

export function toDate({ date = Date.now(), format = 'DD/MM/YYYY' }) {
	return dayjs(date).format(format);
}

export function toUniqueLocationId({ facility_id = '', row = '', level = '' }) {
	if (facility_id && row && level) {
		return `${facility_id}/${row}/${level}`;
	}
	if (facility_id && row) {
		return `${facility_id}/${row}`;
	}
	if (facility_id) {
		return `${facility_id}`;
	}
	return '';
}

export function toTasksCardEntity(
	values,
	type,
	isAsset = false,
	isBatch = false,
) {
	const id = values.rw_request_id ?? values.rw_asset_id ?? values.path;
	const date =
		values?.request_created ??
		values?.asset_nonce ??
		values.asset_checkin_date ??
		new Date().getTime();
	const dueDate = values?.request_due ?? date;
	const status = values?.request_status ?? TASK_TABLE_STATUS.AWAITING_APPROVAL;
	const action = isBatch ? BATCH_TYPE : TASK_TYPES[type];
	const assignees = values.assignees ?? [];
	const client = values.client ?? null;
	const sources = values.source_properties ?? null;
	const destinations = values.destination_propertiesnull;
	const location = values.location ?? null;
	const shipAddress = values.properties?.destination;
	let parent_asset_id = null;
	if (type === REQUEST_TYPES_REVERSE[values?.request_type]) {
		parent_asset_id = values?.destinations?.find(
			(asset) => asset?.asset_type === ASSET_TYPES.pallet,
		)?.rw_asset_id;
	}

	return {
		id,
		type,
		isAsset,
		batch: isBatch,
		date: new Date(date).toLocaleDateString(),
		dueDate: new Date(dueDate).toLocaleDateString(),
		status,
		action,
		assignees,
		path: values.asset_id ?? values.path,
		client,
		sources,
		destinations,
		location,
		shipAddress,
		parent_asset_id,
	};
}

export function fromReportId(path) {
	const [report_type = '', nonce = ''] = path?.split('/') ?? [];
	return {
		report_type,
		nonce: Number(nonce),
	};
}

export function toSendW1ReportRequest(values) {
	const startDate = new Date(values?.start_date);
	const endDate = new Date(values?.end_date);
	return {
		...values,
		start_date: `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`,
		end_date: `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`,
	};
}

export const toPrice = ({ rawPrice, currencyCode = DEFAULT_CURRENCY }) => {
	const price = rawPrice * 0.01;

	if (Number.isNaN(price)) return '--';

	return new Intl.NumberFormat('en', {
		style: 'currency',
		currency: currencyCode,
	}).format(price);
};
