import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import withSmallDialogCard from 'src/components/Cards/SmallDialogCard';
import { FlexBox } from 'src/components/Layout/FlexBox';
import Pagination from 'src/components/Pagination';
import RequestListItem from 'src/components/RequestListItem';
import GeneralSearch from 'src/components/Search/GeneralSearch';
import ListItemSkeleton from 'src/components/sekeletons/ListItemSkeleton';
import { Body3, H5 } from 'src/components/Typography';
import {
	REQUEST_EDIT_URL,
	REQUEST_NAMES,
	REQUEST_TYPES_REVERSE,
} from 'src/consts/requests';
import styles from 'src/styles/content.styles';
import { useRecentRequestQueryPaginated } from 'src/utilities/hooks/useRecentRequestQuery';

function LatestAddedRequests({ requestType }) {
	const navigate = useNavigate();

	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		return {
			type: requestType,
			limit: Number(pageSize),
		};
	}, [requestType, pageSize]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useRecentRequestQueryPaginated(reqParams);
	const goToItem = (params) => {
		const id = params?.path ?? params?.request_id ?? 'new';
		const route = REQUEST_EDIT_URL?.[requestType];
		console.log('Navigating to', `${route}/${id}`);
		navigate(`${route}/${id}`, { replace: true });
	};

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<GeneralSearch
				title='Search'
				types={{ request_types: [requestType] }}
				label={REQUEST_NAMES[requestType]}
				buttonIcon={<ArrowForward />}
				onItemClick={goToItem}
			/>

			<Stack spacing={1}>
				<H5>Latest Added</H5>
				{!isLoading && page && page.length > 0 && (
					<Stack spacing={0.5}>
						<Pagination
							hasNextPage={hasNext}
							hasPrevPage={hasPrev}
							onPressNextPage={loadNext}
							onPressPrevPage={loadPrev}
							onChangePageSize={setPageSize}
							defaultPageSize={pageSize}
						/>
						<Stack className='list'>
							{page.map((i) => (
								<RequestListItem
									key={i.path}
									item={i}
									buttonIcon={<ArrowForward />}
									onItemClick={() => goToItem(i)}
								/>
							))}
						</Stack>
					</Stack>
				)}
				{!isLoading && (!page || page.length === 0) && (
					<Body3>Nothing yet.</Body3>
				)}
				{isLoading && (
					<Stack spacing={0.5}>
						<FlexBox justifyContent='flex-end' gap='2rem'>
							<Skeleton variant='rectangular' width={45} height={45} />
							<Skeleton variant='rectangular' width={60} height={35} />
						</FlexBox>
						<Stack className='list'>
							<ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
							<ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
							<ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
						</Stack>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}

export default withSmallDialogCard(LatestAddedRequests);
