import { Download, Print } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import QRImage from 'src/components/QRImage';
import { H6 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import { toUniqueLocationId } from 'src/utilities/format';
import useDownloadFacilityAssetsLazyQuery from 'src/utilities/hooks/useDownloadFacilityAssetsQuery';
import useDownloadLazyQuery from 'src/utilities/hooks/useDownloadQuery';
import useEncodeLocationQrCode from 'src/utilities/hooks/useEncodeLocationQrCode';
import { QrCodes } from '../InventorySelector/PrintModal';
import printElement from 'src/utilities/printElement';

function Toolbar() {
	const { enqueueSnackbar } = useSnackbar();
	const { values } = useContext(FormikContext);
	const [download, { isLoading: isDownloading }] = useDownloadLazyQuery();
	const [fetchAssets] = useDownloadFacilityAssetsLazyQuery();
	const [printing, setPrinting] = useState(false);

	const currentAddress = useMemo(
		() =>
			toUniqueLocationId({
				facility_id: values.location?.facility_id,
				row: values.location?.row,
				level: values.location?.level,
			}),
		[values.location],
	);

	const encodedQrData = useEncodeLocationQrCode(
		'dt',
		values.location?.facility_id,
		values.location?.row,
		values.location?.level,
	);

	const handleDownload = useCallback(async () => {
		try {
			// facility_type, facility_name, bay
			const [facility_type, facility_name] = (
				values.location?.facility_id ?? ''
			).split('/');
			const facility_id = [facility_type, facility_name]
				.filter(Boolean)
				.join('/');
			await download({ facility_id });
			enqueueSnackbar(
				'Downloading data has been completed. You are ready to go!',
				{ variant: 'success' },
			);
		} catch (error) {
			enqueueSnackbar('Failed to download data!', { variant: 'error' });
		}
	}, [download, enqueueSnackbar, values.location?.facility_id]);

	const handlePrint = useCallback(async () => {
		const [facility_type, facility_name] = (
			values.location?.facility_id ?? '0/0/0'
		).split('/');
		const facility_id = values?.location?.bay
			? [facility_type, facility_name, values.location.bay].join('/')
			: [facility_type, facility_name].join('/');
		const assets = await fetchAssets({
			facility_id,
			row: values.location?.row,
			level: values.location?.level,
		});

		if (assets?.length) {
			setPrinting(true);
			const element = renderToString(QrCodes(assets));
			printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
			setPrinting(false);
		}
	}, [
		fetchAssets,
		values.location.bay,
		values.location?.facility_id,
		values.location?.level,
		values.location?.row,
	]);

	return (
		<Stack spacing={0.25}>
			<FlexBox>
				{currentAddress && (
					<Stack direction='row' spacing={1} alignItems='center'>
						<QRImage boxSize={60} isLogoVisible value={encodedQrData} />
						<H6 sx={{ whiteSpace: 'nowrap' }}>{currentAddress}</H6>
					</Stack>
				)}
				<FlexBox justifyContent='flex-end'>
					<TitledButton
						handleClick={handlePrint}
						disabled={printing}
						label='Print'
						variant='outlined'
						color='secondary'
						sx={{ width: '72px', height: '55px' }}
					>
						<Print height={24} width={24} />
					</TitledButton>
					<TitledButton
						handleClick={handleDownload}
						label='Offline'
						variant='outlined'
						color='secondary'
						sx={{ width: '72px', height: '55px' }}
					>
						<Download height={24} width={24} />
					</TitledButton>
				</FlexBox>
			</FlexBox>
			{isDownloading && <LinearProgress color='primary' />}
		</Stack>
	);
}

export default Toolbar;
