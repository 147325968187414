import { store } from '.';

export const setupNetworkListenerClient = async () => {
	if (
		!(
			'serviceWorker' in navigator ||
			typeof ServiceWorkerRegistration !== 'undefined'
		)
	) {
		return console.error('No service worker support!');
	}
	// Wait for service worker to become active
	if (navigator.serviceWorker) {
		await navigator.serviceWorker.ready;
	}
	const payloadExists = (payload) => payload !== undefined && payload !== null;
	// Listen for service worker 'message' event
	navigator.serviceWorker.addEventListener('message', function (event) {
		if (event.data && event.data.type && payloadExists(event.data.type)) {
			store.dispatch(event.data);
		}
	});
};
