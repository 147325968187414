import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import { FormikCheckBox, FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import WithHelp from 'src/components/Instructions/WithHelp';
import { H5 } from 'src/components/Typography';
import { updateProfile } from 'src/services/profile.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import useUser from 'src/utilities/hooks/useUser';
import * as yup from 'yup';

function Settings() {
  const [isLoading, setLoading] = useLoadingHook();

  const { account_name = '', account_email = '', notifications } = useUser();
  const { events = true, marketing = true } = notifications ?? {};

  const [AlertView, { setAlert }] = useAlertView();

  const changeSet = useMemo(() => ({
    name: [account_name, yup.string()],
    email: [account_email, yup.string().email('Invalid email!').required('Email required!')],
    notify_events: [events, yup.boolean()],
    notify_marketing: [marketing, yup.boolean()],
  }), [account_name, account_email, events, marketing]);

  const handleSubmit = async (values) => {
    setLoading(true);
    setAlert(null);
    try {
      console.log('Saving profile', values);
      // Save the description
      const response = await updateProfile({
        ...values,
      });
      console.log('Updated public profile', response);
      setAlert({
        severity: 'success',
        title: 'Profile updated',
        content: 'Settings successfully updated!',
      });
    } catch (e) {
      console.log('Failed to update public settings!', e);
      setAlert({
        severity: 'error',
        title: 'Failed to update',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing="1.25rem">
      <H5>Profile</H5>

      <FormikForm
        readonly
        changeSet={changeSet}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Stack className="form" spacing="1.2rem">
          <FormikTextField name="name" label="Full name" autoComplete="name" disabled />
          <FormikTextField name="email" label="Contact email" autoComplete="email" disabled />

          <WithHelp instruction="Uncheck this box if you do not want to receive any system notifications (updates to assets, assignments etc.)">
            <FormikCheckBox name="notify_events" label="Platform notifications" />
          </WithHelp>

          <WithHelp instruction="Uncheck this box if you do not want to receive any marketing communications from us or our carefully curated partners.">
            <FormikCheckBox name="notify_marketing" label="Marketing communications" />
          </WithHelp>


          <AlertView />

          <Box className='action-buttons'>
            <FilledButton type='submit' size='small' disabled={isLoading}>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </Stack>
  );
}

export default Settings;
