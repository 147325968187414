import { Skeleton } from '@mui/material';
import SourceTypeIcon from 'src/components/Liquid/SourceTypeIcon';
import QRImage from 'src/components/QRImage/index';
import { Body2 } from 'src/components/Typography';
import { PUBLIC_APP_BASE_URL } from 'src/consts/config';
import { REQUEST_EDIT_URL, REQUEST_TYPES_REVERSE } from 'src/consts/requests';
import React, { useMemo } from 'react';

const styles = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		pageBreakInside: 'none',
		pageBreadAfter: 'auto',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		pageBreakInside: 'none',
		pageBreadAfter: 'auto',
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	qrDetails: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	qrIcon: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		border: '2px solid',
		padding: '5px',
		height: '72px',
		width: '72px',
		borderRadius: '50%',
	},
	type: {
		textTransform: 'uppercase',
		fontSize: '0.8rem',
		marginTop: '.25rem',
	},
	title: {
		borderTop: '1px solid',
		paddingTop: '0.25rem',
		fontSize: '12pt',
		fontWeight: '600',
		fontFamily:
			'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
	},
};

function QrDetails({ item }) {
	return (
		<div style={styles.qrDetails}>
			<div style={styles.qrIcon}>
				<SourceTypeIcon
					type={REQUEST_TYPES_REVERSE[item?.request_type] ?? item?.request_type}
					height={42}
					width={42}
				/>
			</div>
		</div>
	);
}

function QrDetailsSkeleton() {
	return (
		<div style={styles.qrDetails}>
			<div style={styles.qrIcon}>
				<Skeleton variant='circular' width={60} height={72} />
			</div>
		</div>
	);
}

function QrCode({ request, fullScreen = true }) {
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const route = REQUEST_EDIT_URL[request?.request_type];
	const qrValue = useMemo(() => `${origin}${route}/${request?.path}`, [request, origin]);

	return (
		<div style={fullScreen ? styles.rootFullScreen : styles.root}>
			<div style={styles.content}>
				<QRImage value={qrValue} />
				<QrDetails item={request} />
			</div>
			{request?.rw_request_id && <Body2 style={styles.title}>{request?.rw_request_id}</Body2>}
		</div>
	);
}

export function RequestQrCodeSkeleton() {
	return (
		<div style={styles.root}>
			<div style={styles.content}>
				<Skeleton variant='rectangular' width={200} height={165} />
				<QrDetailsSkeleton />
			</div>
			<Body2 style={styles.title}><Skeleton variant="text" /></Body2>
		</div>
	);
}


export default QrCode;
