import { AddAPhoto, DeleteForever } from '@mui/icons-material';
import { Box, ButtonBase } from '@mui/material';
import React, { useCallback } from 'react';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { useToggleState } from 'src/utilities/hooks/useToggleState';
import { ImagePickerModal } from './ImagePickerModal';
import { styles } from './styles';

function ImagePicker({ title = 'Images', onAdd, onRemove, images = [] }) {
	const [open, toggle] = useToggleState(false);
	const onClose = useCallback(() => {
		toggle();
	}, [toggle]);

	const onAddImage = (image) => {
		onAdd(image);
		toggle();
	};

	return (
		<Box sx={styles} className='section'>
			<H5 className='section-header'>{title}</H5>
			<FlexBox>
				<Box>&nbsp;</Box>

				<TitledButton
					handleClick={toggle}
					label='Photo'
					variant='outlined'
					color='secondary'
					sx={{ width: '72px', height: '55px' }}
				>
					<AddAPhoto height={24} width={24} />
				</TitledButton>
			</FlexBox>

			<Box className='inventory'>
				<Box className='inventory-contents photos-container has-photo'>
					<Box className='photos'>
						{images.map((image, index) => (
							<Box className='photo-item' key={index}>
								<img
									key={index}
									src={image.dataUrl}
									alt={`Photo ${index + 1}`}
									className='photo-style'
								/>
								<div className='delete'>
									<ButtonBase onClick={() => onRemove(image)}>
										<DeleteForever />
									</ButtonBase>
								</div>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
			{open && (
				<ImagePickerModal
					onClose={onClose}
					onAdd={onAddImage}
					images={images}
				/>
			)}
		</Box>
	);
}

export default ImagePicker;
