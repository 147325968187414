import { Box } from '@mui/material';
import { FormikCountrySelect, FormikTextField } from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';

export default function Address() {
  return (
    <>
      <FormikTextField title="line2" name="line2" label="Building" fullWidth />

      <FlexBox>
        <FormikTextField title="line1" name="line1" label="Street/Number" fullWidth required />
        <FormikTextField title="city" name="city" label="City" fullWidth required />
      </FlexBox>

      <FlexBox>
        <FormikTextField title="state" name="state" label="State" fullWidth />
        <FormikTextField title="postCode" name="postCode" label="Postal Code" fullWidth required />
      </FlexBox>

      <FormikCountrySelect
        name="country"
        label="Country"
        fullWidth
        required
      />
    </>
  );
}
