import {
	CheckBoxOutlineBlankRounded,
	CheckBoxRounded,
	CheckCircle,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import SmallAvatar from 'src/components/Avatar/SmallAvatar';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import AssetTag from 'src/components/Tasks/AssetTag';
import { Body3, H5 } from 'src/components/Typography';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { mr1 } from 'src/styles/margins';
import useRequestAssignMutation from 'src/utilities/hooks/useRequestAssignMutation';
import styles from 'src/styles/modal.styles';

function User({ id, name, username, selected, selectUser }) {
	return (
		<FlexBox className='user-entry' justifyContent='start' spacing='0.1rem'>
			{selected ? (
				<CheckBoxRounded
					className='check'
					sx={{ ...mr1 }}
					onClick={() => selectUser(false)}
				/>
			) : (
				<CheckBoxOutlineBlankRounded
					className='check'
					sx={{ ...mr1 }}
					onClick={() => selectUser(true)}
				/>
			)}
			<FlexBox sx={{ flexGrow: 1 }} justifyContent='space-between'>
				<SmallAvatar id={id} name={name ?? username} linked={false} />
				<Body3 sx={{ flexGrow: 1 }}>{username}</Body3>
			</FlexBox>
		</FlexBox>
	);
}

function Assignees({ tasks, users, setAssignees }) {
	const existingAssignees = useMemo(
		() => [...new Set(tasks.flatMap((t) => t.assignees ?? []))],
		[tasks],
	);
	const [assigneeIds, setAssigneeIds] = useState(existingAssignees);

	const isSelected = useCallback(
		(uid) => !!(assigneeIds ?? []).find(([eid]) => uid === eid),
		[assigneeIds],
	);

	const selectUser = ([uid, un], state) =>
		setAssigneeIds((v) =>
			state ? [...v, [uid, un]] : v.filter(([i]) => i !== uid),
		);

	useEffect(() => setAssignees(assigneeIds), [assigneeIds]);

	return (
		<Stack className='assignees' spacing={1}>
			<Box className='label'>Assignees</Box>
			<Box className='user-list'>
				<Stack spacing='0.1rem'>
					{users.map((u) => (
						<User
							key={u.account_id}
							id={u.account_id}
							name={u.account_name}
							username={u.account_username}
							selected={isSelected(u.account_id)}
							selectUser={(state) =>
								selectUser([u.account_id, u.account_name], state)
							}
						/>
					))}
				</Stack>
			</Box>
		</Stack>
	);
}

function AssignModal({ tasks, users, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	const [mutate, { isLoading }] = useRequestAssignMutation();

	const [assignees, setAssignees] = useState([]);

	const assignTasks = useCallback(() => {
		if (!assignees || assignees.length === 0) return;

		const requests = tasks.map((t) => ({ path: t.path }));

		mutate({ requests, assignees })
			.then(() => {
				enqueueSnackbar('Tasks assigned!', { variant: 'success' });
				onClose(true, true);
			})
			.catch((err) => {
				const errorMessage = err?.message?.data
					? err?.message?.data[0]
					: 'Failed to assign!';
				enqueueSnackbar(errorMessage, { variant: 'error' });
			});
	}, [enqueueSnackbar, mutate, onClose, tasks, assignees]);

	return (
		<Modal open onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Assign Tasks</H5>
					<IconButton
						onClick={onClose}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<FlexBox className='asset-container' justifyContent='start' spacing={1}>
					{tasks.map((task) => (
						<AssetTag
							key={task.id}
							id={task.id}
							action={task.action}
							type={task.type}
						/>
					))}
				</FlexBox>
				<Assignees
					users={users}
					tasks={tasks}
					setAssignees={setAssignees}
					isLoading={isLoading}
				/>
				<FlexBox justifyContent='end'>
					<TextButton
						size='small'
						handleClick={() => onClose(false, true)}
						disabled={isLoading}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						size='small'
						icon={<CheckCircle />}
						iconPosition='start'
						handleClick={assignTasks}
						loading={isLoading}
					>
						Confirm
					</FilledButton>
				</FlexBox>
			</Stack>
		</Modal>
	);
}

export default AssignModal;
