const styles = {
  '&.root': {
    overflow: 'auto',
  },
  '.content': {
    maxWidth: '960px',
  },
  '.item, .sub-rows, .sub-row': {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  '.item': {
    width: '100%',
    padding: {
      xs: '1rem',
      md: '1.5rem',
    },
    borderBottom: '1px solid #181808',
    background: 'white',
  },
  '.sub-rows': {
    flexGrow: 100,
    width: {
      xs: '100%',
      md: 'calc(100% - 100px)',
    },
    // paddingLeft: {
    //   xs: 0,
    //   md: '0.5rem',
    // },
  },
  '.sub-row': {
    margin: {
      xs: '1rem 0 0 ',
      md: '0',
    },
    width: '100%',
    justifyContent: 'space-between',
    justifySelf: 'flex-start',
  },

  '.label': {},
  '.value': {
    fontWeight: '600',
  },

  '.img': {
    fontSize: '36px',

    border: '1px solid #D0D0D0',
    background: '#F7F6F4',
    borderRadius: '12px',
    color: '#888',
    flexGrow: {
      xs: 10,
      md: 0,
    },
    width: {
      xs: '100%',
      md: '100px',
    },
    minHeight: {
      xs: 'fit-content',
      md: '100px',
    },
    textAlign: 'center',

    svg: {
      marginTop: '2rem',
      fill: 'currentColor',
    },
  },

  '.sub-row.first': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',

    '.property': {
      padding: {
        xs: 0,
        md: '0 1.5rem',
      },
    },

    '.value': {
      fontSize: '1rem',
    },
  },
  '.second': {
    justifyContent: 'flex-start',

    '.property': {
      border: '1px solid #D0D0D0',
      borderWidth: {
        xs: '1px 0 0',
        md: '0 0 0 1px',
      },
      '&:first-of-type': {
        borderWidth: {
          md: 0,
        },
      },
    },
  },
  '.property': {
    minWidth: '135px',
    width: {
      xs: '100%',
      md: 'auto',
    },
    padding: {
      xs: '0.5rem 0',
      md: '0 1.5rem',
    },

    '*': {
      fontSize: '14px',
      color: '#4c4c4c',
    },
  },
  '.total.total.total': {
    padding: '1rem',
    margin: {
      xs: 0,
      md: '-0.5rem -0.5rem 0 0',
    },
    flexShirnk: 1,
    alignItems: 'center',
    background: '#F7F6F4',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    textAlign: {
      xs: 'right',
      md: 'left',
    },

    '.label': {
      marginRight: {
        xs: 0,
        md: '0.5em',
      },
    },
  },

};
export default styles;
