import { getTaxCodes } from 'src/services/taxCode.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params) =>
	getTaxCodes(params).then(({ items = [] }) =>
		items?.map((item) => ({
			label: `${item?.path?.split('/').reverse()[0]} (${item?.description})`,
			value: item?.path,
		})),
	);
export const [useTaxCodesLazyQuery, useTaxCodesQuery] =
	genServiceCallHooks(serviceFunction);

export default useTaxCodesQuery;
