import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import LiquidContentSearch from './LiquidContentSearch';
import LocationContent from 'src/components/AssetListItem/LocationContent';
import { Body3 } from 'src/components/Typography';
import IDDisplay from 'src/components/properties/IDDisplay';
import SourceDisplay from 'src/components/properties/SourceDisplay';
import {
	ASSET_EDIT_URL,
	ASSET_TYPES,
	ASSET_TYPES_REVERSE,
} from 'src/consts/asset';
import Switch from '../SwitchComponent';

/**
 * Renders a search asset list item component with various content based on the asset type.
 *
 * @param {Object} item - The asset item object.
 * @param {function} display - An optional function to display additional content.
 * @returns {JSX.Element} - The rendered search asset list item component.
 */
export function SearchAssetListItemComponent({ item, display }) {
	const assetTypeString =
		ASSET_TYPES_REVERSE[item?.token_type] ?? item?.token_type ?? '';

	const openAsset = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			const path = item?.path;
			const route = ASSET_EDIT_URL?.[item?.token_type];
			console.log('Routing to', route, path);
			window.open(`${route}/${path}`, '_blank').focus();
		},
		[item],
	);
	return (
		<Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: 'left' }}>
			<IDDisplay id={item?.token_name} onClick={openAsset} />
			<SourceDisplay
				type={assetTypeString}
				name={assetTypeString?.toUpperCase()}
			/>
			<Switch value={item?.token_type}>
				<Switch.Case condition={ASSET_TYPES.cask}>
					<LocationContent location={item?.unique_location_id} />
					<LiquidContentSearch
						bl={item?.bl}
						la={item?.la}
						abv={item?.token_abv}
						tcf={item?.tcf}
						weightFactor={item?.weight_factor}
					/>
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.ibc}>
					<LocationContent location={item?.unique_location_id} />
					<LiquidContentSearch
						bl={item?.bl}
						la={item?.la}
						abv={item?.token_abv}
						tcf={item?.tcf}
						weightFactor={item?.weight_factor}
					/>
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.tanker}>
					<LiquidContentSearch
						bl={item?.bl}
						la={item?.la}
						abv={item?.token_abv}
						tcf={item?.tcf}
						weightFactor={item?.weight_factor}
					/>
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.filling_tank}>
					<LocationContent location={item?.unique_location_id} />
					<LiquidContentSearch
						bl={item?.bl}
						la={item?.la}
						abv={item?.token_abv}
						tcf={item?.tcf}
						weightFactor={item?.weight_factor}
					/>
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.pallet}>
					<LocationContent location={item?.unique_location_id} />
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.container}>
					<LocationContent location={item?.unique_location_id} />
				</Switch.Case>
				<Switch.Default>
				</Switch.Default>
			</Switch>
			{display && display(item)}
		</Stack>
	);
}
