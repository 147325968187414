import { APP_API_GATEWAY } from 'src/consts/config';
import fetchGet from 'src/utilities/fetchGet';
import fetchPost from 'src/utilities/fetchPost';

const BASE = `${APP_API_GATEWAY}/pw/admin/facility`;

export const upsertFacility = async (facility, headers = {}) => fetchPost(BASE, headers, facility);

export const getFacilities = async (request = {}, headers = {}) =>
	fetchPost(`${BASE}/list`, headers, request);

export const getAllFacilities = async (request = {}, headers = {}) =>
	fetchGet(`${BASE}/all`, headers, request);
