import { Google } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import FilledButton from 'src/components/Buttons/FilledButton';
import { signin } from 'src/services/auth.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoading from 'src/utilities/hooks/useLoadingHook';

function SocialSignin({ onLogin, account, invite }) {
  const [, setLoading] = useLoading();
	const { ident } = account ?? {};

	const [AlertView, { setAlert }] = useAlertView();

	const login = async (type) => {
		setLoading(true);
		setAlert(null);

		const auth = getAuth();
		auth.useDeviceLanguage();

		const provider = new GoogleAuthProvider();
		provider.setCustomParameters({
			prompt: 'select_account',
		});

		// const defaultEmail = window?.localStorage?.getItem('social-user');
		// provider.setCustomParameters({
		//   'login_hint': defaultEmail
		// });

		try {
			// Google account select..
			const result = await signInWithPopup(auth, provider);
			// const credential = GoogleAuthProvider.credentialFromResult(result);
			const { user } = result;
			const { email, stsTokenManager } = user;

			if (ident && email !== ident) {
				setAlert({
					severity: 'error',
					title: 'Failed to sign in',
					content: 'Your selected social account did not match the invite!',
				});
				return;
			}

			// const firebaseNotificationToken = await getFcmToken();
			// console.debug(`FCM TOKEN: '${firebaseNotificationToken}'`);

			// Hit the back-end to authenticate this user
			const response = await signin({
				type,
				ident: email,
				token: stsTokenManager.accessToken,
				invite,
			});

			window?.localStorage?.setItem('social-user', email);

			console.log('Authentication response', response);
			onLogin(response);
		} catch (error) {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			// The email of the user's account used.
			// The AuthCredential type that was used.
			const credential = GoogleAuthProvider.credentialFromError(error);
			// ...
			console.log('Error', error, credential, errorCode, errorMessage);

			if (errorCode) {
				switch (errorCode) {
				case 'auth/popup-closed-by-user': {
					setAlert({
						severity: 'error',
						title: 'Failed to sign in',
						content: 'You did not select an account!',
					});
					break;
				}
				default: {
					setAlert({
						severity: 'error',
						title: 'Failed to sign in',
						content: `There was an error signing in, see more details here [${errorCode}, ${errorMessage}]!`,
					});
					break;
				}
				}
			} else {
				setAlert({
					severity: 'error',
					title: 'Failed to sign in',
					content: error.message ?? 'There was a problem signing in, please try again!',
				});
			}
		}

		setLoading(false);
	};

	return (
		<Stack spacing={2}>
			<AlertView />
			<FilledButton icon={<Google />} iconPosition="start" label="Google" handleClick={() => login('google')} />
		</Stack>
	);
}

export default SocialSignin;
