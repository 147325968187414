import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from './consts';

const initialState = {
  auth_token: '',
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase('USER/setUser', (state, { payload }) => {
      if(payload.Authorization){
        state.auth_token = payload.Authorization;
      }
    });
    builder.addCase('USER/clearUser', () => {
      return { ...initialState };
    });
  },
});

export const { reducer, actions } = slice;