import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from 'src/components/AssetListItem';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidSummary from 'src/components/LiquidSummary';
import LinkedAssetItem from 'src/components/SKUSelector/items/LinkedAssetItem';
import { H5 } from 'src/components/Typography';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import React from 'react';

export function AssetLinks({ asset }) {
	const { parent_asset_id, parent_asset, children } = asset ?? {};

	if (!parent_asset_id && !parent_asset && !children) return;

	return (
		<FlexBox className='asset-container' justifyContent='start' gap='0.75rem'>
			{parent_asset_id && parent_asset && <LinkedAssetItem asset={parent_asset} />}
			{children &&
				children.map((_item) => <LinkedAssetItem key={_item.asset_id} asset={_item} />)}
		</FlexBox>
	);
}

function AssetSummaryList({ items, onRemove }) {
	const display = (value) => <AssetLinks asset={value} />;

	return (
		<Box className='inventory'>
			<H5 className='inventory-header'>Assets</H5>
			<Box className='inventory-contents'>
				<Stack className='list'>
					{items.map((asset) => (
						<AssetListComponentV2
							key={asset.path ?? asset.asset_id ?? asset.rw_asset_id}
							item={asset}
							onRemove={onRemove}
							display={display}
							selected={(i) => i.processed === ASSET_PROCESSED_STATUS.CONFIRMED}
						/>
					))}
				</Stack>
			</Box>
			<LiquidSummary assets={items} />
		</Box>
	);
}

export default AssetSummaryList;
