import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts';
import { H5 } from 'src/components/Typography';

function LiquidTransfersWidget({ title, la_in = 0, la_out = 0, bl_in = 0, bl_out  = 0}) {
	return (
		<Stack className='content-section'>
			<H5 className='content-section-title'>{title}</H5>
			<Stack className='content-section-content'>
				<PieChart
					series={[
						{
							data: [
								{
									id: 0,
									value: la_in,
									label: 'Alcohol In',
									color: '#41424c',
								},
								{
									id: 1,
									value: la_out,
									label: 'Alcohol Out',
									color: '#F0AA29',
								},
							],
							innerRadius: 60,
							outerRadius: 100,
							paddingAngle: 5,
							cornerRadius: 5,
							startAngle: -225,
							endAngle: 45,
							cx: 125,
							cy: 100,
							highlightScope: { faded: 'global', highlighted: 'item' },
							faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
						},
					]}
					width={275}
					height={220}
				/>
				<PieChart
					series={[
						{
							data: [
								{
									id: 0,
									value: bl_in,
									label: 'Bulk In',
									color: '#41424c',
								},
								{
									id: 1,
									value: bl_out,
									label: 'Bulk Out',
									color: '#F0AA29',
								},
							],
							innerRadius: 60,
							outerRadius: 100,
							paddingAngle: 5,
							cornerRadius: 5,
							startAngle: -225,
							endAngle: 45,
							cx: 125,
							cy: 100,
							highlightScope: { faded: 'global', highlighted: 'item' },
							faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
						},
					]}
					width={250}
					height={220}
				/>
			</Stack>
		</Stack>
	);
}

export default LiquidTransfersWidget;
