import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Body1 } from 'src/components/Typography';
import React from 'react';

export default function Loading({ message = 'Loading', mode = 'warning' }) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				gap: '1rem',
			}}
		>
			<CircularProgress size={64} color={mode} />
			<Body1>
				{`${message}`}
				...
			</Body1>
		</Box>
	);
}
