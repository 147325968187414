import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import AssetAccounts from 'src/components/AssetAccounts';
import { assetAccountsFields } from 'src/components/AssetAccounts/assetAccountsFields';
import AssetForm from 'src/components/AssetForm';
import assetIdFields from 'src/components/AssetID/assetIdFields';
import { storageChargeCodeFields } from 'src/components/ChargeCode/storageChargeCodeFields';
import { FormikCheckBox } from 'src/components/Forms/FormikForm';
import { TagSelect } from 'src/components/Forms/TagSelect';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidProperties, {
	liquidPropertyFields,
} from 'src/components/Liquid/LiquidProperties';
import Location from 'src/components/Location';
import { locationFields } from 'src/components/Location/locationFields';
import SKUField from 'src/components/SKUSelector/SKUField';
import skuFields from 'src/components/SKUSelector/skuFields';
import templateFields from 'src/components/TemplateHandler/templateFields';
import { Body2, H5, H6 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { SKU_TYPES } from 'src/consts/sku';
import { FormikProvider } from 'src/providers/FormikProvider';
import processLiquid from 'src/utilities/adapters/processLiquid';
import toUniqueLocation from 'src/utilities/adapters/toUniqueLocation';
import useMeasures from 'src/utilities/hooks/useMeasures';
import usePermissions from 'src/utilities/hooks/usePermissions';
import * as yup from 'yup';

function propertyFields(fields = {}, measures = {}) {
	const {
		ibc: tags = [],
		imported = false,
		duty_paid = false,
		liquid = {},
		sku_id = '',
		sku = {},
	} = fields;

	return {
		ibc: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		imported: [imported, yup.boolean()],
		duty_paid: [duty_paid, yup.boolean()],
		liquid: liquidPropertyFields(liquid, measures),
		...skuFields(sku_id, sku),
	};
}

function Properties() {
	const { editLiquid = false } = usePermissions();
	const filter = (item) => item?.asset_type === ASSET_TYPES.ibc;

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>IBC Properties</H5>
				<SKUField
					title='Type'
					skuTypes={[SKU_TYPES.TRACKED]}
					tags='ibc'
					itemFilter={filter}
				/>
				<TagSelect name='ibc' label='IBC properties' type='ibc' fullWidth />
				<Stack className='section'>
					<H6 className='section-title'>Liquid Status</H6>
					<FlexBox justifyContent='space-between'>
						<FormikCheckBox name='imported' label={<Body2>Imported</Body2>} />
						<FormikCheckBox name='duty_paid' label={<Body2>Duty Paid</Body2>} />
					</FlexBox>
				</Stack>
			</Stack>
			<LiquidProperties name='liquid' disabled={!editLiquid} />
		</FormikProvider>
	);
}

function IBCForm(props) {
	const measures = useMeasures();

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			...skuFields(initialValues.sku_id, initialValues.sku),
			location: locationFields(initialValues?.location),
			properties: propertyFields(initialValues.properties, measures),
			accounts: assetAccountsFields(initialValues.accounts),
			charge_code: storageChargeCodeFields(initialValues?.charge_code),
		}),
		[measures],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
			unique_location_id: toUniqueLocation(entity.location),
		};
	};

	return (
		<AssetForm
			assetLabel='IBC'
			assetType={ASSET_TYPES.ibc}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<SKUField title='Marketplace Product' skuTypes={[SKU_TYPES.WIP]} />

			<Location name='location' />

			<Properties />

			<AssetAccounts />
		</AssetForm>
	);
}

export default withAppLayout(IBCForm, { title: 'IBC Management' });
