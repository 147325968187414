import Organizations from 'src/components/Account/Organizations';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function OrganizationsPage() {
	usePageTitle('Manage Organizations');

	return (
		<Organizations />
	);
}

export default withAppLayout(withDialogCard(OrganizationsPage));
