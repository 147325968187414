import { AddCircle, CheckBox, Edit, PendingActions } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Gateway from 'src/components/admin/Sensors/Gateway';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import withDialogCard from 'src/components/Cards/DialogCard';
import Monitoring from 'src/components/icons/Monitoring';
import Instructions from 'src/components/Instructions';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { Body3, H5 } from 'src/components/Typography';
import Bolder from 'src/components/Typography/Bolder';
import styles from 'src/styles/content.styles';
import { useGateWayListLazyQuery } from 'src/utilities/hooks/useGateWayListQuery';
import usePageTitle from 'src/utilities/hooks/usePageTitle';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';

function SensorContent({ sensors }) {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        gap: '0.25rem',
      }}
    >
      <Monitoring style={{ width: '12px', height: '14px' }} />
      <Body3>
        <Bolder>Sensors:</Bolder>
        {` ${sensors}`}
      </Body3>
    </Box>
  );
}

function GatewayItem({ entry, onEditClick }) {
  const { status, path, sensors = [] } = entry;
  return (
    <Stack className="listItem">
      <Box className="listContent">
        <Stack direction="row" spacing="0.75rem">
          {status === 'inactive' && (
            <PendingActions title="Pending" aria-label="Pending" />
          )}
          {status === 'active' && <CheckBox />}
          <Stack spacing={0.25}>
            <H5>{path.replace('/gateway/', '')}</H5>
            <SensorContent sensors={sensors.length} />
          </Stack>
        </Stack>
      </Box>
      <Stack className="listButtons">
        <IconCircleButton onClick={() => onEditClick(entry)}>
          <Edit />
        </IconCircleButton>
      </Stack>
    </Stack>
  );
}

function SensorsManagement() {
  const [target, scroll] = useScrollTarget();

  const [
    refetch,
    {
      data: list = [], // isLoading, error, isError,
    },
  ] = useGateWayListLazyQuery([]);

  usePageTitle('Sensors');

  const [gateway, setGateway] = useState(null);

  const handleSave = useCallback(() => {
    setGateway(null);
    refetch();
    scroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const editGateway = (s) => {
    setGateway(s);
    scroll();
  };

  useEffect(() => {
    refetch();
    scroll();
  }, [refetch]);

  return (
    <Stack sx={styles} className="root" spacing="1.25rem">
      <Box className="header" ref={target}>
        <H5>Sensors</H5>

        <TitledButton
          handleClick={() => editGateway({})}
          variant="outlined"
          color="secondary"
          label="New"
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>You can manage the sensor setup here.</Instructions>

      <Collapse
        in={!!gateway}
        onExited={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <Stack spacing={2}>
          <Divider />
          <Gateway
            key={JSON.stringify(gateway)}
            entity={gateway}
            onSave={handleSave}
            cancel={() => editGateway(null)}
          />
          <Divider />
        </Stack>
      </Collapse>

      {list.length === 0 && <Alert severity="warning">No gateway configured.</Alert>}

      <Stack spacing={0} className="list">
        {list.map((gw) => (
          <GatewayItem key={gw.path} entry={gw} onEditClick={editGateway} />
        ))}
      </Stack>
    </Stack>
  );
}

export default withAppLayout(withDialogCard(SensorsManagement));
