import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Body3, H5 } from 'src/components/Typography';
import { ba, br3, bw1 } from 'src/styles/border';
import { bg_near_white } from 'src/styles/colors';
import { pv5 } from 'src/styles/padding';
import useIsMobile from 'src/utilities/hooks/useIsMobile';

const styles = {
	'&.root': {
		width: '100%',
	},
	'.fileSelector': {
		...ba,
		...bw1,
		borderStyle: 'dashed',
		...pv5,
		...br3,
		textAlign: 'center',
		zIndex: 2,
		borderColor: '#5d7ec1',
		'&[data-active=true]': {
			...bg_near_white,
			transition: 'background 0.1s ease-out',
		},
	},
};

function ImageDropZone({ setData }) {
	const { enqueueSnackbar } = useSnackbar();

	const onDrop = useCallback(
		async (acceptedFiles) => {
			try {
				if (acceptedFiles.length < 1) {
					return;
				}
				// enqueueSnackbar(`Successfully uploaded`, {
				// 	variant: 'success',
				// });
				setData(acceptedFiles);
			} catch (error) {
				enqueueSnackbar(error?.message, {
					variant: 'error',
				});
			}
		},
		[setData, enqueueSnackbar],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			// 'image/jpeg': ['.jpeg'],
			'image/png': ['.png'],
		},
		multiple: true,
		onDrop,
	});

	const isMobile = useIsMobile();

	return (
		<Box sx={styles} className='root'>
			<div
				className='fileSelector'
				data-active={!!isDragActive}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<InsertPhotoIcon />
				<H5>Select an image to upload</H5>
				<Body3>
					{isMobile ? 'Tap to select' : 'Or drag and drop an image'}
				</Body3>
			</div>
		</Box>
	);
}

export default ImageDropZone;
