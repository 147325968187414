import { AddCircle } from '@mui/icons-material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AssetListComponentV2 } from 'src/components/AssetListItem';
import TitledButton from 'src/components/Buttons/TitledButton';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { ASSET_EDIT_URL } from 'src/consts/asset';
function RecentAssetsWidget({ title, data = [] }) {
	const navigate = useNavigate();

	const goToItem = (params) => {
		const id = params?.path ?? params?.asset_id ?? 'new';
		const assetType = params?.asset_type ?? '1';
		const route = ASSET_EDIT_URL?.[assetType];
		navigate(`${route}/${id}`);
	};

	return data.length ? (
		<Stack className='content-section'>
			<H5 className='content-section-title'>{title}</H5>
			<Stack className='content-section-content list recent-list'>
				{data.map((i) => (
					<AssetListComponentV2
						key={i.path}
						item={i}
						buttonIcon={<ArrowForward />}
						onClick={() => goToItem(i)}
					/>
				))}
			</Stack>
			<FlexBox className='content-section-footer'>
				<TitledButton
					handleClick={() => goToItem({ asset_type: '1' })}
					variant='outlined'
					color='secondary'
					label='Cask'
					sx={{ width: '76px', height: '55px' }}
				>
					<AddCircle height={24} width={24} />
				</TitledButton>
				<TitledButton
					handleClick={() => goToItem({ asset_type: '22' })}
					variant='outlined'
					color='secondary'
					label='Pallet'
					sx={{ width: '76px', height: '55px' }}
				>
					<AddCircle height={24} width={24} />
				</TitledButton>
				<TitledButton
					handleClick={() => goToItem({ asset_type: '21' })}
					variant='outlined'
					color='secondary'
					label='IBC'
					sx={{ width: '76px', height: '55px' }}
				>
					<AddCircle height={24} width={24} />
				</TitledButton>
				<TitledButton
					handleClick={() => goToItem({ asset_type: '24' })}
					variant='outlined'
					color='secondary'
					label='Container'
					sx={{ width: '76px', height: '55px' }}
				>
					<AddCircle height={24} width={24} />
				</TitledButton>
			</FlexBox>
		</Stack>
	) : null;
}

RecentAssetsWidget.propTypes = {
	title: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.object),
};

export default RecentAssetsWidget;
