import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikMeasuresField, FormikNumberField } from 'src/components/Forms/FormikForm';
import { AlertView } from 'src/utilities/hooks/useAlertView';
import * as yup from 'yup';

function TrackedSKU({ item, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	// We track the "amount" we want at the SKU Item level
	const {
		amount,
	} = item ?? {};

	const changeSet = useMemo(
		() => ({ amount: [amount ?? '', yup.number().positive('Must be positive!').required('Amount required!')] }),
		[amount],
	);

	const handleSubmit = (values) => {
		try {
			const sku = {
				...item,
				amount: values.amount,
			};
			console.log('Setting SKU', values, sku);
			onClose(sku);
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={1}>
				<FormikNumberField
					label="Items"
					name="amount"
					fullWidth
				/>

				<AlertView />

				<Box className='action-buttons'>
					<TextButton size='small' handleClick={() => onClose()} color='secondary'>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default TrackedSKU;
