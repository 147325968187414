import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useParams,
} from 'react-router-dom';
import AuthGuard from 'src/components/Auth/AuthGuard';
import { PERMISSIONS } from 'src/consts/permissions';
import Custom404 from 'src/pages/404';
import AccountSettingsPage from 'src/pages/account/AccountSettings';
import ActivityPage from 'src/pages/account/Activity';
import PersonalInvitePage from 'src/pages/account/Invite';
import OrganizationsPage from 'src/pages/account/Organizations';
import PortfolioPage from 'src/pages/account/Portfolio';
import CompanyPage from 'src/pages/admin/Company';
import ContractsPage from 'src/pages/admin/contracts';
import CustomerPage from 'src/pages/admin/Customers';
import FacilitiesPage from 'src/pages/admin/facilities';
import PartnersPage from 'src/pages/admin/Partners';
import RolesPage from 'src/pages/admin/Roles';
import SensorsPage from 'src/pages/admin/sensors';
import SkuPage from 'src/pages/admin/sku';
import TeamsPage from 'src/pages/admin/Teams';
import UsersPage from 'src/pages/admin/Users';
import AssetTablePage from 'src/pages/app/asset-table';
import CaskPage from 'src/pages/app/cask';
import ChangeOwnershipPage from 'src/pages/app/change-ownership';
import ContainerPage from 'src/pages/app/container';
import FillingTankPage from 'src/pages/app/tank';
import DeliveryPage from 'src/pages/app/delivery';
import DutySubmissionPage from 'src/pages/app/duty-submission';
import DutyTransferPage from 'src/pages/app/duty-transfer';
import ExplorerPage from 'src/pages/app/explorer';
import IbcPage from 'src/pages/app/ibc';
import InventoryAuditPage from 'src/pages/app/inventory-audit';
import LocationsPage from 'src/pages/app/locations';
import PalletPage from 'src/pages/app/pallet';
import PickPage from 'src/pages/app/pick';
import ProductionRunPage from 'src/pages/app/production-run';
import ReguagePage from 'src/pages/app/reguage';
import ReportDefectPage from 'src/pages/app/report-defect';
import ReportsPage from 'src/pages/app/reports';
import ReportsInstantPage from 'src/pages/app/reports-instant';
import ReportsMonthlyPage from 'src/pages/app/reports-monthly';
import RestockPage from 'src/pages/app/restock';
import SamplePage from 'src/pages/app/sample';
import SearchPage from 'src/pages/app/search';
import ShipPage from 'src/pages/app/ship';
import SyncPage from 'src/pages/app/sync';
import TankerPage from 'src/pages/app/tanker';
import TasksPage from 'src/pages/app/tasks';
import TransferPage from 'src/pages/app/transfer';
import InvoicePage from 'src/pages/app/invoice';
import DashBoard from 'src/pages/Dashboard';
import LogoutPage from 'src/pages/Logout';
import Recover from 'src/pages/Recover';
import Register from 'src/pages/Register';
import SignIn from 'src/pages/SignIn';
import { useCompanyFacilitiesQuery } from 'src/utilities/hooks/useAllFacilitiesQuery';
import { useCompanySKUsQuery } from 'src/utilities/hooks/useCompanySKUsQuery';
import { useCompanyUsersQuery } from 'src/utilities/hooks/useCompanyUsersQuery';
import { useNotificationsQuery } from 'src/utilities/hooks/useNotificationsQuery';

function RDRedirect({ base }) {
	let { platform, chain, address } = useParams();
	return <Navigate to={`${base}/${platform}/${chain}/${address}`} replace />;
}

function AppRoutes() {
	useNotificationsQuery();
	useCompanyUsersQuery();
	useCompanySKUsQuery();
	useCompanyFacilitiesQuery();

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Navigate to='/app/overview' replace />} />
				<Route path='/signin' element={<SignIn />} />
				<Route path='/register' element={<Register />} />
				<Route path='/recover' element={<Recover />} />

				{/**next config re routes start*/}
				<Route path='/pallet' element={<Navigate to='/app/pallet' replace />} />
				<Route
					path='/logout'
					element={
						<AuthGuard>
							<LogoutPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/cask/edit/:platform/:chain/:address'
					element={<RDRedirect base='/app/cask' />}
				/>
				<Route
					path='/app/cask//rd/:platform/:chain/:address'
					element={<RDRedirect base='/app/cask' />}
				/>
				<Route
					path='/app/pallet//rd/:platform/:chain/:address'
					element={<RDRedirect base='/app/pallet' />}
				/>
				<Route
					path='/app/pallet/rd/:platform/:chain/:address'
					element={<RDRedirect base='/app/pallet' />}
				/>
				{/**next config re routes end*/}
				<Route
					path='/app/overview'
					element={
						<AuthGuard>
							<DashBoard />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/search'
					element={
						<AuthGuard>
							<SearchPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/sync'
					element={
						<AuthGuard>
							<SyncPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/explorer'
					element={
						<AuthGuard>
							<ExplorerPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/reports'
					element={
						<AuthGuard>
							<ReportsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/tasks'
					element={
						<AuthGuard>
							<TasksPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/activity'
					element={
						<AuthGuard>
							<ActivityPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/locations'
					element={
						<AuthGuard>
							<LocationsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/reports/instant/:type/:nonce'
					element={
						<AuthGuard>
							<ReportsInstantPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/reports/monthly/:type/:nonce'
					element={
						<AuthGuard>
							<ReportsMonthlyPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/pallet/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<PalletPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/container/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<ContainerPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/cask/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<CaskPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/ibc/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<IbcPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/tank/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<FillingTankPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/tanker/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<TankerPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/transfer/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<TransferPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/pick/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<PickPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/duty-transfer/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<DutyTransferPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/duty-submission/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<DutySubmissionPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/ship/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<ShipPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/sample/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<SamplePage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/report-defect/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<ReportDefectPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/regauge/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<ReguagePage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/restock/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<RestockPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/change-ownership/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<ChangeOwnershipPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/inventory-audit/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<InventoryAuditPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/delivery/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<DeliveryPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/production-run/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<ProductionRunPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/app/invoice/:platform?/:chain?/:address?'
					element={
						<AuthGuard>
							<InvoicePage />
						</AuthGuard>
					}
				/>

				<Route
					path='/app/asset-table'
					element={
						<AuthGuard>
							<AssetTablePage />
						</AuthGuard>
					}
				/>

				<Route
					path='/admin/company'
					element={
						<AuthGuard permissions={[PERMISSIONS.COMPANY_ADMIN]}>
							<CompanyPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/customers'
					element={
						<AuthGuard>
							<CustomerPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/partners'
					element={
						<AuthGuard>
							<PartnersPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/facilities'
					element={
						<AuthGuard permissions={[PERMISSIONS.FACILITY_ADMIN]}>
							<FacilitiesPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/sku'
					element={
						<AuthGuard permissions={[PERMISSIONS.SKU_ADMIN]}>
							<SkuPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/users'
					element={
						<AuthGuard permissions={[PERMISSIONS.USER_ADMIN]}>
							<UsersPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/roles'
					element={
						<AuthGuard permissions={[PERMISSIONS.USER_ADMIN]}>
							<RolesPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/teams'
					element={
						<AuthGuard permissions={[PERMISSIONS.USER_ADMIN]}>
							<TeamsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/contracts'
					element={
						<AuthGuard permissions={[PERMISSIONS.COMPANY_ADMIN]}>
							<ContractsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/sensors'
					element={
						<AuthGuard permissions={[PERMISSIONS.SENSOR_ADMIN]}>
							<SensorsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/admin/filling-tank'
					element={
						<AuthGuard>
							<FillingTankPage />
						</AuthGuard>
					}
				/>

				<Route
					path='/account/settings'
					element={
						<AuthGuard>
							<AccountSettingsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/account/portfolio'
					element={
						<AuthGuard>
							<PortfolioPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/account/activity'
					element={
						<AuthGuard>
							<ActivityPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/account/organizations'
					element={
						<AuthGuard>
							<OrganizationsPage />
						</AuthGuard>
					}
				/>
				<Route
					path='/account/invite'
					element={
						<AuthGuard>
							<PersonalInvitePage />
						</AuthGuard>
					}
				/>

				<Route path='/not-found' element={<Custom404 />} />
				<Route path='*' element={<Custom404 />} />
			</Routes>
		</BrowserRouter>
	);
}
export default AppRoutes;
