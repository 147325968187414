
import { AccountCircle } from '@mui/icons-material';
import { Collapse, Divider, Stack } from '@mui/material';
import { startAuthentication } from '@simplewebauthn/browser';
import { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SocialSignin from 'src/components/Auth/SocialSignin';
import FilledButton from 'src/components/Buttons/FilledButton';
import Errors from 'src/components/Forms/FormErrors';
import {
	FormikForm,
	FormikPasswordField,
} from 'src/components/Forms/FormikForm';
import { Body3, H5 } from 'src/components/Typography';
import { IDENTITY } from 'src/consts/account';
import FormikContext from 'src/context/FormikContext';
import { setUser } from 'src/redux/containers/User';
import {
	authenticateCredentials,
	authenticationOptions,
	signin,
} from 'src/services/auth.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useInviteHook from 'src/utilities/hooks/useInviteHook';
import useLoading from 'src/utilities/hooks/useLoadingHook';
import * as yup from 'yup';
import 'yup-phone';
import Identity from '../Identity';

function PasswordForm() {
	const { values, errors } = useContext(FormikContext);
	const { type, ident } = values;

	const show = useMemo(() => {
		return ident && ident.length > 0;
	}, [ident, errors]);

	return (
		<Collapse in={!!show} unmountOnExit>
			<Stack spacing={3}>
				<Stack spacing={2}>
					<FormikPasswordField
						name='password'
						label='Password'
						fullWidth
						required
						autoComplete='password'
					/>
					<Body3>
						Click&nbsp;
						<Link to="/recover" state={{ account: { type, ident } }} className='forgotLink'>
							here
						</Link>
						&nbsp;if you have forgotten your password.
					</Body3>
				</Stack>

				<FilledButton
					icon={<AccountCircle />}
					iconPosition='start'
					label='Sign In'
					type='submit'
				/>
			</Stack>
		</Collapse>
	);
}

function LoginForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// See if there is an invite
	const { account, invite } = useInviteHook();
	const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};

	const { state } = useLocation();
	const { from = '/' } = state ?? {};

	const [, setLoading] = useLoading();

	const [AlertView, { setAlert }] = useAlertView();

	const changeSet = useMemo(
		() => ({
			type: [
				type ?? IDENTITY.EMAIL,
				yup.string().required('Identity type is required!'),
			],
			ident: [
				ident,
				yup.string().test({
					name: 'valid_identity',
					test: (value, ctx) => {
						// console.log('Testing', value, ctx.parent.type);
						switch (ctx.parent.type) {
							case IDENTITY.EMAIL: {
								if (!value) {
									return ctx.createError({
										path: 'identity',
										message: 'Email is required!',
									});
								}
								const valid = yup.string().email().isValidSync(value);
								// console.log(' -->', value, valid);
								if (!valid) {
									return ctx.createError({
										path: 'identity',
										message: 'Invalid email!',
									});
								}
								break;
							}
							case IDENTITY.PHONE: {
								// regex only phone number with +
								const onlyNumeric = /^\+?\d+$/;
								if (!value || !onlyNumeric.test(value)) {
									return ctx.createError({
										path: 'identity',
										message: 'Phone number is required!',
									});
								}
								const valid = yup.string().phone().isValidSync(value);
								// console.log(' -->', value, valid);
								if (!valid) {
									return ctx.createError({
										path: 'identity',
										message: 'Invalid phone number!',
									});
								}
								break;
							}
							default: {
								break;
							}
						}
						return true;
					},
				}),
			],
			password: ['', yup.string().required('Password is required!')],
		}),
		[ident, type],
	);

	const handleResponse = (response) => {
		const { verified = false } = response ?? {};
		if (!verified) {
			setAlert({
				severity: 'error',
				title: 'Failed to sign in',
				content:
					'if you have forgotten your password, please reset it using the link above!',
			});
		} else {
			dispatch(setUser(response));
			if (['/logout', '/signin'].includes(from.pathname)) {
				navigate('/app/overview');
			} else {
				navigate(from);
			}
		}
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		setAlert(null);
		try {
			const response = await signin({
				...values,
				invite,
			});

			console.log('Response', response);
			handleResponse(response);
		} catch (e) {
			console.log('Failed to authenticate!', e);
			setAlert({
				severity: 'error',
				title: 'Failed to sign in',
				content: e.message,
			});
		} finally {
			setLoading(false);
		}
	};

	const handlePasskeyClick = async (type, ident) => {
		setLoading(true);
		setAlert(null);

		try {
			const id = {
				type,
				ident,
			};
			console.log('identity', id);
			const opt = await authenticationOptions(id);
			console.debug('Options', opt);
			const reg = await startAuthentication(opt);
			console.debug('Authentication', reg);

			const response = await authenticateCredentials({
				...id,
				token: reg,
				invite,
			});
			console.log('Response', response);
			handleResponse(response);
		} catch (err) {
			setAlert({
				severity: 'error',
				title: 'Failed to Sign in',
				content:
					'Passkey not recognized. Please first sign in using another method and then create a passkey for your account!',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit} enableReinitialize={false}>
				<Stack spacing={3}>
					<Identity
						type='type'
						name='ident'
						handlePasskeyClick={handlePasskeyClick}
					/>

					<PasswordForm />

					<AlertView />

					<Errors />
				</Stack>
			</FormikForm>

			<Divider>or</Divider>

			<SocialSignin
				onLogin={handleResponse}
				account={account}
				invite={invite}
			/>
		</>
	);
}

// function PasswordSignin(props) {
// 	// For this to remain secure, user must not share the registration link!
// 	const { invited } = useContext(SigninContext);
//
// 	return (
// 			<LoginForm />}
// 			{page === 1 && <PasswordForm changeIdentity={() => setPage(0)} {...props} />}
// 		</>
// 	);
// }

export default LoginForm;
