import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useCallback, useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import {
	FormikForm,
	FormikMeasuresField,
	FormikNumberField,
} from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import useMeasures from 'src/utilities/hooks/useMeasures';

import styles from 'src/styles/modal.styles';
import * as yup from 'yup';

// /*
// // How much to take from each storage item
// export interface SKUStorageItem {
//   amount: number;
//   asset_type: string;
//   asset_id: string;
//   rw_asset_id: string;
// }
//
// open={!!selectedAsset}
// item={selectedAsset}
// items={assets}
// upsert={handleAssetUpdate}
// onClose={handleAssetUpdate}

function StorageSetupModal({
	item,
	open,
	onClose,
	unit,
	upsert,
	title = 'Allocation',
}) {
	// asset_id: path,
	// 	asset_type,
	// 	rw_asset_id,
	// 	unique_location_id,
	// 	processed,
	// 	amount: 0,
	// 	unit: itemUnit,

	console.log('Storage selection item', unit, item);

	const { amount, rw_asset_id, unit: itemUnit = unit } = item ?? {};

	const { to: cto, from: cfrom } = useMeasures();

	const changeSet = useMemo(
		() => ({
			amount: [
				itemUnit && amount ? cto(amount, itemUnit) : amount ?? '',
				yup.number().min(0, 'Must be positive!').required('Amount required!'),
			],
		}),
		[cto, amount, unit],
	);

	const handleSubmit = useCallback(
		(values) => {
			console.log('Storage item', item, values);

			const skuStorage = {
				...item,
				// processed: ASSET_PROCESSED_STATUS.CONFIRMED,
				amount: itemUnit ? cfrom(values.amount, itemUnit) : values.amount,
			};
			onClose(skuStorage);
		},
		[item],
	);

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>
						{rw_asset_id} {title}
					</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={1}>
							{!!unit && (
								<FormikMeasuresField
									label='Amount'
									name='amount'
									measure={unit}
									fullWidth
								/>
							)}
							{!unit && (
								<FormikNumberField label='Items' name='amount' fullWidth />
							)}

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small'>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default StorageSetupModal;
