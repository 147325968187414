/* eslint-disable react/no-array-index-key */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import BinIcon from 'src/components/icons/BinIcon';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { Body3 } from 'src/components/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { shadow_3 } from 'src/styles/shadow';
import debounce from 'src/utilities/debounce';
import { getFirstNDataForFieldPreview } from '../utils';

const styles = {
	'&.root': {
		width: '100%',
		height: '100%',
		maxHeight: '60vh',
		overflowY: 'auto',
		overflowX: 'hidden',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '0.5rem',
		gap: '1rem',

		'.drop-item': {
			borderRadius: '.25rem',
			borderWidth: '1px',
			padding: '.5rem',
			borderStyle: 'dashed',
			borderColor: '#b7b7b7',
			backgroundColor: '#f0f0f0',
			position: 'relative',

			'&[data-is-over=true]': {
				borderStyle: 'solid',
				...shadow_3,
			},

			'&[data-is-filled=true]': {
				borderStyle: 'solid',
				borderColor: '#cecece',
			},
		},
	},

	'.title': {
		textAlign: 'left',
		color: '#1a3352',
	},
	'.dnd': {
		color: '#404040',
	},
	'.Drag-me': {
		fontSize: '10px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.9',
		letterSpacing: 'normal',
		textAlign: 'left',
		color: '#ea1f1f',
		marginRight: '5px',
	},
	'.body': {
		fontSize: '13px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.46',
		letterSpacing: 'normal',
		textAlign: 'left',
		color: '#1a3352',
	},
	'.Drag-me-btn': {
		padding: '0px',
		margin: '0px',
	},

	'.label': {
		padding: '0px 5px 0px 5px',
		display: 'block',
		transformOrigin: 'left top',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 'calc(133% - 32px)',
		position: 'absolute',
		left: '0px',
		top: '0px',
		transform: 'translate(14px, -9px) scale(1)',
		transition:
			'color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
		zIndex: '1',
		pointerEvents: 'auto',
		userSelect: 'none',
		fontSize: '11px',
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.73',
		letterSpacing: 'normal',
		textAlign: 'left',
		color: '#1a3352',
	},
	'.label-hide': {
		backgroundColor: '#f0f0f0',
		color: 'transparent',
	},
};

function DataItem({ fieldName, label, data, disabled, onReset, mappingValue }) {
	const [isOpen, setOpen] = useState(false);
	const toggle = useCallback(() => {
		setOpen(!isOpen);
	}, [isOpen]);

	const [{ isOver }, drop] = useDrop(() => ({
		accept: 'test',
		drop: () => ({ fieldName }),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));

	return (
		<Box
			sx={styles}
			className='drop-item'
			data-is-over={isOver}
			data-is-filled={disabled}
			ref={drop}
		>
			<legend aria-hidden='true' className='label label-hide'>
				{label}
			</legend>
			<label className='label'>{label}</label>
			{disabled ? (
				<FlexBox>
					<Body3>{mappingValue.join(', ')}</Body3>

					<FlexBox sx={{ maxWidth: 'min-content' }}>
						{isOpen ? (
							<ArrowDropUpIcon onClick={toggle} className='up-down-icon' />
						) : (
							<ArrowDropDownIcon onClick={toggle} className='up-down-icon' />
						)}
						<ButtonBase
							size='small'
							variant='text'
							className='Drag-me-btn'
							onClick={onReset}
						>
							<BinIcon className='Drag-me' />
						</ButtonBase>
					</FlexBox>
				</FlexBox>
			) : (
				<FlexBox>
					<Body3>Drag and drop here</Body3>
				</FlexBox>
			)}
			{isOpen && (
				<Collapse in={isOpen}>
					<Stack spacing={0.25}>
						{data.map((d, index) => (
							<Body3 key={`${d}+${index}`}>{`${d}`}</Body3>
						))}
					</Stack>
				</Collapse>
			)}
		</Box>
	);
}

export default function PaginatedDroppableList({ rawData, mappings, model, removeMapping }) {
	const { data: fileData } = rawData ?? {};
	const { data = [] } = fileData ?? {};
	const list = useMemo(() => Object.entries(model), [mappings]);

	const handleOnReset = useCallback(
		(key) => (e) => {
			e.stopPropagation();
			e.preventDefault();
			debounce(() => removeMapping(key), 25);
		},
		[removeMapping],
	);

	return (
		<Stack sx={styles} className='root'>
			{list.map(([key, value]) => (
				<DataItem
					key={key}
					fieldName={key}
					label={value.label}
					onReset={handleOnReset(key)}
					data={getFirstNDataForFieldPreview(mappings[key], 3, data)} // TODO: convert to array
					disabled={!!mappings[key].length}
					mappingValue={mappings[key]}
				/>
			))}
		</Stack>
	);
}
