import { AccountCircleRounded, AddCircle, PendingActions, Send } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';
import TitledButton from 'src/components/Buttons/TitledButton';
import Instructions from 'src/components/Instructions';
import AccountDisplay from 'src/components/properties/AccountDisplay';
import IDDisplay from 'src/components/properties/IDDisplay';
import { Body3, H5, Overline } from 'src/components/Typography';
import { removeUser } from 'src/services/company.service';
import styles from 'src/styles/content.styles';
import useAlertView from 'src/utilities/hooks/useAlertView';
import { useCompanyUsersQuery } from 'src/utilities/hooks/useCompanyUsersQuery';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import User from './UserForm';
import { selectCompanyUsersWithRoles, setCompanyUsers, useCompanyUsers } from 'src/redux/containers/Companies';
import { useDispatch, useSelector } from 'react-redux';
import { FlexBox } from 'src/components/Layout/FlexBox';
import PersonOffIcon from '@mui/icons-material/PersonOff';

function UserItem({ user, disableAccount, editInvite }) {
  const { path, account_id, account_username, account_name, role, team, invited, joined } = user;
  // console.log('%cindex.js line:14 user', 'color: white; background-color: #007acc;', account);
  return (
    <Box className="listItem">
      <FlexBox>
        <Box className="listContent">
          <Stack direction='row' spacing='0.75rem'>
            {!account_id && (
              <PendingActions title='Pending' aria-label='Pending' />
            )}
            {account_id && <AccountCircleRounded />}
            <Stack spacing={0.25}>
              <H5>{account_name}</H5>
              <IDDisplay id={account_id} />
              <Body3>{account_username}</Body3>
              {role && <AccountDisplay name={role.id} />}
              {team && <AccountDisplay name={team.id} />}
              {!account_id && <Overline>{new Date(invited).toLocaleString()}</Overline>}
            </Stack>
          </Stack>
        </Box>

        <Stack className='listButtons'>
          {account_id && joined && (
            <IconCircleButton onClick={() => disableAccount(user)}>
              <PersonOffIcon />
            </IconCircleButton>
          )}

          {(!account_id || !joined) && (
            <IconCircleButton
              onClick={() => editInvite({ name: account_name, email: path.split('/').reverse()[0], role_id: role?.id ? `/r/${role?.id}` : undefined, team_id: team?.id ? `/t/${team?.id}` : undefined })}
            >
              <Send />
            </IconCircleButton>
          )}
        </Stack>
      </FlexBox>
    </Box>
  );
}

function Users() {
  const dispatch = useDispatch();
  const users = useCompanyUsers();

  // console.log('%cindex.js line:57 users', 'color: white; background-color: #007acc;', users);

  const [target, scroll] = useScrollTarget();
  const [, setLoading] = useLoadingHook();

  const [AlertView, { setAlert }] = useAlertView();

  const [newUser, setNewUser] = useState(null);

  useEffect(() => scroll(), [scroll]);

  const disableAccount = (user) => {
    console.log('Disabling', user);
    setLoading(true);
    setAlert(null);

    removeUser({ user_id: user.account_id })
      .then((response) => {
        console.log('Disabled user', response);

       })
      .catch((e) => {
        console.log('Failed to remove user!', e);
        setAlert({
          severity: 'error',
          title: 'Failed to remove',
          content: e.message,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      <Box className="header">
        <H5>Manage Users</H5>

        <TitledButton
          handleClick={() => {
            setNewUser({});
            scroll();
          }}
          variant='outlined'
          color='secondary'
          label='New'
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>Manage the users in your organization.</Instructions>

      <Collapse in={!!newUser}>
        <User
          user={newUser}
          addUser={(addedUser) => {
            setNewUser(false);
            if (addedUser) {
              console.log('Adding user', [...(users ?? []), addedUser]);
              dispatch(setCompanyUsers([...(users ?? []), addedUser]));
            }
            scroll();
          }}
        />
      </Collapse>

      <AlertView />

      <Stack spacing={0} className="list">
        {users.map((p) => <UserItem key={p.account_id} user={p} disableAccount={disableAccount} editInvite={setNewUser} />)}
      </Stack>

    </Stack>
  );
}

export default Users;
