import withDialogCard from 'src/components/Cards/DialogCard';
import Teams from 'src/components/Company/admin/Teams';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function TeamsPage() {
  usePageTitle('Manage Teams');

  return (
    <Teams />
  );
}

export default withAppLayout(withDialogCard(TeamsPage));
