import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import React, { useMemo } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import {
	FormikForm,
	FormikMeasuresField,
	FormikSelect,
} from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidSummary from 'src/components/SKUSelector/items/LiquidSummary';
import { H5 } from 'src/components/Typography';
import { MEASURE } from 'src/consts/measures';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import styles from 'src/styles/modal.styles';
import { AlertView } from 'src/utilities/hooks/useAlertView';
import useMeasures from 'src/utilities/hooks/useMeasures';
import * as yup from 'yup';

function DutySubmissionSourceAssetModal({ item, open, onClose, taxCodes }) {
	const { to: cto } = useMeasures();
	const { rw_asset_id, properties = {} } = item ?? {};
	const { liquid = {} } = properties;
	const { level = {}, sku = {} } = liquid;
	const {
		la,
		bl,
		duty_paid_bl,
		duty_paid_la,
		tax_code = sku?.tax_code,
	} = level;
	console.log(`tax_code ${tax_code}`);

	const changeSet = useMemo(
		() => ({
			duty_paid_bl: [
				Number(cto(duty_paid_bl ?? bl ?? 0, MEASURE.l_m)).toFixed(2),
				yup.number().min(0, 'Must be positive!'),
			],
			duty_paid_la: [
				Number(cto(duty_paid_la ?? la ?? 0, MEASURE.l_m)).toFixed(2),
				yup.number().min(0, 'Must be positive!'),
			],
			tax_code: [
				tax_code ?? '',
				yup.string().required('Please select tax code!'),
			],
		}),
		[cto, duty_paid_bl, bl, duty_paid_la, la, tax_code],
	);

	const handleSubmit = (values) => {
		console.log('submitted', values);
		const { duty_paid_bl, duty_paid_la, tax_code: tCode } = values ?? {};
		const valid = !!duty_paid_bl && !!duty_paid_la && !!tCode;
		onClose({
			...item,
			properties: {
				...properties,
				liquid: {
					...liquid,
					level: {
						...level,
						duty_paid_bl,
						duty_paid_la,
						tax_code: tCode,
					},
				},
			},
			processed: valid
				? ASSET_PROCESSED_STATUS.CONFIRMED
				: item.processed
					? item.processed
					: ASSET_PROCESSED_STATUS.PENDING,
		});
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{rw_asset_id}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
						gap: '1.5rem',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Instructions>Duty Submission.</Instructions>

					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={2}>
							<FlexBox gap={2}>
								<FormikMeasuresField
									label='Bulk liter'
									name='duty_paid_bl'
									measure={MEASURE.l_m}
									fullWidth
								/>
								<FormikMeasuresField
									label='La'
									name='duty_paid_la'
									measure={MEASURE.l_m}
									fullWidth
								/>
							</FlexBox>
							<FlexBox gap={2}>
								<FormikSelect
									label={'Tax Code'}
									name='tax_code'
									options={taxCodes}
									fullWidth
								/>
							</FlexBox>
							<LiquidSummary level={level} />
							<AlertView />
							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small'>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default DutySubmissionSourceAssetModal;
