import UploadWizard from 'src/components/BatchUpload/Wizard';
import {
	ASSET_CASK_TAGS_MAPPING,
	ASSET_LIQUID_TAGS_MAPPING,
	ASSET_TYPES,
	ASSET_TYPES_REVERSE,
} from 'src/consts/asset';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback, useMemo } from 'react';
import useIsUsUser from 'src/utilities/hooks/useIsUsUser';
import { getMaxMatchingString } from 'src/utilities/stringSimilarity';
import * as Yup from 'yup';

dayjs.extend(customParseFormat);

const transformToCaskTagsFormat = (tags) => {
	if (!tags) return [];
	if (ASSET_CASK_TAGS_MAPPING[tags.toLowerCase()])
		return ASSET_CASK_TAGS_MAPPING[tags.toLowerCase()];
	const { result: key } = getMaxMatchingString(tags, Object.keys(ASSET_CASK_TAGS_MAPPING));
	const v = ASSET_CASK_TAGS_MAPPING[key];
	return v ? v.split(',') : [];
};

const transformToLiquidTagsFormat = (tags) => {
	if (!tags) return [];
	const { result: key } = getMaxMatchingString(tags, Object.keys(ASSET_LIQUID_TAGS_MAPPING));
	const v = ASSET_LIQUID_TAGS_MAPPING[key];
	return v ? v.split(',') : [];
};

const optionalString = Yup.string().when({
	is: (exists) => !!exists,
	then: (schema) => schema.required('Value is required!'),
	otherwise: (schema) => schema,
});

const getLiquidLevelPropertiesMapping = () => ({
	bl: {
		label: 'Bulk',
		validation: optionalString,
		transform: (s) => Number(s),
	},
	la: {
		label: 'Alcohol',
		validation: optionalString,
		transform: (s) => Number(s),
	},
	abv: {
		label: 'Strength',
		validation: optionalString,
		transform: (s) => Number(s),
	},
	tcf: {
		label: 'TCF',
		validation: optionalString,
		transform: (s) => Number(s),
	},
});

function Upload({ sku, onCancel, onConfirm }) {
	const isUS = useIsUsUser();

	const type = sku?.asset_type ?? ASSET_TYPES.cask;

	const ACCEPTED_DATE_FORMATS = useMemo(() => {
		const ACCEPTED_US_DATE_FORMATS = [
			'MM/DD/YY',
			'M/D/YY',
			'MM-DD-YY',
			'M-D-YY',
			'MM.DD.YY',
			'M.D.YY',
			'MM-DD-YYYY',
			'MM.DD.YYYY',
			'MM/DD/YYYY',
		];
		const ACCEPTED_INT_DATE_FORMATS = [
			'DD-MM-YY',
			'D-M-YY',
			'DD.MM.YY',
			'D.M.YY',
			'DD/MM/YY',
			'D/M/YY',
			'DD-MM-YYYY',
			'DD.MM.YYYY',
			'DD/MM/YYYY',
		];
		return isUS ? ACCEPTED_US_DATE_FORMATS : ACCEPTED_INT_DATE_FORMATS;
	}, [isUS]);

	const displayDateFormat = useMemo(() => (isUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY'), [isUS]);

	const transformToDate = useCallback((s, def) => {
		if (s) {
			if(dayjs(s, displayDateFormat).isValid()){
				return dayjs(s, displayDateFormat);
			}
			return dayjs(s, ACCEPTED_DATE_FORMATS);
		}
		return def;
	}, [displayDateFormat, ACCEPTED_DATE_FORMATS]);

	const checkDateFormat = (dateString) => {
		console.log('Checking date format', dateString);
		return dayjs(dateString, ACCEPTED_DATE_FORMATS).isValid();
	};

	const checkDate = (dateString) => {
		console.log('Checking date', dateString);
		return dayjs(dateString, ACCEPTED_DATE_FORMATS).isBefore(dayjs());
	};

	const transformToDateFormat = useCallback(
		(stringDate) => {
			if(dayjs(stringDate, displayDateFormat).isValid()){
				return dayjs(stringDate, displayDateFormat).format(displayDateFormat);
			}else{
				return dayjs(stringDate, ACCEPTED_DATE_FORMATS).format(displayDateFormat);
			}
		},
		[ACCEPTED_DATE_FORMATS, displayDateFormat],
	);

	const dateSchema = Yup.string().when({
		is: (exists) => !!exists,
		then: (schema) =>
			schema
				.test('format_check', 'Invalid date format!', checkDateFormat)
				.transform(transformToDateFormat)
				.test('logical_check', 'Invalid date!', checkDate),
		otherwise: (schema) => schema.nullable(),
	});

	const IBC_MODEL = useMemo(
		() => ({
			rw_asset_id: {
				label: 'IBC ID',
				validation: Yup.string().required('Asset id is required!'),
				transform: (s) => s,
				isRequired: true,
			},
			check_in_date: {
				label: 'Create Date',
				validation: dateSchema,
				transform: transformToDate,
			},
			// liquid properties
			liquid_tags: {
				label: 'Liquid Properties',
				validation: optionalString,
				transform: transformToLiquidTagsFormat,
			},
			date: {
				label: 'Production Date',
				validation: dateSchema,
				transform: transformToDate,
			},
			// liquid level properties
			...getLiquidLevelPropertiesMapping(),
		}),
		[dateSchema],
	);

	const CASK_MODEL = useMemo(
		() => ({
			rw_parent_asset_id: {
				asset_type: ASSET_TYPES.pallet,
				label: 'Pallet ID',
				validation: optionalString,
				transform: (s) => s,
			},
			rw_asset_id: {
				label: 'Cask ID',
				validation: Yup.string().required('Asset id is required!'),
				transform: (s) => s,
				isRequired: true,
			},
			check_in_date: {
				label: 'Create Date',
				validation: dateSchema,
				transform: transformToDate,
			},
			// wood properties
			cask_tags: {
				label: 'Cask Properties',
				validation: optionalString,
				transform: transformToCaskTagsFormat,
			},
			// liquid properties
			liquid_tags: {
				label: 'Liquid Properties',
				validation: optionalString,
				transform: transformToLiquidTagsFormat,
			},
			date: {
				label: 'Production Date',
				validation: dateSchema,
				transform: transformToDate,
			},
			// liquid level properties
			...getLiquidLevelPropertiesMapping(),
		}),
		[optionalString, dateSchema],
	);

	const PALLET_MODEL = useMemo(
		() => ({
			rw_asset_id: {
				label: 'Pallet ID',
				validation: Yup.string().required('Asset id is required!'),
				transform: (s) => s,
				isRequired: true,
			},
			check_in_date: {
				label: 'Create Date',
				validation: dateSchema,
				transform: transformToDate,
			},
		}),
		[dateSchema],
	);

	const MODEL_MAP = useMemo(
		() => ({
			[ASSET_TYPES.cask]: CASK_MODEL,
			[ASSET_TYPES.ibc]: IBC_MODEL,
			[ASSET_TYPES.pallet]: PALLET_MODEL,
		}),
		[CASK_MODEL, IBC_MODEL, PALLET_MODEL],
	);

	const model = useMemo(() => MODEL_MAP[type], [MODEL_MAP, type]);

	const assetName = ASSET_TYPES_REVERSE[type];

	return (
		<UploadWizard
			sku={sku}
			type={type}
			assetName={assetName}
			model={model}
			onCancel={onCancel}
			onConfirm={onConfirm}
		/>
	);
}

export default Upload;
