import Print from '@mui/icons-material/Print';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from 'src/components/Buttons/TitledButton';
import withDialogCard from 'src/components/Cards/DialogCard';
import ExplorerLocation from 'src/components/ExplorerLocation';
import { explorerLocationFields } from 'src/components/ExplorerLocation/explorerLocationFields';
import LocationQRCodes from 'src/components/ExplorerLocation/LocationQRCodes';
import { FormikForm, FormikSelect } from 'src/components/Forms/FormikForm';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import React, { useContext, useMemo, useRef, useState } from 'react';
import printElement from 'src/utilities/printElement';
import FormikContext from 'src/context/FormikContext';
import {
	LOCATION_PRINT_MAP,
	LOCATION_PRINT_OPTIONS,
} from 'src/consts/facility';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { useSelector } from 'react-redux';
import { selectCompanyFacilities } from 'src/redux/containers/Companies';

function makeNumbers(number) {
	return Array(+number)
		.fill(0)
		.map((_, i) => i + 1);
}

function Content({ facilities = {} }) {
	const [filter, setFilter] = useState(LOCATION_PRINT_MAP.all);
	const printRef = useRef();
	const { values } = useContext(FormikContext);

	const handlePrint = () => {
		const element = printRef?.current?.innerHTML;
		printElement({
			element,
			styles:
				'p{ margin-top: 0; margin-bottom: 10px;   text-overflow: ellipsis };',
		});
	};

	const currentWarehouse = useMemo(
		() => facilities?.[values?.location?.facility_id] ?? {},
		[values?.location?.facility_id, facilities],
	);

	const rows = useMemo(() => {
		if (values?.location?.row) return [values?.location?.row];
		const bayRef = currentWarehouse?.bays?.find(
			(b) => b?.[0] === values?.location?.bay,
		);
		return makeNumbers(bayRef?.[1] ?? 0);
	}, [values?.location?.row, values?.location?.bay, currentWarehouse?.bays]);

	const levels = useMemo(() => {
		if (values?.location?.level) return [values?.location?.level];
		const bayRef = currentWarehouse?.bays?.find(
			(b) => b?.[0] === values?.location?.bay,
		);
		return makeNumbers(bayRef?.[2] ?? 0);
	}, [values?.location?.level, values?.location?.bay, currentWarehouse?.bays]);

	const filterOptions = useMemo(() => {
		if (!values?.location?.facility_id) {
			return LOCATION_PRINT_OPTIONS.filter(
				(o) =>
					o.value !== LOCATION_PRINT_MAP.rows &&
					o.value !== LOCATION_PRINT_MAP.bays,
			);
		}
		if (!values?.location?.bay) {
			return LOCATION_PRINT_OPTIONS.filter(
				(o) => o.value !== LOCATION_PRINT_MAP.rows,
			);
		}
		return LOCATION_PRINT_OPTIONS;
	}, [values]);

	return (
		<Stack spacing={3}>
			<FlexBox gap={2}>
				<FormikSelect
					fullWidth
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					options={filterOptions}
					label='Print'
					withNone={false}
				/>
				<TitledButton
					onClick={handlePrint}
					label='Print'
					variant='outlined'
					color='secondary'
					sx={{ width: '72px', height: '55px' }}
				>
					<Print height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box ref={printRef}>
				<LocationQRCodes
					filter={filter}
					origin={origin}
					facilities={facilities}
					facility_id={values?.location?.facility_id}
					bay={values?.location?.bay}
					bays={values?.location?.bay}
					rows={rows}
					levels={levels}
				/>
			</Box>
		</Stack>
	);
}

function Locations() {
	const changeSet = { location: explorerLocationFields({}) };
	const facilities = useSelector(selectCompanyFacilities);

	return (
		<FormikForm changeSet={changeSet}>
			<Stack spacing={2}>
				<ExplorerLocation
					name='location'
					label='Storage Location'
					facilities={facilities}
					withPrintAllButton={false}
				/>
				<Divider />
				<Content facilities={facilities} />
			</Stack>
		</FormikForm>
	);
}

export default withAppLayout(withDialogCard(Locations));
