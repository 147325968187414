import { Box } from '@mui/material';
import { useState } from 'react';
import Customers from 'src/components/Company/admin/Customers';
import CustomerAssets from 'src/components/Company/admin/Customers/components/CustomerAssets';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { CLIENT_TYPE } from 'src/consts/contacts';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function CustomerPage() {
	const [customer, setCustomer] = useState(null);
	usePageTitle('Manage Customers');
	return (
		<Box className='adaptive-content'>
			<Customers customer={customer} setCustomer={setCustomer} />
			{!!customer?.account_id && (
				<CustomerAssets type={CLIENT_TYPE.ACCOUNT} id={customer?.account_id} />
			)}
		</Box>
	);
}

export default withAppLayout(CustomerPage);
