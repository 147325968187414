// TODO: grab from theme / use existing mui responsive in here?
// how to mesh together?
export const _bp_xs = '30em'; // 480
export const _bp_sm = '37.5em'; // 600
export const _bp_md = '60em'; // 960
export const _bp_lg = '75em'; // 1200
export const _bp_xl = '90em'; // 1440
export const _bp_xxl = '120em'; // 1920

export const bp = {
	xs: _bp_xs,
	sm: _bp_sm,
	md: _bp_md,
	lg: _bp_lg,
	xl: _bp_xl,
	xxl: _bp_xxl,
};

export const mq = (size = 'xs', selector = 'up') => {
	const selectorValid = ['up', 'down'];
	if (selectorValid.indexOf(selector) === -1) {
		console.error(`invalid direction passed to "mq" helper.
      value "${selector}" is not one of "${selectorValid}"`);
		return {};
	}
	if (!bp[size]) {
		console.error(`invalid breakpoint size passed to "mq" helper.
    value "${size}" is not one of "${Object.keys(bp)}"`);
		return {};
	}
	const property = 'width';
	const condition = selector === 'up' ? 'min' : 'max';
	const breakpoint = bp[size];
	return `@media(${condition}-${property}: ${breakpoint})`;
};

export const media_sm_up = (styles) => ({
	[mq()]: {
		...styles,
	},
});

export const media_md_up = (styles) => ({
	[mq('md')]: {
		...styles,
	},
});

export const media_lg_up = (styles) => ({
	[mq('lg')]: {
		...styles,
	},
});

export const media_xl_up = (styles) => ({
	[mq('xl')]: {
		...styles,
	},
});

export const media_xxl_up = (styles) => ({
	[mq('xxl')]: {
		...styles,
	},
});

export const media_xs_down = (styles) => ({
	[mq('xs', 'down')]: {
		...styles,
	},
});

export const media_sm_down = (styles) => ({
	[mq('sm', 'down')]: {
		...styles,
	},
});

export const media_md_down = (styles) => ({
	[mq('md', 'down')]: {
		...styles,
	},
});

export const media_lg_down = (styles) => ({
	[mq('lg', 'down')]: {
		...styles,
	},
});

export const media_xl_down = (styles) => ({
	[mq('xl', 'down')]: {
		...styles,
	},
});

export const media_xxl_down = (styles) => ({
	[mq('xxl', 'down')]: {
		...styles,
	},
});
