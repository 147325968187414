import { APP_API_GATEWAY } from 'src/consts/config';
import fetchDelete from 'src/utilities/fetchDelete';
import fetchPost from 'src/utilities/fetchPost';
import fetchGet from 'src/utilities/fetchGet';
import fetchPut from 'src/utilities/fetchPut';
import objectToQueryString from 'src/utilities/objectToQueryString';

const PUB_BASE = `${APP_API_GATEWAY}/accounts-pub`;
const PRIV_BASE = `${APP_API_GATEWAY}/accounts`;

// export const getCompanyList = async (request = {}, headers = {}) =>
// 	fetchPost(`${BASE}/list`, headers, request);

// export const fundAccount = async (request = {}, headers = {}) =>
// 	fetchPost(`${BASE}/fund-account`, headers, request);

// export const activateCompany = async (request = {}, headers = {}) =>
// 	fetchPost(`${BASE}/activate`, headers, request);

// export const installCompanyContract = async (request = {}, headers = {}) =>
// 	fetchPost(`${BASE}/install-contract`, headers, request);

// CRUD
export const getCompany = async (request = {}, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company`, headers, request);
export const createCompany = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company`, headers, request);
export const updateCompany = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/company`, headers, request);

// export const getCompany = async (params, headers = {}) =>
// 	fetchGet(`${PRIV_BASE}/company${objectToQueryString(params)}`, headers);

// USERS
export const listUsers = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company/users`, headers);
export const inviteUser = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/user`, headers, request);
export const updateUser = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/user`, headers, request);
export const removeUser = async (request, headers = {}) =>
	fetchDelete(`${PRIV_BASE}/user`, headers, request);

// ROLES
export const listRoles = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company/roles${objectToQueryString(params)}`, headers);
export const createRole = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/roles`, headers, request);
export const updateRole = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/company/roles`, headers, request);

// TEAMS
export const listTeams = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company/teams${objectToQueryString(params)}`, headers);
export const createTeam = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/teams`, headers, request);
export const updateTeam = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/company/teams`, headers, request);

// PERMISSIONS
export const listPermissions = async () => fetchGet(`${PUB_BASE}/perm`);

// CUSTOMERS
export const listCustomers = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers`, headers, request);
export const createCustomer = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/create`, headers, request);
export const updateCustomer = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/update`, headers, request);
export const inviteCustomer = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/invite`, headers, request);
export const resetCustomerPassword = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/reset`, headers, request);
export const uploadCustomers = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/upload`, headers, request);

// PARTNERS
export const listPartners = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners`, headers, request);
export const createPartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/create`, headers, request);
export const updatePartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/update`, headers, request);
export const shareWithPartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/share`, headers, request);
export const confirmPartnerRequest = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/confirm`, headers, request);

// SEARCH CUSTOMERS AND PARTNERS
export const searchPartnersAndRequestsRequest = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers-partners/search`, headers, request);

export const customerPartnerAssets = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers-partners/assets`, headers, request);
