import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import withSmallDialogCard from 'src/components/Cards/SmallDialogCard';
import ReportListItem from 'src/components/ReportListItem';
import { H5 } from 'src/components/Typography';
import { REPORT_EDIT_URL } from 'src/consts/reports';
import { bg_near_white } from 'src/styles/colors';

function LatestAddedReports({ items = [], navigationKey = 'path' }) {
	const navigate = useNavigate();

	const goToItem = useCallback(
		(params) => {
			const id = params?.[navigationKey] ?? 'new';
			navigate(`${REPORT_EDIT_URL[params?.report_type]}/${id}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[navigationKey, navigate],
	);

	return (
		<Box>
			<H5>Latest Added Reports</H5>
			<Stack
				spacing={2}
				sx={{ '>:nth-of-type(even)': { ...bg_near_white }, marginTop: '1rem' }}
			>
				{items.map((i) => (
					<ReportListItem
						key={i.path}
						item={i}
						buttonIcon={<ArrowForward />}
						onItemClick={() => goToItem(i)}
					/>
				))}
			</Stack>
		</Box>
	);
}

export default withSmallDialogCard(LatestAddedReports);
