import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AssetForm from 'src/components/AssetForm/AssetForm';
import withDialogCard from 'src/components/Cards/DialogCard';
import LatestAddedAssets from 'src/components/LatestAdded/LatestAddedAssets';
import ItemSkeleton from 'src/components/sekeletons/ItemSkeleton';
import SkeletonSummary from 'src/components/sekeletons/SkeletonSummary';
import AssetSummary from 'src/components/summary/AssetSummary';
import { ASSET_EDIT_URL } from 'src/consts/asset';
import styles from 'src/styles/content.styles';
import useAssetQuery from 'src/utilities/hooks/useAssetQuery';
import useGetId from 'src/utilities/hooks/useGetId';
import usePageTitle from 'src/utilities/hooks/usePageTitle';
import useReadOnly from 'src/utilities/hooks/useReadOnly';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';

const AssetFormHandlerImpl = withDialogCard(
	({ children, assetType, readonly, onSave, ...rest }) => {
		const navigate = useNavigate();
		const [target, scroll] = useScrollTarget();

		// eslint-disable-next-line react-hooks/exhaustive-deps
		useEffect(scroll, []);

		const handleSave = (mutatedAsset) => {
			console.log('Mutated asset', mutatedAsset);
			const route = ASSET_EDIT_URL?.[assetType];
			navigate(`${route}/${mutatedAsset?.path}`, { replace: true });
			onSave?.(mutatedAsset);
		};

		return (
			<Box sx={styles} className='root' ref={target}>
				<AssetForm
					onSave={handleSave}
					assetType={assetType}
					readonly={readonly}
					{...rest}
				>
					{children}
				</AssetForm>
			</Box>
		);
	},
);

function NewForm({ changeSetGenerator, onInit, ...rest }) {
	let [searchParams] = useSearchParams();

	const { assetType, assetLabel } = rest;

	// AI stuff disabled for now
	// const [suggested, setSuggested] = useState(null);
	let clone = searchParams.get('clone');

	const formValues = useMemo(() => {
		// See if there is a clone parameter
		let obj = {};
		if (clone) {
			try {
				const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
				obj = JSON.parse(cv);

				if (onInit) {
					onInit(obj);
				} else {
					onInit({});
				}
			} catch (e) {
				console.log('Failed to decode clone!', e);
			}
		}
		// if (suggested) {
		//   const { properties = {} } = suggested;
		//   obj = { ...suggested, properties: { ...properties, [suggested.asset_type]: properties.tags ?? [] } };
		// }
		return { ...obj, asset_type: assetType };
	}, [assetType, clone]);

	const changeSet = useMemo(
		() => changeSetGenerator(formValues),
		[changeSetGenerator, formValues],
	);

	usePageTitle(`Create ${assetLabel}`);

	return (
		<AssetFormHandlerImpl changeSet={changeSet} {...rest} />
	);
}
// const DSensorWidget = withSmallDialogCard(SensorWidget);

function LoadAsset({ changeSetGenerator, onInit, assetLabel, ...rest }) {
	const { id, assetType } = rest;
	const { data: entity, isError, refetch, isLoading } = useAssetQuery(id);
	const { readonly } = useReadOnly(entity);

	usePageTitle(`${assetLabel} ${entity?.rw_asset_id ?? '...'}`);

	// Generate the change set
	const formValues = useMemo(() => {
		// Specific initializer
		if (onInit) {
			onInit(entity);
		}
		return { ...(entity ?? {}), asset_type: assetType };
	}, [entity, assetType]);

	const changeSet = useMemo(
		() => changeSetGenerator(formValues),
		[changeSetGenerator, formValues],
	);

	const refreshData = useCallback(() => refetch(id), [id, refetch]);

	return (
		<Box className='adaptive-content'>
			{isLoading && (
				<>
					<SkeletonSummary />
					<ItemSkeleton />
				</>
			)}

			{isError && (
				<NewForm
					changeSetGenerator={changeSetGenerator}
					onInit={onInit}
					{...rest}
				/>
			)}
			{entity && changeSet && (
				<>
					<AssetSummary entity={entity} refresh={refreshData} />
					<AssetFormHandlerImpl
						entity={entity}
						changeSet={changeSet}
						edit
						readonly={readonly}
						onSave={refreshData}
						{...rest}
					/>
				</>
			)}
			<LatestAddedAssets assetType={assetType} />
		</Box>
	);
}

function AssetFormHandler(props) {
	const { id, path } = useGetId();
	console.log('AssetFormHandler', id, path, props);
	const navigate = useNavigate();
	let location = useLocation();
	useEffect(() => {
		if (id.length !== 3 && id.length > 0) {
			navigate(location.pathname.replace('/[[...id]]', '/new'));
		}
	}, [id, location.pathname, navigate, path]);

	return (
		<>
			{id.length !== 3 && (
				<Box className='adaptive-content'>
					<NewForm {...props} path={path} />
					<LatestAddedAssets assetType={props.assetType} />
				</Box>
			)}
			{id.length === 3 && <LoadAsset id={id} path={path} {...props} />}
		</>
	);
}

export default AssetFormHandler;
