import { APP_API_GATEWAY } from 'src/consts/config';
import fetchGet from 'src/utilities/fetchGet';
import fetchPost from 'src/utilities/fetchPost';
import objectToQueryString from 'src/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/template`;

export const getTemplates = async (params, headers) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const createTemplate = async (template) => fetchPost(BASE, {}, template);
