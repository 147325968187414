import Stack from '@mui/material/Stack';
import { FlexBox } from 'src/components/Layout/FlexBox';
import AccountDisplay from 'src/components/properties/AccountDisplay';
import { Body3, Overline } from 'src/components/Typography';

function Assignee({ entry }) {
	const [, name] = entry ?? [];

	return <AccountDisplay name={name} />;
}

function AssigneeProps({ entity }) {
	const { assignees = [] } = entity ?? {};

	return (
		<FlexBox className='creator-view'>
			<Stack spacing={0.5} className='column'>
				<Overline>Assignees</Overline>
				{assignees.map((a, i) => (
					<Assignee key={`a_${a??i}`} entry={a} />
				))}
				{assignees.length === 0 && <Body3>Not Assigned</Body3>}
			</Stack>
		</FlexBox>
	);
}

export default AssigneeProps;
