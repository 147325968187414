import Search from '@mui/icons-material/Search';
import IconCircleButton from 'src/components/Buttons/IconCircleButton';

function SearchButton({ onClick, ...restProps }) {
	return (
		<IconCircleButton variant='contained' onClick={onClick} {...restProps}>
			<Search />
		</IconCircleButton>
	);
}

export default SearchButton;
