import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { Body2 } from 'src/components/Typography';
import { useEffect, useState } from 'react';

const PAGE_SIZES = ['5', '12', '25', '50', '100'];

function Pagination({
	hasNextPage,
	hasPrevPage,
	onPressNextPage,
	onPressPrevPage,
	onChangePageSize,
	pageSize: size,
	defaultPageSize = PAGE_SIZES[0],
}) {
	const [pageSize, setPageSize] = useState(size ?? defaultPageSize);

	useEffect(() => {
		onChangePageSize(pageSize);
	}, [pageSize]);

	return (
		<FlexBox gap={3} justifyContent='flex-end' width='100%'>
			<FormControl variant='filled'>
				<InputLabel id='page_size' shrink>
					Items
				</InputLabel>
				<Select
					labelId='page_size'
					native
					variant='filled'
					options={PAGE_SIZES}
					value={pageSize}
					onChange={(e) => setPageSize(e.target.value)}
				>
					{PAGE_SIZES.map((size) => (
						<option key={size} value={size}>
							{size}
						</option>
					))}
				</Select>
			</FormControl>
			<IconButton edge='end' onClick={onPressPrevPage} disabled={!hasPrevPage}>
				<ChevronLeftIcon />
			</IconButton>
			<IconButton edge='end' onClick={onPressNextPage} disabled={!hasNextPage}>
				<ChevronRightIcon />
			</IconButton>
		</FlexBox>
	);
}

export default Pagination;
