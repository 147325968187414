import { Box, Divider } from '@mui/material';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import RequestChargeCodeProperties from 'src/components/ChargeCode/RequestChargeCode';
import Client from 'src/components/Client';
import Errors from 'src/components/Forms/FormErrors';
import { FormikForm } from 'src/components/Forms/FormikForm';
import FormWrapper from 'src/components/Layout/FormWrapper';
import RequestID from 'src/components/RequestID';
import { REQUEST_TYPES } from 'src/consts/requests';
import React from 'react';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useRequestMutation from 'src/utilities/hooks/useRequestMutation';
import { useSelector } from 'react-redux';
import {
	selectAccountChain,
	selectAccountPlatform,
} from 'src/redux/containers/User';
import useCheckCompany from 'src/utilities/useCheckCompany';

function RequestForm({
	id,
	requestType,
	entity,
	title,
	changeSet,
	readonly = false,
	edit = false,
	onCancel,
	onBeforeSave,
	onSave,
	children,
}) {
	const [AlertView, { setAlert }] = useAlertView();

	const [mutate, { isLoading: isMutating = false }] = useRequestMutation();

	const platform = useSelector(selectAccountPlatform);
	const chain = useSelector(selectAccountChain);
	const [sameCompany] = useCheckCompany(entity);

	const handleSubmit = async (values) => {
		if (!sameCompany) {
			setAlert({
				severity: 'error',
				title: 'Forbidden',
				content: `Request ${response?.rw_request_id} has been ${id ? 'updated' : 'created'}.`,
			});

			return;
		}
		setAlert(null);
		try {
			const updated = onBeforeSave ? onBeforeSave(values) : values;

			console.log('Submitting', updated);
			const response = await mutate(id, {
				...entity,
				...updated,
				request_type: requestType,
				chain,
				platform,
			});
			console.log('Saved item', response);
			setAlert({
				severity: 'success',
				title: id ? 'Updated' : 'Created',
				content: `Request ${response?.rw_request_id} has been ${id ? 'updated' : 'created'}.`,
			});
			onSave(response);
		} catch (err) {
			setAlert({
				severity: 'error',
				title: id ? 'Failed to Update' : 'Failed to Add',
				content: err.message,
			});
		}
	};

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			enableReinitialize
			readonly={readonly}
			edit={edit}
		>
			<FormWrapper>
				<RequestID type={requestType} title={title} label='ID.' />

				{children}

				{!readonly && (
					<Client name='client' />
				)}

				<AlertView />

				{!readonly && (
					<>
						<Errors />

						<Box className='action-buttons'>
							{onCancel && (
								<TextButton
									size='small'
									handleClick={onCancel}
									color='secondary'
									disabled={isMutating}
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small' disabled={isMutating}>
								{id?.length ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</>
				)}
			</FormWrapper>
		</FormikForm>
	);
}

export default RequestForm;
