import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthToken } from 'src/redux/containers/Authorization';
import { setCompanyFacilities, setCompanySKUs, useCompanyId } from 'src/redux/containers/Companies';
import { getAllFacilities } from 'src/services/facility.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';
import { useCompanySKUsLazyQuery } from 'src/utilities/hooks/useCompanySKUsQuery';

const serviceFunction = (params) => getAllFacilities(params);
export const [useAllFacilitiesLazyQuery, useAllFacilitiesQuery] = genServiceCallHooks(serviceFunction);

export function useCompanyFacilitiesQuery() {
  const dispatch = useDispatch();

  const { data } = useAllFacilitiesQuery();

  useEffect(() => {
    dispatch(setCompanyFacilities(data ?? []));
  }, [data]);

  return data;
}
