import { useCallback, useMemo } from 'react';
import { useCompanySKUs } from 'src/redux/containers/Companies';
import { getSKUList } from 'src/services/sku.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params)=> getSKUList(params).then(({ items = [] }) => items);
export const [useGetSKUListLazyQuery, useGetSKUListQuery] = genServiceCallHooks(serviceFunction);

export default useGetSKUListQuery;

export const useGetSKUList = () => {

  const skus = useCompanySKUs();

  const search = useCallback((filter = [], param = '', itemFilter = (x) => x) => {
    // Find all skus matching this
    return skus.filter((sku) => {
      // Sku type must be in the filter
      if (filter.length !== 0 && !filter.includes(sku.sku_type)) {
        return false;
      }
      // Search term must be in the sku properties..
      const search_key = `${sku.sku_id} ${sku.sku_name} ${sku.sku_description}`.toLowerCase();
      return search_key.includes(param.toLowerCase());
    })
      .filter(itemFilter);
  }, [skus]);

  return [search];
};
