import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Instructions from 'src/components/Instructions';
import { FlexBox } from 'src/components/Layout/FlexBox';
import GeneralSourceSKU from 'src/components/SKUSelector/modals/source/GeneralSourceSKU';
import TrackedSKU from 'src/components/SKUSelector/modals/source/TrackedSKU';
import { H5 } from 'src/components/Typography';
import { SKU_TYPES } from 'src/consts/sku';
import React from 'react';
import styles from 'src/styles/modal.styles';
import ServiceSKU from './source/ServiceSKU';

function SourceSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_type, sku_description } = item ?? {};

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{sku_name}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<Instructions>{sku_description}</Instructions>

					{sku_type === SKU_TYPES.TRACKED && (
						<TrackedSKU item={item} onClose={onClose} {...rest} />
					)}

					{sku_type === SKU_TYPES.SERVICE && (
						<ServiceSKU item={item} onClose={onClose} {...rest} />
					)}

					{![SKU_TYPES.TRACKED, SKU_TYPES.SERVICE].includes(sku_type) && (
						<GeneralSourceSKU item={item} onClose={onClose} {...rest} />
					)}
				</Box>
			</Stack>
		</Modal>
	);
}

export function ExportSourceSKUModal(props) {
	return <SourceSKUModal {...props} exportItems={true} />;
}

export default SourceSKUModal;
