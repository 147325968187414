import Collapse from '@mui/material/Collapse';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import useInventorySelectorHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import DestinationSKUModal from 'src/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import debounce from 'src/utilities/debounce';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';

function DutyForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const filter = {
		asset_types: [
			ASSET_TYPES.cask,
			ASSET_TYPES.ibc,
			ASSET_TYPES.pallet,
			ASSET_TYPES.container,
		],
		sku_types: [SKU_TYPES.FINISHED, SKU_TYPES.WIP, SKU_TYPES.SERVICE],
	};

	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useInventorySelectorHook({
		title: 'Duty Suspended Goods',
		filter,
		SKUModal: SourceSKUModal,
		skuModalProps: { duty_paid: 'false' },
		assetAdapter: (a) => {
			if (a.id === '/t/' && a.properties?.duty_paid) {
				enqueueSnackbar(`Asset duty is paid!`, { variant: 'error' });
				return undefined;
			} else {
				return a;
			}
		},
	});

	const [
		[destinationSkus, , addDestinationSku],
		[destinationAssets, , addDestinationAsset],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Duty Paid Goods',
		filter,
		submitForm,
		SKUModal: DestinationSKUModal,
	});

	const hasDestinations = useMemo(
		() => destinationSkus.length > 0 || destinationAssets.length > 0,
		[destinationSkus, destinationAssets],
	);

	useEffect(() => {
		// If source changes, then add this to the destination..
		if (sourceSkus.length > 0) {
			// Find any skus that have changed..
			const changedSkus = sourceSkus.filter((s) => {
				const dest = destinationSkus.find((d) => d.sku_id === s.sku_id);
				if (!dest) {
					return true;
				}
				if (s.amount !== dest.amount) {
					return true;
				}
			});
			if (changedSkus.length === 1) {
				// Create a destination sku based on this and add it..
				debounce(
					() =>
						addDestinationSku({
							...changedSkus[0],
							entries: (changedSkus[0]?.entries ?? []).map(({ amount }) => ({
								amount,
								duty_paid: true,
							})),
						}),
					25,
				);
			}
		}
	}, [sourceSkus]);

	useEffect(() => {
		// If source changes, then add this to the destination..
		if (sourceAssets.length > 0) {
			const changedAssets = sourceAssets.filter((s) => {
				const dest = destinationAssets.find(
					(d) => d.rw_asset_id === s.rw_asset_id,
				);
				return !dest;
			});
			if (changedAssets.length > 0) {
				debounce(() => addDestinationAsset(changedAssets[0]), 25);
			}
		}
	}, [sourceAssets]);

	const changeSetGenerator = useMemo(
		() => (initialValues) => requestIdFields(initialValues),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: entity?.sku_destinations,
			});
		},
		[initSources, initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			// Check the details match
			if (
				sourceSkus.length > 0 &&
				sourceSkus.length !== destinationSkus.length
			) {
				throw new Error('Duty suspended and duty paid SKUs do not match!');
			}
			if (
				sourceAssets.length > 0 &&
				sourceAssets.length !== destinationAssets.length
			) {
				throw new Error('Duty suspended and duty paid assets do not match!');
			}

			const matchedSkus = sourceSkus.filter((s) => {
				const dest = destinationSkus.find((d) => d.sku_id === s.sku_id);
				if (!dest) {
					return false;
				}
				const sourceSkuAmount = (s.entries ?? [])?.reduce(
					(acc, entry) => acc + Number(entry?.amount ?? 0),
					0,
				);
				const destSkuAmount = (dest.entries ?? [])?.reduce(
					(acc, entry) => acc + Number(entry?.amount ?? 0),
					0,
				);
				return sourceSkuAmount === destSkuAmount;
			});
			if (matchedSkus.length !== sourceSkus.length) {
				throw new Error('Amounts do not match!');
			}

			const matchedAssets = sourceAssets.filter((s) => {
				const dest = destinationAssets.find(
					(d) => d.rw_asset_id === s.rw_asset_id,
				);
				return !!dest;
			});
			if (matchedAssets.length !== sourceAssets.length) {
				throw new Error('Assets do not match!');
			}

			return {
				...entity,
				sku_sources: sourceSkus,
				sources: sourceAssets,
				sku_destinations: destinationSkus,
				destinations: destinationAssets,
			};
		},
		[sourceSkus, sourceAssets, destinationSkus, destinationAssets],
	);

	console.log(destinationAssets);

	return (
		<>
			<RequestForm
				requestLabel='Duty Transfer'
				requestType={REQUEST_TYPES.duty_transfer}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />

				<Collapse in={!!hasDestinations}>
					<DestinationInventory />
				</Collapse>
				<FormSubmitter />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
		</>
	);
}

export default withAppLayout(DutyForm, { title: 'Duty Transfer' });
