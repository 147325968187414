import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useContext, useEffect, useState } from 'react';

import {
	FormikCheckBox,
	FormikDatePicker,
	FormikMeasuresField,
	FormikNumberField,
} from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import StrengthDisplay from 'src/components/properties/StrengthDisplay';
import { H5 } from 'src/components/Typography';
import { MEASURE } from 'src/consts/measures';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import debounce from 'src/utilities/debounce';
import useMeasures from 'src/utilities/hooks/useMeasures';

function LiquidLevelImpl({ title, disabled = false }) {
	const { values, setFieldValue } = useContext(FormikContext);

	const [normalisedAbv, setNormalisedAbv] = useState(null);

	const measures = useMeasures();

	const { to: cto, from: cfrom, imperial } = measures;

	useEffect(() => {
		const { la, abv, tcf = '1.000' } = values;

		// const nabv = Number(cfrom(abv, MEASURE.alc)) * Number(tcf);
		// const nla = Number(bl) * (cto(nabv, MEASURE.alc) / 100.0);
		// setNormalisedAbv(nabv);
		// debounce(() => setFieldValue('la', cfrom(nla, MEASURE.l_m), 50));


		if (la && abv && tcf) {
			const nabv = Number(cfrom(abv, MEASURE.alc)) * Number(tcf);
			const nbl = Number(la) / (cto(nabv, MEASURE.alc) / 100.0);
			setNormalisedAbv(nabv);
			debounce(() => setFieldValue('bl', cfrom(nbl, MEASURE.l_m)), 50);
		} else {
			setNormalisedAbv(null);
			debounce(() => setFieldValue('bl', '0'), 50);
		}
	}, [values]);

	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				disabled={disabled}
				label={<H5>{title}</H5>}
			/>

			<Collapse in={!!values?.enable}>
				<Stack spacing={2}>
					<FlexBox gap={2}>
						<FormikMeasuresField
							name='la'
							measure={MEASURE.l_m}
							label='Alcohol'
							fullWidth
							required
							disabled={disabled}
						/>
						<FormikDatePicker
							name='action_date'
							label='Action Date'
							fullWidth
							required
							disabled={disabled}
						/>
					</FlexBox>

					<FlexBox gap={2}>
						<FormikMeasuresField
							name='abv'
							measure={MEASURE.alc}
							label='Strength'
							fullWidth
							required
							disabled={disabled}
						/>
						<FormikNumberField
							name='tcf'
							label='TCF'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>

					<FlexBox>
						<StrengthDisplay
							label={imperial ? 'Strength @ 60°F' : 'Strength @ 20°C'}
							value={normalisedAbv}
						/>
						<LiquidDisplay label='Bulk' value={values['bl']} />
					</FlexBox>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function LOALiquidLevel({
	name,
	title = 'Liquid Level',
	disabled = false,
}) {
	return (
		<FormikProvider path={name}>
			<LiquidLevelImpl title={title} disabled={disabled} />
		</FormikProvider>
	);
}
