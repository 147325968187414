import { useContext, useEffect } from 'react';
import PageContext from 'src/context/PageContext';

const usePageTitle = (title) => {
	const [ctxTitle, setTitle] = useContext(PageContext);
	useEffect(() => title && setTitle(title), [setTitle, title]);
	return [ctxTitle, setTitle];
};

export default usePageTitle;
