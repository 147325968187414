import { Box, Stack } from '@mui/material';
import { FieldArray } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import FilledButton from 'src/components/Buttons/FilledButton';
import TextButton from 'src/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from 'src/components/Forms/FormikForm';
import LoadingSmall from 'src/components/Loading/LoadingSmall';
import { Body3, H5 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import { createTeam, listPermissions } from 'src/services/company.service';
import useAlertView from 'src/utilities/hooks/useAlertView';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';
import * as yup from 'yup';

function ErrorDisplay({ error, helperText }) {
  if (!error) return;

  return (
    <Body3 color="error">{helperText}</Body3>
  );
}

function PermissionSelector() {
  const [loading, setLoading] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const { values, withErrors } = useContext(FormikContext);

  useEffect(() => {
    setLoading(true);
    console.log('Loading permissions..');
    listPermissions()
      .then((p) => setPermissions(p))
      .catch((e) => {
        console.log('Failed to load permissions!', e);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Stack spacing="0.33rem">
      <Box className="fixedList">
        {loading && <LoadingSmall message="Loading" />}
        {!loading && (
          <FieldArray
            name="permissions"
            render={(arrayHelpers) => (
              <Stack spacing="0.75rem">
                {permissions.map((perm) => (
                  <Stack direction="row" spacing="0.5rem" key={perm.code}>
                    <input
                      name="permissions"
                      type="checkbox"
                      value={perm.code}
                      checked={values.permissions.includes(perm.code)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(perm.code);
                        } else {
                          const idx = values.permissions.indexOf(perm.code);
                          arrayHelpers.remove(idx);
                        }
                      }}
                    />
                    <span>{perm.label}</span>
                  </Stack>
                ))}
              </Stack>
            )}
          />
        )}
      </Box>
      <ErrorDisplay {...withErrors('permissions')} />
    </Stack>
  );
}

function Team({ setTeams, cancel }) {
  const [isLoading, setLoading] = useLoadingHook();

  const [AlertView, { setAlert }] = useAlertView();

  const onSubmit = useCallback(async (values) => {
    console.log('Saving team', values);
    setLoading(true);
    setAlert(null);
    try {
      const response = await createTeam(values);
      console.log('Created team', response);
      setTeams(response);
    } catch (e) {
      console.log('Failed to create team!', e);
      setAlert({
        severity: 'error',
        title: 'Failed create',
        content: e.message,
      });
    } finally {
      setLoading(false);
    }
  }, [setTeams]);

  const changeSet = {
    // company identifiers
    name: ['', yup.string().required('Name is required!')],
    description: ['', yup.string().required('Description is required!')],
    permissions: [[], yup.array().required('Permissions are required!').min(1, 'Select at least one permission for the role!')],
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={onSubmit}
    >
      <Stack className="form" spacing="2rem">
        <Stack spacing="1.5rem">
          <H5>Team</H5>
          <FormikTextField name="name" label="Team name" fullWidth required />
          <FormikTextField name="description" label="Team description" required />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Permissions</H5>
          <PermissionSelector />
        </Stack>

        <AlertView />

        <Box className='action-buttons'>
          {cancel && (
            <TextButton
              size='small'
              handleClick={cancel}
              color='secondary'
              disabled={isLoading}
            >
              Cancel
            </TextButton>
          )}

          <FilledButton type='submit' size='small' disabled={isLoading}>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default Team;
