import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';
// SELECTORS
export const coreSelector = (state) => state;
export const selectSlice = createSelector(
	coreSelector,
	(state) => state[SLICE],
);
export const selectNetwork = createSelector(
	selectSlice,
	(slice) => slice.isOnline,
);
