import * as yup from 'yup';

function sampleFields(params = {}) {
	const {
		enable = false,
		duty_paid = false,
		sample_size = '250',
		action_date = Date.now(),
	} = params;
	return {
		enable: [enable, yup.bool()],
		sample_size: [
			sample_size,
			yup.string().required('Sample size is required!'),
		],
		action_date: [
			action_date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0).required('Date is required!'),
				otherwise: (schema) => schema,
			}),
		],
		duty_paid: [duty_paid, yup.bool()],
	};
}
export default sampleFields;
