import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { InteractionContext } from '../../context/InteractionProvider';
import { getIsHighlighted, getIsFaded } from '../../hooks/useInteractionItemProps';
export function useTransformData(series) {
  const {
    id: seriesId,
    highlightScope,
    data,
    faded,
    highlighted,
    paddingAngle: basePaddingAngle = 0,
    innerRadius: baseInnerRadius = 0,
    arcLabelRadius: baseArcLabelRadius,
    outerRadius: baseOuterRadius,
    cornerRadius: baseCornerRadius = 0
  } = series;
  const {
    item: highlightedItem
  } = React.useContext(InteractionContext);
  const getHighlightStatus = React.useCallback(dataIndex => {
    const isHighlighted = getIsHighlighted(highlightedItem, {
      type: 'pie',
      seriesId,
      dataIndex
    }, highlightScope);
    const isFaded = !isHighlighted && getIsFaded(highlightedItem, {
      type: 'pie',
      seriesId,
      dataIndex
    }, highlightScope);
    return {
      isHighlighted,
      isFaded
    };
  }, [highlightScope, highlightedItem, seriesId]);
  const dataWithHighlight = React.useMemo(() => data.map((item, itemIndex) => {
    var _attributesOverride$p, _attributesOverride$i, _attributesOverride$o, _attributesOverride$c, _ref, _attributesOverride$a;
    const {
      isHighlighted,
      isFaded
    } = getHighlightStatus(itemIndex);
    const attributesOverride = _extends({
      additionalRadius: 0
    }, isFaded && faded || isHighlighted && highlighted || {});
    const paddingAngle = Math.max(0, Math.PI * ((_attributesOverride$p = attributesOverride.paddingAngle) != null ? _attributesOverride$p : basePaddingAngle) / 180);
    const innerRadius = Math.max(0, (_attributesOverride$i = attributesOverride.innerRadius) != null ? _attributesOverride$i : baseInnerRadius);
    const outerRadius = Math.max(0, (_attributesOverride$o = attributesOverride.outerRadius) != null ? _attributesOverride$o : baseOuterRadius + attributesOverride.additionalRadius);
    const cornerRadius = (_attributesOverride$c = attributesOverride.cornerRadius) != null ? _attributesOverride$c : baseCornerRadius;
    const arcLabelRadius = (_ref = (_attributesOverride$a = attributesOverride.arcLabelRadius) != null ? _attributesOverride$a : baseArcLabelRadius) != null ? _ref : (innerRadius + outerRadius) / 2;
    return _extends({}, item, attributesOverride, {
      isFaded,
      isHighlighted,
      paddingAngle,
      innerRadius,
      outerRadius,
      cornerRadius,
      arcLabelRadius
    });
  }), [baseCornerRadius, baseInnerRadius, baseOuterRadius, basePaddingAngle, baseArcLabelRadius, data, faded, getHighlightStatus, highlighted]);
  return dataWithHighlight;
}