import Portfolio from 'src/components/Account/Portfolio';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function PortfolioPage() {
  usePageTitle('Your Portfolio');

  return (
    <Portfolio />
  );
}

export default withAppLayout(withDialogCard(PortfolioPage));
