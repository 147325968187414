import { useCallback } from 'react';
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import debounce from 'src/utilities/debounce';

const useAssetConfirmHook = (assets, upsert) => {
	return useCallback(
		(asset_id, properties) => {
			const confirm = [];
			console.log('Finding asset', assets.length, asset_id, properties);
			const item = assets.find((a) => a.asset_id === asset_id);
			console.log('    -> found', item);
			if (item) {
				const copy = {
					...item,
					...properties,
					processed: ASSET_PROCESSED_STATUS.CONFIRMED,
				};
				confirm.push(copy);
			}

			// See if this item has children to deal with..
			const newChildren = properties?.children ?? [];

			console.log(' --> new children', newChildren);

			// Grab the existing children
			const existingChildren = assets.filter((a) => a.parent_asset_id === asset_id);

			for (const child of existingChildren) {
				const found = newChildren.find((i) => i.asset_id === child.asset_id);
				if (!found) {
					// Reset the parent asset
					const copy = {
						...child,
						parent_asset_id: undefined,
						parent_asset: undefined,
						processed: ASSET_PROCESSED_STATUS.PENDING,
					};
					confirm.push(copy);
				} else {
					// Just confirm the asset
					const copy = {
						...child,
						processed: ASSET_PROCESSED_STATUS.CONFIRMED,
					};
					confirm.push(copy);
				}
			}

			for (const child of newChildren) {
				const found = existingChildren.find((i) => i.asset_id === child.asset_id);
				if (!found) {
					const childAsset = assets.find((a) => a.asset_id === child.asset_id);
					if (childAsset) {
						const copy = {
							...childAsset,
							parent_asset_id: asset_id,
							parent_asset: {
								rw_asset_id: item?.rw_asset_id ?? properties?.rw_asset_id,
								asset_type: item?.asset_type ?? properties?.asset_type,
							},
							processed: ASSET_PROCESSED_STATUS.CONFIRMED,
						};
						confirm.push(copy);
					}
				}
			}

			// Filter any that have been removed..
			const removedChildren = existingChildren.filter((a) => !newChildren.find((i) => i.asset_id === a.asset_id));

			// Reset the removed children
			if (removedChildren.length > 0) {
				removedChildren.forEach((c) => {
					const copy = {
						...c,
						parent_asset_id: undefined,
						parent_asset: undefined,
						processed: ASSET_PROCESSED_STATUS.PENDING,
					};
					confirm.push(copy);
				});
			}

			if (confirm.length > 0) {
				debounce(() => upsert(confirm), 50);
			}

			return !!item;
		},
		[assets, upsert],
	);
};

export default useAssetConfirmHook;
