import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssetListComponentV2 } from 'src/components/AssetListItem';
import PalletListItem from 'src/components/AssetListItem/PalletListItem';
import LoadingSmall from 'src/components/Loading/LoadingSmall';
import Pagination from 'src/components/Pagination';
import { Body1 } from 'src/components/Typography';
import { ASSET_EDIT_URL, ASSET_TYPES } from 'src/consts/asset';
import FormikContext from 'src/context/FormikContext';
import { vh_50 } from 'src/styles/heights';
import { useAssetsLazyQueryPaginated } from 'src/utilities/hooks/useAssetsLazyQuery';
import Toolbar from './Toolbar';

const styles = {
	loader: {
		zIndex: 1000,
		color: '#fff',
	},
	content: {
		...vh_50,
		overflow: 'scroll',
		display: 'flex',
		flexDirection: 'column',
		padding: 0.2,
		paddingTop: 2,
	},
	emptyContent: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		p: 8,
	},
};

function ExplorerTable() {
	const navigate = useNavigate();
	const { values } = useContext(FormikContext);

	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		const { location = {} } = values ?? {};
		const { facility_id, bay, row, level } = location;

		let ff;
		let rf;
		let lf;

		if (facility_id) {
			if (bay) {
				ff = `${facility_id}/${bay}`;
				if (row) {
					rf = row;
					if (level) {
						lf = level;
					}
				}
			} else {
				ff = facility_id;
			}
		}

		return {
			facility_id: ff,
			row: rf,
			level: lf,
			limit: Number(pageSize),
		};
	}, [
		values.location?.bay,
		values.location?.facility_id,
		values.location?.level,
		values.location?.row,
		pageSize,
	]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] = useAssetsLazyQueryPaginated(reqParams);

	const goToItem = useCallback((params) => {
		const id = params?.path ?? 'new';
		const route = ASSET_EDIT_URL[params?.asset_type];
		navigate(`${route}/${id}`);
	}, []);

	return (
		<Stack spacing={2}>
			<Toolbar />
			<Pagination
				hasNextPage={hasNext}
				hasPrevPage={hasPrev}
				onPressNextPage={loadNext}
				onPressPrevPage={loadPrev}
				onChangePageSize={setPageSize}
			/>
			{!isLoading && (
				<Stack className='list'>
					{page.map((asset) =>
						asset?.asset_type === ASSET_TYPES.pallet ? (
							<PalletListItem key={asset.path} item={asset} onClick={goToItem} />
						) : (
							<AssetListComponentV2
								key={asset.path}
								item={asset}
								onClick={goToItem}
							/>
						),
					)}
					{page.length === 0 && (
						<Box sx={styles.emptyContent}>
							<Body1>Assets not found</Body1>
						</Box>
					)}
				</Stack>
			)}
			{isLoading && (
				<Box sx={styles.emptyContent}>
					<LoadingSmall message='Loading assets' />
				</Box>
			)}
		</Stack>
	);
}

export default ExplorerTable;
