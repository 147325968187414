import { AutofpsSelect } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import TitledButton from 'src/components/Buttons/TitledButton';
import { clientFields } from 'src/components/Client/clientFields';
import { explorerLocationFields } from 'src/components/ExplorerLocation/explorerLocationFields';
import Instructions from 'src/components/Instructions';
import useSourceInventoryHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LoadingSmall from 'src/components/Loading/LoadingSmall';
import Location from 'src/components/Location';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import SkuEntityConfirmModal from 'src/components/SKUSelector/modals/SkuEntityConfirmModal';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import { FormikProvider } from 'src/providers/FormikProvider';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';
import { useAuditListLazyQuery } from 'src/utilities/hooks/useAuditListQuery';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';
import useLoadingHook from 'src/utilities/hooks/useLoadingHook';

function propertyFields(properties = {}) {
	const { location = { enable: true } } = properties;
	return {
		location: explorerLocationFields(location),
	};
}

function AuditAssetSelector({ assets, updateAssets, location }) {
	const { enqueueSnackbar } = useSnackbar();

	const [facility_id, bay, row, level] = location ?? [];
	const [fetchAuditList, { isLoading }] = useAuditListLazyQuery();

	const [, setLoading] = useLoadingHook();

	const locationId = useMemo(() => {
		if (!facility_id && !bay) {
			return null;
		}
		if (!row) {
			return `${facility_id}/${bay}`;
		}
		if (!level) {
			return `${facility_id}/${bay}/${row}`;
		}
		return `${facility_id}/${bay}/${row}/${level}`;
	}, [bay, facility_id, level, row]);

	const handleGenerateAssets = useCallback(() => {
		setLoading(true);
		fetchAuditList({ location: locationId })
			.then((res) => updateAssets(res.map((t) => toTaggedAsset(t))))
			.catch((err) => {
				console.log('Generate audit list', err);
				enqueueSnackbar(`Failed to generate audit asset list!`, {
					variant: 'error',
				});
			})
			.finally(() => setLoading(false));
	}, [fetchAuditList, locationId, updateAssets, enqueueSnackbar]);

	if (!locationId) return;

	return (
		<Stack spacing='2rem'>
			{assets.length === 0 && (
				<Instructions>
					Generate a list of assets to audit for this location
				</Instructions>
			)}
			{assets.length > 0 && (
				<Instructions>List of assets to audit</Instructions>
			)}

			<FlexBox>
				{assets.length === 0 && (
					<>
						<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
						{!isLoading && (
							<TitledButton
								handleClick={handleGenerateAssets}
								label='Find'
								variant='outlined'
								color='secondary'
								sx={{ width: '42px', height: '52px' }}
							>
								<AutofpsSelect />
							</TitledButton>
						)}
						{isLoading && <LoadingSmall />}
					</>
				)}
			</FlexBox>
		</Stack>
	);
}

function InventoryAudit(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [location, setLocation] = useState(null);

	const assetFilter = useCallback((asset) => {
		return asset?.sku_storage?.length;
	}, []);

	const [
		[sourceSkus],
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Audit Assets',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.container,
				ASSET_TYPES.filling_tank,
			],
		},
		assetFilter,
		submitForm,
		SKUModal: SourceSKUModal,
		AssetModal: SkuEntityConfirmModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties),
			client: clientFields(initialValues.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
		},
		[initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
		}),
		[sourceSkus, sourceAssets],
	);

	return (
		<>
			<RequestForm
				requestLabel='Audit'
				requestType={REQUEST_TYPES.inventory_audit}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<FormikProvider path='properties'>
					<Location name='location' setLocation={setLocation} />
				</FormikProvider>

				<AuditAssetSelector
					location={location}
					assets={sourceAssets}
					updateAssets={initSourceAssets}
				/>

				<SourceInventory />
				<FormSubmitter />
			</RequestForm>

			<SourceModals />
		</>
	);
}

export default withAppLayout(InventoryAudit, { title: 'Audit' });
