import listStyles from 'src/styles/list.styles';
import widgetStyles from 'src/styles/widget.styles';

const styles = {
	'&.root': {
		display: 'flex',
		flexDirection: 'column',
		gap: {
			xs: '0.5rem',
			md: '2rem',
		},

		'.filter': {
			marginTop: {
				xs: '-3.5rem',
				md: '-5rem',
			},
			padding: {
				xs: '0.25rem',
				md: '0.5rem',
			},
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'end',

			'.filter-bar': {
				width: 'min-content',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'end',

				'.filter-form': {
					width: {
						xs: '100%',
						md: '550px',
					},
					gap: {
						xs: '0.75rem',
						md: '1rem',
					},
					display: 'flex',
					flexDirection: {
						xs: 'column',
						md: 'row',
					},
					alignItems: 'center',
					justifyContent: 'flex-end',
				},
			},
		},

		'.content-row': {
			padding: {
				xs: '1rem',
				md: '1.5rem',
			},
			backgroundColor: '#fff',
			borderRadius: {
				xs: 'none',
				md: '5px',
			},
			overflow: 'hidden',
			'--shadow-color': '180deg 4% 9%',
			boxShadow: {
				xs: 'none',
				md: `
              0.3px 0.4px 0.5px hsl(var(--shadow-color) / 0.45),
              0.6px 0.7px 1px -1.3px hsl(var(--shadow-color) / 0.43),
              1.4px 1.9px 2.6px -2.7px hsl(var(--shadow-color) / 0.41)`,
			},

			'.content-header': {
				color: '#41424c',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',

				'.title-box': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '0.5rem',
				},
			},

			'.content-body': {
				marginTop: {
					xs: '2rem',
					md: '2.5rem',
				},
				display: 'grid',
				alignItems: 'stretch',
				gap: '3rem',

				'.content-section': {
					gap: '1rem',
					flexGrow: 1,

					border: '1px solid rgba(255, 255, 255, 0.4)',
					borderRadius: '5px',
					overflow: 'hidden',
					backgroundColor: 'white',
					// '--shadow-color': '180deg 4% 9%',
					// boxShadow: `
          //   0.3px 0.4px 0.5px hsl(var(--shadow-color) / 0.45),
          //   0.6px 0.7px 1px -1.3px hsl(var(--shadow-color) / 0.43),
          //   1.4px 1.9px 2.6px -2.7px hsl(var(--shadow-color) / 0.41)`,
					padding: '0.5rem',

					'.content-section-title': {
						width: 'min-content',
						whiteSpace: 'nowrap',
						paddingBottom: '0.35rem',
					},

					'.content-section-content': {
						flexGrow: 1,
						display: 'flex',
						paddingBottom: '10px',
						paddingTop: '10px',

						'&.task-content': {
							backgroundColor: '#eeeeee',
							padding: '10px',
						},
					},

					'.content-section-footer': {
						display: 'flex',
						flexDirection: 'row',
						gap: '1rem',
						justifyContent: 'flex-start',
					},
				},

				'.recent-list': {
					display: 'block',
					flexGrow: 1,
					maxHeight: '550px',
					overflowY: 'auto',
				},

				...listStyles,
				...widgetStyles,
			},

			'.content-4col': {
				gridTemplateColumns: 'repeat(auto-fit, minmax(min(15rem, 100%), 1fr))',
			},

			'.content-3col': {
				gridTemplateColumns: 'repeat(auto-fit, minmax(min(20rem, 100%), 1fr))',
			},
		},

		'.content-light': {
			backgroundColor: '#fff',
		},
	},
};

export default styles;
