import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { createTemplate } from 'src/services/template.service';

const useFormTemplateHandlerSave = () => {
	// const [save, result] = useCreateTemplateMappingMutation();
	const { enqueueSnackbar } = useSnackbar();
	const saveAsTemplate = useCallback(
		async (values) => {
			try {
				console.log('Saving template', values);
				const { templateType = '', templateName = '', saveTemplate = false } = values;
				if (templateName && saveTemplate) {
					console.log('Saving as Template...', templateName, templateType, values);
					const params = {
						template_name: templateName,
						asset_type: templateType,
						properties: values,
					};
					await createTemplate(params);
					enqueueSnackbar(`Template ${templateName} is saved`, {
						variant: 'success',
					});
				}
			} catch (error) {
				console.log('Error saving', error);
				enqueueSnackbar(error?.message ?? 'Error saving template', {
					variant: 'error',
				});
				return error;
			}
		},
		[enqueueSnackbar],
	);

	return saveAsTemplate;
};

export default useFormTemplateHandlerSave;
