import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clientFields } from 'src/components/Client/clientFields';
import useSourceInventoryHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import SampleProperties from 'src/components/SampleProperties';
import sampleFields from 'src/components/SampleProperties/sampleFields';
import SourceSKUModal from 'src/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';
import debounce from 'src/utilities/debounce';
import { useAssetLazyQuery } from 'src/utilities/hooks/useAssetQuery';

function SampleForm() {
	let [searchParams] = useSearchParams();
	const asset = searchParams.get('asset');
	const [fetchAsset] = useAssetLazyQuery();

	const [
		[sourceSkus],
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Assets',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.filling_tank,
			],
			sku_types: [SKU_TYPES.SERVICE],
		},
		SKUModal: SourceSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: sampleFields(initialValues?.properties),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
		},
		[initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
		}),
		[sourceSkus, sourceAssets],
	);

	useEffect(() => {
		if (asset) {
			fetchAsset(asset).then((a) => {
				debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
			});
		}
	}, [asset, initSourceAssets, fetchAsset]);

	return (
		<>
			<RequestForm
				requestLabel='Sample'
				requestType={REQUEST_TYPES.sample}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
			>
				<SourceInventory />

				<SampleProperties name='properties' />
			</RequestForm>

			<SourceModals />
		</>
	);
}

export default withAppLayout(SampleForm, { title: 'Sample' });
