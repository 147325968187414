import { Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useCallback, useContext, useMemo } from 'react';
import { clientFields } from 'src/components/Client/clientFields';
import { FormikCheckBox, FormikDatePicker, FormikTextField } from 'src/components/Forms/FormikForm';
import useInventorySelectorHook from 'src/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from 'src/components/Layout/AppLayout';
import { FlexBox } from 'src/components/Layout/FlexBox';
import RequestForm from 'src/components/RequestForm';
import requestIdFields from 'src/components/RequestID/requestIdFields';
import DeliverySKUModal from 'src/components/SKUSelector/modals/DeliverySKUModal';
import DestinationAssetModal from 'src/components/SKUSelector/modals/DestinationAssetModal';
import { H5 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import { REQUEST_TYPES } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';
import useFormSubmissionHook from 'src/utilities/hooks/useFormSubmissionHook';
import * as yup from 'yup';

function Properties() {
	const { values, readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path='properties'>
				<Stack spacing={2}>
					<H5>Invoice Details</H5>
					<FlexBox>
						<FormikTextField
							name='invoice_number'
							label='Invoice Number'
							fullWidth
						/>
						<FormikDatePicker
							name='invoice_date'
							label='Invoice Date'
							fullWidth
						/>
					</FlexBox>
					<FlexBox>
						<FormikTextField
							name='invoice_amount'
							label='Total Cost'
							fullWidth
						/>
						<FormikTextField name='tax_amount' label='Tax' fullWidth />
					</FlexBox>
					<Stack>
						<FormikCheckBox name='paid' label='Paid' />
						<Collapse in={Boolean(values?.properties?.paid)}>
							<FormikTextField
								name='payment_confirmation'
								label='Payment confirmation'
								fullWidth
							/>
						</Collapse>
					</Stack>
				</Stack>
			</FormikProvider>
		)
	);
}

function propertyFields(fields = {}) {
	const {
		invoice_number = '',
		invoice_date = Date.now(),
		invoice_amount = '',
		tax_amount = '',
		paid = false,
		payment_confirmation = '',
	} = fields;

	return {
		invoice_number: [invoice_number, yup.string()],
		invoice_date: [invoice_date, yup.number()],
		invoice_amount: [invoice_amount, yup.number()],
		tax_amount: [tax_amount, yup.number()],
		paid: [paid, yup.bool()],
		payment_confirmation: [payment_confirmation, yup.string()],
	};
}

function DeliveryForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [
		[destinationSkus],
		[destinationAssets],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Material',
		filter: {
			sku_types: [
				SKU_TYPES.TRACKED,
				SKU_TYPES.RAW,
				SKU_TYPES.EXPIRING,
				SKU_TYPES.CONSUMABLE,
				SKU_TYPES.SERVICE,
				SKU_TYPES.FINISHED,
			],
		},
		submitForm,
		assetAdapter: toTaggedAsset,
		assetFilter: (asset) =>
			[ASSET_TYPES.pallet, ASSET_TYPES.container].includes(asset.asset_type),
		SKUModal: DeliverySKUModal,
		AssetModal: DestinationAssetModal,
		parentAssetSupport: true,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties),
			client: clientFields(initialValues.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: entity?.sku_destinations,
			});
		},
		[initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_destinations: destinationSkus,
			destinations: destinationAssets,
		}),
		[destinationSkus, destinationAssets],
	);

	return (
		<>
			<RequestForm
				requestLabel='Delivery'
				requestType={REQUEST_TYPES.delivery}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<DestinationInventory />

				<Properties />
				{/*<FormSubmitter />*/}
			</RequestForm>

			<DestinationModals />
		</>
	);
}

export default withAppLayout(DeliveryForm, { title: 'Deliveries' });
