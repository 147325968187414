import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';
import { SKU_TYPES } from 'src/consts/sku';

// Selectors
export const selectCompanies = (state) => state[SLICE];

export const selectAllCompanies = createSelector(
	selectCompanies,
	(companies) => companies?.companies,
);

export const selectCompanyUsersWithRoles = createSelector(
	selectCompanies,
	(companies) => companies?.users,
);

export const selectCompanyUsers = createSelector(
	selectCompanies,
	(companies) => companies?.users,
);

export const selectCompanySKUs = createSelector(
	selectCompanies,
	(companies) => companies?.skus,
);

export const selectCompanyFacilities = createSelector(
	selectCompanies,
	(companies) => companies?.facilities,
);

export const selectCompanyLiquidTypes = createSelector(
	selectCompanySKUs,
	(skus) => skus.filter((sku) => sku?.sku_type === SKU_TYPES.LIQUID),
);

// Current company
export const selectActiveCompanyId = createSelector(
	selectCompanies,
	(companies) => companies?.company_id,
);
export const hasCompanyId = createSelector(
	selectActiveCompanyId,
	(companyId) => companyId && companyId.length > 0,
);

// Get the current active company
export const activeCompany = createSelector(selectCompanies, (companies) =>
	companies?.companies.find(
		(company) => company.company_id === companies.company_id,
	),
);

export const selectCompanyId = createSelector(
	activeCompany,
	(company) => company?.company_id,
);

export const selectCompanyName = createSelector(
	activeCompany,
	(company) => company?.company_name,
);

export const selectCompanyCC = createSelector(
	activeCompany,
	(company) => company?.company_cc,
);

export const selectIsCompanyActive = createSelector(activeCompany, () => true);

export const selectIsUsUser = createSelector(
	activeCompany,
	(company) => company?.company_cc === 'US',
);

// export const selectAccountId = createSelector(selectUser, (user) => user?.account_id);
// export const selectAccountName = createSelector(selectUser, (user) => user?.account_name);
// export const selectAccountUsername = createSelector(selectUser, (user) => user?.account_username);
// export const selectAccountEmail = createSelector(selectUser, (user) => user?.account_email);
// export const selectAccountLocation = createSelector(selectUser, (user) => user?.account_location);
// export const selectAccountLastAccess = createSelector(selectUser, (user) => user?.account_last_access);
// export const selectAccountStatus = createSelector(selectUser, (user) => user?.account_status);
// export const selectUnitFormat = createSelector(selectUser, (user) => user?.unit_format);
// export const selectAccountPerms = createSelector(selectUser, (user) => user?.account_perms);
//
// export const selectAccountRegistered = createSelector(selectAccountId, (id) => id && id.length > 0);
//
// export const selectCompanyId = createSelector(selectUser, (user) => user?.company_id);
//
// export const selectCompanyName = createSelector(selectUser, (user) => user?.company_name);
//
// export const selectCompanyCC = createSelector(selectUser, (user) => user?.company_cc);

// export const selectIsAccountActive = createSelector(
// 	selectAccountStatus,
// 	(status) => Number(status) === ACCOUNT_STATUS.ACTIVE,
// );

// export const selectIsCompanyActive = createSelector(
// 	selectUser,
// 	(user) => user?.company_active === 'true',
// );

// export const selectCanEdit = createSelector(
// 	selectIsCompanyActive,
// 	selectIsAccountActive,
// 	(companyActive, accountActive) => companyActive && accountActive,
// );

// export const selectIsUsUser = createSelector(selectUser, (user) => user?.company_cc === 'US');
//
// export const selectIsUnitFormatImperial = createSelector(selectUser, (user) => user.unit_format);
//
// export const selectPermissions = createSelector(selectCanEdit, selectAccountPerms, getPermissions);

// export const selectCompanies = createSelector(
// 	selectUser,
// 	(user) => user?.companies,
// );
