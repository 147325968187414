import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useCallback, useState } from 'react';
import Instructions from 'src/components/Instructions';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import styles from 'src/styles/modal.styles';
import { FormikForm } from 'src/components/Forms/FormikForm';
import TextButton from 'src/components/Buttons/TextButton';
import FilledButton from 'src/components/Buttons/FilledButton';
import { Box, TextField } from '@mui/material';
import useAlertView from 'src/utilities/hooks/useAlertView';
import toTaggedAsset from 'src/utilities/adapters/toTaggedAsset';

function SkuItem({ sku, onChange }) {
	console.log('sku', sku);
	return (
		<Stack>
			<Instructions instruction={sku?.sku_name}></Instructions>
			<FlexBox alignItems='top'>
				<TextField
					label='Quantity'
					InputLabelProps={{ shrink: true }}
					InputProps={{ disableUnderline: true }}
					variant='filled'
					className='field'
					type='number'
					value={sku?.available_quantity}
					onChange={({ target }) =>
						onChange({ ...sku, available_quantity: target.value })
					}
				/>
				<TextField
					label='Reason'
					fullWidth
					variant='filled'
					InputLabelProps={{ shrink: true }}
					InputProps={{ disableUnderline: true }}
					className='field'
					value={sku?.reason}
					onChange={({ target }) => onChange({ ...sku, reason: target.value })}
				/>
			</FlexBox>
		</Stack>
	);
}

function SkuEntityConfirmModal({ item, open, onClose }) {
	const { sku_storage } = item ?? {};

	const [AlertView] = useAlertView();
	const [skus, setSkus] = useState(sku_storage ?? []);

	const handleSubmit = useCallback(() => {
		const asset = item.asset_id
			? { ...item, sku_storage: skus }
			: toTaggedAsset({ ...item, sku_storage: skus });
		onClose(asset);
	}, [item, onClose, skus]);

	const handleSku = useCallback(
		(sku) => {
			setSkus((s) =>
				s.map((i) => (i.sku_name === sku.sku_name ? { ...sku } : s)),
			);
		},
		[setSkus],
	);

	return (
		<>
			<Modal open={open} onClose={() => onClose()}>
				<Stack sx={styles} className='root' spacing={2}>
					<FlexBox>
						<H5>{item?.rw_asset_id}</H5>
						<IconButton
							onClick={() => onClose()}
							className='close-btn'
							aria-label='Close'
						>
							<CloseIcon />
						</IconButton>
					</FlexBox>
					<FormikForm onSubmit={handleSubmit} enableReinitialize>
						<Stack spacing={2}>
							<Instructions>Confirm the quantity of each sku </Instructions>
							{skus.map((i) => (
								<SkuItem sku={i} key={i.sku_name} onChange={handleSku} />
							))}
							<AlertView />

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small' disabled={!location}>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Stack>
			</Modal>
		</>
	);
}

export default SkuEntityConfirmModal;
