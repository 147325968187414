import { AddCircle } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Collapse, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import TitledButton from 'src/components/Buttons/TitledButton';
import { countriesOptions } from 'src/components/Forms/FormikForm';
import Instructions from 'src/components/Instructions';
import Pagination from 'src/components/Pagination';
import { H5 } from 'src/components/Typography';
import ListItemSkeleton from 'src/components/sekeletons/ListItemSkeleton';
import { UK_COUNTRY_CODES } from 'src/consts/admin';
import { useCompanyCountry } from 'src/redux/containers/Companies';
import styles from 'src/styles/content.styles';
import generateUsername from 'src/utilities/generateUsername';
import { useCustomersLazyQueryPaginated } from 'src/utilities/hooks/useCustomerLazyQuery';
import {
	useInviteCustomer, useResetCustomerPassword,
	useUploadCustomer,
} from 'src/utilities/hooks/useMutateCustomer';
import useScrollTarget from 'src/utilities/hooks/useScrollTarget';
import CustomerForm from './components/CustomerForm';
import { CustomerItem } from './components/CustomerItem';
import { ResetPasswordDialog } from './components/ResetPasswordDialog';
import withDialogCard from 'src/components/Cards/DialogCard';

function Customers({ setCustomer, customer }) {
	// selectors
	const company_cc = useCompanyCountry();
	// states
	const [target, scroll] = useScrollTarget();
	const [pageSize, setPageSize] = useState('5');
	const [isUploadOpen, setUploadModalOpen] = useState(false);
	const [resetPassword, setResetPassword] = useState();
	// calculated
	const reqParams = useMemo(() => ({ limit: Number(pageSize) }), [pageSize]);
	const uploadFields = useMemo(
		() => [
			{
				// Visible in table header and when matching columns.
				label: 'Full name',
				// This is the key used for this field when we call onSubmit.
				key: 'name',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['first name', 'first'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'John Doe',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					{
						// Can be "required" / "unique" / "regex"
						rule: 'required',
						errorMessage: 'Full name is required',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
					},
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'User name',
				// This is the key used for this field when we call onSubmit.
				key: 'username',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['account', 'accountId', 'generated username'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'john.doe',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'User name is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Contact email',
				// This is the key used for this field when we call onSubmit.
				key: 'email',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['mail', 'e-mail'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'john.doe@example.com',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					{
						// Can be "required" / "unique" / "regex"
						rule: 'required',
						errorMessage: 'Contact email is required',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
					},
					{
						// Can be "required" / "unique" / "regex"
						rule: 'unique',
						errorMessage: 'Contact email should be unique',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
					},
					{
						// Can be "required" / "unique" / "regex"
						rule: 'regex',
						errorMessage: 'Invalid contact email',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
						value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
					},
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Street/Number',
				// This is the key used for this field when we call onSubmit.
				key: 'line1',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'line1'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'Street/Number is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Building',
				// This is the key used for this field when we call onSubmit.
				key: 'line2',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'line2', 'building'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Town',
				// This is the key used for this field when we call onSubmit.
				key: 'line3',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'line3'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [],
			},
			{
				// Visible in table header and when matching columns.
				label: 'City',
				// This is the key used for this field when we call onSubmit.
				key: 'city',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'city'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'City is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'State',
				// This is the key used for this field when we call onSubmit.
				key: 'state',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'state'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'City is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Postal Code',
				// This is the key used for this field when we call onSubmit.
				key: 'postCode',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['code', 'post', 'postCode', 'post code'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'Postal Code is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Country',
				// This is the key used for this field when we call onSubmit.
				key: 'country',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['country code'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'select',
					options: countriesOptions,
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'GB',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'Country is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Phone',
				// This is the key used for this field when we call onSubmit.
				key: 'telephone',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['telephone', 'phone', 'tel'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '+9 999 9999 999...',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [],
			},
			...(UK_COUNTRY_CODES.includes(company_cc)
				? [
						{
							// Visible in table header and when matching columns.
							label: 'Require Duty Representation',
							// This is the key used for this field when we call onSubmit.
							key: 'duty_req',
							// Allows for better automatic column matching. Optional.
							alternateMatches: ['duty'],
							// Used when editing and validating information.
							fieldType: {
								// There are 3 types - "input" / "checkbox" / "select".
								type: 'checkbox',
							},
							// Used in the first step to provide an example of what data is expected in this field. Optional.
							example: 'TRUE',
							// Can have multiple validations that are visible in Validation Step table.
							validations: [],
						},
						{
							// Visible in table header and when matching columns.
							label: 'Is registered for WOWGR',
							// This is the key used for this field when we call onSubmit.
							key: 'wowgr',
							// Allows for better automatic column matching. Optional.
							alternateMatches: ['is registered'],
							// Used when editing and validating information.
							fieldType: {
								// There are 3 types - "input" / "checkbox" / "select".
								type: 'checkbox',
								disabled: true,
							},
							// Used in the first step to provide an example of what data is expected in this field. Optional.
							example: 'FALSE',
							// Can have multiple validations that are visible in Validation Step table.
							validations: [],
						},
					]
				: []),
		],
		[company_cc],
	);
	// apis
	const [
		isLoading,
		page,
		hasPrev,
		loadPrev,
		hasNext,
		loadNext,
		refetchCurrent,
	] = useCustomersLazyQueryPaginated(reqParams);
	const [uploadCustomerApi] = useUploadCustomer();
	const [inviteCustomer] = useInviteCustomer();
	const [resetCustomerPassword] = useResetCustomerPassword();

	// callbacks
	const onCloseUploadModal = useCallback(() => {
		setUploadModalOpen(false);
	}, []);

	const onSubmitUpload = useCallback(
		async (data) => uploadCustomerApi(data.validData).finally(refetchCurrent),
		[refetchCurrent, uploadCustomerApi],
	);

	const handleEditCustomer = useCallback(
		({
			path,
			account_name,
			account_email,
			account_username,
			country,
			wowgr,
			duty_req,
			...rest
		}) =>
			setCustomer({
				path,
				name: account_name,
				email: account_email,
				username: account_username,
				country,
				wowgr,
				duty_req,
				...rest,
			}),
		[],
	);

	const handleInviteCustomer = useCallback(
		(a) => inviteCustomer(a).finally(refetchCurrent),
		[inviteCustomer, refetchCurrent],
	);

	const handleResetCustomerPassword = useCallback(
		(a) => resetCustomerPassword(a).finally(refetchCurrent),
		[resetCustomerPassword, refetchCurrent],
	);

	// effects
	useEffect(() => scroll(), [scroll]);
	return (
		<Stack sx={styles} className='root' ref={target}>
			<Box className='header'>
				<H5>Manage Customers</H5>
				<Box display={'flex'} justifyContent='flex-end' alignItems='center'>
					<TitledButton
						handleClick={() => {
							setUploadModalOpen(true);
						}}
						variant='outlined'
						color='secondary'
						label='upload'
						sx={{ width: '42px', height: '52px' }}
					>
						<UploadFileIcon height={24} width={24} />
					</TitledButton>
					<ReactSpreadsheetImport
						isOpen={isUploadOpen}
						onClose={onCloseUploadModal}
						onSubmit={onSubmitUpload}
						fields={uploadFields}
						isNavigationEnabled
						rowHook={(data) => {
							if (!data.username) {
								return { ...data, username: generateUsername() };
							}
							return data;
						}}
						translations={{
							uploadStep: {
								title: 'Upload Customers',
							},
						}}
						customTheme={{
							colors: {
								subtitleColor: '#718096',
								inactiveColor: '#A0AEC0',
								border: '#E2E8F0',
								background: 'white',
								backgroundAlpha: 'rgba(255,255,255,0)',
								secondaryBackground: '#f5f5f5',
								highlight: '#E2E8F0',
								rsi: {
									50: '#E6E6FF',
									100: '#C4C6FF',
									200: '#A2A5FC',
									300: '#8888FC',
									400: '#7069FA',
									500: '#F0AA29',
									600: '#a8761c',
									700: '#a8761c',
									800: '#1D0EBE',
									900: '#0C008C',
								},
								red: {
									600: '#fd3b02',
									500: '#d32f2f',
								},
							},
						}}
					/>
					<TitledButton
						handleClick={() => {
							setCustomer({});
							scroll();
						}}
						variant='outlined'
						color='secondary'
						label='Add'
						sx={{ width: '42px', height: '52px' }}
					>
						<AddCircle height={24} width={24} />
					</TitledButton>
				</Box>
			</Box>

			<Instructions>
				Manage customers who have access to assets managed by your organization.
			</Instructions>

			<Collapse in={!!customer}>
				<CustomerForm
					user={customer}
					onCancel={() => {
						setCustomer(undefined);
						scroll();
					}}
					onSubmit={() => {
						setCustomer(undefined);
						refetchCurrent();
					}}
				/>
			</Collapse>

			<Stack spacing={0.5}>
				<Pagination
					hasNextPage={hasNext}
					hasPrevPage={hasPrev}
					onPressNextPage={loadNext}
					onPressPrevPage={loadPrev}
					onChangePageSize={setPageSize}
				/>
				<Stack spacing={0} className='list'>
					{isLoading
						? Array.from({ length: pageSize }).map((_, i) => (
								<ListItemSkeleton key={i} />
							))
						: page.map((p) => (
								<CustomerItem
									key={p.path}
									customer={p}
									onInvite={handleInviteCustomer}
									onResetPassword={setResetPassword}
									onEdit={handleEditCustomer}
								/>
							))}
				</Stack>
			</Stack>
			<ResetPasswordDialog
				item={resetPassword}
				handleClose={() => setResetPassword(undefined)}
				handleResetPassword={handleResetCustomerPassword}
			/>
		</Stack>
	);
}

export default withDialogCard(Customers);
