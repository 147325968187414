import QRImage from 'src/components/QRImage/index';
import { Body1 } from 'src/components/Typography';
import { ASSET_TYPES_REVERSE } from 'src/consts/asset';
import { SKU_TYPES } from 'src/consts/sku';

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		marginTop: '2rem',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
	},
	content: {
		// alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
		justifyContent: 'space-between',
	},
	qrDetails: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	qrDetails2: {
		width: '100%',
	},
	qrIcon: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		border: '2px solid',
		padding: '5px',
		height: '72px',
		width: '72px',
		borderRadius: '50%',
	},
	type: {
		textTransform: 'uppercase',
		fontSize: '0.8rem',
		marginTop: '.25rem',
	},
	title: {
		borderTop: '1px solid',
		paddingTop: '0.25rem',
		fontSize: '1rem',
		fontWeight: '600',
	},
	m5: {
		margin: '5px',
	},
};

function QrDetails({ item = {}, companyName, unit, abvUnit, abv }) {
	return (
		<div style={styles.qrDetails2}>
			<Body1 style={styles.m5}>{companyName}</Body1>
			<Body1 style={styles.m5}>{item?.product}</Body1>
			<Body1 style={styles.m5}>{item?.sku_name}</Body1>
			{[SKU_TYPES.TRACKED].includes(item.sku_type) && (
				<>
					<Body1 style={styles.m5}>
						{item.capacity} {unit}
					</Body1>
					<Body1 style={styles.m5}>{ASSET_TYPES_REVERSE[item?.asset_type ?? '1']}</Body1>
				</>
			)}
			{item.sku_type === SKU_TYPES.FINISHED && (
				<>
					<Body1 style={styles.m5}>
						{item.count}x{item.size} {unit}
					</Body1>
					<Body1 style={styles.m5}>
						ABV {abv}
						{abvUnit}
					</Body1>
				</>
			)}
			{[SKU_TYPES.WASTE, SKU_TYPES.RAW, SKU_TYPES.EXPIRING].includes(item.sku_type) && (
				<Body1 style={styles.m5}>{unit}</Body1>
			)}
		</div>
	);
}

function SkuQrCode({ sku, companyName, qrValue, unit, abvUnit, abv, fullScreen = true }) {
	return (
		<div style={fullScreen ? styles.rootFullScreen : styles.root}>
			<div style={styles.content}>
				<QRImage value={qrValue} />
				<QrDetails
					item={sku}
					companyName={companyName}
					unit={unit}
					abvUnit={abvUnit}
					abv={abv}
				/>
			</div>
		</div>
	);
}

export default SkuQrCode;
