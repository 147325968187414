import { useFormikContext } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import FormikContext from 'src/context/FormikContext';
import debounce from 'src/utilities/debounce';

function useSubmissionHook() {
  const { edit } = useContext(FormikContext);
  const { submitForm } = useFormikContext();

  return useCallback(() => {
    if (edit && submitForm) {
      submitForm().finally(() => {});
    }
  }, [edit, submitForm]);
}

function FormSubmitter({ submit, setSubmit }) {
  const submitForm = useSubmissionHook();

  useEffect(() => {
    if (submit) {
      debounce(() => submitForm(), 100);
      setSubmit(false);
    }
  }, [submit]);

  return (
    <></>
  );
}

function useFormSubmissionHook() {
  const [submit, setSubmit] = useState(false);

  function Wrapper() {
    return (
      <FormSubmitter submit={submit} setSubmit={setSubmit} />
    );
  }

  return [Wrapper, () => setSubmit(true)];
}


export default useFormSubmissionHook;
