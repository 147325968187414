import { enqueueSnackbar } from 'notistack';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = async (text, successMsg = 'Copied successfully!', failMsg = 'Failed to copy!')=>{
	try {
		await navigator.clipboard.writeText(text);
		enqueueSnackbar(successMsg, { variant: 'success' });
		return text;
	} catch (error) {
		enqueueSnackbar(failMsg, { variant: 'error' });
		throw error;
	}
};
export const [useCopyToClipboard] = genServiceCallHooks(serviceFunction);

export default useCopyToClipboard;
