import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthToken } from 'src/redux/containers/Authorization';
import { setCompanySKUs, useCompanyId } from 'src/redux/containers/Companies';
import { getSKUList } from 'src/services/sku.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params = {}) => getSKUList(params);

export const [useCompanySKUsLazyQuery] = genServiceCallHooks(serviceFunction);

export function useCompanySKUsQuery(...params) {
  const dispatch = useDispatch();

  const [refetch, res] = useCompanySKUsLazyQuery(...params);

  const companyId = useCompanyId();
  const token = useAuthToken();

  useEffect(() => {
    if (token && companyId) {
      refetch({ id: companyId }).then((response) => {
        dispatch(setCompanySKUs(response ?? []));
      });
    }
  }, [companyId, dispatch, refetch, token]);

  return res;
}
