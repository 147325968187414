import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { Body1 } from 'src/components/Typography';
import { ASSET_TYPES } from 'src/consts/asset';
import useConfirm from 'src/utilities/hooks/useConfirm';

const useParentAssetHook = (enable = false, assets = [], upsertAssets) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [parentAsset, setParentAsset] = useState(null);

  const _isParent = (asset) => [ASSET_TYPES.pallet].includes(asset?.asset_type);
  const _isChild = (asset) => [ASSET_TYPES.cask].includes(asset?.asset_type);

  const _addChild = useCallback((asset) => {
    // Get the latest version of the parent asset
    const parent = assets.find((a) => a.asset_id === parentAsset.asset_id);
    if (!parent) {
      console.error('Parent asset not found', parentAsset.asset_id);
      return;
    }
    const children = parent.children ?? [];
    const exists = children.find((c) => c.asset_id === asset.asset_id);
    if (!exists) {
      const updatedParent = {
        ...parent,
        children: [...children, { asset_id: asset.asset_id ?? asset.path, rw_asset_id: asset.rw_asset_id, asset_type: asset.asset_type }]
      };
      upsertAssets(updatedParent);
      setParentAsset(updatedParent);
    }
  }, [parentAsset, assets]);

  const applyParentAsset = useCallback((asset) => {
    if (!asset) return asset;
    if (!enable || !_isChild(asset) || !parentAsset) {
      return asset;
    }

    console.log('Applying parent', parentAsset.rw_asset_id, asset.rw_asset_id);
    // See if there is a parent asset already
    if (asset.parent_asset_id && asset.parent_asset_id !== parentAsset.asset_id) {
      confirm({
        title: 'Changing parent',
        content: (
          <Body1>{`Would you like to change existing parent from ${asset.parent_asset?.rw_asset_id} to ${parentAsset?.rw_asset_id}?`}</Body1>
        ),
      })
      .then(() => {
        asset.parent_asset_id = parentAsset.path;
        asset.parent_asset = {
          rw_asset_id: parentAsset.rw_asset_id,
          asset_type: parentAsset.asset_type,
        };

        _addChild(asset);
      })
      .catch(() => {
        /** not sure we have to also add pallet or not or just put is as parent */
      });
    } else {
      asset.parent_asset_id = parentAsset.asset_id ?? parentAsset.path;
      asset.parent_asset = {
        rw_asset_id: parentAsset.rw_asset_id,
        asset_type: parentAsset.asset_type,
      };

      _addChild(asset);
    }

    return asset;
  }, [parentAsset, _addChild]);

  const changeParentAsset = useCallback((asset) => {
    if (!enable) return asset;
    if (!_isParent(asset)) {
      console.log('Applying parent asset', asset.rw_asset_id, parentAsset);
      return applyParentAsset(asset);
    }

    if (parentAsset) {
      console.log('Changing parent asset', asset.rw_asset_id);
      const id = asset.asset_id ?? asset.path;
      if (id === parentAsset.asset_id) {
        setParentAsset(null);
        enqueueSnackbar(`Closed parent ${asset.rw_asset_id}`, {
          variant: 'success',
        });
        return asset;
      }
    }
    console.log('Setting parent asset', asset.rw_asset_id);
    setParentAsset(asset);
    enqueueSnackbar(`Setting parent ${asset.rw_asset_id}`, {
      variant: 'info',
    });
    return asset;
  }, [enable, parentAsset, applyParentAsset]);

  const closeParent = useCallback((asset) => {
    if (!enable) return;
    if (!_isParent(asset)) return;

    console.log('Closing parent asset', asset.rw_asset_id);
    setParentAsset(null);
  }, [enable, parentAsset]);

  return [
    applyParentAsset,
    changeParentAsset,
    closeParent
  ]
};

export default useParentAssetHook;
