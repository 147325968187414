import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import {
	SLICE as authorization,
	reducer as authorizationReducer,
} from '../containers/Authorization';
import {
	reducer as companiesReducer,
	SLICE as companiesSliceName,
} from '../containers/Companies';
import {
	SLICE as network,
	reducer as networkReducer,
} from '../containers/NetworkSwitch';
import {
	SLICE as notification,
	reducer as notificationReducer,
} from '../containers/Notifications';
import {
	reducer as userReducer,
	SLICE as userSliceName,
} from '../containers/User';
import {
	reducer as appStateReducer,
	SLICE as appStateSliceName,
} from '../containers/AppState';

const rootReducer = combineReducers({
	[authorization]: authorizationReducer,
	[companiesSliceName]: companiesReducer,
	[network]: networkReducer,
	[notification]: notificationReducer,
	[userSliceName]: userReducer,
	[appStateSliceName]: appStateReducer,
});

export default rootReducer;
