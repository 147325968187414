import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material';
import Box from '@mui/material/Box';
import styles from 'src/components/Tasks/styles';
import TaskModal from 'src/components/Tasks/TaskModal';
import { H4 } from 'src/components/Typography';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { mr1 } from 'src/styles/margins';
import slugify from 'src/utilities/slugihfy';
import usePermissions from 'src/utilities/hooks/usePermissions';
import TaskCard from '../TaskCard';
import { BaseMenu } from './Menus';

function TaskGroup({ title, tasks, users, refresh, menuItems = [] }) {
	const { enqueueSnackbar } = useSnackbar();

	const { canApprove = false, assignTask = false } = usePermissions();

	const [selectAll, setSelectAll] = useState(false);
	const [selectedTasks, setSelectedTasks] = useState([]);
	const [actionKey, setActionKey] = useState(null);

	const onCloseModal = useCallback(
		(forceRefresh, reset) => {
			if (forceRefresh) {
				refresh();
			}
			if (reset) {
				setSelectAll(false);
				setSelectedTasks([]);
			}
			setActionKey(null);
		},
		[refresh],
	);

	const isSelected = useCallback(
		(t) => !!selectedTasks.find((a) => a.id === t.id),
		[selectedTasks],
	);

	useEffect(() => (selectAll ? setSelectedTasks(tasks) : setSelectedTasks([])), [selectAll]);

	const onClick = useCallback(
		(key) => {
			if (selectedTasks.length !== 0) {
				setActionKey(key);
			} else {
				enqueueSnackbar('Please select some tasks first!', {
					variant: 'warning',
				});
			}
		},
		[selectedTasks],
	);

	return (
		<>
			<Box sx={styles} className='column'>
				<Box className='column-header'>
					{selectAll ? (
						<CheckBoxRounded
							className='check'
							sx={{ ...mr1 }}
							onClick={() => setSelectAll(false)}
						/>
					) : (
						<CheckBoxOutlineBlankRounded
							className='check'
							sx={{ ...mr1 }}
							onClick={() => setSelectAll(true)}
						/>
					)}
					<H4 className='title' sx={{ flexGrow: 1 }}>
						{title}
					</H4>
					{menuItems && (
						<BaseMenu items={menuItems} idSuffix={slugify(title)} onClick={onClick} />
					)}
				</Box>
				<Box className='column-content'>
					{tasks.map((t) => (
						<TaskCard
							key={t.id}
							task={t}
							selected={isSelected(t)}
							onSelect={(add) =>
								setSelectedTasks((v) =>
									add ? [...v, t] : v.filter((x) => x.id !== t.id),
								)
							}
						/>
					))}
				</Box>
			</Box>
			{(canApprove || assignTask) && actionKey && (
				<TaskModal
					tasks={selectedTasks}
					users={users}
					onClose={onCloseModal}
					actionKey={actionKey}
				/>
			)}
		</>
	);
}

export default TaskGroup;
