import { Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import {
	FormikCheckBox,
	FormikDatePicker,
	FormikMeasuresField,
} from 'src/components/Forms/FormikForm';
import { FlexBox } from 'src/components/Layout/FlexBox';
import { H5 } from 'src/components/Typography';
import { MEASURE } from 'src/consts/measures';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import { mt2 } from 'src/styles/margins';

function SamplePropertiesImpl() {
	const { values } = useContext(FormikContext);

	return (
		<>
			<FormikMeasuresField
				name='sample_size'
				label='Sample Size'
				fullWidth
				measure={MEASURE.l_s}
			/>

			<Divider />

			<Stack>
				<FormikCheckBox name='enable' label={<H5>Sample Liquid</H5>} />
				<Collapse in={Boolean(values?.enable)}>
					<Stack spacing={2} sx={{ ...mt2 }}>
						<FlexBox alignItems='top'>
							<FormikDatePicker
								name='action_date'
								label='Action Date'
								fullWidth
							/>
						</FlexBox>
					</Stack>
				</Collapse>
			</Stack>

			<Divider />
			<FormikCheckBox name='duty_paid' label='Duty Paid' />
		</>
	);
}

export default function SampleProperties({ name = 'properties' }) {
	return (
		<FormikProvider path={name}>
			<SamplePropertiesImpl />
		</FormikProvider>
	);
}
