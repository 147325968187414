import { useMemo, useState } from 'react';
import Loading from 'src/components/Loading';
import LoadingContext from 'src/context/LoadingContext';

function LoadingProvider({ children }) {
	const [loading, setLoading] = useState(false);

	return (
		<>
			<Loading loading={loading} />
			<LoadingContext.Provider value={[loading, setLoading]}>{children}</LoadingContext.Provider>
		</>
	);
}

export default LoadingProvider;
