import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { FlexBox } from 'src/components/Layout/FlexBox';
import LiquidDisplay from 'src/components/properties/LiquidDisplay';
import { H5 } from 'src/components/Typography';

function StorageLossGain({ sources, destinations }) {
	const sv = useMemo(() => {
		let gbl = 0;
		let gla = 0;

		let abl = 0;
		let ala = 0;

		console.log(`sources ${JSON.stringify(sources)}`);

		sources.forEach((asset) => {
			const { properties = {} } = asset;
			const { liquid = {} } = properties;
			const { level = {} } = liquid;
			const {
				bl = 0,
				expected_bl = 0,
				updated_abv = 0,
				updated_tcf = 1,
				updated_bl = 0,
				// updated_la = 0,
				// actual_bl = 0,
			} = level;

			// bl: original
			// expected_bl: expected how much we took out
			// actual_bl: how much we took out
			// updated_bl: how much left on asset
			// storage gain/loss = expected_bl - actual_bl;
			const actual_bl = Number(bl) - Number(updated_bl);
			const bulk = Number(expected_bl) - Number(actual_bl);
			gbl += bulk;
			gla += bulk * ((Number(updated_abv) * Number(updated_tcf)) / 100);

			abl += Number(expected_bl);
			ala +=
				Number(expected_bl) *
				((Number(updated_abv) * Number(updated_tcf)) / 100);
		});
		console.log('Totals', gbl, gla, abl, ala);
		if (sources.length > 0) {
			return [gbl, gla, abl, ala];
		} else {
			return [];
		}
	}, [sources]);

	const dv = useMemo(() => {
		let abl = 0;
		let ala = 0;

		destinations.forEach((asset) => {
			const { properties = {} } = asset;
			const { liquid = {} } = properties;
			const { level = {} } = liquid;
			const {
				filled_bl = 0,
				// updated_la = 0,
				updated_abv = 0,
				updated_tcf = 0,
			} = level;

			// console.log('Dest Level', level);
			const ab = Math.abs(Number(filled_bl));
			abl += ab;
			ala += ab * ((Number(updated_abv) * Number(updated_tcf)) / 100);
		});
		console.log('Totals', abl, ala);
		if (destinations.length > 0) {
			return [abl, ala];
		} else {
			return [];
		}
	}, [destinations]);

	const ov = useMemo(() => {
		if (sv.length && dv.length) {
			const obl = dv[0] - sv[2];
			const ola = dv[1] - sv[3];
			return [obl, ola];
		} else {
			return [];
		}
	}, [sv, dv]);

	if (!sources || sources.length === 0) return;
	if (!destinations || destinations.length === 0) return;

	return (
		<Stack className='section'>
			<H5 className='section-header'>Gains & Losses</H5>
			<Box className='inventory'>
				<FlexBox className='inventory-footer'>
					{sv[0] >= 0 && (
						<LiquidDisplay
							label='Storage Gain'
							value={sv[0]}
							la={sv[1]}
							force
						/>
					)}
					{sv[0] < 0 && (
						<LiquidDisplay label='Storage Loss' value={sv[0]} la={sv[1]} />
					)}
					{ov[0] >= 0 && (
						<LiquidDisplay
							label='Operations Gain'
							value={ov[0]}
							la={ov[1]}
							force
						/>
					)}
					{ov[0] < 0 && (
						<LiquidDisplay label='Operations Loss' value={ov[0]} la={ov[1]} />
					)}
				</FlexBox>
			</Box>
		</Stack>
	);
}

export default StorageLossGain;
