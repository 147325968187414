import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthorizationToken } from 'src/redux/containers/Authorization';
import { setNotificationCount } from 'src/redux/containers/Notifications';
import { getNotifications } from 'src/services/notification.service';
import { genServiceCallHooks } from 'src/utilities/hooks/genHooks';

const serviceFunction = (params)=>getNotifications(params);
export const [useNotificationsLazyQuery, _useNotificationsQuery] = genServiceCallHooks(serviceFunction);

export function useNotificationsQuery(...params){
    const dispatch = useDispatch();
    const [refetch, res] = useNotificationsLazyQuery(...params);
    const token = useSelector(selectAuthorizationToken);
    useEffect(()=>{
        if(token){
            const fetchNotifications = () => refetch().then((response)=>{
                if (!response) {
                    return;
                }
                const { summary, requests } = response;
                const { pending_asset_count, rejected_asset_count, approved_asset_count } = summary;
                let _pendingAssetCount = parseInt(pending_asset_count ?? 0, 10) - (parseInt(rejected_asset_count ?? 0, 10) + parseInt(approved_asset_count ?? 0, 10));
                // If the result is negative, set it to zero
                if (_pendingAssetCount < 0) {
                  _pendingAssetCount = 0;
                }
                const { assigned_request_count } = requests;
                let assignedRequestCount = parseInt(assigned_request_count ?? 0, 10);
                // If the result is negative, set it to zero
                if (Number.isNaN(assignedRequestCount) || assignedRequestCount < 0) {
                assignedRequestCount = 0;
                }
                const total = assignedRequestCount + _pendingAssetCount;
                dispatch(setNotificationCount({ pendingAssets: _pendingAssetCount, assignedRequests: assignedRequestCount, totalCount: total }));
            });
            fetchNotifications();
            const timer = setInterval(() => {
                fetchNotifications();
            }, 1000*60*20);
            return () => clearInterval(timer);
        }
    },[dispatch, refetch, token]);

    return res;
}
