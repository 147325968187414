import { Box, LinearProgress } from '@mui/material';
import { clamp } from '@mui/x-data-grid/internals';
import { Body3 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import React, { useContext, useEffect, useState } from 'react';

const useProgress = () => {
	const [progress, setProgress] = useState(0);
	const [completion, setCompletion] = useState(0);

	function ProgressBar(props) {
		const { name, label, ...rest } = props;
		const { values } = useContext(FormikContext);
		const [error, setError] = useState(null);

		useEffect(() => {
			const total = values?.[name] || 0;
			setError(null);
			const totalValue = parseInt(total, 10);
			let p = 0;
			if (totalValue !== 0) p = (progress / parseInt(total, 10)) * 100.0;
			setCompletion(clamp(p, 0, 100));
			if (total <= 0) {
				setError(`${label} not set yet`);
				return;
			}
			if (progress <= 0) {
				setError('No allocations!');
				return;
			}
			if (progress > totalValue) {
				setError(`Over allocated by ${progress - total}!`);
			}
		}, [label, name, values]);

		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ width: '100%', mr: 1, height: '8px' }}>
					<LinearProgress
						variant='determinate'
						color={completion < 100 ? 'primary' : 'success'}
						{...rest}
						value={completion}
					/>
					{error && <Body3 color='error'>{error}</Body3>}
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Body3 color='secondary'>{`${Math.round(completion)}%`}</Body3>
				</Box>
			</Box>
		);
	}

	return [ProgressBar, { completion, setProgress }];
};

export default useProgress;
