import { Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from 'src/components/Auth/auth.styles';
import PasswordSignin from 'src/components/Auth/PasswordSignin';
import withDialogCard from 'src/components/Cards/DialogCard';
import { withEmptyLayout } from 'src/components/Layout/EmptyLayout';
import { Body3, H5 } from 'src/components/Typography';
import usePageTitle from 'src/utilities/hooks/usePageTitle';

function SignIn() {
	usePageTitle('Sign in');
	const [show, setShow] = useState(false);

	return (
		<Stack spacing={4} sx={styles} className='root'>
			<PasswordSignin />

			<Stack className='passkey'>
				<H5 sx={{ cursor: 'pointer' }} onClick={() => setShow((v) => !v)}>
					What is a passkey?
				</H5>
				<Collapse in={show}>
					<Stack spacing={1.5} className='passkey-help'>
						<Body3>
							Use the&nbsp;
							<Link to='https://fidoalliance.org/passkeys/'>passkey</Link>
							&nbsp;you created for this account to login. If you
							haven&apos;t yet setup a passkey, &nbsp;please first login to
							your account and then create one.
						</Body3>
						<Body3>
							Passkeys are an easier and safer alternative to passwords.
						</Body3>
						<Body3>
							With passkeys, your biometric information is never revealed to
							us. Biometric material never leaves your personal device.
						</Body3>
					</Stack>
				</Collapse>
			</Stack>

			<Body3>
				By continuing, you are indicating that you accept our{' '}
				<Link href='https://proofworks.com/terms'>Terms of Service</Link> and{' '}
				<Link href='https://proofworks.com/privacy'>Privacy Policy</Link> .
			</Body3>
		</Stack>
	);
}

export default withEmptyLayout(withDialogCard(SignIn));
