import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import SvgPasskey from 'src/components/icons/Passkey';

const styles = {
	'&.root': {
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0,
		borderBottomRightRadius: '10px',
		borderTopRightRadius: '10px',
		display: 'flex',
		alignItems: 'center',
		margin: '0.5rem',
		cursor: 'pointer',
		fill: '#5f5f5f',

		'.icon': {
			border: '1px solid red',
			width: '24px',
			height: '24px',
		},

		'&:hover': {
			fill: 'rgb(48, 111, 219)',
		},
	},
};

function PasskeyButton({ handleClick }) {
	return (
		<Tooltip title="Use the registered passkey to login instead of password">
			<Box
				onClick={handleClick}
				sx={styles}
				className="root"
			>
				<SvgPasskey className="icon" />
			</Box>
		</Tooltip>
	);
}

export default PasskeyButton;
