import { enqueueSnackbar } from 'notistack';
import { synchronize } from 'src/services/sync.service';
import { useServiceCallHook } from 'src/utilities/hooks/genHooks';
import useLoadingHook from './useLoadingHook';

const serviceFunction = (params)=>synchronize(params).then((uploadedCount)=>{
    enqueueSnackbar(
        `${uploadedCount} changeset${uploadedCount > 1 && 's'} uploaded`,
        { variant: 'success' }
    );
}).catch((error)=>{
    enqueueSnackbar(
        error?.message ?? 'Error on synchronizing data', {
        variant: 'error',
    });
    throw error;
});

const useSyncChangesMutation = useServiceCallHook(serviceFunction, {useLoadingHook});

export default useSyncChangesMutation;
