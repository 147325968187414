import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FormikCheckBox } from 'src/components/Forms/FormikForm';
import BulkLiquidLevel from 'src/components/Liquid/BulkLiquidLevel';
import liquidLevelFields from 'src/components/Liquid/liquidLevelFields';
import LOALiquidLevel from 'src/components/Liquid/LOALiquidLevel';
import LiquidSource from 'src/components/LiquidSource';
import skuFields from 'src/components/SKUSelector/skuFields';
import { H5 } from 'src/components/Typography';
import FormikContext from 'src/context/FormikContext';
import { FormikProvider } from 'src/providers/FormikProvider';
import { useContext, useEffect } from 'react';
import * as yup from 'yup';
import LiquidWeight from './LiquidWeight/LiquidWeight';
import liquidWeightFields from './LiquidWeight/liquidWeightFields';

export function liquidPropertyFields(
	params = {},
	measures = {},
	enableWeights = false,
) {
	const {
		enable = true,
		liquid: tags = [],
		date = Date.now(),
		batch = '',
		level = {},
		weight = {},
		liquid_type = '',
		sku_id = '',
		sku = {},
	} = params;

	return {
		enable: [enable, yup.bool()],
		date: [
			date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.required('Production date is required!'),
				otherwise: (schema) => schema,
			}),
		],
		batch: [batch, yup.string()],
		liquid: [tags, yup.array().of(yup.string())],
		liquid_type: [liquid_type, yup.string()],
		...skuFields(sku_id, sku),
		level: liquidLevelFields(level, measures),
		...(enableWeights ? { weight: liquidWeightFields(weight, measures) } : {}),
	};
}

function LiquidPropertiesImpl({
	disabled = false,
	useWeight = false,
	batchRequired = false,
	useLiquidSelector = true,
	useBulk = true,
}) {
	const { values, setFieldValue } = useContext(FormikContext);

	useEffect(() => {
		if (!values?.enable) {
			process.nextTick(() => {
				setFieldValue('level.enable', false);
				if (useWeight) setFieldValue('weight.enable', false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.weight?.enable, values?.enable, useWeight]);

	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				label={<H5>Liquid Properties</H5>}
				disabled={disabled}
			/>

			<Collapse in={!!values?.enable} sx={{ paddingTop: '0.5rem' }}>
				<Stack spacing={2}>
					<LiquidSource
						disabled={disabled}
						batchRequired={batchRequired}
						useLiquidSelector={useLiquidSelector}
					/>
					{useBulk && <BulkLiquidLevel name='level' disabled={disabled} />}
					{!useBulk && <LOALiquidLevel name='level' disabled={disabled} />}
					{useWeight && <LiquidWeight name='weight' disabled={disabled} />}
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function LiquidProperties({
	name,
	disabled = false,
	useWeight = false,
	batchRequired = false,
	useType = true,
	useBulk = true,
}) {
	return (
		<FormikProvider path={name}>
			<LiquidPropertiesImpl
				disabled={disabled}
				useWeight={useWeight}
				batchRequired={batchRequired}
				useType={useType}
				useBulk={useBulk}
			/>
		</FormikProvider>
	);
}
