import { APP_API_GATEWAY } from 'src/consts/config';
import fetchGet from 'src/utilities/fetchGet';
import fetchPost from 'src/utilities/fetchPost';
import objectToQueryString from 'src/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/stats`;

export const getOverview = async (params, headers = {}) =>
	fetchGet(`${BASE}/overview${objectToQueryString(params)}`, headers);

export const getLiquidOverview = async (payload, headers = {}) =>
	fetchPost(`${BASE}/overview/liquid`, headers, payload);

export const getReportOverview = async (payload, headers = {}) =>
	fetchPost(`${BASE}/overview/report`, headers, payload);

export const getAssetsOverview = async (params, headers = {}) =>
	fetchGet(`${BASE}/overview/assets${objectToQueryString(params)}`, headers);
