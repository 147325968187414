/* eslint-disable  implicit-arrow-linebreak */
import { Typography } from '@mui/material';
import { forwardRef } from 'react';
import { f6 } from 'src/styles/fonts';
import splitToParagraphs from 'src/utilities/splitToParagraphs';

export const H1 = forwardRef((props, ref) => <Typography ref={ref} variant='h1' {...props} />);
H1.displayName = 'H1';

export const H2 = forwardRef((props, ref) => <Typography ref={ref} variant='h2' {...props} />);
H2.displayName = 'H2';

export const H3 = forwardRef((props, ref) => <Typography ref={ref} variant='h3' {...props} />);
H3.displayName = 'H3';

export const H4 = forwardRef((props, ref) => <Typography ref={ref} variant='h4' {...props} />);
H4.displayName = 'H4';

export const H5 = forwardRef((props, ref) => <Typography ref={ref} variant='h5' {...props} />);
H5.displayName = 'H5';

export const H6 = forwardRef((props, ref) => <Typography ref={ref} variant='h6' {...props} />);
H6.displayName = 'H6';

export const Button = forwardRef((props, ref) => (
	<Typography ref={ref} variant='button' {...props} />
));
Button.displayName = 'Button';

export const Body1 = forwardRef((props, ref) => (
	<Typography ref={ref} variant='body1' {...props} />
));
Body1.displayName = 'Body1';

export const Body2 = forwardRef((props, ref) => (
	<Typography ref={ref} variant='body2' {...props} />
));
Body2.displayName = 'Body2';

export const Body3 = forwardRef(({ sx, ...props }, ref) => (
	<Typography ref={ref} variant='body1' sx={{ ...f6, ...props?.sx }} {...props} />
));
Body3.displayName = 'Body3';

export const Caption = forwardRef((props, ref) => (
	<Typography ref={ref} variant='caption' {...props} />
));
Caption.displayName = 'Caption';

export const Overline = forwardRef((props, ref) => (
	<Typography ref={ref} variant='overline' {...props} />
));
Overline.displayName = 'Overline';

export const Subtitle1 = forwardRef((props, ref) => (
	<Typography ref={ref} variant='subtitle1' {...props} />
));
Subtitle1.displayName = 'Subtitle1';

export const Subtitle2 = forwardRef((props, ref) => (
	<Typography ref={ref} variant='subtitle2' {...props} />
));
Subtitle2.displayName = 'Subtitle2';

export const Paragraphs = forwardRef((props, ref) => {
	const { text, as = Body1, ...rest } = props;
	if (!text || text.length === 0) return null;
	const Component = as;
	// if array or string
	let paras = text;
	// if array of one, unwrap
	if (typeof text === 'object' && text.length === 1) {
		paras = text[0];
	}
	// if string, split. if already array, assumes no need for splitting
	if (typeof paras === 'string') {
		paras = splitToParagraphs(paras);
	}
	// eslint-disable-next-line react/no-array-index-key
	return paras.map((p, i) => (
		<Component key={i} ref={ref} {...rest}>
			{p}
		</Component>
	));
});
Paragraphs.displayName = 'Paragraphs';
