import Box from '@mui/material/Box';
import { forwardRef } from 'react';
// import sanityClient from 'src/utilities/sanityClient';

// stop the wrapping span from adding space at bottom!
const nextImgBase = {
	lineHeight: 0,
};

const Image = forwardRef((props, ref) => {
	// const imageProps = useNextSanityImage(
	//   sanityClient(),
	//   props.data,
	// );
	const { sx, raw = false, ...rest } = props;
	return raw ? (
		<img
			// {...imageProps}
			{...rest}
			style={sx?.imgTag}
		/>
	) : (
		<Box component="img" ref={ref} sx={{ ...nextImgBase, ...sx, ...sx?.imgTag }} {...rest} />
	);
});
Image.displayName = 'Image';

export default Image;
