import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from './consts';

const initialState = {
  active_item: null,
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    clearState() {
      return { ...initialState };
    },
    setActiveItem(state, { payload }) {
      console.log('Setting active item', payload);
      state.active_item = payload;
    },
  },
});

export const { reducer, actions } = slice;
export const { clearState, setActiveItem } = actions;
