import { TASK_TYPES } from 'src/consts/tasks';
import { BATCH_TYPE } from 'src/consts/batch';
import { br3 } from 'src/styles/border';
import { bg_surface_4 } from 'src/styles/colors';
import { f6 } from 'src/styles/fonts';
import { mb1, mv1 } from 'src/styles/margins';
import { pa2 } from 'src/styles/padding';

const styles = {
	[`&[action="${TASK_TYPES.cask}"]`]: { '--action-color': '#4C4' },
	[`&[action="${TASK_TYPES.ibc}"]`]: { '--action-color': '#4C4' },
	[`&[action="${TASK_TYPES.pallet}"]`]: { '--action-color': '#4C4' },
	[`&[action="${TASK_TYPES.pick}"]`]: { '--action-color': '#00a89d' },
	[`&[action="${TASK_TYPES.container}"]`]: { '--action-color': '#44880a' },
	[`&[action="${TASK_TYPES.regauge}"]`]: { '--action-color': '#1a3352' },
	[`&[action="${TASK_TYPES.sample}"]`]: { '--action-color': '#fd3b02' },
	[`&[action="${TASK_TYPES.ship}"]`]: { '--action-color': '#C9C9C2' },
	[`&[action="${TASK_TYPES.transfer}"]`]: { '--action-color': '#D0B158' },
	[`&[action="${BATCH_TYPE}"]`]: { '--action-color': '#616e79' },
	[`&[action="${TASK_TYPES.tanker}"]`]: { '--action-color': '#4C4' },
	[`&[action="${TASK_TYPES.duty_transfer}"]`]: { '--action-color': '#1a1a1a' },
	[`&[action="${TASK_TYPES.duty_submission}"]`]: {
		'--action-color': '#1a1a1a',
	},
	[`&[action="${TASK_TYPES.filling_tank}"]`]: { '--action-color': '#c24f1e' },
	[`&[action="${TASK_TYPES.restock}"]`]: { '--action-color': '#6e0606' },
	[`&[action="${TASK_TYPES.change_ownership}"]`]: {
		'--action-color': '#60b4f1',
	},
	[`&[action="${TASK_TYPES.inventory_audit}"]`]: {
		'--action-color': '#7a136d',
	},
	[`&[action="${TASK_TYPES.delivery}"]`]: { '--action-color': '#a99223' },
	[`&[action="${TASK_TYPES.production_run}"]`]: { '--action-color': '#775b0d' },
	'&.root': {
		...br3,
		...mv1,
		...bg_surface_4,
		boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12)`,

		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
		backgroundColor: '#fff',
		overflow: 'hidden',
		transition: 'all 0.2s',
		height: 'min-content',

		'&:first-of-type': {
			marginLeft: 0,
		},

		'&:hover': {
			cursor: 'pointer',
		},
	},
	'.tab': {
		alignItems: 'stretch',
		borderLeft: '0.2rem solid var(--action-color)',
		borderRight: '0.2rem solid var(--action-color)',
	},
	'.content': {
		...pa2,
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	'.asset-id': {
		...f6,
		...mb1,
		display: 'flex',
		alignItems: 'center',
		svg: {
			verticalAlign: 'middle',
		},
	},
};

export default styles;
