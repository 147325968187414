/**
 * Convert from Asset to SKUStorageItem
 * @param asset
 */
import { ASSET_PROCESSED_STATUS } from 'src/consts/requests';
import { SKU_TYPES } from 'src/consts/sku';

// export interface SKUStorageItem {
//   amount: number;
//   asset_type: string;
//   asset_id: string;
//   rw_asset_id: string;
// }

const toSKUStorageItem = (asset, sku_type, unit) => {
	// Inbound asset details
	console.log(' --> SKU Storage Item', sku_type, unit);

	const {
		path,
		token_type,
		asset_type,
		token_name,
		rw_asset_id,
		unique_location_id,
		processed = ASSET_PROCESSED_STATUS.PENDING,
	} = asset;

	const itemUnit = ![
		SKU_TYPES.SERVICE,
		SKU_TYPES.FINISHED,
		SKU_TYPES.TRACKED,
		SKU_TYPES.CONSUMABLE,
	].includes(sku_type)
		? unit
		: null;

	// Return the SKUItem
	return {
		asset_id: path,
		asset_type: asset_type ?? token_type,
		rw_asset_id: rw_asset_id ?? token_name,
		unique_location_id,
		processed,
		amount: 0,
		unit: itemUnit,
	};
};

export default toSKUStorageItem;
